import axios from 'axios';
import domain from '@/environment';
import VariableTH from '../../models/VariableTH';
const state = {
  tableVariableTh: [],
  employeeActiveParamsTh: [],
  loadingVariableTh: false,
  loadingEmployeeVariableTh: false,
  errorVariableTh: null,
  errorEmployeeVariableTh: null,
  totalRowtableVariableTh: null
};
const getters = {
  getTableVariableTh: state => state.tableVariableTh,
  getEmployeeActiveParamsTh: state => state.employeeActiveParamsTh,
  getLoadingVariableTh: state => state.loadingVariableTh,
  getLoadingEmployeeVariableTh: state => state.loadingEmployeeVariableTh,
  getErrorVariableTh: state => state.errorVariableTh,
  getErrorEmployeeVariableTh: state => state.errorEmployeeVariableTh,
  getTotalRowtableVariableTh: state => state.totalRowtableVariableTh
};
const mutations = {
  SET_VARIAVLES_TH(state, payload) {
    state.tableVariableTh = payload.map(item => VariableTH.create(item));
  },
  SET_LOADING_VARIAVLES_TH(state, payload) {
    state.loadingVariableTh = payload;
  },
  SET_LOADING_EMPLOYEE_VARIAVLES_TH(state, payload) {
    state.loadingEmployeeVariableTh = payload;
  },
  SET_ERROR_VARIAVLES_TH(state, payload) {
    state.errorVariableTh = payload;
  },
  SET_ERROR_EMPLOYEE_VARIAVLES_TH(state, payload) {
    state.errorEmployeeVariableTh = payload;
  },
  NEW_VARIAVLES_TH(state, payload) {
    payload.map(item => {
      state.tableVariableTh.push(VariableTH.create(item));
    });
  },
  SET_EMPLOYEE_ACTIVE_PARAMS_TH(state, payload) {
    state.employeeActiveParamsTh = payload;
  },
  SET_TOTAL_ROW_TABLE_VARIABLES_TH_CONFIG(state, payload) {
    state.totalRowtableVariableTh = payload;
  },
  UPDATE_VARIAVLES_TH(state, payload) {
    for (let index = 0; index < state.tableVariableTh.length; index++) {
      if (state.tableVariableTh[index].id == payload.userToUpdate.id) {
        state.tableVariableTh[index].update(payload.response);
      }
    }
  }
};
const actions = {
  //get user active in th project
  async getActiveEmployeeForThVariable({ commit }, payload) {
    commit('SET_LOADING_EMPLOYEE_VARIAVLES_TH', true);
    try {
      const response = await axios.get(
        domain + '/api/salaryManagement/online_employee',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_EMPLOYEE_ACTIVE_PARAMS_TH', response.data.data);
      commit('SET_LOADING_EMPLOYEE_VARIAVLES_TH', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_EMPLOYEE_VARIAVLES_TH', error.response.data.message);
      } else {
        commit('SET_ERROR_EMPLOYEE_VARIAVLES_TH', ['Une erreur est survenue ']);
      }
      commit('SET_LOADING_EMPLOYEE_VARIAVLES_TH', false);

      return false;
    }
  },
  //fetch all config params th
  async fetchAllParamsThConfig({ commit }, payload) {
    commit('SET_LOADING_VARIAVLES_TH', true);
    let params = {
      year: payload.year,
      page: payload.page,
      per_page: payload.per_page
    };
    if (payload.id != null) {
      params.employee_id = payload.id.id;
    }
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    try {
      const response = await axios.get(domain + '/api/salaryManagement/all', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_VARIAVLES_TH', response.data.data.data);
      commit(
        'SET_TOTAL_ROW_TABLE_VARIABLES_TH_CONFIG',
        response.data.data.total
      );
      commit('SET_LOADING_VARIAVLES_TH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_VARIAVLES_TH', error.response.data.error);
      } else {
        commit('SET_ERROR_VARIAVLES_TH', ['Une erreur est survenue']);
      }
      commit('SET_LOADING_VARIAVLES_TH', false);
      return false;
    }
  },
  //new config params th
  async newConfigVariablesTh({ commit }, payload) {
    try {
      payload.value_m2 = parseFloat((payload.value_m2 + '').replace(',', '.'));
      payload.vente = parseFloat((payload.vente + '').replace(',', '.'));
      payload.previsite = parseFloat(
        (payload.previsite + '').replace(',', '.')
      );
      payload.interne = parseFloat((payload.interne + '').replace(',', '.'));
      payload.externe = parseFloat((payload.externe + '').replace(',', '.'));
      payload.SD = parseFloat((payload.SD + '').replace(',', '.'));
      payload.RPLUS = parseFloat((payload.RPLUS + '').replace(',', '.'));
      payload.plafond = parseFloat((payload.plafond + '').replace(',', '.'));
      payload.iti = parseFloat((payload.iti + '').replace(',', '.'));
      payload.ite = parseFloat((payload.ite + '').replace(',', '.'));
      payload.RR = parseFloat((payload.RR + '').replace(',', '.'));
      payload.RO = parseFloat((payload.RO + '').replace(',', '.'));
      payload.balonThermo = parseFloat(
        (payload.balonThermo + '').replace(',', '.')
      );
      payload.fix = parseFloat((payload.fix + '').replace(',', '.'));
      payload.igd = parseFloat((payload.igd + '').replace(',', '.'));
      payload.additional_prime = parseFloat(
        (payload.additional_prime + '').replace(',', '.')
      );
      payload.vmc = parseFloat((payload.vmc + '').replace(',', '.'));
      payload.pac = parseFloat((payload.pac + '').replace(',', '.'));
      payload.prime_qualite = parseFloat(
        (payload.prime_qualite + '').replace(',', '.')
      );
      payload.prime_objectif = parseFloat(
        (payload.prime_objectif + '').replace(',', '.')
      );
      payload.prime_sav = parseFloat(
        (payload.prime_sav + '').replace(',', '.')
      );
      payload.other_prime_value = parseFloat(
        (payload.other_prime_value + '').replace(',', '.')
      );
      payload.deducation = parseFloat(
        (payload.deducation + '').replace(',', '.')
      );
      const response = await axios.post(
        domain + '/api/salaryManagement/create',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('NEW_VARIAVLES_TH', response.data.data);
      return {
        succes: true
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une error est servenue']
        };
      }
    }
  },
  //update config params th for user
  async updateConfigVariablesThForUser({ commit }, payload) {
    try {
      payload.value_m2 = parseFloat((payload.value_m2 + '').replace(',', '.'));
      payload.vente = parseFloat((payload.vente + '').replace(',', '.'));
      payload.previsite = parseFloat(
        (payload.previsite + '').replace(',', '.')
      );
      payload.interne = parseFloat((payload.interne + '').replace(',', '.'));
      payload.externe = parseFloat((payload.externe + '').replace(',', '.'));
      payload.SD = parseFloat((payload.SD + '').replace(',', '.'));
      payload.RPLUS = parseFloat((payload.RPLUS + '').replace(',', '.'));
      payload.plafond = parseFloat((payload.plafond + '').replace(',', '.'));
      payload.iti = parseFloat((payload.iti + '').replace(',', '.'));
      payload.ite = parseFloat((payload.ite + '').replace(',', '.'));
      payload.RR = parseFloat((payload.RR + '').replace(',', '.'));
      payload.RO = parseFloat((payload.RO + '').replace(',', '.'));
      payload.balonThermo = parseFloat(
        (payload.balonThermo + '').replace(',', '.')
      );
      payload.fix = parseFloat((payload.fix + '').replace(',', '.'));
      payload.igd = parseFloat((payload.igd + '').replace(',', '.'));
      payload.additional_prime = parseFloat(
        (payload.additional_prime + '').replace(',', '.')
      );
      payload.vmc = parseFloat((payload.vmc + '').replace(',', '.'));
      payload.pac = parseFloat((payload.pac + '').replace(',', '.'));
      payload.prime_qualite = parseFloat(
        (payload.prime_qualite + '').replace(',', '.')
      );
      payload.prime_objectif = parseFloat(
        (payload.prime_objectif + '').replace(',', '.')
      );
      payload.prime_sav = parseFloat(
        (payload.prime_sav + '').replace(',', '.')
      );
      payload.other_prime_value = parseFloat(
        (payload.other_prime_value + '').replace(',', '.')
      );
      payload.deducation = parseFloat(
        (payload.deducation + '').replace(',', '.')
      );
      const response = await axios.post(
        domain + '/api/salaryManagement/update',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_VARIAVLES_TH', {
        response: response.data.data,
        userToUpdate: payload
      });
      return {
        succes: true
      };
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.message
      ) {
        return {
          succes: false,
          error: error.response.data.errors.message
        };
      } else {
        return {
          succes: false,
          error: ['Une error est servenue']
        };
      }
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
