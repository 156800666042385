import axios from 'axios';
import domain from '@/environment';
import fraisWebAbondance from '../../models/WebAbondanceFraisTh';
const prefixFraisWebAbondance = 'frais';
let requestFraisWa = null;
const state = {
  webAbondanceFraisData: [],
  loadingWebAbondanceFrais: false,
  errorWebAbondanceFrais: null,
  webAbondanceFraisDataTotal: null
};
const getters = {
  getWebAbondanceFraisData: state => state.webAbondanceFraisData,
  getLoadingWebAbondanceFrais: state => state.loadingWebAbondanceFrais,
  getErrorWebAbondanceFrais: state => state.errorWebAbondanceFrais,
  getWebAbondanceFraisDataTotal: state => state.webAbondanceFraisDataTotal
};
const mutations = {
  SET_DATA_FRAIS_WEB_ABONDANCE(state, payload) {
    state.webAbondanceFraisData = payload.map(item =>
      fraisWebAbondance.create(item)
    );
  },
  SET_TOTAL_DATA_FRAIS_WEB_ABONDANCE(state, payload) {
    state.webAbondanceFraisDataTotal = payload;
  },
  SET_LOADING_FRAIS_WEB_ABONDANCE(state, payload) {
    state.loadingWebAbondanceFrais = payload;
  },
  SET_ERROR_FRAIS_WEB_ABONDANCE(state, payload) {
    state.errorWebAbondanceFrais = payload;
  },
  SET_DATA_FRAIS_VISION_FILIALE_DETAILS_WA(state, payload) {
    payload.row.update(payload.response);
  }
};
const actions = {
  async getAllWebAbondanceFrais({ commit }, payload) {
    commit('SET_ERROR_FRAIS_WEB_ABONDANCE', null);
    commit('SET_LOADING_FRAIS_WEB_ABONDANCE', true);
    const params = {
      year: payload.year,
      page: payload.page,
      perPage: payload.perPage
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.filiale != null) {
      params.filiale = payload.filiale;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.week != null) {
      params.week = payload.week;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }
    try {
      if (requestFraisWa) {
        commit('SET_ERROR_FRAIS_WEB_ABONDANCE', null);
        requestFraisWa.cancel('cancel-request');
      }
      requestFraisWa = axios.CancelToken.source();
      const response = await axios.get(
        domain + '/api/' + prefixFraisWebAbondance + '/webAbondance_vision',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestFraisWa.token
        }
      );
      commit('SET_DATA_FRAIS_WEB_ABONDANCE', response.data.data);
      commit('SET_TOTAL_DATA_FRAIS_WEB_ABONDANCE', response.data.count);
      commit('SET_LOADING_FRAIS_WEB_ABONDANCE', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_FRAIS_WEB_ABONDANCE', error.response.data.errors);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_LOADING_FRAIS_WEB_ABONDANCE', false);
          commit('SET_ERROR_FRAIS_WEB_ABONDANCE', ['Une erreur est servenue']);
        }
      }

      return false;
    }
  },
  async getDataForRowFraisWA({ commit }, payload) {
    payload.item.loadingDetailt = true;
    commit('SET_ERROR_FRAIS_WEB_ABONDANCE', null);
    const params = {
      year: payload.year,
      page: payload.page,
      perPage: payload.perPage,
      filiale: payload.item.nom
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.week != null) {
      params.week = payload.week;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }
    try {
      const response = await axios.get(
        domain + '/api/frais/webAbondance_vision_projects',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_DATA_FRAIS_VISION_FILIALE_DETAILS_WA', {
        response: response.data,
        row: payload.item
      });
      payload.item.loadingDetailt = false;
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_FRAIS_WEB_ABONDANCE', error.response.data.errors);
      } else {
        commit('SET_ERROR_FRAIS_WEB_ABONDANCE', ['Une erreur est servenue']);
      }
      payload.item.loadingDetailt = false;

      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
