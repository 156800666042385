import FraisBureauEtudeProjectTh from './FraisBureauEtudeProjectTh';
export default class FraisBureauEtudeTh {
  constructor(data) {
    this.name = data.name;
    this.kynan = data.kynan;
    this.alteo = data.alteo;
    this.be = data.be;
    this.total = data.total;
    this.check = false;
    this.loadingDetailt = false;
    this.projects = data.projects;
  }
  update(data) {
    this.projects = data.projects.map(item =>
      FraisBureauEtudeProjectTh.create(item)
    );
  }
  static create(data) {
    return new FraisBureauEtudeTh(data);
  }
}
