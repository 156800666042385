export default class Paie {
  constructor(data) {
    this.id = data.id;
    this.variable_101 = data.variable_101;
    this.variable_102_Standard = data.variable_102_Standard;
    this.variable_102_ITI = data.variable_102_ITI;
    this.variable_102_ITI_placo = data.variable_102_ITI_placo;
    this.variable_103 = data.variable_103;
    this.variable_vmc = data.variable_vmc;
    this.variable_pac = data.variable_pac;
    this.IGD = data.IGD;
    this.additional_prime = data.additional_prime;
    this.fix = data.fix;
    this.prime_qualite = data.prime_qualite;
    this.prime_objectif = data.prime_objectif;
    this.prime_sav = data.prime_sav;
    this.other_prime_name = data.other_prime_name;
    this.other_prime_value = data.other_prime_value;
    this.deducation = data.deducation;
    this.employee = data.employee;
    this.semaine = data.semaine;
    this.type = data.type;
    this.variable_source = data.variable_source;
    this.source = data.source;
    this.variable_depot = data.variable_depot;
    this.depot = data.depot;
    this.total = data.total;
    this.validation = data.validation;
    this.montant_depot = data.montant_depot;
    this.montant_source = data.montant_source;
    this.number_depot = data.number_depot;
    this.number_source = data.number_source;
    this.comment = data.comment;
    this.totalm2 = data.totalm2;
    this.total_variables = data.total_variables;
    this.statue = data.statue;
    this.payed_at = data.payed_at;
    this.bill = data.bill;
    this.facture_type = data.facture_type;
    this.TH_ITE = data.TH_ITE;
    this.TH_ITI = data.TH_ITI;
    this.TH_PAC_Balon_thermo = data.TH_PAC_Balon_thermo;
    this.TH_PAC_RO = data.TH_PAC_RO;
    this.TH_RR = data.TH_RR;
    this.TH_condition = data.TH_condition;
    this.TH_externe = data.TH_externe;
    this.TH_interne = data.TH_interne;
    this.TH_value = data.TH_value;
    this.TH_total = data.TH_total;
    this.total_variables = data.total_variables;
    this.interne = data.interne;
    this.externe = data.externe;
    this.vente = data.vente;
    this.previsite = data.previsite;
    this.surface = data.surface;
    this.surface_iti = data.surface_iti;
    this.interne_mpr_oui = data.interne_mpr_oui;
    this.externe_mpr_oui = data.externe_mpr_oui;
    this.interne_mpr_non = data.interne_mpr_non;
    this.externe_mpr_non = data.externe_mpr_non;
    this.previsite_mpr_oui = data.previsite_mpr_oui;
    this.vente_mpr_oui = data.vente_mpr_oui;
    this.previsite_mpr_non = data.previsite_mpr_non;
    this.vente_mpr_non = data.vente_mpr_non;
    this.surface_mpr_oui = data.surface_mpr_oui;
    this.surface_iti_mpr_oui = data.surface_iti_mpr_oui;
    this.surface_mpr_non = data.surface_mpr_non;
    // this.surface_iti_mpr_non = data.surface_iti_mpr_non;
    this.TH_vente = data.TH_vente;
    this.TH_previsite = data.TH_previsite;
  }
  update(data) {
    this.variable_101 = data.variable_101;
    this.variable_102_Standard = data.variable_102_Standard;
    this.variable_102_ITI = data.variable_102_ITI;
    this.variable_102_ITI_placo = data.variable_102_ITI_placo;
    this.variable_103 = data.variable_103;
    this.variable_vmc = data.variable_vmc;
    this.variable_pac = data.variable_pac;
    this.IGD = data.IGD;
    this.additional_prime = data.additional_prime;
    this.fix = data.fix;
    this.prime_qualite = data.prime_qualite;
    this.prime_objectif = data.prime_objectif;
    this.prime_sav = data.prime_sav;
    this.other_prime_name = data.other_prime_name;
    this.other_prime_value = data.other_prime_value;
    this.deducation = data.deducation;
    this.variable_source = data.variable_source;
    this.source = data.source;
    this.variable_depot = data.variable_depot;
    this.depot = data.depot;
    this.validation = data.validation;
    this.comment = data.comment;
    this.totalm2 = data.totalm2;
    this.total_variables = data.total_variables;
    this.total = data.total;
    this.statue = data.statue;
    this.payed_at = data.payed_at;
    this.bill = data.bill;
    this.facture_type = data.facture_type;
    this.TH_ITE = data.TH_ITE;
    this.TH_ITI = data.TH_ITI;
    this.TH_PAC_Balon_thermo = data.TH_PAC_Balon_thermo;
    this.TH_PAC_RO = data.TH_PAC_RO;
    this.TH_RR = data.TH_RR;
    this.TH_condition = data.TH_condition;
    this.TH_externe = data.TH_externe;
    this.TH_interne = data.TH_interne;
    this.TH_value = data.TH_value;
    this.TH_total = data.TH_total;
    this.total_variables = data.total_variables;
    this.interne = data.interne;
    this.externe = data.externe;
    this.vente = data.vente;
    this.previsite = data.previsite;
    this.surface = data.surface;
    this.surface_iti = data.surface_iti;
    this.interne_mpr_oui = data.interne_mpr_oui;
    this.externe_mpr_oui = data.externe_mpr_oui;
    this.interne_mpr_non = data.interne_mpr_non;
    this.externe_mpr_non = data.externe_mpr_non;
    this.previsite_mpr_oui = data.previsite_mpr_oui;
    this.vente_mpr_oui = data.vente_mpr_oui;
    this.previsite_mpr_non = data.previsite_mpr_non;
    this.vente_mpr_non = data.vente_mpr_non;
    this.surface_mpr_oui = data.surface_mpr_oui;
    this.surface_iti_mpr_oui = data.surface_iti_mpr_oui;
    this.surface_mpr_non = data.surface_mpr_non;
    // this.surface_iti_mpr_non = data.surface_iti_mpr_non;
    this.TH_vente = data.TH_vente;
    this.TH_previsite = data.TH_previsite;
  }
  static create(data) {
    return new Paie(data);
  }
}
