import axios from 'axios';
import domain from '@/environment';
import DocumentsSociete from '../../models/DocumentsSociete';
const state = {
  loadingUploadFileDocumentsSociete: false,
  errorLoadingFile: null,
  chargementDocDocumentsSociete: false,
  documentsSociete: null
};
const getters = {
  getLoadingUploadFileDocumentsSociete: state =>
    state.loadingUploadFileDocumentsSociete,
  getErrorLoadingFileSociete: state => state.errorLoadingFileSociete,
  getChargementDocDocumentsSociete: state =>
    state.chargementDocDocumentsSociete,
  getAlldocumentsSociete: state => state.documentsSociete
};
const mutations = {
  SET_LOADING_UPLOAD_FILE_SOCIETE(state, payload) {
    state.loadingUploadFileDocumentsSociete = payload;
  },
  ADD_NEW_FILE_SOCIETE(state, payload) {
    for (let index in payload.files) {
      if (payload.files[index].length != 0) {
        payload.files[index].map(item => {
          const result = state.documentsSociete.files[index].find(
            ({ id }) => id === item.id
          );
          if (result === undefined) {
            state.documentsSociete.files[index].push(item);
          }
        });
      }
    }
  },
  SET_ERROR_IN_LOADING_FILE_SOCIETE(state, payload) {
    state.errorLoadingFile = payload;
  },
  DLETE_SOCIETE_FILE(state, payload) {
    const type = payload.file.type;
    for (
      let index = 0;
      index < state.documentsSociete.files[type].length;
      index++
    ) {
      if (state.documentsSociete.files[type][index].id == payload.file.id) {
        state.documentsSociete.files[type].splice(index, 1);
        break;
      }
    }
  }
};
const actions = {
  async uploadFilesDocumentsSociete({ commit }, payload) {
    commit('SET_ERROR_IN_LOADING_FILE_SOCIETE', null);
    commit('SET_LOADING_UPLOAD_FILE_SOCIETE', true);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.state.User.access_token}`
      }
    };
    try {
      const responce = await axios.post(
        domain + `/api/addRegieFiles`,
        payload,
        config
      );
      commit('ADD_NEW_FILE_SOCIETE', responce.data.regie);
      commit('SET_LOADING_UPLOAD_FILE_SOCIETE', false);

      return true;
    } catch (error) {
      commit('SET_ERROR_IN_LOADING_FILE_SOCIETE', error.response.data.errors);
      commit('SET_LOADING_UPLOAD_FILE_SOCIETE', false);

      return false;
    }
  },

  async deleteDocumentsSocieteFile({ commit }, payload) {
    try {
      const responce = await axios.post(
        domain + `/api/deleteRegieFile/${payload.file.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DLETE_SOCIETE_FILE', payload);
      return true;
    } catch (error) {
      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
