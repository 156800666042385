import axios from 'axios';
import domain from '@/environment';
import Facture from '../../models/Facture';
const state = {
  RegieFactureTable: [],
  totalRowFactureRegie: null,
  chargementFactureRegie: false,
  chargementUploadFactureRegie: null,
  allRegie: [],
  errorUploadFacture: null,
  loadingValidationCoutRegie: false,
  RegieValidationCoutTable: [],
  totalRowValidationCoutRegie: null,
  commercialRegieFactureTable: [],
  totalRowFactureComercailRegie: null
};
const getters = {
  getChargementFactureRegie: state => state.chargementFactureRegie,
  getChargementUploadFactureRegie: state => state.chargementUploadFactureRegie,
  getTotalRowFactureRegie: state => state.totalRowFactureRegie,
  getFactureRegie: state => state.RegieFactureTable,
  getAllRegie: state => state.allRegie,
  getErrorUploadFacture: state => state.errorUploadFacture,
  getLoadingValidationCoutRegie: state => state.loadingValidationCoutRegie,
  getRegieValidationCoutTable: state => state.RegieValidationCoutTable,
  getTotalRowValidationCoutRegie: state => state.totalRowValidationCoutRegie,
  getCommercialRegieFactureTable: state => state.commercialRegieFactureTable,
  getTotalRowFactureComercailRegie: state => state.totalRowFactureComercailRegie
};
const mutations = {
  ALL_REGIE(state, payload) {
    state.allRegie = payload;
  },
  SET_LOADING_FACTURE_REGIE(state, payload) {
    state.chargementFactureRegie = payload;
  },
  SET_LOADING_UPLOAD_FACTURE_REGIE(state, payload) {
    state.ChargementUploadFactureRegie = payload;
  },
  SET_TOTAL_ROW_FACTURE_REGIE(state, payload) {
    state.totalRowFactureRegie = payload;
  },
  SET_FACTURE_REGIE(state, payload) {
    state.RegieFactureTable = payload.map(item => Facture.create(item));
  },
  UPDATE_FACTURE_REGIE(state, payload) {
    payload.row.update(payload.response);
  },
  SET_ERROR_UPLOAD_FACTURE(state, payload) {
    state.errorUploadFacture = payload;
  },
  SET_LOADING_VALIDATION_COUT_REGIE(state, payload) {
    state.loadingValidationCoutRegie = payload;
  },
  SET_VALIDATION_COUT_REGIE(state, payload) {
    state.RegieValidationCoutTable = payload.map(item => Facture.create(item));
  },
  SET_TOTAL_ROW_VALIDATION_COUT_REGIE(state, payload) {
    state.totalRowValidationCoutRegie = payload;
  },
  SET_FACTURE_COMMERCIAL_REGIE(state, payload) {
    state.commercialRegieFactureTable = payload.map(item =>
      Facture.create(item)
    );
  },
  SET_TOTAL_ROW_FACTURE_COMMERCIAL_REGIE(state, payload) {
    state.totalRowFactureComercailRegie = payload;
  }
};
const actions = {
  async getFactureFiltredForRegie({ commit }, payload) {
    commit('SET_LOADING_FACTURE_REGIE', true);
    try {
      const params = {
        page: payload.page,
        year: payload.year,
        per_page: payload.per_page
      };
      if (payload.semaine != null) {
        params.semaine = payload.semaine;
      }
      if (payload.statu != null) {
        params.statue = payload.statu;
      }
      if (
        payload.filterRegie &&
        payload.filterRegie.id &&
        payload.filterRegie.id != null
      ) {
        params.regie_id = payload.filterRegie.id;
      }
      if (payload.precarite != null) {
        params.precarite = payload.precarite;
      }
      if (payload.type_de_chauffage != null) {
        params.type_de_chauffage = payload.type_de_chauffage;
      }
      if (payload.zonz_climatique != null) {
        params.zone_climatique = payload.zonz_climatique;
      }
      if (payload.filterProjectType != null) {
        params.type = payload.filterProjectType;
      }
      const response = await axios.get(domain + `/api/getregieBillsV2`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_FACTURE_REGIE', response.data.bill.data);
      commit('SET_TOTAL_ROW_FACTURE_REGIE', response.data.bill.total);
      commit('SET_LOADING_FACTURE_REGIE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_FACTURE_REGIE', false);
      return false;
    }
  },
  async validationFactureRegie({ commit }, payload) {
    commit('SET_LOADING_FACTURE_REGIE', true);
    try {
      const params = new URLSearchParams();
      params.append('id', payload.row.id);
      params.append('commentaire', payload.row.commentaire);
      if (payload.value) {
        params.append('statue', payload.value);
      }
      const response = await axios.post(
        domain + `/api/validateOrPayRegieBills`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.bill,
        row: payload.row
      };
      commit('UPDATE_FACTURE_REGIE', obj);
      commit('SET_LOADING_FACTURE_REGIE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_FACTURE_REGIE', false);
      return false;
    }
  },
  async newCommentRegie({ commit }, payload) {
    const params = new URLSearchParams();
    params.append('id', payload.id);
    params.append('commentaire', payload.comment);
    if (payload.row.statue) {
      params.append('statue', payload.row.statue);
    }
    try {
      const response = await axios.post(
        domain + `/api/validateOrPayRegieBills`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.bill,
        row: payload.row
      };
      commit('UPDATE_FACTURE_REGIE', obj);
      return true;
    } catch (error) {
      return false;
    }
  },
  async downloadFactureRegiePDF({ commit }, payload) {
    try {
      const params = { year: payload.year, bill_id: payload.item.id };
      if (payload.project_type && payload.project_type != null) {
        params.project_type = payload.project_type;
      }
      const response = await axios.get(domain + `/api/downloadRegieBill`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        responseType: 'blob'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute(
        'download',
        'Appel à facturation ' +
          payload.item.regie_name +
          ' - Semaine ' +
          payload.item.semaine.substr(1) +
          '.pdf'
      );
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  },
  async downloadFactureRegieXML({ commit }, payload) {
    const params = { year: payload.year };
    if (payload.semaine != null) {
      params.semaine = payload.semaine;
    }
    if (payload.statu != null) {
      params.statue = payload.statu;
    }
    try {
      const response = await axios.get(domain + `/api/downloadRegieBill`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        responseType: 'blob'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      if (payload.semaine != null) {
        link.setAttribute(
          'download',
          'Facture' + payload.year + ' ' + payload.semaine + '.xlsx'
        );
      } else {
        link.setAttribute('download', 'Facture' + payload.year + '.xlsx');
      }
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  },
  async uploadFactureRegie({ commit }, payload) {
    commit('SET_ERROR_UPLOAD_FACTURE', null);
    commit('SET_LOADING_UPLOAD_FACTURE_REGIE', true);
    try {
      var bodyFormData = new FormData();
      bodyFormData.append('file', payload.file);
      bodyFormData.append('bill_id', payload.id);
      const responce = await axios.post(
        domain + '/api/uploadRegieBill',
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: responce.data.bill,
        row: payload
      };
      commit('UPDATE_FACTURE_REGIE', obj);
      commit('SET_LOADING_UPLOAD_FACTURE_REGIE', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.file
      ) {
        commit('SET_ERROR_UPLOAD_FACTURE', error.response.data.errors.file);
      }
      commit('SET_LOADING_UPLOAD_FACTURE_REGIE', false);
      return false;
    }
  },
  async fetchAllRegieForAdmin({ commit }, payload) {
    try {
      const responce = await axios.get(domain + `/api/allEmployee`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('ALL_REGIE', responce.data.regie);
      return true;
    } catch (error) {
      return false;
    }
  },
  async DownloadingRegieBill({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/AdminDownloadingRegieBill2/${payload.uploaded.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          responseType: 'blob'
        }
      );
      const blob = response.config.url;
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = blob;
      a.click();
      return true;
    } catch (error) {
      return false;
    }
  },
  async fetchValidationCout({ commit }, payload) {
    commit('SET_LOADING_VALIDATION_COUT_REGIE', true);
    try {
      const params = {
        page: payload.page,
        year: payload.year,
        per_page: payload.per_page
      };
      if (payload.semaine != null) {
        params.semaine = payload.semaine;
      }
      if (
        payload.filterRegie &&
        payload.filterRegie.id &&
        payload.filterRegie.id != null
      ) {
        params.regie_id = payload.filterRegie.id;
      }
      const response = await axios.get(
        domain + `/api/regieBillsSalaryStatistic`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_VALIDATION_COUT_REGIE', response.data.bill.data);
      commit('SET_TOTAL_ROW_VALIDATION_COUT_REGIE', response.data.bill.total);
      commit('SET_LOADING_VALIDATION_COUT_REGIE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_VALIDATION_COUT_REGIE', false);
      return false;
    }
  },
  async exportFileValidationCoutRegie({ commit }, payload) {
    commit('SET_LOADING_VALIDATION_COUT_REGIE', true);
    const params = { semaine: payload.semaine, year: payload.year };
    if (payload.filterRegie && payload.filterRegie.id != null) {
      params.regie_id = payload.filterRegie.id;
    }
    try {
      const response = await axios.get(
        domain + `/api/downloadRegieBillsSalaryStatistic`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          responseType: 'blob'
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      if (payload.semaine != null) {
        link.setAttribute(
          'download',
          'Validation Cout ' + payload.year + ' ' + payload.semaine + '.xlsx'
        );
      } else {
        link.setAttribute('download', 'Facture' + payload.year + '.xlsx');
      }
      document.body.appendChild(link);
      link.click();

      commit('SET_LOADING_VALIDATION_COUT_REGIE', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_VALIDATION_COUT_REGIE', false);
      return false;
    }
  },
  async payedFactureRegie({ commit }, payload) {
    const params = { payed_at: payload.value, id: payload.row.id };
    try {
      const response = await axios.post(domain + `/api/regiePayed_at`, params, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      const obj = {
        response: response.data.bill,
        row: payload.row
      };
      commit('UPDATE_FACTURE_REGIE', obj);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getFactureFiltredForResponsableRegie({ commit }, payload) {
    commit('SET_LOADING_FACTURE_REGIE', true);
    try {
      const params = {
        page: payload.page,
        year: payload.year,
        per_page: payload.per_page
      };
      if (payload.semaine != null) {
        params.semaine = payload.semaine;
      }
      if (payload.statu != null) {
        params.statue = payload.statu;
      }
      if (
        payload.filterRegie &&
        payload.filterRegie.id &&
        payload.filterRegie.id != null
      ) {
        params.regie_id = payload.filterRegie.id;
      }
      if (
        payload.filterSousRegie &&
        payload.filterSousRegie.id &&
        payload.filterSousRegie.id != null
      ) {
        params.user_id = payload.filterSousRegie.id;
      }
      if (payload.precarite != null) {
        params.precarite = payload.precarite;
      }
      if (payload.type_de_chauffage != null) {
        params.type_de_chauffage = payload.type_de_chauffage;
      }
      if (payload.zonz_climatique != null) {
        params.zone_climatique = payload.zonz_climatique;
      }
      if (payload.filterProjectType != null) {
        params.type = payload.filterProjectType;
      }
      const response = await axios.get(domain + `/api/getCommercialRegieBill`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_FACTURE_COMMERCIAL_REGIE', response.data.bill.data);
      commit(
        'SET_TOTAL_ROW_FACTURE_COMMERCIAL_REGIE',
        response.data.bill.total
      );
      commit('SET_LOADING_FACTURE_REGIE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_FACTURE_REGIE', false);
      return false;
    }
  },
  async validationFactureCommercialRegie({ commit }, payload) {
    commit('SET_LOADING_FACTURE_REGIE', true);
    try {
      const params = new URLSearchParams();
      params.append('id', payload.row.id);
      params.append('commentaire', payload.row.commentaire);
      if (payload.value) {
        params.append('statue', payload.value);
      }
      const response = await axios.post(
        domain + `/api/validateCommercialRegieBill`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.bill,
        row: payload.row
      };
      commit('UPDATE_FACTURE_REGIE', obj);
      commit('SET_LOADING_FACTURE_REGIE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_FACTURE_REGIE', false);
      return false;
    }
  },
  async uploadFactureCommercialRegie({ commit }, payload) {
    commit('SET_ERROR_UPLOAD_FACTURE', null);
    commit('SET_LOADING_UPLOAD_FACTURE_REGIE', true);
    try {
      var bodyFormData = new FormData();
      bodyFormData.append('file', payload.file);
      bodyFormData.append('bill_id', payload.id);
      const responce = await axios.post(
        domain + '/api/uploadCommercialRegieBill',
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: responce.data.bill,
        row: payload
      };
      commit('UPDATE_FACTURE_REGIE', obj);
      commit('SET_LOADING_UPLOAD_FACTURE_REGIE', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.file
      ) {
        commit('SET_ERROR_UPLOAD_FACTURE', error.response.data.errors.file);
      }
      commit('SET_LOADING_UPLOAD_FACTURE_REGIE', false);
      return false;
    }
  },
  async downloadFactureCommercialRegiePDF({ commit }, payload) {
    try {
      const params = { year: payload.year, bill_id: payload.item.id };
      const response = await axios.get(
        domain + `/api/downloadCommercialRegiebill`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          responseType: 'blob'
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute(
        'download',
        'Facture' + payload.year + '/' + payload.item.semaine + '.pdf'
      );
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
