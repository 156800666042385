import FraisWeekTh from './FraisWeekTh';
export default class FraisMonthTh {
  constructor(data) {
    this.id = data.id;
    this.month = data.month;
    this.year = data.year;
    this.month_name = data.month_name;
    this.operationnel = data.operationnel;
    this.commercial = data.commercial;
    this.marketing = data.marketing;
    this.technique = data.technique;
    this.rge = data.rge;
    this.CDG = data.CDG;
    this.administratif_sav = data.administratif_sav;
    this.dsi = data.dsi;
    this.juridique = data.juridique;
    this.alger = data.alger;
    this.alteo_control_gestion = data.alteo_control_gestion;
    // this.comptabilite = data.comptabilite;
    // this.RH = data.RH;
    this.bureau_detude = data.bureau_detude;
    this.mairie = data.mairie;
    this.maintenance_pac = data.maintenance_pac;
    this.upcube = data.upcube;
    this.externe = data.externe;
    this.interne = data.interne;
    this.alteo_commercial = data.alteo_commercial;
    this.alteo_supervision = data.alteo_supervision;
    this.weeks = data.weeks.map(item => FraisWeekTh.create(item));
  }
  update(data) {
    this.operationnel = data.operationnel;
    this.commercial = data.commercial;
    this.marketing = data.marketing;
    this.technique = data.technique;
    this.rge = data.rge;
    this.CDG = data.CDG;
    this.administratif_sav = data.administratif_sav;
    this.dsi = data.dsi;
    this.juridique = data.juridique;
    this.alger = data.alger;
    this.alteo_control_gestion = data.alteo_control_gestion;

    // this.comptabilite = data.comptabilite;
    // this.RH = data.RH;
    this.bureau_detude = data.bureau_detude;
    this.mairie = data.mairie;
    this.maintenance_pac = data.maintenance_pac;
    this.upcube = data.upcube;
    this.externe = data.externe;
    this.interne = data.interne;
    this.alteo_commercial = data.alteo_commercial;
    this.alteo_supervision = data.alteo_supervision;
    this.weeks = data.weeks.map(item => FraisWeekTh.create(item));
  }
  static create(data) {
    return new FraisMonthTh(data);
  }
}
