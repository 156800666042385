import axios from 'axios';
import domain from '@/environment';
import Row from '../../models/Row';
const state = {
  dataPoseur: null,
  errorPoseur: null,
  loadingPoseur: false,
  totalRowsPoseur: null,
  poseurActive: []
};
const getters = {
  getDataPoseur: state => state.dataPoseur,
  getErrorPoseur: state => state.errorPoseur,
  getLoadingPoseur: state => state.loadingPoseur,
  getTotalRowsPoseur: state => state.totalRowsPoseur,
  getPoseurActive: state => state.poseurActive
};
const mutations = {
  SET_DATA_POSEUR(state, payload) {
    state.dataPoseur = payload.map(item => Row.create(item));
  },
  SET_ERROR_POSEUR(state, payload) {
    state.errorPoseur = payload;
  },
  SET_LOADING_POSEUR(state, payload = true) {
    state.loadingPoseur = payload;
  },
  SET_TOTAL_ROW_POSEUR(state, payload) {
    state.totalRowsPoseur = payload;
  },
  SET_LIST_POSEUR_ACTIF(state, payload) {
    state.poseurActive = payload;
  },
  RESET_TABLE_POSEUR(state) {
    state.dataPoseur = [];
  }
};
const actions = {
  async fetchRapportDataPoseur(
    { commit },
    { page, per_page, semaine, annee, poseur }
  ) {
    commit('SET_ERROR_POSEUR', null);

    commit('SET_LOADING_POSEUR');

    try {
      const params = {
        page: page,
        per_page: per_page,
        annee: annee,
        semaine: semaine.value
      };
      if (poseur && poseur !== null && poseur.value !== null) {
        params.id = poseur.value;
      }
      const response = await axios.get(domain + `/api/getProjectByPoseur`, {
        params: params,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_DATA_POSEUR', response.data.projects);
      commit('SET_LOADING_POSEUR', false);
      return true;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        commit('SET_ERROR_POSEUR', error.response.data.message);
      } else {
        commit('SET_ERROR_POSEUR', 'Une erreur est survenue');
      }
      commit('SET_LOADING_POSEUR', false);
      return false;
    }
  },
  async fetchPoseurActive({ commit }, { semaine, year }) {
    commit('SET_ERROR_POSEUR', null);
    commit('SET_LOADING_POSEUR');
    try {
      const params = { annee: year, semaine: semaine.value };
      const response = await axios.get(domain + `/api/getOnlinePoseur`, {
        params: params,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_LIST_POSEUR_ACTIF', response.data.employees);
      commit('SET_LOADING_POSEUR', false);
      return true;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        commit('SET_ERROR_POSEUR', error.response.data.message);
      } else {
        commit('SET_ERROR_POSEUR', 'Une erreur est survenue');
      }
      commit('SET_LOADING_POSEUR', false);
      return false;
    }
  },
  async exportFilePoseur({ commit }, { semaine, annee, poseur }) {
    commit('SET_LOADING_POSEUR', true);
    const params = { annee: annee, id: poseur.value, semaine: semaine.value };
    try {
      const response = await axios.get(
        domain + `/api/downloadProjectByPoseur`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute(
        'download',
        'Compil ' + semaine.value + ' A' + annee + '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_POSEUR', false);
      return true;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        commit('SET_ERROR_POSEUR', error.response.data.message);
      } else {
        commit('SET_ERROR_POSEUR', 'Une erreur est survenue');
      }
      commit('SET_LOADING_POSEUR', false);
      return false;
    }
  },
  resetTablePoseur({ commit }) {
    commit('RESET_TABLE_POSEUR');
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
