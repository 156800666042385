import axios from 'axios';
import domain from '@/environment';
import SuiviRegieTh from '../../models/SuiviRegieTh';
const regieBillSuiviTH = 'regieBillSuiviTH';
const state = {
  chargementSuiviRegieTh: false,
  SuiviRegieTableTh: [],
  totalRowSuiviIndependant: null,
  allRegieActiveInSuiviRegieTh: []
};
const getters = {
  getChargementSuiviRegieTh: state => state.chargementSuiviRegieTh,
  getSuiviRegieTableTh: state => state.SuiviRegieTableTh,
  getTotalRowSuiviRegieTh: state => state.totalRowSuiviRegieTh,
  getAllRegieActiveInSuiviRegieTh: state => state.allRegieActiveInSuiviRegieTh
};
const mutations = {
  SET_LOADING_SUIVI_REGIE_TH(state, payload) {
    state.chargementSuiviRegieTh = payload;
  },
  SUIVI_REGIE_FILTRED_TH(state, payload) {
    state.SuiviRegieTableTh = payload.map(item => SuiviRegieTh.create(item));
  },
  SET_TOTAL_ROW_SUIVI_REGIE_TH(state, payload) {
    state.totalRowSuiviRegieTh = payload;
  },
  UPDATE_FACTURE_SUIVI_REGIE_TH(state, payload) {
    payload.row.update(payload.response);
  },
  ALL_REGIE_ACTIVE_IN_SUIVI_REGIE_TH(state, payload) {
    state.allRegieActiveInSuiviRegieTh = payload;
  }
};
const actions = {
  async getSuiviRegieFiltredTh({ commit }, payload) {
    commit('SET_LOADING_SUIVI_REGIE_TH', true);
    try {
      const params = {
        page: payload.page,
        per_page: payload.per_page,
        year: payload.year
      };
      if (payload.month && payload.month != null) {
        params.month = payload.month;
      }
      if (payload.depot && payload.depot != null) {
        params.depot = payload.depot;
      }

      if (
        payload.filterRegie &&
        payload.filterRegie.id &&
        payload.filterRegie.id != null
      ) {
        params.regie_id = payload.filterRegie.id;
      }
      if (payload.validation != null) {
        params.statue = payload.validation;
      }
      const response = await axios.get(
        domain + `/api/${regieBillSuiviTH}/getRegieSuivi`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SUIVI_REGIE_FILTRED_TH', response.data.data.data);
      commit('SET_TOTAL_ROW_SUIVI_REGIE_TH', response.data.data.total);
      commit('SET_LOADING_SUIVI_REGIE_TH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_SUIVI_REGIE_TH', false);
      return false;
    }
  },
  async updateSuiviRegieTh({ commit }, payload) {
    const params = {
      column: payload.column,
      value: payload.value,
      id: payload.row.id
    };
    try {
      const response = await axios.post(
        domain + `/api/${regieBillSuiviTH}/updateregiesuivi`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        row: payload.row
      };
      commit('UPDATE_FACTURE_SUIVI_REGIE_TH', obj);
      return true;
    } catch (error) {
      return false;
    }
  },
  async exportExcelSuiviRegieTh({ commit }, payload) {
    commit('SET_LOADING_SUIVI_REGIE_TH', true);
    const params = {
      page: payload.page,
      per_page: payload.perPage,
      year: payload.year
    };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    if (payload.depot && payload.depot != null) {
      params.depot = payload.depot;
    }
    if (
      payload.filterRegie &&
      payload.filterRegie.id &&
      payload.filterRegie.id != null
    ) {
      params.regie_id = payload.filterRegie.id;
    }
    if (payload.validation != null) {
      params.statue = payload.validation;
    }
    try {
      const response = await axios.get(
        domain + `/api/${regieBillSuiviTH}/regieSuiviExport`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'SUIVI REGIE - ' + payload.year + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_SUIVI_REGIE_TH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_SUIVI_REGIE_TH', false);
      return false;
    }
  },
  async fetchAllRegieActiveInSuiviRegieTh({ commit }, payload) {
    try {
      const responce = await axios.get(
        domain + `/api/${regieBillSuiviTH}/regie_online_suivi`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('ALL_REGIE_ACTIVE_IN_SUIVI_REGIE_TH', responce.data.data);
      return true;
    } catch (error) {
      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
