export default class Template {
  constructor(data) {
    this.id = data.id;
    this.content = data.content;
    this.name = data.name;
    this.type = data.type;
    this.sci_id = data.sci_id;
    this.description = data.description;
    this.description2 = data.description2;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.category_id = data.category_id;
    this.ribs = data.ribs.map(item => {
      return { ...item, changeSave: false, newLine: false };
    });
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('template::create: "data" is undefined');
    }
    return new Template(data);
  }
  update(data) {
    this.content = data.content;
    this.name = data.name;
    this.type = data.type;
    this.sci_id = data.sci_id;
    this.description = data.description;
    this.description2 = data.description2;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.category_id = data.category_id;
    //this.ribs = data.ribs;
    // this.ribs = data.ribs.map(item => {
    //   return { ...item, changeSave: false };
    // });
  }
  // addRib(data) {

  //   this.template_id = data.template_id;
  //   this.id = data.id;
  //   this.blocked = data.blocked;
  //   this.iban = data.iban;
  //   this.swift = data.swift;
  //   this.blocked = data.blocked;
  //   this.ribs = data.ribs.map(item => {
  //     return { ...item, changeSave: false };
  //   });

  // }
}
