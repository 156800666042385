import axios from 'axios';
import domain from '@/environment';
import SuiviRegie from '../../models/SuiviRegie';
const state = {
  chargementSuiviRegie: false,
  SuiviRegieTable: [],
  totalRowSuiviRegie: null
};
const getters = {
  getChargementSuiviRegie: state => state.chargementSuiviRegie,
  getSuiviRegieTable: state => state.SuiviRegieTable,
  getTotalRowSuiviRegie: state => state.totalRowSuiviRegie
};
const mutations = {
  SET_LOADING_SUIVI_REGIE(state, payload) {
    state.chargementSuiviRegie = payload;
  },
  SUIVI_REGIE_FILTRED(state, payload) {
    state.SuiviRegieTable = payload.map(item => SuiviRegie.create(item));
  },
  SET_TOTAL_ROW_SUIVI_REGIE(state, payload) {
    state.totalRowSuiviRegie = payload;
  },
  UPDATE_FACTURE_SUIVI_REGIE(state, payload) {
    payload.row.update(payload.response);
  }
};
const actions = {
  async getSuiviRegieFiltred({ commit }, payload) {
    commit('SET_LOADING_SUIVI_REGIE', true);
    try {
      const params = {
        page: payload.page,
        per_page: payload.perPage,
        year: payload.year
      };
      if (payload.switchMonth && payload.switchMonth == true) {
        params.month = payload.month;
      } else {
        if (payload.semaine != null) {
          params.semaine = payload.semaine;
        }
      }

      if (
        payload.filterRegie &&
        payload.filterRegie.id &&
        payload.filterRegie.id != null
      ) {
        params.regie_id = payload.filterRegie.id;
      }
      if (payload.validation != null) {
        params.statue = payload.validation;
      }
      const response = await axios.get(domain + `/api/getRegieSuivi`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SUIVI_REGIE_FILTRED', response.data.bill.data);
      commit('SET_TOTAL_ROW_SUIVI_REGIE', response.data.bill.total);
      commit('SET_LOADING_SUIVI_REGIE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_SUIVI_REGIE', false);
      return false;
    }
  },
  async updateSuiviRegie({ commit }, payload) {
    const params = {
      column: payload.column,
      value: payload.value,
      id: payload.row.id
    };
    try {
      const response = await axios.post(
        domain + `/api/updateregiesuivi`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        row: payload.row
      };
      commit('UPDATE_FACTURE_SUIVI_REGIE', obj);
      return true;
    } catch (error) {
      return false;
    }
  },
  async exportExcelSuiviRegie({ commit }, payload) {
    commit('SET_LOADING_SUIVI_REGIE', true);
    const params = {
      page: payload.page,
      per_page: payload.perPage,
      year: payload.year
    };
    if (payload.semaine != null) {
      params.semaine = payload.semaine;
    }
    if (
      payload.filterRegie &&
      payload.filterRegie.id &&
      payload.filterRegie.id != null
    ) {
      params.regie_id = payload.filterRegie.id;
    }
    if (payload.validation != null) {
      params.statue = payload.validation;
    }
    try {
      const response = await axios.get(domain + `/api/regieSuiviExport`, {
        params: params,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'SUIVI REGIE - ' + payload.year + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_SUIVI_REGIE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_SUIVI_REGIE', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
