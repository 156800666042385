export default class Facture {
  constructor(data) {
    this.id = data.id;
    this.regie_name = data.regie_name;
    this.coeff_101_deroule = data.coeff_101_deroule;
    this.coeff_101_souffle = data.coeff_101_souffle;
    this.coeff_101_rampant = data.coeff_101_rampant;
    this.coeff_102_murs = data.coeff_102_murs;
    this.coeff_102_murs_iti = data.coeff_102_murs_iti;
    this.coeff_102_murs_ite = data.coeff_102_murs_ite;
    this.coeff_102_pignons = data.coeff_102_pignons;
    this.coeff_103_vide_sanitaire = data.coeff_103_vide_sanitaire;
    this.coeff_103_plafonds = data.coeff_103_plafonds;
    this.total_surface_101 = data.total_surface_101;
    this.total_surface_102 = data.total_surface_102;
    this.total_surface_103 = data.total_surface_103;
    this.pay_previsite = data.pay_previsite;
    this.montant_101 = data.montant_101;
    this.montant_102 = data.montant_102;
    this.montant_103 = data.montant_103;
    this.total = data.total;
    this.tasks = data.tasks;
    this.th_tasks = data.th_tasks;
    this.total_tasks = data.total_tasks;
    this.statue = data.statue;
    this.file = data.file;
    this.semaine = data.semaine;
    this.commentaire = data.commentaire;
    this.uploaded = data.uploaded;
    this.payed_at = data.payed_at;
    this.pourcentage = data.pourcentage;
    this.TH_total = data.TH_total;
    this.totalm2_totalEuro = data.totalm2_totalEuro;
    if (data.statue === 'paye') {
      this.showpaye = true;
    } else {
      this.showpaye = false;
    }
  }
  update(data) {
    this.statue = data.statue;
    this.commentaire = data.commentaire;
    this.payed_at = data.payed_at;
    this.pourcentage = data.pourcentage;
    this.totalm2_totalEuro = data.totalm2_totalEuro;
  }
  static create(data) {
    return new Facture(data);
  }
}
