import axios from 'axios';
import domain from '@/environment';
import FraisResponsableFiliale from '../../models/FraisResponsableFiliale';
const prefixFraisResponsableFiliale = 'responsable_filiale_managment';

const state = {
  errorFraisResponsableFilialeParamsTh: null,
  loagingFraisResponsableFilialeParamsTh: false,
  fraisResponsableFilialeThData: [],
  totalRowFraisResponsableFilialeParamsTh: null
};
const getters = {
  getErrorFraisResponsableFilialeParamsTh: state =>
    state.errorFraisResponsableFilialeParamsTh,
  getLoagingFraisResponsableFilialeParamsTh: state =>
    state.loagingFraisResponsableFilialeParamsTh,
  getFraisResponsableFilialeParamsThData: state =>
    state.fraisResponsableFilialeThData,
  getTotalRowFraisResponsableFilialeParamsTh: state =>
    state.totalRowFraisResponsableFilialeParamsTh
};
const mutations = {
  ERROR_FRAIS_RESPONSABLE_FILIALE_TH_TO_CONFIG(state, payload) {
    state.errorFraisResponsableFilialeParamsTh = payload;
  },
  LOADING_FRAIS_RESPONSABLE_FILIALE_TH_TO_CONFIG(state, payload) {
    state.loagingFraisResponsableFilialeParamsTh = payload;
  },
  NEW_FRAIS_RESPONSABLE_FILIALE_TH_CONFIG(state, payload) {
    let existe = state.fraisResponsableFilialeThData.filter(
      item => item.id == payload.id
    );

    if (existe.length) {
      existe[0].update(payload);
    } else {
      state.fraisResponsableFilialeThData.push(
        FraisResponsableFiliale.create(payload)
      );
    }
  },
  ALL_FRAIS_RESPONSABLE_FILIALE_TH_CONFIG(state, payload) {
    state.fraisResponsableFilialeThData = payload.map(item =>
      FraisResponsableFiliale.create(item)
    );
  },
  TOTAL_FRAIS_RESPONSABLE_FILIALE_TH_CONFIG(state, payload) {
    state.totalRowFraisResponsableFilialeParamsTh = payload;
  },
  UPDATE_FRAIS_RESPONSABLE_FILIALE_TH_CONFIG(state, payload) {
    for (var i = 0; i < state.fraisResponsableFilialeThData.length; i++) {
      if (state.fraisResponsableFilialeThData[i].id == payload.data.id) {
        state.fraisResponsableFilialeThData[i].update(payload.response);
        return;
      }
    }
  }
};
const actions = {
  async newConfigFraisResponsableFilialeParamsTh({ commit }, payload) {
    let params = { ...payload };
    params.filiale = payload.filiale;
    params.responsable = payload.responsable;
    params.percent = payload.percent;
    params.name_holding = payload.name_holding;
    params.year = payload.year;
    params.month = payload.month;

    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisResponsableFiliale}/add`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('NEW_FRAIS_RESPONSABLE_FILIALE_TH_CONFIG', response.data.data);

      return {
        succes: true
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async fetchAllFraisResponsableFilialeParamsTh({ commit }, payload) {
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      year: payload.year
    };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    commit('LOADING_FRAIS_RESPONSABLE_FILIALE_TH_TO_CONFIG', true);
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisResponsableFiliale}/all`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('ALL_FRAIS_RESPONSABLE_FILIALE_TH_CONFIG', response.data.data);
      commit('TOTAL_FRAIS_RESPONSABLE_FILIALE_TH_CONFIG', response.data.total);
      commit('LOADING_FRAIS_RESPONSABLE_FILIALE_TH_TO_CONFIG', false);

      return true;
    } catch (error) {
      if (error.response && error.respons.data && error.response.data.error) {
        commit(
          'ERROR_FRAIS_RESPONSABLE_FILIALE_TH_TO_CONFIG',
          error.response.data.error
        );
      } else {
        commit('ERROR_FRAIS_RESPONSABLE_FILIALE_TH_TO_CONFIG', [
          'Une erreur est servenue'
        ]);
      }
      commit('LOADING_FRAIS_RESPONSABLE_FILIALE_TH_TO_CONFIG', false);

      return false;
    }
  },

  async updateConfigParamsFraisResponsableFilialeParamsTh({ commit }, payload) {
    let params = { ...payload };
    params.filiale = payload.filiale;
    params.responsable = payload.responsable;
    params.percent = payload.percent;
    params.name_holding = payload.name_holding;
    params.year = payload.year;
    params.month = payload.month;

    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisResponsableFiliale}/update`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FRAIS_RESPONSABLE_FILIALE_TH_CONFIG', {
        response: response.data.data,
        data: payload
      });

      return {
        succes: true
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
