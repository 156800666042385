import axios from 'axios';
import domain from '@/environment';
import FraisCactusAlteoTh from '../../models/FraisCactusAlteoTh';
const prefixCactusAlteo = 'suivi_management_commercial_gloabal';

const state = {
  errorFraisCactusAlteoParamsTh: null,
  loagingFraisCactusAlteoParamsTh: false,
  fraisCactusAlteoThData: [],
  totalRowFraisCactusAlteoParamsTh: null
};
const getters = {
  getErrorFraisCactusAlteoParamsTh: state =>
    state.errorFraisCactusAlteoParamsTh,
  getLoagingFraisCactusAlteoParamsTh: state =>
    state.loagingFraisCactusAlteoParamsTh,
  getFraisCactusAlteoParamsThData: state => state.fraisCactusAlteoThData,
  getTotalRowFraisCactusAlteoParamsTh: state =>
    state.totalRowFraisCactusAlteoParamsTh
};
const mutations = {
  ERROR_FRAIS_CACTUS_ALTEO_TH_TO_CONFIG(state, payload) {
    state.errorFraisCactusAlteoParamsTh = payload;
  },
  LOADING_FRAIS_CACTUS_ALTEO_TH_TO_CONFIG(state, payload) {
    state.loagingFraisCactusAlteoParamsTh = payload;
  },
  NEW_FRAIS_CACTUS_ALTEO_TH_CONFIG(state, payload) {
    let existe = state.fraisCactusAlteoThData.filter(
      item => item.id == payload.id
    );

    if (existe.length) {
      existe[0].update(payload);
    } else {
      state.fraisCactusAlteoThData.push(FraisCactusAlteoTh.create(payload));
    }
  },
  ALL_FRAIS_CACTUS_ALTEO_TH_CONFIG(state, payload) {
    state.fraisCactusAlteoThData = payload.map(item =>
      FraisCactusAlteoTh.create(item)
    );
  },
  TOTAL_FRAIS_CACTUS_ALTEO_TH_CONFIG(state, payload) {
    state.totalRowFraisCactusAlteoParamsTh = payload;
  },
  UPDATE_FRAIS_CACTUS_ALTEO_TH_CONFIG(state, payload) {
    for (var i = 0; i < state.fraisCactusAlteoThData.length; i++) {
      if (state.fraisCactusAlteoThData[i].id == payload.data.id) {
        state.fraisCactusAlteoThData[i].update(payload.response);
        return;
      }
    }
  }
};
const actions = {
  async newConfigFraisCactusAlteoParamsTh({ commit }, payload) {
    let params = { ...payload };
    params.interne = parseFloat((payload.interne + '').replace(',', '.'));
    params.externe = parseFloat((payload.externe + '').replace(',', '.'));
    params.commercial = parseFloat((payload.commercial + '').replace(',', '.'));
    params.alteo1 = parseFloat((payload.alteo1 + '').replace(',', '.'));
    params.alteo2 = parseFloat((payload.alteo2 + '').replace(',', '.'));
    try {
      const response = await axios.post(
        domain + `/api/${prefixCactusAlteo}/add`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('NEW_FRAIS_CACTUS_ALTEO_TH_CONFIG', response.data.data);

      return {
        succes: true
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async fetchAllFraisCactusAlteoParamsTh({ commit }, payload) {
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      year: payload.year
    };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    commit('LOADING_FRAIS_CACTUS_ALTEO_TH_TO_CONFIG', true);
    try {
      const response = await axios.get(
        domain + `/api/${prefixCactusAlteo}/all`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('ALL_FRAIS_CACTUS_ALTEO_TH_CONFIG', response.data.data);
      commit('TOTAL_FRAIS_CACTUS_ALTEO_TH_CONFIG', response.data.total);
      commit('LOADING_FRAIS_CACTUS_ALTEO_TH_TO_CONFIG', false);

      return true;
    } catch (error) {
      if (error.response && error.respons.data && error.response.data.error) {
        commit(
          'ERROR_FRAIS_CACTUS_ALTEO_TH_TO_CONFIG',
          error.response.data.error
        );
      } else {
        commit('ERROR_FRAIS_CACTUS_ALTEO_TH_TO_CONFIG', [
          'Une erreur est servenue'
        ]);
      }
      commit('LOADING_FRAIS_CACTUS_ALTEO_TH_TO_CONFIG', false);

      return false;
    }
  },

  async updateConfigParamsFraisCactusAlteoParamsTh({ commit }, payload) {
    let params = { ...payload };
    params.interne = parseFloat((payload.interne + '').replace(',', '.'));
    params.externe = parseFloat((payload.externe + '').replace(',', '.'));
    params.commercial = parseFloat((payload.commercial + '').replace(',', '.'));
    params.alteo1 = parseFloat((payload.alteo1 + '').replace(',', '.'));
    params.alteo2 = parseFloat((payload.alteo2 + '').replace(',', '.'));

    try {
      const response = await axios.post(
        domain + `/api/${prefixCactusAlteo}/update`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FRAIS_CACTUS_ALTEO_TH_CONFIG', {
        response: response.data.data,
        data: payload
      });

      return {
        succes: true
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
