import SubLCR from './SubLCR';
export default class LCRModal {
  constructor(data) {
    this.id = data.id;
    this.montant_ttc = data.montant_ttc;
    this.numero = data.numero;
    this.type_de_periode = data.type_de_periode;
    this.can_update = data.can_update;
    this.frequence = data.frequence;
    this.can_create = data.can_create;
    this.date = data.date;
    this.swift = data.swift;
    this.iban = data.iban;
    this.lcr = data.lcr.map(item => SubLCR.create(item));
  }

  update(data) {}
  static create(data) {
    return new LCRModal(data);
  }
}
