export default class ProjectsFraisClient {
  constructor(data) {
    this.CDG = data.CDG;
    this.CDG_date = data.CDG_date;
    this.CDG_download = data.CDG_download;
    this.CDG_id = data.CDG_id;
    this.CDG_numero = data.CDG_numero;
    this.CDG_statue_installateur = data.CDG_statue_installateur;
    this.RH = data.RH;
    this.RH_date = data.RH_date;
    this.RH_download = data.RH_download;
    this.RH_id = data.RH_id;
    this.RH_numero = data.RH_numero;
    this.RH_statue_installateur = data.RH_statue_installateur;
    this.administratif_sav = data.administratif_sav;
    this.administratif_sav_date = data.administratif_sav_date;
    this.administratif_sav_download = data.administratif_sav_download;
    this.administratif_sav_id = data.administratif_sav_id;
    this.administratif_sav_numero = data.administratif_sav_numero;
    this.administratif_sav_statue_installateur =
      data.administratif_sav_statue_installateur;
    this.alger = data.alger;
    this.alger_date = data.alger_date;
    this.alger_download = data.alger_download;
    this.alger_id = data.alger_id;
    this.alger_numero = data.alger_numero;
    this.alger_statue_installateur = data.alger_statue_installateur;
    this.bureau_detude = data.bureau_detude;
    this.bureau_detude_date = data.bureau_detude_date;
    this.bureau_detude_download = data.bureau_detude_download;
    this.bureau_detude_id = data.bureau_detude_id;
    this.bureau_detude_numero = data.bureau_detude_numero;
    this.bureau_detude_statue_installateur =
      data.bureau_detude_statue_installateur;
    this.commercial = data.commercial;
    this.commercial_date = data.commercial_date;
    this.commercial_download = data.commercial_download;
    this.commercial_id = data.commercial_id;
    this.commercial_numero = data.commercial_numero;
    this.commercial_statue_installateur = data.commercial_statue_installateur;
    this.comptabilite = data.comptabilite;
    this.comptabilite_date = data.comptabilite_date;
    this.comptabilite_download = data.comptabilite_download;
    this.comptabilite_id = data.comptabilite_id;
    this.comptabilite_numero = data.comptabilite_numero;
    this.comptabilite_statue_installateur =
      data.comptabilite_statue_installateur;
    this.depot = data.depot;
    this.depot_stockage = data.depot_stockage;
    this.dsi = data.dsi;
    this.dsi_date = data.dsi_date;
    this.dsi_download = data.dsi_download;
    this.dsi_id = data.dsi_id;
    this.dsi_numero = data.dsi_numero;
    this.dsi_statue_installateur = data.dsi_statue_installateur;
    this.id = data.id;
    this.installer_rge = data.installer_rge;
    this.juridique = data.juridique;
    this.juridique_date = data.juridique_date;
    this.juridique_download = data.juridique_download;
    this.juridique_id = data.juridique_id;
    this.juridique_numero = data.juridique_numero;
    this.juridique_statue_installateur = data.juridique_statue_installateur;
    this.maintenance_pac = data.maintenance_pac;
    this.maintenance_pac_download = data.maintenance_pac_download;
    this.maintenance_pac_id = data.maintenance_pac_id;
    this.maintenance_pac_numero = data.maintenance_pac_numero;
    this.maintenance_pac_statue_installateur =
      data.maintenance_pac_statue_installateur;
    this.mairie = data.mairie;
    this.mairie_date = data.mairie_date;
    this.mairie_download = data.mairie_download;
    this.mairie_id = data.mairie_id;
    this.mairie_numero = data.mairie_numero;
    this.mairie_statue_installateur = data.mairie_statue_installateur;
    this.marketing = data.marketing;
    this.marketing_date = data.marketing_date;
    this.marketing_download = data.marketing_download;
    this.marketing_id = data.marketing_id;
    this.marketing_numero = data.marketing_numero;
    this.marketing_statue_installateur = data.marketing_statue_installateur;
    this.montant_a_5_ht = data.montant_a_5_ht;
    this.montant_final = data.montant_final;
    this.montant_prime_cee = data.montant_prime_cee;
    this.montant_total_ht = data.montant_total_ht;
    this.nom1 = data.nom1;
    this.nom_pernom = data.nom_pernom;
    this.numero_de_depot = data.numero_de_depot;
    this.numero_dossier = data.numero_dossier;
    this.operationnel = data.operationnel;
    this.operationnel_date = data.operationnel_date;
    this.operationnel_download = data.operationnel_download;
    this.operationnel_id = data.operationnel_id;
    this.operationnel_numero = data.operationnel_numero;
    this.operationnel_statue_installateur =
      data.operationnel_statue_installateur;
    //
    this.operationnel_email_sent_at = data.operationnel_email_sent_at;
    this.operationnel_lot = data.operationnel_lot;
    this.mairie_email_sent_at = data.mairie_email_sent_at;
    this.mairie_lot = data.mairie_lot;
    this.administratif_sav_email_sent_at = data.administratif_sav_email_sent_at;
    this.administratif_sav_lot = data.administratif_sav_lot;
    this.alger_email_sent_at = data.alger_email_sent_at;
    this.alger_lot = data.alger_lot;
    this.bureau_detude_email_sent_at = data.bureau_detude_email_sent_at;
    this.bureau_detude_lot = data.bureau_detude_lot;
    this.commercial_email_sent_at = data.commercial_email_sent_at;
    this.commercial_lot = data.commercial_lot;
    this.dsi_email_sent_at = data.dsi_email_sent_at;
    this.dsi_lot = data.dsi_lot;
    this.juridique_email_sent_at = data.juridique_email_sent_at;
    this.juridique_lot = data.juridique_lot;
    this.maintenance_pac_email_sent_at = data.maintenance_pac_email_sent_at;
    this.maintenance_pac_lot = data.maintenance_pac_lot;
    this.CDG_email_sent_at = data.CDG_email_sent_at;
    this.CDG_lot = data.CDG_lot;
    this.marketing_email_sent_at = data.marketing_email_sent_at;
    this.marketing_lot = data.marketing_lot;
    this.rge_email_sent_at = data.rge_email_sent_at;
    this.rge_lot = data.rge_lot;
    this.technique_email_sent_at = data.technique_email_sent_at;
    this.technique_lot = data.technique_lot;
    this.upcube_email_sent_at = data.upcube_email_sent_at;
    this.upcube_lot = data.upcube_lot;
    //
    this.period_de_paie = data.period_de_paie;
    this.pernom1 = data.pernom1;
    this.rge = data.rge;
    this.rge_date = data.rge_date;
    this.rge_download = data.rge_download;
    this.rge_id = data.rge_id;
    this.rge_numero = data.rge_numero;
    this.rge_statue_installateur = data.rge_statue_installateur;
    this.statue_CDG = data.statue_CDG;
    this.statue_RH = data.statue_RH;
    this.statue_administratif_sav = data.statue_administratif_sav;
    this.statue_alger = data.statue_alger;
    this.statue_bureau_detude = data.statue_bureau_detude;
    this.statue_commercial = data.statue_commercial;
    this.statue_comptabilite = data.statue_comptabilite;
    this.statue_dsi = data.statue_dsi;
    this.statue_juridique = data.statue_juridique;
    this.statue_maintenance_pac = data.statue_maintenance_pac;
    this.statue_mairie = data.statue_mairie;
    this.statue_marketing = data.statue_marketing;
    this.statue_operationnel = data.statue_operationnel;
    this.statue_rge = data.statue_rge;
    this.statue_technique = data.statue_technique;
    this.technique = data.technique;
    this.technique_numero = data.technique_numero;
    this.technique_statue_installateur = data.technique_statue_installateur;
    this.technique_date = data.technique_date;
    this.technique_download = data.technique_download;
    this.technique_id = data.technique_id;
    this.BE_TYPE = data.BE_TYPE;
    this.month = data.month;
    this.pu_CDG = data.pu_CDG;
    this.pu_administratif_sav = data.pu_administratif_sav;
    this.pu_alger = data.pu_alger;
    this.pu_bureau_detude = data.pu_bureau_detude;
    this.pu_commercial = data.pu_commercial;
    this.pu_comptabilite = data.pu_comptabilite;
    this.pu_dsi = data.pu_dsi;
    this.pu_juridique = data.pu_juridique;
    this.pu_maintenance_pac = data.pu_maintenance_pac;
    this.pu_mairie = data.pu_mairie;
    this.pu_marketing = data.pu_marketing;
    this.pu_operationnel = data.pu_operationnel;
    this.pu_rge = data.pu_rge;
    this.pu_technique = data.pu_technique;
    this.numero_dossier = data.numero_dossier;
    this.upcube = data.upcube;
    this.upcube_numero = data.upcube_numero;
    this.upcube_statue_installateur = data.upcube_statue_installateur;
    this.upcube_date = data.upcube_date;
    this.upcube_download = data.upcube_download;
    this.upcube_id = data.upcube_id;
    this.statue_upcube = data.statue_upcube;
    this.check = false;
    this.operationnel_responsable_email = data.operationnel_responsable_email;
    this.CDG_responsable_email = data.CDG_responsable_email;
    this.administratif_sav_responsable_email =
      data.administratif_sav_responsable_email;
    this.alger_responsable_email = data.alger_responsable_email;
    this.bureau_detude_responsable_email = data.bureau_detude_responsable_email;
    this.commercial_responsable_email = data.commercial_responsable_email;
    this.dsi_responsable_email = data.dsi_responsable_email;
    this.juridique_responsable_email = data.juridique_responsable_email;
    this.maintenance_pac_responsable_email =
      data.maintenance_pac_responsable_email;
    this.mairie_responsable_email = data.mairie_responsable_email;
    this.marketing_responsable_email = data.marketing_responsable_email;
    this.rge_responsable_email = data.rge_responsable_email;
    this.technique_responsable_email = data.technique_responsable_email;
    this.upcube_responsable_email = data.upcube_responsable_email;
    this.alteo_control_gestion = data.alteo_control_gestion;
    this.alteo_control_gestion_date = data.alteo_control_gestion_date;
    this.alteo_control_gestion_download = data.alteo_control_gestion_download;
    this.alteo_control_gestion_id = data.alteo_control_gestion_id;
    this.alteo_control_gestion_numero = data.alteo_control_gestion_numero;
    this.alteo_control_gestion_statue_installateur =
      data.alteo_control_gestion_statue_installateur;
    this.alteo_control_gestion_email_sent_at =
      data.alteo_control_gestion_email_sent_at;
    this.alteo_control_gestion_lot = data.alteo_control_gestion_lot;
    this.statue_alteo_control_gestion = data.statue_alteo_control_gestion;
    this.pu_alteo_control_gestion = data.pu_alteo_control_gestion;
    this.alteo_control_gestion_responsable_email =
      data.alteo_control_gestion_responsable_email;
    //
    this.operationnel_av_id = data.operationnel_av_id;
    this.operationnel_av_numero = data.operationnel_av_numero;
    this.operationnel_lcr = data.operationnel_lcr;
    this.operationnel_type_paiement = data.operationnel_type_paiement;
    //
    this.commercial_av_id = data.commercial_av_id;
    this.commercial_av_numero = data.commercial_av_numero;
    this.commercial_lcr = data.commercial_lcr;
    this.commercial_type_paiement = data.commercial_type_paiement;
    //
    this.marketing_av_id = data.marketing_av_id;
    this.marketing_av_numero = data.marketing_av_numero;
    this.marketing_lcr = data.marketing_lcr;
    this.marketing_type_paiement = data.marketing_type_paiement;
    //
    this.technique_av_id = data.technique_av_id;
    this.technique_av_numero = data.technique_av_numero;
    this.technique_lcr = data.technique_lcr;
    this.technique_type_paiement = data.technique_type_paiement;
    //
    this.rge_av_id = data.rge_av_id;
    this.rge_av_numero = data.rge_av_numero;
    this.rge_lcr = data.rge_lcr;
    this.rge_type_paiement = data.rge_type_paiement;
    //
    this.CDG_av_id = data.CDG_av_id;
    this.CDG_av_numero = data.CDG_av_numero;
    this.CDG_lcr = data.CDG_lcr;
    this.CDG_type_paiement = data.CDG_type_paiement;
    //
    this.administratif_sav_av_id = data.administratif_sav_av_id;
    this.administratif_sav_av_numero = data.administratif_sav_av_numero;
    this.administratif_sav_lcr = data.administratif_sav_lcr;
    this.administratif_sav_type_paiement = data.administratif_sav_type_paiement;
    ///
    this.dsi_av_id = data.dsi_av_id;
    this.dsi_av_numero = data.dsi_av_numero;
    this.dsi_lcr = data.dsi_lcr;
    this.dsi_type_paiement = data.dsi_type_paiement;
    //
    this.juridique_av_id = data.juridique_av_id;
    this.juridique_av_numero = data.juridique_av_numero;
    this.juridique_lcr = data.juridique_lcr;
    this.juridique_type_paiement = data.juridique_type_paiement;
    //
    this.alger_av_id = data.alger_av_id;
    this.alger_av_numero = data.alger_av_numero;
    this.alger_lcr = data.alger_lcr;
    this.alger_type_paiement = data.alger_type_paiement;
    //
    this.alteo_control_gestion_av_id = data.alteo_control_gestion_av_id;
    this.alteo_control_gestion_av_numero = data.alteo_control_gestion_av_numero;
    this.alteo_control_gestion_lcr = data.alteo_control_gestion_lcr;
    this.alteo_control_gestion_type_paiement =
      data.alteo_control_gestion_type_paiement;
    //
    this.mairie_av_id = data.mairie_av_id;
    this.mairie_av_numero = data.mairie_av_numero;
    this.mairie_lcr = data.mairie_lcr;
    this.mairie_type_paiement = data.mairie_type_paiement;
    //
    this.maintenance_pac_av_id = data.maintenance_pac_av_id;
    this.maintenance_pac_av_numero = data.maintenance_pac_av_numero;
    this.maintenance_pac_lcr = data.maintenance_pac_lcr;
    this.maintenance_pac_type_paiement = data.maintenance_pac_type_paiement;
  }

  update(data) {
    this.CDG_date = data.CDG_date;
    this.CDG_download = data.CDG_download;
    this.CDG_id = data.CDG_id;
    this.RH_date = data.RH_date;
    this.RH_download = data.RH_download;
    this.RH_id = data.RH_id;
    this.administratif_sav_date = data.administratif_sav_date;
    this.administratif_sav_download = data.administratif_sav_download;
    this.administratif_sav_id = data.administratif_sav_id;
    this.alger_date = data.alger_date;
    this.alger_download = data.alger_download;
    this.alger_id = data.alger_id;
    this.bureau_detude_date = data.bureau_detude_date;
    this.bureau_detude_download = data.bureau_detude_download;
    this.bureau_detude_id = data.bureau_detude_id;
    this.commercial_date = data.commercial_date;
    this.commercial_download = data.commercial_download;
    this.commercial_id = data.commercial_id;
    this.comptabilite_date = data.comptabilite_date;
    this.comptabilite_download = data.comptabilite_download;
    this.comptabilite_id = data.comptabilite_id;
    this.dsi_date = data.dsi_date;
    this.dsi_download = data.dsi_download;
    this.dsi_id = data.dsi_id;
    this.id = data.id;
    this.juridique_date = data.juridique_date;
    this.juridique_download = data.juridique_download;
    this.juridique_id = data.juridique_id;
    this.maintenance_pac_download = data.maintenance_pac_download;
    this.maintenance_pac_id = data.maintenance_pac_id;
    this.mairie_date = data.mairie_date;
    this.mairie_download = data.mairie_download;
    this.mairie_id = data.mairie_id;
    this.marketing_date = data.marketing_date;
    this.marketing_download = data.marketing_download;
    this.marketing_id = data.marketing_id;
    this.operationnel_date = data.operationnel_date;
    this.operationnel_download = data.operationnel_download;
    this.operationnel_id = data.operationnel_id;
    this.rge_date = data.rge_date;
    this.rge_download = data.rge_download;
    this.rge_id = data.rge_id;
    this.statue_CDG = data.statue_CDG;
    this.statue_RH = data.statue_RH;
    this.statue_administratif_sav = data.statue_administratif_sav;
    this.statue_alger = data.statue_alger;
    this.statue_bureau_detude = data.statue_bureau_detude;
    this.statue_commercial = data.statue_commercial;
    this.statue_comptabilite = data.statue_comptabilite;
    this.statue_dsi = data.statue_dsi;
    this.statue_juridique = data.statue_juridique;
    this.statue_maintenance_pac = data.statue_maintenance_pac;
    this.statue_mairie = data.statue_mairie;
    this.statue_marketing = data.statue_marketing;
    this.statue_operationnel = data.statue_operationnel;
    this.statue_rge = data.statue_rge;
    this.statue_technique = data.statue_technique;
    this.technique_date = data.technique_date;
    this.technique_download = data.technique_download;
    this.technique_id = data.technique_id;
    this.pu_CDG = data.pu_CDG;
    this.pu_administratif_sav = data.pu_administratif_sav;
    this.pu_alger = data.pu_alger;
    this.pu_bureau_detude = data.pu_bureau_detude;
    this.pu_commercial = data.pu_commercial;
    this.pu_comptabilite = data.pu_comptabilite;
    this.pu_dsi = data.pu_dsi;
    this.pu_juridique = data.pu_juridique;
    this.pu_maintenance_pac = data.pu_maintenance_pac;
    this.pu_mairie = data.pu_mairie;
    this.pu_marketing = data.pu_marketing;
    this.pu_operationnel = data.pu_operationnel;
    this.pu_rge = data.pu_rge;
    this.pu_technique = data.pu_technique;
    this.rge_numero = data.rge_numero;
    this.CDG_numero = data.CDG_numero;
    this.RH_numero = data.RH_numero;
    this.administratif_sav_numero = data.administratif_sav_numero;
    this.alger_numero = data.alger_numero;
    this.bureau_detude_numero = data.bureau_detude_numero;
    this.commercial_numero = data.commercial_numero;
    this.comptabilite_numero = data.comptabilite_numero;
    this.dsi_numero = data.dsi_numero;
    this.juridique_numero = data.juridique_numero;
    this.maintenance_pac_numero = data.maintenance_pac_numero;
    this.mairie_numero = data.mairie_numero;
    this.marketing_numero = data.marketing_numero;
    this.operationnel_numero = data.operationnel_numero;
    this.technique_numero = data.technique_numero;
    this.upcube = data.upcube;
    this.upcube_numero = data.upcube_numero;
    this.upcube_date = data.upcube_date;
    this.upcube_download = data.upcube_download;
    this.upcube_id = data.upcube_id;
    this.statue_upcube = data.statue_upcube;
    this.CDG_statue_installateur = data.CDG_statue_installateur;
    this.RH_statue_installateur = data.RH_statue_installateur;
    this.administratif_sav_statue_installateur =
      data.administratif_sav_statue_installateur;
    this.administratif_sav_statue_installateur =
      data.administratif_sav_statue_installateur;
    this.alger_statue_installateur = data.alger_statue_installateur;
    this.bureau_detude_statue_installateur =
      data.bureau_detude_statue_installateur;
    this.commercial_statue_installateur = data.commercial_statue_installateur;
    this.comptabilite_statue_installateur =
      data.comptabilite_statue_installateur;
    this.dsi_statue_installateur = data.dsi_statue_installateur;
    this.juridique_statue_installateur = data.juridique_statue_installateur;
    this.maintenance_pac_statue_installateur =
      data.maintenance_pac_statue_installateur;
    this.marketing_statue_installateur = data.marketing_statue_installateur;
    this.operationnel_statue_installateur =
      data.operationnel_statue_installateur;
    //
    this.operationnel_email_sent_at = data.operationnel_email_sent_at;
    this.operationnel_lot = data.operationnel_lot;
    this.mairie_email_sent_at = data.mairie_email_sent_at;
    this.mairie_lot = data.mairie_lot;
    this.administratif_sav_email_sent_at = data.administratif_sav_email_sent_at;
    this.administratif_sav_lot = data.administratif_sav_lot;
    this.alger_email_sent_at = data.alger_email_sent_at;
    this.alger_lot = data.alger_lot;
    this.bureau_detude_email_sent_at = data.bureau_detude_email_sent_at;
    this.bureau_detude_lot = data.bureau_detude_lot;
    this.commercial_email_sent_at = data.commercial_email_sent_at;
    this.commercial_lot = data.commercial_lot;
    this.dsi_email_sent_at = data.dsi_email_sent_at;
    this.dsi_lot = data.dsi_lot;
    this.juridique_email_sent_at = data.juridique_email_sent_at;
    this.juridique_lot = data.juridique_lot;
    this.maintenance_pac_email_sent_at = data.maintenance_pac_email_sent_at;
    this.maintenance_pac_lot = data.maintenance_pac_lot;
    this.CDG_email_sent_at = data.CDG_email_sent_at;
    this.CDG_lot = data.CDG_lot;
    this.marketing_email_sent_at = data.marketing_email_sent_at;
    this.marketing_lot = data.marketing_lot;
    this.rge_email_sent_at = data.rge_email_sent_at;
    this.rge_lot = data.rge_lot;
    this.technique_email_sent_at = data.technique_email_sent_at;
    this.technique_lot = data.technique_lot;
    this.upcube_email_sent_at = data.upcube_email_sent_at;
    this.upcube_lot = data.upcube_lot;
    //
    this.rge_statue_installateur = data.rge_statue_installateur;
    this.technique_statue_installateur = data.technique_statue_installateur;
    this.upcube_statue_installateur = data.upcube_statue_installateur;
    this.operationnel_responsable_email = data.operationnel_responsable_email;
    this.CDG_responsable_email = data.CDG_responsable_email;
    this.administratif_sav_responsable_email =
      data.administratif_sav_responsable_email;
    this.alger_responsable_email = data.alger_responsable_email;
    this.bureau_detude_responsable_email = data.bureau_detude_responsable_email;
    this.commercial_responsable_email = data.commercial_responsable_email;
    this.dsi_responsable_email = data.dsi_responsable_email;
    this.juridique_responsable_email = data.juridique_responsable_email;
    this.maintenance_pac_responsable_email =
      data.maintenance_pac_responsable_email;
    this.mairie_responsable_email = data.mairie_responsable_email;
    this.marketing_responsable_email = data.marketing_responsable_email;
    this.rge_responsable_email = data.rge_responsable_email;
    this.technique_responsable_email = data.technique_responsable_email;
    this.upcube_responsable_email = data.upcube_responsable_email;

    this.alteo_control_gestion = data.alteo_control_gestion;
    this.alteo_control_gestion_date = data.alteo_control_gestion_date;
    this.alteo_control_gestion_download = data.alteo_control_gestion_download;
    this.alteo_control_gestion_id = data.alteo_control_gestion_id;
    this.alteo_control_gestion_numero = data.alteo_control_gestion_numero;
    this.alteo_control_gestion_statue_installateur =
      data.alteo_control_gestion_statue_installateur;
    this.alteo_control_gestion_email_sent_at =
      data.alteo_control_gestion_email_sent_at;
    this.alteo_control_gestion_lot = data.alteo_control_gestion_lot;
    this.statue_alteo_control_gestion = data.statue_alteo_control_gestion;
    this.pu_alteo_control_gestion = data.pu_alteo_control_gestion;
    this.alteo_control_gestion_responsable_email =
      data.alteo_control_gestion_responsable_email;
  }
  static create(data) {
    return new ProjectsFraisClient(data);
  }
}
