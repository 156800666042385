export default class SuiviRegie {
  constructor(data) {
    this.id = data.id;
    this.regie = data.regie;
    this.semaine = data.semaine;
    this.m2 = data.m2;
    this.total = data.total;
    this.prix_monyen = data.prix_monyen;
    this.date_envoi = data.date_envoi;

    this.raison_social = data.raison_social;
    this.SEPA = data.SEPA;
    this.s_monatant_ttc = data.s_monatant_ttc;
    this.reference_facture = data.reference_facture;
    this.date_de_facture = data.date_de_facture;
    this.saisie_comptable = data.saisie_comptable;
    this.date_saisie = data.date_saisie;
    this.statue = data.statue;
    this.date = data.date;
  }
  update(data) {
    this.raison_social = data.raison_social;
    this.SEPA = data.SEPA;
    this.s_monatant_ttc = data.s_monatant_ttc;
    this.reference_facture = data.reference_facture;
    this.date_de_facture = data.date_de_facture;
    this.saisie_comptable = data.saisie_comptable;
    this.date_saisie = data.date_saisie;
    this.statue = data.statue;
    this.date = data.date;
  }
  static create(data) {
    return new SuiviRegie(data);
  }
}
