import axios from 'axios';
import domain from '@/environment';
import produitTh from '../../models/produitTh';

const state = {
  produits: [],
  produitsIsLoading: false,
  produitsError: null,
  TotalRowProduits: 0
};
const getters = {
  getProduits: state => state.produits,
  getProduitIsLoding: state => state.produitsIsLoading,
  getProduitError: state => state.produitsError,
  getTotalRowProduit: state => state.TotalRowProduits
};
const mutations = {
  ADD_NEW_PRODUCT(state, payload) {
    state.produits.unshift(produitTh.create(payload));
  },
  SET_ALL_PRODUCTS(state, payload) {
    state.produits = payload.map(item => produitTh.create(item));
  },
  DELETE_PRODUCTS(state, payload) {
    const index = state.produits.findIndex(
      element => element.id === payload.id
    );
    state.produits.splice(index, 1);
  },
  UPDATE_PRODUCT(state, payload) {
    payload.produitOrigin.update(payload.response);
  },
  SET_PRODUCTS_ERROR(state, payload) {
    state.produitsError = payload;
  },
  SET_PRODUCTS_IS_LOADING(state, payload) {
    state.produitsIsLoading = payload;
  },
  SET_TOTAL_ROWS_PRODUCT(state, payload) {
    state.TotalRowProduits = payload;
  }
};
const actions = {
  //recuperation de tous les produits
  async fetchAllProducts({ commit }, payload) {
    const params = {};
    if (payload?.page) {
      params.page = payload.page;
    }
    if (payload?.per_page) {
      params.per_page = payload.per_page;
    }
    commit('SET_PRODUCTS_ERROR', null);
    commit('SET_PRODUCTS_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/produits`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_ALL_PRODUCTS', response.data.data);
      commit('SET_TOTAL_ROWS_PRODUCT', response.data.meta.total);
      commit('SET_PRODUCTS_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_PRODUCTS_ERROR', error?.response?.data?.errors?.nom);
      } else {
        commit('SET_PRODUCTS_ERROR', 'Une erreur est survenue');
      }
      commit('SET_PRODUCTS_IS_LOADING', false);
    }
  },

  //Ajout d'un produit
  async addNewProduct({ commit }, payload) {
    commit('SET_PRODUCTS_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/libre/produit`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_PRODUCT', response.data.data);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return { success: false, error: data };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },

  //Supprimer un produit
  async deleteProduct({ commit }, payload) {
    commit('SET_PRODUCTS_ERROR', null);
    try {
      const response = await axios.delete(
        `${domain}/api/libre/produit/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_PRODUCTS', payload);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        return { success: false, error: error?.response?.data?.error };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },

  //modification d'un produit
  async UpdateProduct({ commit }, payload) {
    commit('SET_PRODUCTS_ERROR', null);
    try {
      const response = await axios.put(
        `${domain}/api/libre/produit`,
        payload.produit,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_PRODUCT', {
        response: response.data.data,
        produitOrigin: payload.produitOrigin
      });
      return { success: true };
    } catch (error) {
      if (error?.response?.data.error) {
        return { success: false, error: error?.response?.data?.error };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
