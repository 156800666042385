import axios from 'axios';
import domain from '@/environment';
import FraisFilialeTh from '../../models/FraisFilialeTh';
const prefixFiliale = 'filiale';

const state = {
  errorFraisFilialeTh: null,
  loagingFraisFilialeTh: false,
  fraisFilialeThData: [],
  totalRowFraisFilialeTh: null
};
const getters = {
  getErrorFraisFilialeTh: state => state.errorFraisFilialeTh,
  getLoagingFraisFilialeTh: state => state.loagingFraisFilialeTh,
  getFraisFilialeThData: state => state.fraisFilialeThData,
  getTotalRowFraisFilialeTh: state => state.totalRowFraisFilialeTh
};
const mutations = {
  ERROR_FRAIS_FILIALE_TH_TO_CONFIG(state, payload) {
    state.errorFraisFilialeTh = payload;
  },
  LOADING_FRAIS_FILIALE_TH_TO_CONFIG(state, payload) {
    state.loagingFraisFilialeTh = payload;
  },
  NEW_FRAIS_FILIALE_TH_CONFIG(state, payload) {
    let existe = state.fraisFilialeThData.filter(item => item.id == payload.id);
    if (existe.length) {
      existe[0].update(payload);
    } else {
      state.fraisFilialeThData.push(FraisFilialeTh.create(payload));
    }
  },
  ALL_FRAIS_FILIAIE_TH_CONFIG(state, payload) {
    state.fraisFilialeThData = payload.map(item => FraisFilialeTh.create(item));
  },
  TOTAL_FRAIS_FILIALE_TH_CONFIG(state, payload) {
    state.totalRowFraisFilialeTh = payload;
  },
  UPDATE_FRAIS_FILIALE_TH_CONFIG(state, payload) {
    for (var i = 0; i < state.fraisFilialeThData.length; i++) {
      if (state.fraisFilialeThData[i].id == payload.data.id) {
        state.fraisFilialeThData[i].update(payload.response);
        return;
      }
    }
    payload.data.update(payload.response);
  }
};
const actions = {
  async newConfigFraisFilialeTh({ commit }, payload) {
    let params = { ...payload };
    params.filiale_id = payload.filiale_id.id;
    try {
      const response = await axios.post(
        domain + `/api/${prefixFiliale}/create_gestion`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('NEW_FRAIS_FILIALE_TH_CONFIG', response.data.data);

      return {
        succes: true
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async fetchAllFraisFilialeTh({ commit }, payload) {
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      year: payload.year
    };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    if (
      payload.filiale &&
      payload.filiale != null &&
      payload.filiale.id &&
      payload.filiale.id != null
    ) {
      params.filiale_id = payload.filiale.id;
    }
    commit('LOADING_FRAIS_FILIALE_TH_TO_CONFIG', true);
    try {
      const response = await axios.get(
        domain + `/api/${prefixFiliale}/all_gestion`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('ALL_FRAIS_FILIAIE_TH_CONFIG', response.data.data.data);
      commit('TOTAL_FRAIS_FILIALE_TH_CONFIG', response.data.data.total);
      commit('LOADING_FRAIS_FILIALE_TH_TO_CONFIG', false);

      return true;
    } catch (error) {
      if (error.response && error.respons.data && error.response.data.error) {
        commit('ERROR_FRAIS_FILIALE_TH_TO_CONFIG', error.response.data.error);
      } else {
        commit('ERROR_FRAIS_FILIALE_TH_TO_CONFIG', ['Une erreur est servenue']);
      }
      commit('LOADING_FRAIS_FILIALE_TH_TO_CONFIG', false);

      return false;
    }
  },

  async updateConfigParamsFraisFilialeTh({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/${prefixFiliale}/edit_gestion`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FRAIS_FILIALE_TH_CONFIG', {
        response: response.data.data,
        data: payload
      });

      return {
        succes: true
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
