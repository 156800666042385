import axios from 'axios';
import domain from '@/environment';
const state = {
  loadingRecapRegie: false,
  allDepotRecapRegie: [],
  recapRegie: []
};
const getters = {
  getLoadingRecapRegie: state => state.loadingRecapRegie,
  getAllDepotRecapRegie: state => state.allDepotRecapRegie,
  getRecapRegie: state => state.recapRegie
};
const mutations = {
  SET_LOADING_RECAP_REGIE(satate, payload) {
    state.loadingRecapRegie = payload;
  },
  SET_All_DEPOT_RECAP(state, payload) {
    state.allDepotRecapRegie = payload;
  },
  SET_RECAP_REGIE(state, payload) {
    if (payload.reset && payload.reset == true) {
      state.recapRegie = [];
    } else {
      let table = [];
      Object.entries(payload).map(([key, value]) => {
        table.push({ depot: key, value: value });
      });
      state.recapRegie = [...table];
    }
  }
};
const actions = {
  async fetchAllDepotRecap({ commit }, payload) {
    commit('SET_LOADING_RECAP_REGIE', true);
    const params = { year: payload.year };
    if (payload.switchMonth && payload.switchMonth == true) {
      params.month = payload.month;
    } else {
      if (payload.semaine != null) {
        params.semaine = payload.semaine;
      }
    }
    try {
      const response = await axios.get(domain + `/api/allDepots`, {
        params: params,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_All_DEPOT_RECAP', response.data.data);
      commit('SET_LOADING_RECAP_REGIE', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_RECAP_REGIE', false);
      return false;
    }
  },
  async getRecapRegieFiltred({ commit, state }, payload) {
    commit('SET_LOADING_RECAP_REGIE', true);
    const params = { year: payload.year, type: payload.type };
    if (payload.switchMonth && payload.switchMonth == true) {
      params.month = payload.month;
    } else {
      if (payload.semaine != null) {
        params.semaine = payload.semaine;
      }
    }
    if (payload.depot && payload.depot.id == null) {
      params.depot = state.allDepotRecapRegie;
    }
    if (payload.depot && payload.depot.id != null) {
      params.depot = [];
      params.depot.push(payload.depot.id);
    }
    try {
      const response = await axios.get(domain + `/api/BillsPerDepot`, {
        params: params,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_RECAP_REGIE', response.data.data);
      commit('SET_LOADING_RECAP_REGIE', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_RECAP_REGIE', false);
      return false;
    }
  },
  async exportExcelRecapRegie({ commit }, payload) {
    commit('SET_LOADING_RECAP_REGIE', true);
    const params = { year: payload.year, type: payload.type };
    if (payload.switchMonth && payload.switchMonth == true) {
      params.month = payload.month;
    } else {
      if (payload.semaine != null) {
        params.semaine = payload.semaine;
      }
    }
    if (payload.depot && payload.depot.id == null) {
      if (state.allDepotRecapRegie.length == 0) {
        commit('SET_LOADING_RECAP_REGIE', false);
        return;
      }
      params.depot = state.allDepotRecapRegie;
    }
    if (payload.depot && payload.depot.id != null) {
      params.depot = [];
      params.depot.push(payload.depot.id);
    }
    try {
      const response = await axios.get(domain + `/api/exportBillsPerDepot`, {
        params: params,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      const name =
        payload.switchMonth && payload.switchMonth == true
          ? 'M' + payload.month
          : payload.semaine;
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute(
        'download',
        'RECAP REGIE - ' + name + ' ' + payload.year + '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_RECAP_REGIE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_RECAP_REGIE', false);
      return false;
    }
  },
  resetRecapRegie({ commit }) {
    commit('SET_RECAP_REGIE', { reset: true });
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
