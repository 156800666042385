import axios from 'axios';
import domain from '@/environment';
import Acompte from '../../models/Acompte';

const state = {
  loadingSuiviAcomptes: false,
  errorSuiviAcomptes: null,
  suiviAcomptes: [],
  totalRowSuiviAcompteTh: null,
  userAcompte: [],
  loadingSelectUserAcount: false
};
const getters = {
  getLoadingSuiviAcomptes: state => state.loadingSuiviAcomptes,
  getErrorSuiviAcomptes: state => state.errorSuiviAcomptes,
  getSuiviAcomptesTable: state => state.suiviAcomptes,
  getTotalRowSuiviAcompteTh: state => state.totalRowSuiviAcompteTh,
  getUserAcompte: state => state.userAcompte,
  getloadingSelectUserAcount: state => state.loadingSelectUserAcount
};
const mutations = {
  SET_LOADING_SUIVI_ACOMPTES(state, payload) {
    state.loadingSuiviAcomptes = payload;
  },
  SET_ERROR_SUIVI_ACOMPTES(state, payload) {
    state.errorSuiviAcomptes = payload;
  },
  SET_SUIVI_ACOMPTES_TABLE_TH(state, payload) {
    state.suiviAcomptes = payload.map(item => Acompte.create(item));
  },
  SET_TOLTAL_ROW_ACPOMPTE_TH(state, payload) {
    state.totalRowSuiviAcompteTh = payload;
  },
  ADD_NEW_ACOMPTE(state, payload) {
    state.suiviAcomptes.push(Acompte.create(payload));
  },
  UPDATE_ACOMPTE(state, payload) {
    payload.acompteToUpdate.update(payload.response);
  },
  DELETE_ACOMPTE(state, payload) {
    for (let index = 0; index < state.suiviAcomptes.length; index++) {
      if (state.suiviAcomptes[index].id == payload.id) {
        state.suiviAcomptes.splice(index, 1);
      }
    }
  },
  SET_USER_ACOMPTE(state, payload) {
    state.userAcompte = payload;
  },
  SET_LOADING_SUIVI_ACOMPTES_SELECT(state, payload) {
    state.loadingSelectUserAcount = payload;
  }
};
const actions = {
  async fetchAllAcomptesTh({ commit }, payload) {
    commit('SET_LOADING_SUIVI_ACOMPTES', true);
    commit('SET_ERROR_SUIVI_ACOMPTES', null);
    const params = {
      page: payload.page,
      per_page: payload.perPage
    };
    try {
      if (payload.user != null && payload.user.id != null) {
        params.employee_id = payload.user.id;
      }
      if (payload.search != null) {
        params.search = payload.search;
      }
      const response = await axios.get(domain + '/api/acompt_TH/all', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params
      });
      commit('SET_SUIVI_ACOMPTES_TABLE_TH', response.data.data.data);
      commit('SET_TOLTAL_ROW_ACPOMPTE_TH', response.data.data.total);
      commit('SET_LOADING_SUIVI_ACOMPTES', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_SUIVI_ACOMPTES', error.response.data.error);
      } else {
        commit('SET_ERROR_SUIVI_ACOMPTES', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_SUIVI_ACOMPTES', false);

      return false;
    }
  },
  async addAcompteTh({ commit }, payload) {
    try {
      const params = {
        numero_dossier:
          payload.filiale + '-' + payload.year + '-' + payload.numero_dossier,
        amount: parseFloat((payload.amount + '').replace(',', '.')),
        employee_id: payload.user.id,
        nom: payload.nom.toUpperCase(),
        prenom: payload.prenom.toUpperCase()
      };
      const response = await axios.post(
        domain + '/api/acompt_TH/create',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_ACOMPTE', response.data.data);
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async updateAcompteTh({ commit }, payload) {
    commit('SET_LOADING_SUIVI_ACOMPTES', true);
    commit('SET_ERROR_SUIVI_ACOMPTES', null);
    try {
      const params = {
        id: payload.row.id,
        numero_dossier: payload.row.numero_dossier,
        amount: parseFloat((payload.row.amount + '').replace(',', '.')),
        employee_id: payload.row.employee.id,
        nom: payload.row.nom,
        prenom: payload.row.prenom
      };
      if (payload.column == 'amount') {
        params.amount = parseFloat((payload.value + '').replace(',', '.'));
      } else {
        params[payload.column] = payload.value.toUpperCase();
      }
      const response = await axios.post(
        domain + '/api/acompt_TH/update',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_ACOMPTE', {
        response: response.data.data,
        acompteToUpdate: payload.row
      });
      commit('SET_LOADING_SUIVI_ACOMPTES', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_SUIVI_ACOMPTES', error.response.data.error);
      } else {
        commit('SET_ERROR_SUIVI_ACOMPTES', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_SUIVI_ACOMPTES', false);
    }
  },
  async deleteAcompteTh({ commit }, payload) {
    commit('SET_LOADING_SUIVI_ACOMPTES', true);
    commit('SET_ERROR_SUIVI_ACOMPTES', null);
    try {
      const response = await axios.get(
        domain + `/api/acompt_TH/delete/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_ACOMPTE', payload);
      commit('SET_LOADING_SUIVI_ACOMPTES', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_SUIVI_ACOMPTES', error.response.data.error);
      } else {
        commit('SET_ERROR_SUIVI_ACOMPTES', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_SUIVI_ACOMPTES', false);
    }
  },
  async fetchUserAcompte({ commit }, payload) {
    commit('SET_LOADING_SUIVI_ACOMPTES_SELECT', true);
    commit('SET_ERROR_SUIVI_ACOMPTES', null);
    try {
      const response = await axios.get(domain + '/api/acompt_TH/employee', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: payload
      });
      commit('SET_USER_ACOMPTE', response.data.data);
      commit('SET_LOADING_SUIVI_ACOMPTES_SELECT', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_SUIVI_ACOMPTES', error.response.data.error);
      } else {
        commit('SET_ERROR_SUIVI_ACOMPTES', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_SUIVI_ACOMPTES_SELECT', false);

      return false;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
