import axios from 'axios';
import domain from '@/environment';
import SuiviPaiementTh from '../../models/SuiviPaiementTh';
import store from '../index';
let requestSuiviPaiementThFiltred = null;
const state = {
  chargementSuiviPaiementTh: false,
  SuiviPaiementThTable: [],
  totalRowSuiviPaiementTh: null,
  errorSuiviPaiementTh: null
};
const getters = {
  getChargementSuiviPaiementTh: state => state.chargementSuiviPaiementTh,
  getSuiviPaiementThTable: state => state.SuiviPaiementThTable,
  getTotalRowSuiviPaiementTh: state => state.totalRowSuiviPaiementTh,
  getErrorSuiviPaiementTh: state => state.errorSuiviPaiementTh
};
const mutations = {
  SET_LOADING_SUIVI_PAIEMENT_TH(state, payload) {
    state.chargementSuiviPaiementTh = payload;
  },
  SUIVI_PAIEMENT_TH_FILTRED(state, payload) {
    state.SuiviPaiementThTable = payload.map(item =>
      SuiviPaiementTh.create(item)
    );
  },
  SET_TOTAL_ROW_SUIVI_PAIEMENT_TH(state, payload) {
    state.totalRowSuiviPaiementTh = payload;
  },
  UPDATE_FACTURE_SUIVI_PAIEMENT_TH(state, payload) {
    if (payload.data) {
      payload.data.updateCoef({
        item: payload.row,
        response: payload.response,
        column: payload.column,
        value: payload.value
      });
      let column =
        payload.column == 'commission'
          ? 'montant_' + payload.column + 's_cee'
          : 'montant_' + payload.column + '_cee';
      let value = 'total_pu_' + payload.column;
      store.state.appelPaiementObligee.totalAppelPaiementObligee[value] = (
        parseFloat(
          (
            store.state.appelPaiementObligee.totalAppelPaiementObligee[value] +
            ''
          ).replace(',', '.')
        ) -
        parseFloat((payload.row[payload.column] + '').replace(',', '.')) +
        parseFloat((payload.response[column] + '').replace(',', '.'))
      ).toFixed(2);
      if (payload.column == 'prime') {
        store.state.appelPaiementObligee.totalAppelPaiementObligee.total_pu_missing_prime = (
          parseFloat(
            (
              store.state.appelPaiementObligee.totalAppelPaiementObligee
                .total_pu_missing_prime + ''
            ).replace(',', '.')
          ) -
          parseFloat((payload.row['missing_prime'] + '').replace(',', '.')) +
          parseFloat(
            (payload.response['montant_missing_prime_cee'] + '').replace(
              ',',
              '.'
            )
          )
        ).toFixed(2);
      }
    }
    payload.row.update(payload.response);
  },
  SET_ERROR_SUIVI_PAIEMENT_OBLIGEE_TH(state, payload) {
    state.errorSuiviPaiementTh = payload;
  }
};
const actions = {
  async getSuiviPaiementThFiltred({ commit }, payload) {
    commit('SET_ERROR_SUIVI_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_SUIVI_PAIEMENT_TH', true);
    try {
      if (requestSuiviPaiementThFiltred) {
        requestSuiviPaiementThFiltred.cancel('cancel-request');
      }
      requestSuiviPaiementThFiltred = axios.CancelToken.source();
      const params = {
        page: payload.page,
        per_page: payload.perPage,
        annee: payload.year
      };
      if (payload.month != null) {
        params.mois = payload.month;
      }
      if (payload.depot != null) {
        params.depot = payload.depot;
      }
      if (payload.organisme != null) {
        params.organisme = payload.organisme;
      }
      const response = await axios.get(domain + `/api/get_suivi_th`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestSuiviPaiementThFiltred.token
      });
      commit('SUIVI_PAIEMENT_TH_FILTRED', response.data.data.data);
      commit('SET_TOTAL_ROW_SUIVI_PAIEMENT_TH', response.data.data.total);
      commit('SET_LOADING_SUIVI_PAIEMENT_TH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_SUIVI_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_SUIVI_PAIEMENT_TH', false);
        commit('SET_ERROR_SUIVI_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }

      return false;
    }
  },
  async updateSuiviPaiementTh({ commit }, payload) {
    commit('SET_ERROR_SUIVI_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_SUIVI_PAIEMENT_TH', true);

    const params = {
      column: payload.column,
      value: payload.value,
      id: payload.row.id
    };

    try {
      const response = await axios.post(
        domain + `/api/update_suivi_th`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        row: payload.row,
        column: payload.valueToShow,
        value: payload.value,
        data: payload.data
      };
      commit('UPDATE_FACTURE_SUIVI_PAIEMENT_TH', obj);
      commit('SET_LOADING_SUIVI_PAIEMENT_TH', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_SUIVI_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_SUIVI_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }
      commit('SET_LOADING_SUIVI_PAIEMENT_TH', false);

      return false;
    }
  },

  async exportExcelSuiviPaiementTh({ commit }, payload) {
    commit('SET_ERROR_SUIVI_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_SUIVI_PAIEMENT_TH', true);
    const params = {
      annee: payload.year
    };
    if (payload.month != null) {
      params.mois = payload.month;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    try {
      const response = await axios.get(domain + `/api/export_suivi_th`, {
        params: params,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute(
        'download',
        'SUIVI DE PAIEMENT BAR TH 164 - ' + payload.year + '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_SUIVI_PAIEMENT_TH', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_SUIVI_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_SUIVI_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }
      commit('SET_LOADING_SUIVI_PAIEMENT_TH', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
