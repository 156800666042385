export default class Salary {
  constructor(data) {
    this.id = data.id;
    // Prévisiteur && Commercial terrain
    this.totalm2 = data.totalm2;
    this.m2previsite = data.m2previsite;
    this.m2vente = data.m2vente;
    this.m2previsitempr = data.m2previsitempr;
    this.m2ventempr = data.m2ventempr;
    this.TH_total = data.TH_total;
    this.prixM2vente = data.prixM2vente;
    this.prixM2previsite = data.prixM2previsite;
    this.prixM2previsiteMPR = data.prixM2previsiteMPR;
    this.prixM2venteMPR = data.prixM2venteMPR;
    this.venteENeuro = data.venteENeuro;
    this.previsiteENeuro = data.previsiteENeuro;
    this.how_calculate_venteENeuro_number =
      data.how_calculate_venteENeuro_number;
    this.how_calculate_venteENeuro_text = data.how_calculate_venteENeuro_text;
    this.how_calculate_previsiteENeuro_number =
      data.how_calculate_previsiteENeuro_number;
    this.how_calculate_previsiteENeuro_text =
      data.how_calculate_previsiteENeuro_text;
    this.total = data.total;

    //Poseur && Co-poseur
    this.m2_iti = data.m2_iti;
    this.m2_isloer = data.m2_isloer;
    this.m2_iti_3_euro = data.m2_iti_3_euro;
    this.m2_iti_2_euro = data.m2_iti_2_euro;
    this.m2_mpr = data.m2_mpr;
    this.m2_rampant_mpr = data.m2_rampant_mpr;
    this.m2_iti_mpr = data.m2_iti_mpr;
    this.prix_rampant_mpr = data.prix_rampant_mpr;
    this.prix_iti_mpr = data.prix_iti_mpr;
    this.total_mpr_en_euro = data.total_mpr_en_euro;
    this.total_payer_1_euro = data.total_payer_1_euro;
    this.total_payer_2_euro = data.total_payer_2_euro;
    this.total_payer_3_euro = data.total_payer_3_euro;
    this.total_iti_payer = data.total_iti_payer;

    //Sédentaire && Confirmateur
    this.m2_sedentaire_mpr = data.m2_sedentaire_mpr;
    this.m2_sedentaire_iso = data.m2_sedentaire_iso;
    this.sedentaire_iso_en_euro = data.sedentaire_iso_en_euro;
    this.sedentaire_mpr_en_euro = data.sedentaire_mpr_en_euro;
    this.fix = data.fix;
    this.prix_iso_interne = data.prix_iso_interne;
    this.prix_iso_externe = data.prix_iso_externe;
    this.prix_mpr_interne = data.prix_mpr_interne;
    this.prix_mpr_externe = data.prix_mpr_externe;

    this.variable_vmc = data.variable_vmc;
    this.variable_pac = data.variable_pac;
    this.IGD = data.IGD;
    this.additional_prime = data.additional_prime;
    this.prime_qualite = data.prime_qualite;
    this.prime_objectif = data.prime_objectif;
    this.prime_sav = data.prime_sav;
    this.other_prime_name = data.other_prime_name;
    this.other_prime_value = data.other_prime_value;
    this.deducation = data.deducation;
    this.employee = data.employee;
    this.semaine = data.semaine;
    this.type = data.type;
    this.comment = data.comment;
    this.total_variables = data.total_variables;
    this.payed_at = data.payed_at;
    this.bill = data.bill;
    this.facture_type = data.facture_type;
    this.validation = data.validation;

    this.interne = data.interne;
    this.externe = data.externe;
    this.ISO_download = data.ISO_download;
    this.TH_download = data.TH_download;
  }
  update(data) {
    // Prévisiteur && Commercial terrain
    this.totalm2 = data.totalm2;
    this.m2previsite = data.m2previsite;
    this.m2vente = data.m2vente;
    this.m2previsitempr = data.m2previsitempr;
    this.m2ventempr = data.m2ventempr;
    this.TH_total = data.TH_total;
    this.prixM2vente = data.prixM2vente;
    this.prixM2previsite = data.prixM2previsite;
    this.prixM2previsiteMPR = data.prixM2previsiteMPR;
    this.prixM2venteMPR = data.prixM2venteMPR;
    this.venteENeuro = data.venteENeuro;
    this.previsiteENeuro = data.previsiteENeuro;
    this.how_calculate_venteENeuro_number =
      data.how_calculate_venteENeuro_number;
    this.how_calculate_venteENeuro_text = data.how_calculate_venteENeuro_text;
    this.how_calculate_previsiteENeuro_number =
      data.how_calculate_previsiteENeuro_number;
    this.how_calculate_previsiteENeuro_text =
      data.how_calculate_previsiteENeuro_text;
    this.total = data.total;

    //Poseur && Co-poseur
    this.m2_iti = data.m2_iti;
    this.m2_isloer = data.m2_isloer;
    this.m2_iti_3_euro = data.m2_iti_3_euro;
    this.m2_iti_2_euro = data.m2_iti_2_euro;
    this.m2_mpr = data.m2_mpr;
    this.m2_rampant_mpr = data.m2_rampant_mpr;
    this.m2_iti_mpr = data.m2_iti_mpr;
    this.prix_rampant_mpr = data.prix_rampant_mpr;
    this.prix_iti_mpr = data.prix_iti_mpr;
    this.total_mpr_en_euro = data.total_mpr_en_euro;
    this.total_payer_1_euro = data.total_payer_1_euro;
    this.total_payer_2_euro = data.total_payer_2_euro;
    this.total_payer_3_euro = data.total_payer_3_euro;
    this.total_iti_payer = data.total_iti_payer;

    //Sédentaire && Confirmateur
    this.m2_sedentaire_mpr = data.m2_sedentaire_mpr;
    this.m2_sedentaire_iso = data.m2_sedentaire_iso;
    this.sedentaire_iso_en_euro = data.sedentaire_iso_en_euro;
    this.sedentaire_mpr_en_euro = data.sedentaire_mpr_en_euro;
    this.fix = data.fix;
    this.prix_iso_interne = data.prix_iso_interne;
    this.prix_iso_externe = data.prix_iso_externe;
    this.prix_mpr_interne = data.prix_mpr_interne;
    this.prix_mpr_externe = data.prix_mpr_externe;

    this.variable_vmc = data.variable_vmc;
    this.variable_pac = data.variable_pac;
    this.IGD = data.IGD;
    this.additional_prime = data.additional_prime;
    this.prime_qualite = data.prime_qualite;
    this.prime_objectif = data.prime_objectif;
    this.prime_sav = data.prime_sav;
    this.other_prime_name = data.other_prime_name;
    this.other_prime_value = data.other_prime_value;
    this.deducation = data.deducation;
    this.employee = data.employee;
    this.semaine = data.semaine;
    this.type = data.type;
    this.comment = data.comment;
    this.total_variables = data.total_variables;
    this.payed_at = data.payed_at;
    this.bill = data.bill;
    this.facture_type = data.facture_type;
    this.validation = data.validation;

    this.interne = data.interne;
    this.externe = data.externe;
    this.ISO_download = data.ISO_download;
    this.TH_download = data.TH_download;
  }
  static create(data) {
    return new Salary(data);
  }
}
