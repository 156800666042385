export default class FSTANDAR {
  constructor(data) {
    this.id = data.id;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.h1Combustible = data.h1Combustible;
    this.h1Electrique = data.h1Electrique;
    this.h2Combustible = data.h2Combustible;
    this.h2Electrique = data.h2Electrique;
    this.h3Combustible = data.h3Combustible;
    this.h3Electrique = data.h3Electrique;
  }
  update(data) {
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.h1Combustible = data.h1Combustible;
    this.h1Electrique = data.h1Electrique;
    this.h2Combustible = data.h2Combustible;
    this.h2Electrique = data.h2Electrique;
    this.h3Combustible = data.h3Combustible;
    this.h3Electrique = data.h3Electrique;
  }
  static create(data) {
    return new FSTANDAR(data);
  }
}
