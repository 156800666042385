export default class FraisFilialeTh {
  constructor(data) {
    this.id = data.id;
    this.month = data.month;
    this.year = data.year;
    this.GE_compta = data.GE_compta;
    this.GE_RH = data.GE_RH;
    this.created_at = data.created_at;
    this.filiale = data.filiale;
    this.filiale_id = data.filiale_id;
    this.updated_at = data.updated_at;
  }
  update(data) {
    this.GE_compta = data.GE_compta;
    this.GE_RH = data.GE_RH;
  }
  static create(data) {
    return new FraisFilialeTh(data);
  }
}
