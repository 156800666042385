import produitFactureLibre from './produitFactureLibreTh';
export default class FactureLibreTh {
  constructor(data) {
    this.id = data.id;
    this.devise = data.devise;
    this.type = data.type;
    this.num = data.num;
    this.lieu_creation = data.lieu_creation;
    this.categorie_id = data.categorie_id;
    this.categorie = data.categorie;
    this.objet = data.objet;
    this.mode_reglement = data.mode_reglement;
    this.date_limite_reglement = data.date_limite_reglement;
    this.date_exact_limite_reglement = data.date_exact_limite_reglement;
    this.etat = data.etat;
    this.famille = data.famille;
    this.siren_tva = data.siren_tva;
    this.siren_value = data.siren_value;
    this.tva_value = data.tva_value;
    this.montant_payer = data.montant_payer;
    this.comment_calculer_facture = data.comment_calculer_facture;
    this.ajout_reduction_global = data.ajout_reduction_global;
    this.swift = data.swift;
    this.iban = data.iban;
    this.objet_color = data.objet_color;
    this.text_color = data.text_color;
    this.motif_avoir = data.motif_avoir;
    this.total_net_ht = data.total_net_ht;
    this.user_id = data.user_id;
    this.total_ht = data.total_ht;
    this.total_tva = data.total_tva;
    this.total_ttc = data.total_ttc;
    this.total_net_ht_data_base = data.total_net_ht_data_base;
    this.total_tva_data_base = data.total_tva_data_base;
    this.total_ttc_data_base = data.total_ttc_data_base;
    this.num_proforma = data.num_proforma;
    this.confirme = data.confirme;
    this.total_ttc_apres_reduction = data.total_ttc_apres_reduction;
    this.total_ht_apres_reduction = data.total_ht_apres_reduction;
    this.pu_ht_apres_reduction = data.pu_ht_apres_reduction;
    this.check = false;
    this.date_paiement = data.date_paiement;
    this.date_creation = data.date_creation;
    this.vendeur = data.vendeur;
    this.acheteur = data.acheteur;
    this.openSelect = false;
    this.type_avoir = data.type_avoir;
    this.description = data.description;
    this.produits = data.produits.map(
      item => new produitFactureLibre.create(item)
    );
    this.FactureAvoir = data.FactureAvoir.map(item => {
      return { ...item, checkAvoir: false };
    });
    this.disabled = data.disabled;
    this.checkGlobale = false;
  }
  updateStatue(data) {
    this.etat = data.etat;
    this.date_paiement = data.date_paiement;
  }
  update(data) {
    this.devise = data.devise;
    this.type = data.type;
    this.numero = data.numero;
    this.dateCreation = data.dateCreation;
    this.lieu_creation = data.lieu_creation;
    this.categorie_id = data.categorie_id;
    this.categorie = data.categorie;
    this.objet = data.objet;
    this.famille = data.famille;
    this.mode_reglement = data.mode_reglement;
    this.date_limite_reglement = data.date_limite_reglement;
    this.date_exact_limite_reglement = data.date_exact_limite_reglement;
    this.etat = data.etat;
    this.montant_payer = data.montant_payer;
    this.confirme = data.confirme;
    this.siren_tva = data.siren_tva;
    this.siren_value = data.siren_value;
    this.tva_value = data.tva_value;
    this.comment_calculer_facture = data.comment_calculer_facture;
    this.ajout_reduction_global = data.ajout_reduction_global;
    this.swift = data.swift;
    this.iban = data.iban;
    this.check = data.check;
    this.objet_color = data.objet_color;
    this.text_color = data.text_color;
    this.motif_avoir = data.motif_avoir;
    this.user_id = data.user_id;
    this.num_proforma = data.num_proforma;
    this.total_ht = data.total_ht;
    this.total_net_ht = data.total_net_ht;
    this.total_tva = data.total_tva;
    this.total_ttc = data.total_ttc;
    this.total_net_ht_data_base = data.total_net_ht_data_base;
    this.total_tva_data_base = data.total_tva_data_base;
    this.total_ttc_data_base = data.total_ttc_data_base;
    this.total_ttc_apres_reduction = data.total_ttc_apres_reduction;
    this.total_ht_apres_reduction = data.total_ht_apres_reduction;
    this.pu_ht_apres_reduction = data.pu_ht_apres_reduction;
    this.date_paiement = data.date_paiement;
    this.vendeur = data.vendeur;
    this.acheteur = data.acheteur;
    this.type_avoir = data.type_avoir;
    this.description = data.description;
    this.produits = data.produits.map(
      item => new produitFactureLibre.create(item)
    );
    this.disabled = data.disabled;
    this.FactureAvoir = data.FactureAvoir.map(item => {
      return { ...item, checkAvoir: false };
    });
    this.FactureAvoir = data.FactureAvoir;
    // this.FactureAvoir.check = data.FactureAvoir.check;
  }
  static create(data) {
    return new FactureLibreTh(data);
  }
}
