import axios from 'axios';
import domain from '@/environment';
import Paie from '../../models/Paie';
import Salary from '../../models/Salary';
import Chart from '../../models/Chart';
const state = {
  loagingUserPaie: false,
  errorUserPaie: null,
  totalRowUser: null,
  UserPaies: [],
  listEmployee: [],
  listType: [],
  chargementPaie: false,
  Depots: [],
  Sources: [],
  SalaryTableValidation: [],
  totalRowSalary: null,
  erronInFetchActiveUser: null,
  statistuqueSalary: [],
  loadingStatistiqueSalary: false,
  loadingStatistiqueSalaryRegie: false,
  statistuqueSalaryRegie: [],
  totalRegie: [],
  totalEmploye: [],
  depotsTrashed: [],
  listEmployeeByType: [],
  onlineTypeEmloyees: [],
  activeEmployyes: [],
  statistiqueSalarySousRegie: [],
  missigSemaineSalarie: null
};
const getters = {
  getloagingUserPaies: state => state.loagingUserPaie,
  getErrorUserPaies: state => state.errorUserPaie,
  getTotalRowUser: state => state.totalRowUser,
  getAllUser: state => state.UserPaies,
  getAllEmployee: state => state.listEmployee,
  getAllType: state => state.listType,
  getChargementPaie: state => state.chargementPaie,
  getDepots: state => state.Depots,
  getSources: state => state.Sources,
  getTotalRowUserSalary: state => state.totalRowSalary,
  getUserSalary: state => state.SalaryTableValidation,
  getErronInFetchActiveUser: state => state.erronInFetchActiveUser,
  getStatistuqueSalary: state => state.statistuqueSalary,
  getloadingStatistiqueSalary: state => state.loadingStatistiqueSalary,
  getloadingStatistiqueRegieSalary: state =>
    state.loadingStatistiqueSalaryRegie,
  getStatistuqueSalaryRegie: state => state.statistuqueSalaryRegie,
  getTotalRegie: state => state.totalRegie,
  getTotalEmploye: state => state.totalEmploye,
  getDepotsTrashed: state => state.depotsTrashed,
  getListEmployeeByType: state => state.listEmployeeByType,
  getOnlineTypeEmloyees: state => state.onlineTypeEmloyees,
  getActiveEmployyes: state => state.activeEmployyes,
  getStatistiqueSalarySousRegie: state => state.statistiqueSalarySousRegie,
  getMissigSemaineSalarie: state => state.missigSemaineSalarie
};
const mutations = {
  SET_LOADING_USER_PAIE(state, payload) {
    state.loagingUserPaie = payload;
  },
  SET_ERROR_USER_PAIE(state, payload) {
    state.errorUserPaie = payload;
  },
  SET_TOTAL_USER(state, payload) {
    state.totalRowUser = payload;
  },
  NEW_PAIE(state, payload) {
    state.UserPaies = payload.map(item => Paie.create(item));
  },
  NEW_CONFIG_PAIE(state, payload) {
    var existe = false;
    if (Array.isArray(payload)) {
      payload.map(item => {
        existe = false;
        for (let index = 0; index < state.UserPaies.length; index++) {
          if (state.UserPaies[index].id == item.id) {
            existe = true;
            state.UserPaies[index].update(item);
            break;
          }
        }
        if (existe == false) {
          state.listEmployee.map(itemuser => {
            if (itemuser.id == item.employee_id) {
              item.employee = itemuser;
            }
          });
          state.UserPaies.push(Paie.create(item));
        }
      });
    } else {
      for (let index = 0; index < state.UserPaies.length; index++) {
        if (state.UserPaies[index].id == payload.id) {
          existe = true;
          state.UserPaies[index].update(payload);
          break;
        }
      }
      if (existe == false) {
        state.listEmployee.map(item => {
          if (item.id == payload.employee_id) {
            payload.employee = item;
          }
        });
        state.UserPaies.push(Paie.create(payload));
      }
    }
  },
  ALL_EMPLOYEE(state, payload) {
    state.listEmployee = payload;
  },
  ALL_TYPE(state, payload) {
    state.listType = payload;
  },
  SET_LOADING_Paie(state, payload) {
    state.chargementPaie = payload;
  },
  ALL_DEPOT(state, payload) {
    state.Depots = payload;
  },
  ALL_DEPOT_TRASHED(state, payload) {
    state.depotsTrashed = payload;
  },
  ALL_SOURCE(state, payload) {
    state.Sources = payload;
  },
  SALARY_FILTRED(state, payload) {
    state.SalaryTableValidation = payload.map(item => Salary.create(item));
  },
  SET_TOTAL_ROW_Salary(state, payload) {
    state.totalRowSalary = payload;
  },
  UPDATE_ROW_SALARY(state, payload) {
    if (payload.multiple && payload.multiple == true) {
      payload.row.validation = payload.validation;
    } else {
      payload.row.update(payload.response);
    }
  },
  SET_ERROR_IN_FETCH_USER(state, payload) {
    state.erronInFetchActiveUser = payload;
  },
  SET_STATISTIQUE_SALARY(state, payload) {
    state.totalEmploye[0] = payload.total;
    var type = [{ name: 'Tous', pourcentage: 0 }, ...payload.data];
    if (state.statistuqueSalary.length === 0) {
      state.statistuqueSalary = type.map(item => Chart.create(item));
    } else {
      var itemtoupdate;
      state.statistuqueSalary.map(item => {
        (itemtoupdate = type.filter(row => {
          if (row.name == item.name) return row;
        })),
          item.update(itemtoupdate[0]);
      });
    }
  },

  LOADING_STATIQTIC_EMLOYE_SALARY(state, payload) {
    state.loadingStatistiqueSalary = payload;
  },
  LOADING_STATIQTIC_REGIE_SALARY(state, payload) {
    state.loadingStatistiqueSalaryRegie = payload;
  },
  SET_STATISTIQUE_SALARY_REGIE(state, payload) {
    state.totalRegie[0] = payload.total;
    state.totalRegie[1] = payload.payed_already;
    state.totalRegie[2] = payload.not_payed_yet;
    var type = [{ name: 'Tous', pourcentage: 0 }, ...payload.data];
    if (state.statistuqueSalaryRegie.length === 0) {
      state.statistuqueSalaryRegie = type.map(item => Chart.create(item));
    } else {
      var itemtoupdate;
      state.statistuqueSalaryRegie.map(item => {
        (itemtoupdate = type.filter(row => {
          if (row.name == item.name) return row;
        })),
          item.update(itemtoupdate[0]);
      });
    }
  },
  ALL_EMPLOYEE_BY_TYPE(state, payload) {
    state.listEmployeeByType = payload;
  },
  SET_ONLINE_TYPE(state, payload) {
    state.onlineTypeEmloyees = payload;
  },
  ALL_ACTIVE_EMPLOYEE_BY_TYPE(state, payload) {
    state.activeEmployyes = payload;
  },
  SET_PRIME(state, payload) {
    payload.row.update(payload.responce);
  },
  SET_STATISTIQUE_SALARY_SOUS_REGIE(state, payload) {
    state.totalRegie[0] = payload.total;
    state.totalRegie[1] = payload.payed_already;
    state.totalRegie[2] = payload.not_payed_yet;
    var type = [{ name: 'Tous', pourcentage: 0 }, ...payload.data];
    if (state.statistiqueSalarySousRegie.length === 0) {
      state.statistiqueSalarySousRegie = type.map(item => Chart.create(item));
    } else {
      var itemtoupdate;
      state.statistiqueSalarySousRegie.map(item => {
        (itemtoupdate = type.filter(row => {
          if (row.name == item.name) return row;
        })),
          item.update(itemtoupdate[0]);
      });
    }
  },
  SET_MISSING_SEMAINE_SALARIE(state, payload) {
    state.missigSemaineSalarie = payload;
  }
};
const actions = {
  async fetchUserConnectedWithType({ commit }, payload) {
    commit('SET_ERROR_IN_FETCH_USER', null);

    if (payload.type == null || payload.semaine == null || payload.year == null)
      return;
    const params = {
      semaine: payload.semaine,
      year: payload.year,
      type: payload.type
    };
    try {
      const responce = await axios.get(domain + `/api/onlineEmployees`, {
        params: params,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('ALL_EMPLOYEE_BY_TYPE', responce.data.employee);
      commit('ALL_TYPE', responce.data.type);
      return true;
    } catch (error) {
      commit('SET_ERROR_IN_FETCH_USER', error.response.data.error);
      return false;
    }
  },
  async fetchAllUserAndType({ commit }, payload) {
    const params = {};
    if (payload && payload.type) {
      params.type = payload.type;
    }
    try {
      const responce = await axios.get(domain + `/api/allEmployee`, {
        params: params,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('ALL_EMPLOYEE', responce.data.employee);
      commit('ALL_TYPE', responce.data.type);
      return true;
    } catch (error) {
      return false;
    }
  },
  async configUserPaie({ commit }, payload) {
    commit('SET_LOADING_USER_PAIE', true);
    commit('SET_ERROR_USER_PAIE', null);
    const body = {
      IGD: parseFloat((payload.userPaie.IGD + '').replace(',', '.')),
      additional_prime: parseFloat(
        (payload.userPaie.additional_prime + '').replace(',', '.')
      ),
      fix: parseFloat((payload.userPaie.fix + '').replace(',', '.')),
      variable_101: parseFloat(
        (payload.userPaie.variable_101 + '').replace(',', '.')
      ),
      variable_102_standard: parseFloat(
        (payload.userPaie.variable_102_Standard + '').replace(',', '.')
      ),
      variable_102_ITI: parseFloat(
        (payload.userPaie.variable_102_ITI + '').replace(',', '.')
      ),
      variable_102_ITI_placo: parseFloat(
        (payload.userPaie.variable_102_ITI_placo + '').replace(',', '.')
      ),
      variable_103: parseFloat(
        (payload.userPaie.variable_103 + '').replace(',', '.')
      ),
      variable_vmc: parseFloat(
        (payload.userPaie.variable_vmc + '').replace(',', '.')
      ),
      variable_pac: parseFloat(
        (payload.userPaie.variable_pac + '').replace(',', '.')
      ),
      prime_qualite: parseFloat(
        (payload.userPaie.prime_qualite + '').replace(',', '.')
      ),
      prime_objectif: parseFloat(
        (payload.userPaie.prime_objectif + '').replace(',', '.')
      ),
      prime_sav: parseFloat(
        (payload.userPaie.prime_sav + '').replace(',', '.')
      ),
      other_prime_name: payload.userPaie.other_prime_name,
      other_prime_value: parseFloat(
        (payload.userPaie.other_prime_value + '').replace(',', '.')
      ),
      deducation: parseFloat(
        (payload.userPaie.deducation + '').replace(',', '.')
      ),

      interne_mpr_oui: parseFloat(
        (payload.userPaie.interne_mpr_oui + '').replace(',', '.')
      ),
      externe_mpr_oui: parseFloat(
        (payload.userPaie.externe_mpr_oui + '').replace(',', '.')
      ),
      interne_mpr_non: parseFloat(
        (payload.userPaie.interne_mpr_non + '').replace(',', '.')
      ),
      externe_mpr_non: parseFloat(
        (payload.userPaie.externe_mpr_non + '').replace(',', '.')
      ),
      previsite_mpr_oui: parseFloat(
        (payload.userPaie.previsite_mpr_oui + '').replace(',', '.')
      ),
      vente_mpr_oui: parseFloat(
        (payload.userPaie.vente_mpr_oui + '').replace(',', '.')
      ),
      previsite_mpr_non: parseFloat(
        (payload.userPaie.previsite_mpr_non + '').replace(',', '.')
      ),
      vente_mpr_non: parseFloat(
        (payload.userPaie.vente_mpr_non + '').replace(',', '.')
      ),
      surface_mpr_oui: parseFloat(
        (payload.userPaie.surface_mpr_oui + '').replace(',', '.')
      ),
      surface_iti_mpr_oui: parseFloat(
        (payload.userPaie.surface_iti_mpr_oui + '').replace(',', '.')
      ),
      surface_mpr_non: parseFloat(
        (payload.userPaie.surface_mpr_non + '').replace(',', '.')
      ),
      // surface_iti_mpr_non: parseFloat(
      //   (payload.userPaie.surface_iti_mpr_non + '').replace(',', '.')
      // ),
      TH_vente: parseFloat((payload.userPaie.TH_vente + '').replace(',', '.')),
      TH_previsite: parseFloat(
        (payload.userPaie.TH_previsite + '').replace(',', '.')
      ),
      semaine: payload.userPaie.semaine,
      type: payload.userPaie.type,
      year: payload.userPaie.year
    };
    body.facture_type = payload.userPaie.facture_type;
    if (payload.userPaie.employee.id != null) {
      if (
        payload.userPaie.type == 'Admin GE' ||
        payload.userPaie.type == 'Responsable planning'
      ) {
        body.IGD = 0;
        body.fix = 0;
        body.variable_102_standard = 0;
        body.variable_102_ITI = 0;
        body.variable_102_ITI_placo = 0;
        body.variable_103 = 0;
        body.variable_vmc = 0;
        body.variable_pac = 0;
        body.prime_qualite = 0;
        body.prime_objectif = 0;
        body.prime_sav = 0;
        body.other_prime_name = 'autre';
        body.other_prime_value = 0;
        body.deducation = 0;
        body.variable_101 = 0;
        body.additional_prime = 0;
      }
      body.id = payload.userPaie.employee.id;
      body.variable_source = parseFloat(
        (payload.userPaie.variable_source + '').replace(',', '.')
      );
      body.source = payload.userPaie.source;
      body.variable_depot = parseFloat(
        (payload.userPaie.variable_depot + '').replace(',', '.')
      );
      body.depot = payload.userPaie.depot;
    }
    if (
      (payload.userPaie.thType && payload.userPaie.thType == true) ||
      (payload.thType && payload.thType == true)
    ) {
      body.TH_ITE = parseFloat(
        (payload.userPaie.TH_ITE + '').replace(',', '.')
      );
      body.TH_ITI = parseFloat(
        (payload.userPaie.TH_ITI + '').replace(',', '.')
      );
      body.TH_PAC_RO = parseFloat(
        (payload.userPaie.TH_PAC_RO + '').replace(',', '.')
      );
      body.TH_PAC_Balon_thermo = parseFloat(
        (payload.userPaie.TH_PAC_Balon_thermo + '').replace(',', '.')
      );
      body.TH_RR = parseFloat((payload.userPaie.TH_RR + '').replace(',', '.'));
      body.TH_interne = parseFloat(
        (payload.userPaie.TH_interne + '').replace(',', '.')
      );
      body.TH_externe = parseFloat(
        (payload.userPaie.TH_externe + '').replace(',', '.')
      );
      body.TH_condition = payload.userPaie.TH_condition;
      body.TH_value = parseFloat(
        (payload.userPaie.TH_value + '').replace(',', '.')
      );
    }
    try {
      const responce = await axios.post(
        domain + `/api/editEmployeeWeeklySalary`,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('NEW_CONFIG_PAIE', responce.data.salary);
      commit('SET_LOADING_USER_PAIE', false);
      const typevalue = await axios.get(domain + `/api/allEmployee`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('ALL_TYPE', typevalue.data.type);
      return true;
    } catch (error) {
      commit('SET_ERROR_USER_PAIE', error);
      commit('SET_LOADING_USER_PAIE', false);
      return false;
    }
  },
  async fetchUserFiltred({ commit }, payload) {
    commit('SET_LOADING_Paie', true);
    const body = {
      year: payload.year.toString(),
      page: payload.page,
      per_page: payload.per_page
    };
    if (payload.id && payload.id.id && payload.id.id != null) {
      body.id = payload.id.id;
    }
    if (payload.semaine != null) {
      body.semaine = payload.semaine;
    }
    if (payload.type != null) {
      body.type = payload.type;
    }
    try {
      const response = await axios.get(domain + `/api/allSalaryFiltred`, {
        params: body,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('NEW_PAIE', response.data.salary.data);
      commit('SET_TOTAL_USER', response.data.salary.total);
      commit('SET_LOADING_Paie', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_Paie', false);
      return false;
    }
  },
  async donwloadSalary({ commit }, payload) {
    commit('SET_LOADING_Paie', true);
    try {
      const body = {};
      if (payload.semaine != null) {
        body.semaine = payload.semaine;
      }
      if (payload.type != null) {
        body.type = payload.type;
      }
      if (
        payload.employee_id &&
        payload.employee_id.id &&
        payload.employee_id.id != null
      ) {
        body.employee_id = payload.employee_id.id;
      }
      const response = await axios.get(
        domain + `/api/downloadSalary/${payload.year}`,
        {
          params: body,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute(
        'download',
        'paramètrage des paies A' + payload.year + '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_Paie', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_Paie', false);
      return false;
    }
  },
  async fetchAllRegieAndSource({ commit }) {
    try {
      const responce = await axios.get(domain + `/api/getAllRegieAndDepot`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('ALL_DEPOT', responce.data.depot);
      commit('ALL_SOURCE', responce.data.regie);
      return true;
    } catch (error) {
      return false;
    }
  },
  async fetchAllDepotsWithTrashed({ commit }) {
    try {
      const responce = await axios.get(domain + `/api/allDepotsWithTrashed`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('ALL_DEPOT_TRASHED', responce.data.depot);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getSalaryFiltred({ commit }, payload) {
    commit('SET_LOADING_Paie', true);
    try {
      const params = {
        page: payload.page,
        per_page: payload.perPage,
        salarie: 'oui'
      };
      if (payload.semaine != null && payload.switchWeek == true) {
        params.semaine = payload.semaine;
      }
      if (payload.month != null && payload.switchMonth == true) {
        params.month = payload.month;
      }
      if (
        payload.employee_id &&
        payload.employee_id.id &&
        payload.employee_id.id != null
      ) {
        params.employee_id = payload.employee_id.id;
      }
      if (payload.validation != null) {
        params.validation = payload.validation;
      }
      if (payload.depot != null) {
        params.depot = payload.depot;
      }
      if (payload.type !== null) {
        params.type = payload.type;
      }
      if (payload.filterProjectType !== null) {
        params.project_type = payload.filterProjectType;
      }
      if (payload.mpr !== null && payload.filterProjectType == 'ISO') {
        params.mpr = payload.mpr;
      }
      const response = await axios.get(
        domain + `/api/getBillsFitred/${payload.year}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SALARY_FILTRED', response.data.bill.data);
      commit('SET_TOTAL_ROW_Salary', response.data.bill.total);
      commit('SET_LOADING_Paie', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_Paie', false);
      return false;
    }
  },
  async getDepotForUser({ commit }, payload) {
    const params = { salarie: 'oui' };
    if (payload && payload.type !== null) {
      params.type = payload.type;
    }
    try {
      const responce = await axios.get(
        domain + `/api/auth/myEmployeesAndDepots`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('ALL_DEPOT', responce.data.depots);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getOnlineSalaryEmployeeBytype(
    { commit },
    { semaine, year, type, salarie, switchMonth, month }
  ) {
    const params = { year: year };
    if (type !== null) {
      params.type = type;
    }
    if (salarie) {
      params.salarie = salarie;
    }
    if (switchMonth && switchMonth == true) {
      params.month = month;
    } else {
      params.semaine = semaine;
    }
    try {
      const responce = await axios.get(
        domain + `/api/getOnlineSalaryEmployeeBytype`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('ALL_ACTIVE_EMPLOYEE_BY_TYPE', responce.data.employees);
      return true;
    } catch (error) {
      return false;
    }
  },
  async validationSalary({ commit }, payload) {
    commit('SET_LOADING_Paie', true);
    const body = {
      id: payload.item.id,
      statue: payload.validation,
      comment: payload.item.comment
    };
    let obj = {};
    try {
      if (Array.isArray(payload.item.id)) {
        var chaineValidation = '';
        for (let index = 0; index < payload.item.id.length; index++) {
          chaineValidation =
            index > 0
              ? chaineValidation + ',' + payload.validation
              : chaineValidation + payload.validation;
          body.id = payload.item.id[index];
          const response = await axios.post(
            domain + `/api/validateSalary`,
            body,
            {
              headers: {
                Authorization: `Bearer ${this.state.User.access_token}`
              }
            }
          );
        }
        obj = {
          row: payload.item,
          validation: chaineValidation,
          multiple: true
        };
      } else {
        const response = await axios.post(
          domain + `/api/validateSalary`,
          body,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        obj = {
          response: response.data.bill,
          row: payload.item
        };
      }
      commit('UPDATE_ROW_SALARY', obj);
      commit('SET_LOADING_Paie', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_Paie', false);
      return false;
    }
  },
  async newComment({ commit }, payload) {
    const body = {
      id: payload.id,
      statue: payload.row.validation,
      comment: payload.comment == '\n' ? 'null' : payload.comment
    };
    try {
      const response = await axios.post(domain + `/api/validateSalary`, body, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      const obj = {
        response: response.data.bill,
        row: payload.row
      };
      commit('UPDATE_ROW_SALARY', obj);
      return true;
    } catch (error) {
      return false;
    }
  },
  async downloadSalaryValidationPDF({ commit }, payload) {
    const params = {};
    if (payload.project_type && payload.project_type != null) {
      params.project_type = payload.project_type;
    }
    if (Array.isArray(payload.item.id)) {
      params.bill_id = payload.item.id;
    } else {
      params.bill_id = [];
      params.bill_id.push(payload.item.id);
    }
    try {
      const response = await axios.get(
        domain + `/api/downloadAllBill/${payload.year}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          responseType: 'blob'
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  },
  async downloadSalaryValidationXML({ commit }, payload) {
    const params = {};
    if (payload.semaine != null) {
      params.semaine = payload.semaine;
    }
    if (payload.employee_id && payload.employee_id.id != null) {
      params.employee_id = payload.employee_id;
    }
    if (payload.validation != null) {
      params.validation = payload.validation;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.type !== null) {
      params.type = payload.type;
    }
    try {
      const response = await axios.get(
        domain + `/api/downloadAllBill/${payload.year}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          responseType: 'blob'
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      if (payload.semaine != null) {
        link.setAttribute(
          'download',
          'Validation des salaires A' +
            payload.year +
            ' ' +
            payload.semaine +
            '.xlsx'
        );
      } else {
        link.setAttribute(
          'download',
          'Validation des salaires A' + payload.year + '.xlsx'
        );
      }
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  },
  async employeeSalarysStatistic({ commit }, payload) {
    commit('LOADING_STATIQTIC_EMLOYE_SALARY', true);
    try {
      const params = {};
      if (
        payload.employe.validation != null &&
        payload.employe.validation !== 'Tous'
      ) {
        params.validation = payload.employe.validation;
      }
      if (payload.employe.filterType != null) {
        params.type = payload.employe.filterType;
      }

      if (payload.employe.filterYearType == 'annee') {
        params.year = payload.employe.Annee;
      } else {
        params.min_year = payload.employe.Anne[0];
        params.max_year = payload.employe.Anne[1];
      }
      if (payload.employe.filterSemaineType == 'semaine') {
        params.semaine = payload.employe.Semaine.value;
      } else {
        params.min_semaine = payload.employe.Semaine_min.value;
        params.max_semaine = payload.employe.Semaine_max.value;
      }

      const response = await axios.get(
        domain + `/api/employeeSalarysStatistic`,
        {
          params: params,
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_STATISTIQUE_SALARY', response.data);

      commit('LOADING_STATIQTIC_EMLOYE_SALARY', false);

      return true;
    } catch (error) {
      commit('LOADING_STATIQTIC_EMLOYE_SALARY', false);

      return false;
    }
  },
  async regieSalarysStatistic({ commit }, payload) {
    commit('LOADING_STATIQTIC_REGIE_SALARY', true);
    try {
      const params = {};
      if (
        payload.employe.validation != null &&
        payload.employe.validation !== 'Tous'
      ) {
        params.statue = payload.employe.validation;
      }
      if (
        payload.employe.filterRegie &&
        payload.employe.filterRegie.id != null
      ) {
        params.id = payload.employe.filterRegie.id;
      }

      if (payload.employe.filterYearType == 'annee') {
        params.year = payload.employe.Annee;
      } else {
        params.min_year = payload.employe.Anne[0];
        params.max_year = payload.employe.Anne[1];
      }
      if (payload.employe.filterSemaineType == 'semaine') {
        params.semaine = payload.employe.Semaine.value;
      } else {
        params.min_semaine = payload.employe.Semaine_min.value;
        params.max_semaine = payload.employe.Semaine_max.value;
      }
      const response = await axios.get(domain + `/api/regieBillsStatistic`, {
        params: params,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_STATISTIQUE_SALARY_REGIE', response.data);

      commit('LOADING_STATIQTIC_REGIE_SALARY', false);

      return true;
    } catch (error) {
      commit('LOADING_STATIQTIC_REGIE_SALARY', false);

      return false;
    }
  },
  async getOnlineType(
    { commit },
    { semaine, year, salarie, switchMonth, month }
  ) {
    const params = { year: year };
    if (salarie) {
      params.salarie = salarie;
    }
    if (switchMonth && switchMonth == true) {
      params.month = month;
    } else {
      params.semaine = semaine;
    }

    try {
      const responce = await axios.get(domain + `/api/getOnlineType`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_ONLINE_TYPE', responce.data.type);
      return true;
    } catch (error) {
      return false;
    }
  },
  async updatePrime({ commit }, payload) {
    const params = { id: payload.row.id };
    try {
      params[payload.column] = parseFloat(
        (payload.value + '').replace(',', '.')
      );
      const responce = await axios.post(
        domain + `/api/editEmployeeBill`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_PRIME', { responce: responce.data.bill, row: payload.row });
      return true;
    } catch (error) {
      return false;
    }
  },
  async sousRegieSalarysStatistic({ commit }, payload) {
    commit('LOADING_STATIQTIC_REGIE_SALARY', true);
    try {
      const params = {};
      if (
        payload.employe.validation != null &&
        payload.employe.validation !== 'Tous'
      ) {
        params.statue = payload.employe.validation;
      }
      if (
        payload.employe.filterRegie &&
        payload.employe.filterRegie.id != null
      ) {
        params.id = payload.employe.filterRegie.id;
      }

      if (payload.employe.filterYearType == 'annee') {
        params.year = payload.employe.Annee;
      } else {
        params.min_year = payload.employe.Anne[0];
        params.max_year = payload.employe.Anne[1];
      }
      if (payload.employe.filterSemaineType == 'semaine') {
        params.semaine = payload.employe.Semaine.value;
      } else {
        params.min_semaine = payload.employe.Semaine_min.value;
        params.max_semaine = payload.employe.Semaine_max.value;
      }
      const response = await axios.get(
        domain + `/api/commercialRegieBillsStatistic`,
        {
          params: params,
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_STATISTIQUE_SALARY_SOUS_REGIE', response.data);

      commit('LOADING_STATIQTIC_REGIE_SALARY', false);

      return true;
    } catch (error) {
      commit('LOADING_STATIQTIC_REGIE_SALARY', false);

      return false;
    }
  },
  // getMissingSemaines
  async getMissingSemaineSalarie({ commit }, payload) {
    try {
      const params = {
        salarie: 'oui',
        month: payload.month,
        year: payload.year
      };
      if (
        payload.employee_id &&
        payload.employee_id.id &&
        payload.employee_id.id != null
      ) {
        params.employee_id = payload.employee_id.id;
      }
      if (payload.validation != null) {
        params.validation = payload.validation;
      }
      if (payload.type !== null) {
        params.type = payload.type;
      }
      if (payload.filterProjectType !== null) {
        params.project_type = payload.filterProjectType;
      }
      if (payload.filterProjectType == 'ISO' && payload.mpr != null) {
        params.mpr = payload.mpr;
      }

      const response = await axios.get(domain + `/api/missing_semaines`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_MISSING_SEMAINE_SALARIE', response.data.data);
      return true;
    } catch (error) {
      return false;
    }
  },
  resetMissingSemaineSalarie({ commit }) {
    commit('SET_MISSING_SEMAINE_SALARIE', null);
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
