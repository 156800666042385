import FraisDepotTh from './FraisDepotTh';
export default class FraisParamsParFilialeTh {
  constructor(data) {
    this.id = data.id;
    //update
    this.name = data.name;
    this.operationnel = 0;
    this.commercial = 0;
    this.marketing = 0;
    this.technique = 0;
    this.rge = 0;
    this.CDG = 0;
    this.administratif_sav = 0;
    this.dsi = 0;
    this.juridique = 0;
    this.alger = 0;
    this.alteo_control_gestion = 0;

    this.bureau_detude = 0;
    this.mairie = 0;
    this.maintenance_pac = 0;
    this.upcube = 0;
    this.detailsShowing = false;
    this.externe = 0;
    this.interne = 0;
    this.alteo_commercial = 0;
    this.alteo_supervision = 0;
    this.depots = data.depots.map(item => FraisDepotTh.create(item));
  }
  update(data) {
    this.depots = data.depots.map(item => FraisDepotTh.create(item));
  }
  static create(data) {
    return new FraisParamsParFilialeTh(data);
  }
}
