import axios from 'axios';
import domain from '@/environment';
const recapRegiePerDepotStockage = 'recapPerDepotStockage';
const state = {
  loadingRecapRegieTh: false,
  allDepotRecapRegieTh: [],
  recapRegieTh: []
};
const getters = {
  getLoadingRecapRegieTh: state => state.loadingRecapRegieTh,
  getAllDepotRecapRegieTh: state => state.allDepotRecapRegieTh,
  getRecapRegieTh: state => state.recapRegieTh
};
const mutations = {
  SET_LOADING_RECAP_REGIE_TH(satate, payload) {
    state.loadingRecapRegieTh = payload;
  },
  SET_All_DEPOT_RECAP_TH(state, payload) {
    state.allDepotRecapRegieTh = payload;
  },
  SET_RECAP_REGIE_TH(state, payload) {
    if (payload.reset && payload.reset == true) {
      state.recapRegieTh = [];
    } else {
      let table = [];
      Object.entries(payload).map(([key, value]) => {
        table.push({ depot: key, value: value });
      });
      state.recapRegieTh = [...table];
    }
  }
};
const actions = {
  async fetchAllDepotRecapTh({ commit }, payload) {
    commit('SET_LOADING_RECAP_REGIE_TH', true);
    const params = { year: payload.year };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    if (payload.num_depot && payload.num_depot != null) {
      params.depot = payload.num_depot;
    }
    try {
      const response = await axios.get(
        domain + `/api/${recapRegiePerDepotStockage}/allDepots`,
        {
          params: params,
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_All_DEPOT_RECAP_TH', response.data.data);
      commit('SET_LOADING_RECAP_REGIE_TH', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_RECAP_REGIE_TH', false);
      return false;
    }
  },
  async getRecapRegieFiltredTh({ commit, state }, payload) {
    commit('SET_LOADING_RECAP_REGIE_TH', true);
    const params = { year: payload.year, type: payload.type };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    if (payload.num_depot && payload.num_depot != null) {
      params.depot = payload.num_depot;
    }
    if (payload.depot && payload.depot.id == null) {
      params.filiales = state.allDepotRecapRegieTh;
    }
    if (payload.depot && payload.depot.id != null) {
      params.filiales = [];
      params.filiales.push(payload.depot.id);
    }
    try {
      const response = await axios.get(
        domain + `/api/${recapRegiePerDepotStockage}/get_recap_dep_depot`,
        {
          params: params,
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_RECAP_REGIE_TH', response.data.data);
      commit('SET_LOADING_RECAP_REGIE_TH', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_RECAP_REGIE_TH', false);
      return false;
    }
  },
  async exportExcelRecapRegieTh({ commit }, payload) {
    commit('SET_LOADING_RECAP_REGIE_TH', true);
    const params = { year: payload.year, type: payload.type };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    if (payload.num_depot && payload.num_depot != null) {
      params.depot = payload.num_depot;
    }
    if (payload.depot && payload.depot.id == null) {
      params.filiales = state.allDepotRecapRegieTh;
    }
    if (payload.depot && payload.depot.id != null) {
      params.filiales = [];
      params.filiales.push(payload.depot.id);
    }
    try {
      const response = await axios.get(
        domain + `/api/${recapRegiePerDepotStockage}/export`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const name_month = payload.month != null ? ' - ' + payload.month : '';
      const name_depot =
        payload.num_depot != null
          ? ' - ' + payload.num_depot
          : payload.year + name_month;
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', payload.nom_facture + name_depot + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_RECAP_REGIE_TH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_RECAP_REGIE_TH', false);
      return false;
    }
  },
  resetRecapRegieTh({ commit }) {
    commit('SET_RECAP_REGIE_TH', { reset: true });
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
