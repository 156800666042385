export default class fraisBddInterneIhsProjectsTh {
  constructor(data) {
    this.id = data.id;
    this.acompte = data.acompte;
    this.agent_commercial_terrain = data.agent_commercial_terrain;
    this.agent_commercial = data.agent_commercial;
    this.blocked = data.blocked;
    this.commercial = data.commercial;
    this.montant_commercial = data.montant_commercial;
    this.commercial_download = data.commercial_download;
    this.commercial_id = data.commercial_id;
    this.commercial_numero = data.commercial_numero;
    this.commercial_statue = data.commercial_statue;
    this.commercial_statue_installateur = data.commercial_statue_installateur;
    this.commercial_date = data.commercial_date;
    this.commercial_responsable_email = data.commercial_responsable_email;

    this.ihs = data.ihs;
    this.montant_ihs = data.montant_ihs;
    this.ihs_download = data.ihs_download;
    this.ihs_id = data.ihs_id;
    this.ihs_numero = data.ihs_numero;
    this.ihs_statue = data.ihs_statue;
    this.ihs_statue_installateur = data.ihs_statue_installateur;
    this.ihs_date = data.ihs_date;
    this.ihs_statue_installateur = data.ihs_statue_installateur;
    this.ihs_type = data.ihs_type;
    this.installeur = data.installeur;
    this.interne = data.interne;
    this.interne_download = data.interne_download;
    this.montant_interne = data.montant_interne;
    this.interne_id = data.interne_id;
    this.interne_numero = data.interne_numero;
    this.interne_statue = data.interne_statue;
    this.interne_date = data.interne_date;
    this.interne_responsable_email = data.interne_responsable_email;
    this.interne_statue_installateur = data.interne_statue_installateur;
    this.numero_dossier = data.numero_dossier;
    this.nom1 = data.nom1;
    this.prenom1 = data.prenom1;
    this.montant_final = data.montant_final;
    this.previsiteur = data.previsiteur;
    this.check = false;
    this.ihs_responsable_email = data.ihs_responsable_email;
    this.acompte_exisit = data.acompte_exisit;
    this.regie = data.regie;

    this.ihs_av_id = data.ihs_av_id;
    this.ihs_av_numero = data.ihs_av_numero;
  }

  update(data) {
    this.blocked = data.blocked;
    this.ihs = data.ihs;
    this.montant_ihs = data.montant_ihs;
    this.ihs_download = data.ihs_download;
    this.ihs_id = data.ihs_id;
    this.ihs_numero = data.ihs_numero;
    this.ihs_statue = data.ihs_statue;
    this.ihs_statue_installateur = data.ihs_statue_installateur;
    this.ihs_date = data.ihs_date;
    this.ihs_statue_installateur = data.ihs_statue_installateur;
    this.ihs_type = data.ihs_type;
    this.ihs_responsable_email = data.ihs_responsable_email;
  }
  static create(data) {
    return new fraisBddInterneIhsProjectsTh(data);
  }
}
