export default class DepotParamsTh {
  constructor(data) {
    this.id = data.id;
    this.depot = data.depot;
    this.pu_commission = data.pu_commission;
    this.pu_paie = data.pu_paie;
    this.pu_prime = data.pu_prime;
    this.pu_suprime = data.pu_suprime;
  }
  update(data) {
    this.pu_commission = data.pu_commission;
    this.pu_paie = data.pu_paie;
    this.pu_prime = data.pu_prime;
    this.pu_suprime = data.pu_suprime;
  }
  static create(data) {
    return new DepotParamsTh(data);
  }
}
