import axios from 'axios';
import stateGlobale from '../index';
import domain from '@/environment';
import fraisvisionFiliale from '../../models/FraisVisionFilialeTh';
import LCRModal from '../../models/LCRModal';
const prefixFrais = 'frais';
const prefixLCR = 'LCR';
const prefixcactussuivi_commercial_global = 'suivi_commercial_global';
let requestFraisGlobale = null;
const state = {
  fraisVisionFiliale: [],
  loadingFraisVisionFiliale: false,
  errorFraisVisionFiliale: null,
  totalVariableFraisVisionFiliale: null,
  onlineFilialeInVisionFilialeFrais: [],
  templateFilialeToUpload: null,
  loadingGetDataTemplate: false,
  errorTemplateDataToUpploadeed: null,
  loadingExtactBddFraisVisionFiliale: false,
  dataBeforSend: [],
  loadingMail: false,
  errorMailing: [],
  errorInvalidElmail: {},
  errorHasntEmail: {},
  successSendMails: {},
  errorHasentTemplateGlobal: {},
  errorHasentTemplateEmailGlobal: {},
  otherErrorMail: null,
  fraisVisionFilialeRows: null,
  errorDataLcr: null,
  loadingDataLcr: false,
  dataLcr: null
};
const getters = {
  getFraisVisionFilialeData: state => state.fraisVisionFiliale,
  getLoadingFraisVisionFiliale: state => state.loadingFraisVisionFiliale,
  getErrorFraisVisionFiliale: state => state.errorFraisVisionFiliale,
  getTotalVariableFraisVisionFiliale: state =>
    state.totalVariableFraisVisionFiliale,
  getOnlineFilialeInVisionFilialeFrais: state =>
    state.onlineFilialeInVisionFilialeFrais,
  getTemplateFilialeToUpload: state => state.templateFilialeToUpload,
  getLoadingGetDataTemplate: state => state.loadingGetDataTemplate,
  getErrorTemplateDataToUpploadeed: state =>
    state.errorTemplateDataToUpploadeed,
  getLoadingExtactBddFraisVisionFiliale: state =>
    state.loadingExtactBddFraisVisionFiliale,
  getLoadingMail: state => state.loadingMail,
  getErrorMailing: state => state.errorMailing,
  getSuccessSendMails: state => state.successSendMails,
  getErrorInvalidElmail: state => state.errorInvalidElmail,
  getErrorHasntEmail: state => state.errorHasntEmail,
  getOtherErrorMail: state => state.otherErrorMail,
  getErrorHasentTemplateGlobal: state => state.errorHasentTemplateGlobal,
  getErrorHasentTemplateEmailGlobal: state =>
    state.errorHasentTemplateEmailGlobal,
  getDtatBeforeSend: state => state.dataBeforSend,
  getFraisVisionFilialeRows: state => state.fraisVisionFilialeRows,
  getErrorDataLcr: state => state.errorDataLcr,
  getLoadingDataLcr: state => state.loadingDataLcr,
  getDataLcr: state => state.dataLcr
};

const mutations = {
  //LCR
  SET_ERROR_LCR(state, payload) {
    state.errorDataLcr = payload;
  },
  SET_LOADING_LCR(state, payload) {
    state.loadingDataLcr = payload;
  },
  SET_DATA_PAIEMENT_LCR(state, payload) {
    if (payload == null) {
      state.dataLcr = null;
    } else {
      state.dataLcr = LCRModal.create(payload);
    }
  },
  SET_DATA_STAT_LCR(state, payload) {
    payload.item.update(payload.response);
  },
  //END LCR
  SET_DATA_FRAIS_VISION_FILIALE(state, payload) {
    state.fraisVisionFiliale = payload.map(item =>
      fraisvisionFiliale.create(item)
    );
  },
  SET_DATA_FRAIS_VISION_FILIALE_DETAILS(state, payload) {
    payload.row.update(payload.response);
  },
  SET_TOTAL_DATA_FRAIS_VISION_FILIALE(state, payload) {
    state.fraisVisionFilialeRows = payload;
  },
  SET_LOADING_FRAIS_VISION_FILIALE(state, payload) {
    state.loadingFraisVisionFiliale = payload;
  },
  SET_ERROR_FRAIS_VISION_FILIALE(state, payload) {
    state.errorFraisVisionFiliale = payload;
  },
  UPDATE_FRAIS_VISION_FILIALE_STAT(state, payload) {
    payload.row.update(payload.response);
  },
  SET_TOTAL_FRAIS_VISION_FILIALE(state, payload) {
    state.totalVariableFraisVisionFiliale = payload;
  },
  SET_FILIALE_ONLINE_FRAIS_VISION_FILIALE(state, payload) {
    state.onlineFilialeInVisionFilialeFrais = payload;
  },
  SET_TEMPLATE_FILIALE_TO_UPLOAD(state, payload) {
    state.templateFilialeToUpload = payload;
  },
  SET_LOADING_GET_TEMPLATE_DATA(state, payload) {
    state.loadingGetDataTemplate = payload;
  },
  SET_ERROR_TEMPLATE_DATA_TO_UPLOAD(state, payload) {
    state.errorTemplateDataToUpploadeed = payload;
  },
  SET_DATA_FOR_FACTURE_UPLOADED(state, payload) {
    payload.item.update(payload.response);
  },
  SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_FILIALE(state, payload) {
    state.loadingExtactBddFraisVisionFiliale = payload;
  },
  SET_MAIL_LODING(state, payload) {
    state.loadingMail = payload;
  },
  SET_ERROR_MAILING(state, payload) {
    state.errorMailing = payload;
  },
  SET_SUCCESS_SEND_MAILS(state, payload) {
    state.successSendMails = payload;
  },
  SET_HASNT_EMAIL(state, payload) {
    state.errorHasntEmail = payload;
  },
  SET_INVALID_EMAIL(state, payload) {
    state.errorInvalidElmail = payload;
  },
  SET_OTHER_ERROR(state, payload) {
    state.otherErrorMail = payload;
  },
  SET_EMAIL_HASENT_TEMPLATE_GLOBAL(state, payload) {
    state.errorHasentTemplateGlobal = payload;
  },
  SET_EMAIL_HASENT_TEMPLATE_EMAIL_GLOBAL(state, payload) {
    state.errorHasentTemplateEmailGlobal = payload;
  },
  SET_STATUE_MAIL_VALUE(state, payload) {
    payload.item.update(payload.response);
  },
  SET_DATA_TO_DISPLAY_BEFOR_SENDING(state, payload) {
    state.dataBeforSend = payload;
  }
};
const actions = {
  async getAllFraisVisionFiliale({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    const params = {
      year: payload.year,
      page: payload.page,
      perPage: payload.perPage
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.filiale != null) {
      params.filiale = payload.filiale;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.week != null) {
      params.week = payload.week;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }
    try {
      if (requestFraisGlobale) {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
        requestFraisGlobale.cancel('cancel-request');
      }
      requestFraisGlobale = axios.CancelToken.source();
      const response = await axios.get(
        domain + '/api/' + prefixFrais + '/vision_filiale',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestFraisGlobale.token
        }
      );
      commit('SET_DATA_FRAIS_VISION_FILIALE', response.data.data.data);
      commit('SET_TOTAL_DATA_FRAIS_VISION_FILIALE', response.data.data.count);
      commit('SET_TOTAL_FRAIS_VISION_FILIALE', {
        total_maintenance_pac: response.data.data.total_maintenance_pac,
        total_mairie: response.data.data.total_mairie,
        total_bureau_detude: response.data.data.total_bureau_detude,
        total_comptabilite: response.data.data.total_comptabilite,
        total_alger: response.data.data.total_alger,
        total_juridique: response.data.data.total_juridique,
        total_dsi: response.data.data.total_dsi,
        total_administratif_sav: response.data.data.total_administratif_sav,
        total_CDG: response.data.data.total_CDG,
        total_rge: response.data.data.total_rge,
        total_technique: response.data.data.total_technique,
        total_marketing: response.data.data.total_marketing,
        total_commercial: response.data.data.total_commercial,
        total_operationnel: response.data.data.total_operationnel,
        total_RH: response.data.data.total_RH,
        total: response.data.data.total
      });
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', error.response.data.errors);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
          commit('SET_ERROR_FRAIS_VISION_FILIALE', ['Une erreur est servenue']);
        }
      }

      return false;
    }
  },
  async exportExcelFraisVisionFilialeTh({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_FILIALE', true);
    try {
      const response = await axios.post(
        domain + `/api/${prefixFrais}/export_vision_filiale`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const type = payload.get('type') != 'null' ? payload.get('type') : '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      let year = payload.get('year') ? '-' + payload.get('year') : '';

      link.setAttribute(
        'download',
        'VISION FILIALE  ' + type.toUpperCase() + year + '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_FILIALE', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FRAIS_VISION_FILIALE', ['Une erreur est servenue']);
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_FILIALE', false);
      return false;
    }
  },
  async exportPdfFactureUploadedType({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    const params = {
      id: payload.id
    };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/download`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FRAIS_VISION_FILIALE', ['Une erreur est servenue']);
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return false;
    }
  },
  // get template data
  async getTemplateAndFiledsDataToUpdate({ commit }, payload) {
    commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD', null);
    commit('SET_LOADING_GET_TEMPLATE_DATA', true);
    const params = {
      type: payload.type,
      BE_TYPE: payload.BE_TYPE,
      project_id: payload.project_id,
      dev: payload.dev
    };
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/get_uploaded_template`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_TEMPLATE_FILIALE_TO_UPLOAD', response.data.data);

      commit('SET_LOADING_GET_TEMPLATE_DATA', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD', error.response.data.error);
      } else {
        commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_GET_TEMPLATE_DATA', false);

      return false;
    }
  },
  // upload template
  async uploadTemplateInServieVisionFiliale({ commit }, payload) {
    const params = {
      type: payload.type,
      description: payload.description,
      iban: payload.iban,
      rib: payload.rib,
      swift: payload.swift,
      num_facture: payload.num_facture,
      date: payload.date,
      date_reglement: payload.date_reglement,
      BE_TYPE: payload.BE_TYPE,
      project_id: payload.project_id
    };

    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/upload`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_DATA_FOR_FACTURE_UPLOADED', {
        response: response.data.data,
        item: payload.item
      });
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async updateStatueFraisVisionFelialeService({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    const params = {
      id: payload.row.id_to_use,
      column: 'statue',
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/${prefixFrais}/vision_filiale_update_statue_date`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      payload.row.item[payload.column] = payload.value;
      if (payload.column.split('_')[1]) {
        let champName = payload.column.split('_')[1] + 'statue_installateur';
        payload.row.item[champName] = payload.value;
      }

      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', error.response.data.errors);
      } else {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);

      return false;
    }
  },
  async updateStatueInstallateurFraisVisionFelialeService({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    const params = {
      id: payload.row.id_to_use,
      statue_installateur: payload.value,
      vision: stateGlobale.state.currentInterfaceFrais
    };
    try {
      const response = await axios.post(
        domain + `/api/${prefixFrais}/update_statue_installateur`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      payload.row.item[payload.column] = payload.value;

      commit('SET_STATUE_MAIL_VALUE', {
        item: payload.row.item,
        response: response.data.data
      });
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', error.response.data.errors);
      } else {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);

      return false;
    }
  },
  //RH && COMPTA
  async exportPdfFactureUploadedTypeParFiliale({ commit }, payload) {
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    const params = {
      year: payload.year,
      filiale: payload.filiale,
      type: payload.type
    };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/download_ge`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return false;
    }
  },
  async updateStatueFraisFilialeVisionFelialeService({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    const params = {
      id: payload.row.id_to_use,
      column: 'statue',
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/${prefixFrais}/vision_filiale_update_statue_date`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      payload.row.item[payload.column] = payload.value;

      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', error.response.data.error);
      } else {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);

      return false;
    }
  },
  setDaraTemplateUploded({ commit }) {
    commit('SET_TEMPLATE_FILIALE_TO_UPLOAD', null);
  },
  //Extrait
  async downloadExtraitSupportServiceAction({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    const params = {
      year: payload.year,
      filiale: payload.filiale,
      type: payload.type
    };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.get(
        domain + `/api/${prefixFrais}/download_vision_filiale`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return true;
    } catch (error) {
      if (error?.response) {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', [
          "il n'y a pas de facture ou peut-être qu'ils sont tous valides !"
        ]);
      } else {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return false;
    }
  },
  downloadExtraitInstalleurAction({ commit, dispatch }, payload) {
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    const params = {
      year: payload.year,
      filiale: payload.filiale
    };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    payload.type.forEach(element => {
      params.type = element;
      dispatch('downloadExtraitSupportServiceAction', params);
    });
  },
  //cactus
  async getTemplateAndFiledsDataToUpdateCommercialCactus({ commit }, payload) {
    commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD', null);
    commit('SET_LOADING_GET_TEMPLATE_DATA', true);
    const params = {
      year: payload.year,
      type: payload.type,
      project_id: payload.project_id,
      dev: payload.dev
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.ihs_type) {
      params.ihs_type = payload.ihs_type;
    }
    try {
      const response = await axios.get(
        domain +
          `/api/${prefixcactussuivi_commercial_global}/get_uploaded_template`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_TEMPLATE_FILIALE_TO_UPLOAD', response.data.data);

      commit('SET_LOADING_GET_TEMPLATE_DATA', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD', error.response.data.error);
      } else {
        commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_GET_TEMPLATE_DATA', false);

      return false;
    }
  },
  async uploadTemplateInServieVisionCommercialCactus({ commit }, payload) {
    const params = {
      year: payload.year,
      type: payload.type,
      description: payload.description,
      description2: payload.description2,
      nom_prenom: payload.nom_prenom,
      num_facture: payload.num_facture,
      date: payload.date,
      date_reglement: payload.date_reglement,
      iban: payload.iban,
      swift: payload.swift,
      project_id: payload.project_id
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.ihs_type) {
      params.ihs_type = payload.ihs_type;
    }

    try {
      const response = await axios.get(
        domain + `/api/${prefixcactussuivi_commercial_global}/upload`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_DATA_FOR_FACTURE_UPLOADED', {
        response: response.data.data,
        item: payload.item
      });
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async exportPdfFactureUploadedTypeCommercialCactus({ commit }, payload) {
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    const params = {
      year: payload.year,
      type: payload.type,
      id: payload.id
    };
    if (payload.av) {
      params.av = payload.av;
    }
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.get(
        domain + `/api/${prefixcactussuivi_commercial_global}/download`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return false;
    }
  },
  async refreshDataGestionGlobalFrais({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    const params = {
      year: payload.year,
      month: payload.month
    };
    if (payload.type != null) {
      params.column = payload.type;
    }
    try {
      const response = await axios.get(
        domain + '/api/' + prefixFrais + '/recalculate_frais',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', error.response.data.error);
      } else {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);

      return false;
    }
  },

  //global validation
  async getGlobalTemplateAndFiledsDataToUpdate({ commit }, payload) {
    commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD', null);
    commit('SET_LOADING_GET_TEMPLATE_DATA', true);
    const params = {
      type: payload.type
    };

    try {
      const response = await axios.get(domain + `/api/templates/get_one`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_TEMPLATE_FILIALE_TO_UPLOAD', response.data.data);

      commit('SET_LOADING_GET_TEMPLATE_DATA', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD', error.response.data.error);
      } else {
        commit('SET_ERROR_TEMPLATE_DATA_TO_UPLOAD', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_GET_TEMPLATE_DATA', false);

      return false;
    }
  },
  async uploadGlobalTemplateInServieVisionFiliale({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/filiale_template_generator/upload_all`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async exportPdfFraisVisionFraisTh({ commit }, payload) {
    commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_FILIALE', true);
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.post(
        domain + `/api/filiale_template_generator/download_all`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const month_name =
        payload.month && payload.month != null ? '-' + payload.month : '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'VISION FRAIS GLOBALE.zip');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_FILIALE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_FILIALE', false);
      return false;
    }
  },
  async exportPdfInvalidFraisVisionFraisTh({ commit }, payload) {
    commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_FILIALE', true);
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.post(
        domain + `/api/download_pdfNonValide`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const month_name =
        payload.month && payload.month != null ? '-' + payload.month : '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'VISION FRAIS GLOBALE INVALID.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_FILIALE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_FILIALE', false);
      return false;
    }
  },
  async sendMailGestionGlobalFrais({ commit }, payload) {
    commit('SET_MAIL_LODING', true);
    try {
      const response = await axios.post(
        domain + `/api/facture/sendMails_multiple_bill`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_MAIL_LODING', false);
      commit('SET_ERROR_MAILING', response.data.error);
      commit('SET_SUCCESS_SEND_MAILS', response.data.success);
      return { succes: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_OTHER_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_OTHER_ERROR', 'Une erreur est servenue');
      }
      commit('SET_MAIL_LODING', false);
      return { succes: false };
    }
  },
  async validateStatueInstallateurGlobale({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/frais/update_multiple_statue `,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, message: response.data };
    } catch (error) {
      return {
        succes: false,
        error: response?.data?.error
          ? response.data.error
          : ['Une erreur est servenue']
      };
    }
  },
  async update_frais_multiple({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/frais/update_frais_multiple `,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, message: response.data };
    } catch (error) {
      return {
        succes: false,
        error: response?.data?.error
          ? response.data.error
          : ['Une erreur est servenue']
      };
    }
  },
  async updateTauxFraisInInterface({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/frais/update_frais_multiple `,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, message: response.data };
    } catch (error) {
      return {
        succes: false,
        error: response?.data?.error
          ? response.data.error
          : ['Une erreur est servenue']
      };
    }
  },
  async BeforSendingMail({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/frais/get_values_form_type `,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_DATA_TO_DISPLAY_BEFOR_SENDING', response?.data?.data);
      return { succes: true };
    } catch (error) {
      return {
        succes: false
      };
    }
  },
  async exportPdfFactureUploadedTypeAvoir({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/download`,
        {
          params: payload,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FRAIS_VISION_FILIALE', ['Une erreur est servenue']);
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return false;
    }
  },
  async avoirFraisFcatureTh({ commit }, payload) {
    try {
      const response = await axios.post(domain + `/api/frais/avoir`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      return { succes: true, data: response.data };
    } catch (error) {
      return false;
    }
  },
  async getPaiementLcrData({ commit }, payload) {
    commit('SET_DATA_PAIEMENT_LCR', null);
    commit('SET_ERROR_LCR', null);
    commit('SET_LOADING_LCR', true);
    try {
      const response = await axios.get(
        domain + `/api/${prefixLCR}/show/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_DATA_PAIEMENT_LCR', response.data.data);

      commit('SET_LOADING_LCR', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_LCR', error.response.data.error);
      } else {
        commit('SET_ERROR_LCR', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_LCR', false);

      return false;
    }
  },
  async updattypePaiementFrais({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    const params = {
      id: payload.idFacture,
      column: 'type_paiement',
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/${prefixFrais}/vision_filiale_update_statue_date`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      payload.row[payload.column] = payload.value;
      if (payload.value == 'LCR') {
        let champName =
          payload.champ == 'facture'
            ? 'lcr'
            : payload.champ == 'facture_alteo'
            ? 'alteo_lcr'
            : payload.champ + '_lcr';
        payload.row[champName] = 0;
      }

      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', error.response.data.errors);
      } else {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);

      return false;
    }
  },
  async createNewLcrFrais({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    try {
      const response = await axios.post(
        domain + `/api/${prefixLCR}/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return { succes: true, data: response.data.data };
    } catch (error) {
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);

      if (error.response && error.response.data && error.response.data.errors) {
        return { succes: false, error: error.response.data.errors };
      } else {
        return { succes: false, error: ['Une erreur est servenue'] };
      }
    }
  },
  async updateLcrFraisitem({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    commit('SET_LOADING_FRAIS_VISION_FILIALE', true);
    try {
      const response = await axios.post(
        domain + `/api/${prefixLCR}/update`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);
      return { succes: true, data: response.data.data };
    } catch (error) {
      commit('SET_LOADING_FRAIS_VISION_FILIALE', false);

      if (error.response && error.response.data && error.response.data.errors) {
        return { succes: false, error: error.response.data.errors };
      } else {
        return { succes: false, error: ['Une erreur est servenue'] };
      }
    }
  },
  async downloadLcr({ commit }, payload) {
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    try {
      const response = await axios.post(
        domain + `/api/${prefixLCR}/download/${payload}`,
        {},
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      commit('SET_ERROR_FRAIS_VISION_FILIALE', ['Une erreur est servenue']);
      return false;
    }
  },
  async updateLcrFraiStatut({ commit }, payload) {
    const params = new FormData();
    params.append('id', payload.id);
    params.append('value', payload.value);
    params.append('column', payload.column);
    try {
      const response = await axios.post(
        domain + `/api/${prefixLCR}/update_statut`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      commit('SET_DATA_STAT_LCR', {
        response: response.data.data,
        item: payload.item
      });
      return { succes: true };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        return { succes: false, error: error.response.data.errors };
      } else {
        return { succes: false, eoor: ['Une erreur est servenue'] };
      }
    }
  },
  async getDataForRowFrais({ commit }, payload) {
    payload.item.loadingDetailt = true;
    commit('SET_ERROR_FRAIS_VISION_FILIALE', null);
    const params = {
      year: payload.year,
      page: payload.page,
      perPage: payload.perPage,
      filiale: payload.item.nom
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.week != null) {
      params.week = payload.week;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }
    try {
      const response = await axios.get(
        domain + '/api/frais/vision_filiale_projects',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_DATA_FRAIS_VISION_FILIALE_DETAILS', {
        response: response.data,
        row: payload.item
      });
      payload.item.loadingDetailt = false;

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', error.response.data.errors);
      } else {
        commit('SET_ERROR_FRAIS_VISION_FILIALE', ['Une erreur est servenue']);
      }
      payload.item.loadingDetailt = false;

      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
