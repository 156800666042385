export default class produitFactureLibre {
  constructor(data) {
    this.id = data.id;
    this.nom = data.nom;
    this.reference = data.reference;
    this.depot = data.depot;
    this.reduction = data.reduction;
    this.qte = data.qte;
    this.unite = data.unite;
    this.isSelectInputTva = data.isSelectInputTva;
    this.isSelectInput = data.isSelectInput;
    this.tva = data.tva;
    this.pu_ht = data.pu_ht;
    this.total_ttc = data.total_ttc;
    this.total_ht = data.total_ht;
    this.factureLibre_id = data.factureLibre_id;
    this.description = data.description;
    this.index = data.index;
    this.type = data.type;
    this.contenu = data.contenu;
    this.isOpenProduitList = data.isOpenProduitList;
    this.nom_apres = data.nom_apres;
    this.reference_apres = data.reference_apres;
    this.depot_apres = data.depot_apres;
    this.reduction_apres = data.reduction_apres;
    this.qte_apres = data.qte_apres;
    this.unite_apres = data.unite_apres;
    this.isSelectInputTva_apres = data.isSelectInputTva_apres;
    this.isSelectInput_apres = data.isSelectInput_apres;
    this.tva_apres = data.tva_apres;
    this.pu_ht_apres = data.pu_ht_apres;
    this.total_ttc_apres = data.total_ttc_apres;
    this.total_ht_apres = data.total_ht_apres;
    this.index = data.index;
    this.contenu_apres = data.contenu_apres;
    this.qte_apres_avoir = data.qte_apres_avoir;
    this.isOpenProduitList_apres = data.isOpenProduitList_apres;
  }
  update(data) {
    this.nom = data.nom;
    this.reference = data.reference;
    this.depot = data.depot;
    this.reduction = data.reduction;
    this.qte = data.qte;
    this.unite = data.unite;
    this.addToCatalog = data.addTocatalog;
    this.tva = data.tva;
    this.pu_ht = data.pu_ht;
    this.total_ttc = data.total_ttc;
    this.total_ht = data.total_ht;
    this.description = data.description;
    this.factureLibre_id = data.factureLibre_id;
    this.isSelectInput = data.isSelectInput;
    this.isSelectInputTva = data.isSelectInputTva;
    this.isSelectInputTva_apres = data.isSelectInputTva_apres;
    this.index = data.index;
    this.type = data.type;
    this.contenu = data.contenu;
    this.isOpenProduitList = data.isOpenProduitList;
    this.nom_apres = data.nom_apres;
    this.reference_apres = data.reference_apres;
    this.depot_apres = data.depot_apres;
    this.reduction_apres = data.reduction_apres;
    this.qte_apres = data.qte_apres;
    this.unite_apres = data.unite_apres;
    this.isSelectInput_apres = data.isSelectInput_apres;
    this.tva_apres = data.tva_apres;
    this.pu_ht_apres = data.pu_ht_apres;
    this.total_ttc_apres = data.total_ttc_apres;
    this.total_ht_apres = data.total_ht_apres;
    this.index = data.index;
    this.contenu_apres = data.contenu_apres;
    this.qte_apres_avoir = data.qte_apres_avoir;
    this.isOpenProduitList_apres = data.isOpenProduitList_apres;
  }

  static create(data) {
    return new produitFactureLibre(data);
  }
}
