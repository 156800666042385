import axios from 'axios';
import domain from '@/environment';
import Commerciaux from '../../models/Salary';
const state = {
  chargementPaieCommerciaux: false,
  salaryCommerciauxTableValidation: [],
  totalRowSalaryCommerciaux: null,
  missigSemaineIndependant: null
};
const getters = {
  getChargementPaieCommerciaux: state => state.chargementPaieCommerciaux,
  getSalaryCommerciauxTableValidation: state =>
    state.salaryCommerciauxTableValidation,
  getTotalRowUserSalaryCommerciaux: state => state.totalRowSalaryCommerciaux,
  getMissigSemaineIndependant: state => state.missigSemaineIndependant
};
const mutations = {
  SET_LOADING_PAIE_COMMERCIAUX(state, payload) {
    state.chargementPaieCommerciaux = payload;
  },
  SALARY_COMMERCIAUX_FILTRED(state, payload) {
    state.salaryCommerciauxTableValidation = payload.map(item =>
      Commerciaux.create(item)
    );
  },
  SET_TOTAL_ROW_Salary(state, payload) {
    state.totalRowSalaryCommerciaux = payload;
  },
  UPDATE_FACTURE_COMMERCIAUX(state, payload) {
    if (payload.multiple && payload.multiple == true) {
      if (payload.payed_at) {
        payload.row.payed_at = payload.payed_at;
      } else {
        payload.row.validation = payload.validation;
      }
    } else {
      payload.row.update(payload.response);
    }
  },
  UPDATE_FACTURE_INDEPENDANT(state) {},
  SET_MISSING_SEMAINE_INDEPENDANT(state, payload) {
    state.missigSemaineIndependant = payload;
  }
};
const actions = {
  async getSalaryCommerciauxFiltred({ commit }, payload) {
    commit('SET_LOADING_PAIE_COMMERCIAUX', true);
    try {
      const params = {
        page: payload.page,
        per_page: payload.perPage,
        salarie: 'non'
      };

      if (payload.semaine != null && payload.switchWeek == true) {
        params.semaine = payload.semaine;
      }
      if (payload.month != null && payload.switchMonth == true) {
        params.month = payload.month;
      }
      if (
        payload.employee_id &&
        payload.employee_id.id &&
        payload.employee_id.id != null
      ) {
        params.employee_id = payload.employee_id.id;
      }
      if (payload.validation != null) {
        params.validation = payload.validation;
      }
      if (payload.depot != null) {
        params.depot = payload.depot;
      }
      if (payload.type !== null) {
        params.type = payload.type;
      }
      if (payload.filterProjectType !== null) {
        params.project_type = payload.filterProjectType;
      }
      if (payload.filterProjectType == 'ISO' && payload.mpr != null) {
        params.mpr = payload.mpr;
      }

      const response = await axios.get(
        domain + `/api/getBillsFitred/${payload.year}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SALARY_COMMERCIAUX_FILTRED', response.data.bill.data);
      commit('SET_TOTAL_ROW_Salary', response.data.bill.total);
      commit('SET_LOADING_PAIE_COMMERCIAUX', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_PAIE_COMMERCIAUX', false);
      return false;
    }
  },
  async validationFactureCommerciaux({ commit }, payload) {
    commit('SET_LOADING_PAIE_COMMERCIAUX', true);
    const body = {
      id: payload.row.id,
      statue: payload.value,
      comment: payload.row.comment
    };
    let obj = {};
    try {
      if (Array.isArray(payload.row.id)) {
        for (let index = 0; index < payload.row.id.length; index++) {
          body.id = payload.row.id[index];
          const response = await axios.post(
            domain + `/api/validateSalary`,
            body,
            {
              headers: {
                Authorization: `Bearer ${this.state.User.access_token}`
              }
            }
          );
        }
        obj = {
          validation: payload.value,
          row: payload.row,
          multiple: true
        };
      } else {
        const response = await axios.post(
          domain + `/api/validateSalary`,
          body,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        obj = {
          response: response.data.bill,
          row: payload.row
        };
      }

      commit('UPDATE_FACTURE_COMMERCIAUX', obj);
      commit('SET_LOADING_PAIE_COMMERCIAUX', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_PAIE_COMMERCIAUX', false);
      return false;
    }
  },
  async payedFactureCommerciaux({ commit }, payload) {
    const params = { payed_at: payload.value, id: payload.row.id };
    var obj = {};
    try {
      if (Array.isArray(payload.row.id)) {
        for (let index = 0; index < payload.row.id.length; index++) {
          params.id = payload.row.id[index];
          const response = await axios.post(
            domain + `/api/payedAtSalary`,
            params,
            {
              headers: {
                Authorization: `Bearer ${this.state.User.access_token}`
              }
            }
          );
        }
        obj = {
          payed_at: payload.value,
          row: payload.row,
          multiple: true
        };
      } else {
        const response = await axios.post(
          domain + `/api/payedAtSalary`,
          params,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        obj = {
          response: response.data.bill,
          row: payload.row
        };
      }
      commit('UPDATE_FACTURE_COMMERCIAUX', obj);
      return true;
    } catch (error) {
      return false;
    }
  },
  async downloadSalaryCommerciauxValidationPDF({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/adminDownloadSalary/${payload.item.bill.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          responseType: 'blob'
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute(
        'download',
        'Facture de ' +
          payload.item.employee.full_name +
          '-' +
          payload.year +
          '/' +
          payload.item.semaine +
          '.pdf'
      );
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  },
  async downloadFactureCommerciauxPDF({ commit }, payload) {
    const params = {};
    if (payload.project_type && payload.project_type != null) {
      params.project_type = payload.project_type;
    }
    if (Array.isArray(payload.item.id)) {
      params.bill_id = payload.item.id;
    } else {
      params.bill_id = [];
      params.bill_id.push(payload.item.id);
    }
    try {
      const response = await axios.get(
        domain + `/api/downloadAllBill/${payload.year}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          responseType: 'blob',
          params: params
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  },
  async uploadFactureCommerciaux({ commit }, payload) {
    commit('SET_ERROR_UPLOAD_FACTURE', null);
    commit('SET_LOADING_UPLOAD_FACTURE_REGIE', true);
    try {
      var obj = {};
      if (Array.isArray(payload.id)) {
        for (let index = 0; index < payload.id.length; index++) {
          var bodyFormData = new FormData();
          bodyFormData.append('file', payload.file);
          bodyFormData.append('bill_id', payload.id[index]);
          const responce = await axios.post(
            domain + '/api/ulpoadBillSalary',
            bodyFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${this.state.User.access_token}`
              }
            }
          );
        }
        obj = {
          row: payload,
          multiple: true
        };
      } else {
        var bodyFormData = new FormData();
        bodyFormData.append('file', payload.file);
        bodyFormData.append('bill_id', payload.id);
        const responce = await axios.post(
          domain + '/api/ulpoadBillSalary',
          bodyFormData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        obj = {
          response: responce.data.bill,
          row: payload
        };
      }

      commit('UPDATE_FACTURE_INDEPENDANT', obj);
      commit('SET_LOADING_UPLOAD_FACTURE_REGIE', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.file
      ) {
        commit('SET_ERROR_UPLOAD_FACTURE', error.response.data.errors.file);
      }
      commit('SET_LOADING_UPLOAD_FACTURE_REGIE', false);
      return false;
    }
  },
  async newCommentCommerciaux({ commit }, payload) {
    const params = new URLSearchParams();
    params.append('id', payload.id);
    params.append('commentaire', payload.comment);
    if (payload.row.validation) {
      params.append('statue', payload.row.validation);
    }
    try {
      const response = await axios.post(
        domain + `/api/validateSalary`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.bill,
        row: payload.row
      };
      commit('UPDATE_FACTURE_INDEPENDANT', obj);
      return true;
    } catch (error) {
      return false;
    }
  },
  async downloadSalaryCommerciauxValidationPDF({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/adminDownloadSalary/${payload.item.bill.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          responseType: 'blob'
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute(
        'download',
        'Facture de ' +
          payload.item.employee.full_name +
          '-' +
          payload.year +
          '/' +
          payload.item.semaine +
          '.pdf'
      );
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  },
  // getMissingSemaines
  async getMissingSemaines({ commit }, payload) {
    commit('SET_LOADING_PAIE_COMMERCIAUX', true);
    try {
      const params = {
        salarie: 'non',
        month: payload.month,
        year: payload.year
      };
      // if (payload.month != null && payload.switchMonth == true) {
      //   params.month = payload.month;
      // }
      if (
        payload.employee_id &&
        payload.employee_id.id &&
        payload.employee_id.id != null
      ) {
        params.employee_id = payload.employee_id.id;
      }
      if (payload.validation != null) {
        params.validation = payload.validation;
      }
      if (payload.type !== null) {
        params.type = payload.type;
      }
      if (payload.filterProjectType !== null) {
        params.project_type = payload.filterProjectType;
      }
      if (payload.filterProjectType == 'ISO' && payload.mpr != null) {
        params.mpr = payload.mpr;
      }

      const response = await axios.get(domain + `/api/missing_semaines`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_MISSING_SEMAINE_INDEPENDANT', response.data.data);
      commit('SET_LOADING_PAIE_COMMERCIAUX', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_PAIE_COMMERCIAUX', false);
      return false;
    }
  },
  resetMissingSemaines({ commit }) {
    commit('SET_MISSING_SEMAINE_INDEPENDANT', null);
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
