import axios from 'axios';
import domain from '@/environment';
import stateGlobale from '../index';
import FraisBureauEtudeTh from '../../models/FraisBureauEtudeTh';
const prefixFraisBureauEtude = 'frais';
let requestBe = null;
const state = {
  fraisBureauEtude: [],
  loadingFraisBureauEtude: false,
  errorFraisBureauEtude: null,
  templateBureauEtudeToUpload: null,
  loadingGetDataTemplateBureauEtude: false,
  errorTemplateBureauEtudeDataToUpploadeed: null,
  loadingExtactionFriasVisionBureauEtude: false,
  totalFraisBureauEtude: null,
  fraisBureauEtudeTotal: null
};
const getters = {
  getFraisBureauEtude: state => state.fraisBureauEtude,
  getLoadingFraisBureauEtude: state => state.loadingFraisBureauEtude,
  getErrorFraisBureauEtude: state => state.errorFraisBureauEtude,
  getTemplateBureauEtudeToUpload: state => state.templateBureauEtudeToUpload,
  getLoadingGetDataTemplateBureauEtude: state =>
    state.loadingGetDataTemplateBureauEtude,
  getErrorTemplateBureauEtudeDataToUpploadeed: state =>
    state.errorTemplateBureauEtudeDataToUpploadeed,
  getLoadingExtactionFriasVisionBureauEtude: state =>
    state.loadingExtactionFriasVisionBureauEtude,
  gettotalFraisBureauEtude: state => state.totalFraisBureauEtude,
  getfraisBureauEtudeTotal: state => state.fraisBureauEtudeTotal
};
const mutations = {
  SET_RAIS_BUREAU_ETUDE(state, payload) {
    state.fraisBureauEtude = payload.map(item =>
      FraisBureauEtudeTh.create(item)
    );
  },
  SET_RAIS_BUREAU_ETUDE_TOTAL(state, payload) {
    state.fraisBureauEtudeTotal = payload;
  },
  SET_LOADING_RAIS_BUREAU_ETUDE(state, payload) {
    state.loadingFraisBureauEtude = payload;
  },
  SET_ERROR_RAIS_BUREAU_ETUDE(state, payload) {
    state.errorFraisBureauEtude = payload;
  },
  SET_TYPE_RAIS_BUREAU_ETUDE(state, payload) {
    payload.item.update(payload.response);
  },
  SET_TEMPLATE_BUREAU_ETUDE_TO_UPLOAD(state, payload) {
    state.templateBureauEtudeToUpload = payload;
  },
  SET_LOADING_GET_TEMPLATE_BUREAU_ETUDE_DATA(state, payload) {
    state.loadingGetDataTemplateBureauEtude = payload;
  },
  SET_ERROR_TEMPLATE_BUREAU_ETUDE_DATA_TO_UPLOAD(state, payload) {
    state.errorTemplateBureauEtudeDataToUpploadeed = payload;
  },
  SET_DATA_FOR_FACTURE_BUREAU_ETUDE_UPLOADED(state, payload) {
    payload.item.update(payload.response);
  },
  SET_TYPE_RAIS_BUREAU_ETUDE_STATUE(state, payload) {
    payload.item[payload.column] = payload.response;
    if (payload.column.split('_')[1]) {
      let champName = payload.column.split('_')[1] + 'statue_installateur';
      payload.item[champName] = payload.response;
    } else {
      payload.item['statue_installateur'] = payload.response;
    }
  },
  SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_BUREAU_ETUDE(state, payload) {
    state.loadingExtactionFriasVisionBureauEtude = payload;
  },
  SET_TOTAL_RAIS_BUREAU_ETUDE(state, payload) {
    state.totalFraisBureauEtude = payload;
  },
  SET_STATUE_MAIL_BUREAU_ETUDE_VALUE(state, payload) {
    payload.item.update(payload.response);
  },
  SET_DATA_FRAIS_VISION_FILIALE_DETAILS_B(state, payload) {
    payload.row.update(payload.response);
  }
};
const actions = {
  // BE
  async fetchFraisBureauEtude({ commit }, payload) {
    commit('SET_ERROR_RAIS_BUREAU_ETUDE', null);

    const params = {
      year: payload.year,
      page: payload.page,
      perPage: payload.perPage
    };
    if (payload.filiale && payload.filiale != null) {
      params.filiale = payload.filiale;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.week != null) {
      params.week = payload.week;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }

    commit('SET_LOADING_RAIS_BUREAU_ETUDE', true);
    try {
      if (requestBe) {
        commit('SET_ERROR_FRAIS_BDD', null);
        requestBe.cancel('cancel-request');
      }
      requestBe = axios.CancelToken.source();
      const response = await axios.get(
        domain + `/api/${prefixFraisBureauEtude}/vision_BE`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestBe.token
        }
      );
      commit('SET_RAIS_BUREAU_ETUDE_TOTAL', response.data.count);
      commit('SET_RAIS_BUREAU_ETUDE', response.data.data);
      commit('SET_TOTAL_RAIS_BUREAU_ETUDE', {
        total: response.data.total,
        total_alteo: response.data.total_alteo,
        total_be: response.data.total_be,
        total_kynan: response.data.total_kynan
      });
      commit('SET_LOADING_RAIS_BUREAU_ETUDE', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', error.response.data.errors);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_LOADING_RAIS_BUREAU_ETUDE', false);
          commit('SET_ERROR_RAIS_BUREAU_ETUDE', ['Une erreur est servenue']);
        }
      }
    }
  },
  async updateBeType({ commit }, payload) {
    const params = {
      column: payload.column,
      value: payload.value,
      id: payload.row.id
    };
    commit('SET_ERROR_RAIS_BUREAU_ETUDE', null);
    commit('SET_LOADING_RAIS_BUREAU_ETUDE', true);
    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisBureauEtude}/update_BE`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TYPE_RAIS_BUREAU_ETUDE', {
        response: response.data.data,
        item: payload.row
      });
      commit('SET_LOADING_RAIS_BUREAU_ETUDE', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', error.response.data.errors);
      } else {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_RAIS_BUREAU_ETUDE', false);
      return false;
    }
  },
  async updateMontantFraisBureauEtudeB({ commit }, payload) {
    const params = {
      column: payload.column,
      value: payload.value,
      id: payload.row.id
    };
    commit('SET_ERROR_RAIS_BUREAU_ETUDE', null);
    commit('SET_LOADING_RAIS_BUREAU_ETUDE', true);
    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisBureauEtude}/update_binifis_value`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TYPE_RAIS_BUREAU_ETUDE', {
        response: response.data.data,
        item: payload.row
      });
      commit('SET_LOADING_RAIS_BUREAU_ETUDE', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', error.response.data.errors);
      } else {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_RAIS_BUREAU_ETUDE', false);
      return false;
    }
  },
  async getBureauEtudeTemplateAndFiledsDataToUpdate({ commit }, payload) {
    commit('SET_ERROR_TEMPLATE_BUREAU_ETUDE_DATA_TO_UPLOAD', null);
    commit('SET_LOADING_GET_TEMPLATE_BUREAU_ETUDE_DATA', true);
    const params = {
      year: payload.year,
      type: payload.type,
      month: payload.month,
      filiale: payload.filiale,
      nom_prenom: payload.nom_prenom,
      BE_TYPE: payload.column,
      project_id: payload.project_id,
      dev: payload.dev
    };

    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/get_uploaded_template`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_TEMPLATE_BUREAU_ETUDE_TO_UPLOAD', response.data.data);

      commit('SET_LOADING_GET_TEMPLATE_BUREAU_ETUDE_DATA', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_TEMPLATE_BUREAU_ETUDE_DATA_TO_UPLOAD',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_TEMPLATE_BUREAU_ETUDE_DATA_TO_UPLOAD', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_GET_TEMPLATE_BUREAU_ETUDE_DATA', false);

      return false;
    }
  },
  async uploadBureauEtudeTemplateInServieVisionFiliale({ commit }, payload) {
    const params = {
      year: payload.year,
      type: payload.type,
      filiale: payload.filiale,
      description: payload.description,
      iban: payload.iban,
      rib: payload.rib,
      swift: payload.swift,
      num_facture: payload.num_facture,
      nom_prenom: payload.nom_prenom,
      date: payload.date,
      date_reglement: payload.date_reglement,
      month: payload.month,
      BE_TYPE: payload.column,
      nom_prenom: payload.nom_prenom,
      project_id: payload.project_id
    };
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/upload`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_DATA_FOR_FACTURE_BUREAU_ETUDE_UPLOADED', {
        response: response.data.data,
        item: payload.item
      });
      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //statut
  async updateStatueFraisBureauEtude({ commit }, payload) {
    commit('SET_ERROR_RAIS_BUREAU_ETUDE', null);
    commit('SET_LOADING_RAIS_BUREAU_ETUDE', true);
    let id =
      payload.column == 'statue'
        ? payload.row.facture_id
        : payload.column == 'statue_alteo'
        ? payload.row.facture_alteo_id
        : payload.row.facture_kynan_id;
    const params = {
      id: id,
      column: 'statue',
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain +
          `/api/${prefixFraisBureauEtude}/vision_filiale_update_statue_date`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TYPE_RAIS_BUREAU_ETUDE_STATUE', {
        response: payload.value,
        item: payload.row,
        column: payload.column
      });

      commit('SET_LOADING_RAIS_BUREAU_ETUDE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', error.response.data.errors);
      } else {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_RAIS_BUREAU_ETUDE', false);

      return false;
    }
  },
  //statut installateur
  async updateStatueInstallateurFraisBureauEtude({ commit }, payload) {
    commit('SET_ERROR_RAIS_BUREAU_ETUDE', null);
    commit('SET_LOADING_RAIS_BUREAU_ETUDE', true);
    let id =
      payload.column == 'statue' || payload.column == 'statue_installateur'
        ? payload.row.facture_id
        : payload.column == 'statue_alteo'
        ? payload.row.facture_alteo_id
        : payload.row.facture_kynan_id;
    const params = {
      id: id,
      statue_installateur: payload.value,
      vision: stateGlobale.state.currentInterfaceFrais
    };
    try {
      const response = await axios.post(
        domain + `/api/frais/update_statue_installateur`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_STATUE_MAIL_BUREAU_ETUDE_VALUE', {
        item: payload.row,
        response: response.data.data
      });

      commit('SET_LOADING_RAIS_BUREAU_ETUDE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', error.response.data.errors);
      } else {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_RAIS_BUREAU_ETUDE', false);

      return false;
    }
  },
  async exportPdfFactureUploadedTypeBureauEtude({ commit }, payload) {
    commit('SET_ERROR_RAIS_BUREAU_ETUDE', null);
    commit('SET_LOADING_RAIS_BUREAU_ETUDE', true);
    const params = {
      year: payload.year,
      filiale: payload.filiale,
      type: payload.type,
      nom_prenom: payload.nom_prenom,
      nom_prenom: payload.nom_prenom,
      id: payload.id
    };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    if (payload.av) {
      params.av = payload.av;
    }
    try {
      const response = await axios.get(
        domain + `/api/filiale_template_generator/download`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_RAIS_BUREAU_ETUDE', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_RAIS_BUREAU_ETUDE', ['Une erreur est servenus']);
      commit('SET_LOADING_RAIS_BUREAU_ETUDE', false);
      return false;
    }
  },
  async exportExcelFraisVisionBureauDetudeTh({ commit }, payload) {
    commit('SET_ERROR_RAIS_BUREAU_ETUDE', null);
    commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_BUREAU_ETUDE', true);
    try {
      const response = await axios.post(
        domain + `/api/frais/export_vision_filiale`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      let year = payload.get('year') ? '-' + payload.get('year') : '';
      link.setAttribute(
        'download',
        'VISION BUREAU DETUDE  ' +
          payload.get('type').toUpperCase() +
          year +
          '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_BUREAU_ETUDE', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_RAIS_BUREAU_ETUDE', ['Une erreur est servenue']);
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_BUREAU_ETUDE', false);
      return false;
    }
  },
  setDaraTemplateBureauEtudeUploded({ commit }) {
    commit('SET_TEMPLATE_BUREAU_ETUDE_TO_UPLOAD', null);
  },
  //global validation BE && BE B
  async getGlobalTemplateAndFiledsDataToUpdateForBe({ commit }, payload) {
    commit('SET_ERROR_TEMPLATE_BUREAU_ETUDE_DATA_TO_UPLOAD', null);
    commit('SET_LOADING_GET_TEMPLATE_BUREAU_ETUDE_DATA', true);
    const params = {
      type: payload.type
    };

    try {
      const response = await axios.get(domain + `/api/templates/get_one`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_TEMPLATE_BUREAU_ETUDE_TO_UPLOAD', response.data.data);

      commit('SET_LOADING_GET_TEMPLATE_BUREAU_ETUDE_DATA', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_TEMPLATE_BUREAU_ETUDE_DATA_TO_UPLOAD',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_TEMPLATE_BUREAU_ETUDE_DATA_TO_UPLOAD', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_GET_TEMPLATE_BUREAU_ETUDE_DATA', false);

      return false;
    }
  },
  async uploadGlobalTemplateForBe({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/filiale_template_generator/upload_all`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async exportPdfFraisVisionBureauDetudeTh({ commit }, payload) {
    commit('SET_ERROR_RAIS_BUREAU_ETUDE', null);
    commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_BUREAU_ETUDE', true);
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.post(
        domain + `/api/filiale_template_generator/download_all`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const month_name =
        payload.month && payload.month != null ? '-' + payload.month : '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'VISION BUREAU DETUDE.zip');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_BUREAU_ETUDE', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_RAIS_BUREAU_ETUDE', ['Une erreur est servenue']);
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_BUREAU_ETUDE', false);
      return false;
    }
  },
  async exportPdfInvalidFraisVisionBureauDetudeTh({ commit }, payload) {
    commit('SET_ERROR_RAIS_BUREAU_ETUDE', null);
    commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_BUREAU_ETUDE', true);
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.post(
        domain + `/api/download_pdfNonValide`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const month_name =
        payload.month && payload.month != null ? '-' + payload.month : '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'VISION BUREAU DETUDE INVALID.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_BUREAU_ETUDE', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_RAIS_BUREAU_ETUDE', ['Une erreur est servenue']);
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_BUREAU_ETUDE', false);
      return false;
    }
  },
  async getDataForRowFraisB({ commit }, payload) {
    payload.item.loadingDetailt = true;
    const params = {
      year: payload.year,
      page: payload.page,
      perPage: payload.perPage,
      filiale: payload.item.name
    };
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.week != null) {
      params.week = payload.week;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }

    try {
      const response = await axios.get(
        domain + `/api/frais/vision_BE_projects`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_DATA_FRAIS_VISION_FILIALE_DETAILS_B', {
        response: response.data,
        row: payload.item
      });
      payload.item.loadingDetailt = false;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', error.response.data.errors);
      } else {
        commit('SET_ERROR_RAIS_BUREAU_ETUDE', ['Une erreur est servenue']);
      }
      payload.item.loadingDetailt = false;

      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
