import fraisBddCommericalCactusProjectsTh from './fraisBddCommericalCactusProjectsTh';
export default class fraisBddCommericalCactusTh {
  constructor(data) {
    this.name = data.name;
    this.alteo = data.alteo;
    this.alteo1 = data.alteo1;
    this.alteo2 = data.alteo2;
    this.externe = data.externe;
    this.interne = data.interne;
    this.commercial = data.commercial;
    this.total = data.total;
    this.cactus = data.cactus;
    this.check = false;
    this.loadingDetailt = false;
    this.projects = data.projects;
  }

  update(data) {
    this.projects = data.projects.map(item =>
      fraisBddCommericalCactusProjectsTh.create(item)
    );
  }
  static create(data) {
    return new fraisBddCommericalCactusTh(data);
  }
}
