export default class FilialeTh {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.depots = data.depots;
    this.volant = data.volant;
    this.responsable_depot = null;
    this.logo = data.logo;
    this.imageDataUrl = null;
    this.imageData = '';
    this.siret = data.siret;
    this.responsables = data.responsables;
    this.adresse = data.adresse;
    this.email = data.email;
    //new property
    this.abreviation = data.abreviation;
    this.couleur = data.couleur;
    this.prenom = data.prenom;
    this.nom_famille = data.nom_famille;
    this.civilite = data.civilite;
    this.siren_value = data.siren_value;
    this.tva_value = data.tva_value;
    this.code_postal = data.code_postal;
    this.site_internet = data.site_internet;
    this.ville = data.ville;
    this.pays = data.pays;
    this.iban = data.iban;
    this.banque = data.banque;
    this.swift = data.swift;
    this.fax = data.fax;
    this.telephone = data.telephone;
    this.description = data.description;
    this.telephone_portable = data.telephone_portable;
    this.type = data.type;
    this.type_societe = data.type_societe;
    this.files = data.files;
    this.domaine = data.domaine;
    this.type_societe_support = data.type_societe_support;
    this.is_societe_support = data.is_societe_support;
    this.ribs = data.ribs;
    this.sequences_factures = data.sequences_factures;
    this.biens = data.biens;
    this.masterFiliales = data.masterFiliales;
    this.societes_of_master_filiale = data.societes_of_master_filiale;
    this.deleted_at = data.deleted_at;
  }

  /**
   * @param {*} data
   */

  update(data) {
    this.name = data.name;
    this.depots = data.depots;
    this.volant = data.volant;
    this.responsable_depot = null;
    this.logo = data.logo;
    this.imageData = '';
    this.siret = data.siret;
    this.responsables = data.responsables;
    this.adresse = data.adresse;
    this.email = data.email;
    //new property
    this.abreviation = data.abreviation;
    this.couleur = data.couleur;
    this.prenom = data.prenom;
    this.nom_famille = data.nom_famille;
    this.civilite = data.civilite;
    this.siren_value = data.siren_value;
    this.tva_value = data.tva_value;
    this.code_postal = data.code_postal;
    this.site_internet = data.site_internet;
    this.ville = data.ville;
    this.pays = data.pays;
    this.iban = data.iban;
    this.banque = data.banque;
    this.swift = data.swift;
    this.fax = data.fax;
    this.telephone = data.telephone;
    this.description = data.description;
    this.telephone_portable = data.telephone_portable;
    this.type = data.type;
    this.type_societe = data.type_societe;
    this.files = data.files;
    this.domaine = data.domaine;
    this.type_societe_support = data.type_societe_support;
    this.is_societe_support = data.is_societe_support;
    this.ribs = data.ribs;
    this.sequences_factures = data.sequences_factures;
    this.biens = data.biens;
    this.masterFiliales = data.masterFiliales;
    this.societes_of_master_filiale = data.societes_of_master_filiale;
    this.deleted_at = data.deleted_at;
  }
  static create(data) {
    if (!data) {
      throw new Error('FilialeTh::create: "data" is undefined');
    }
    return new FilialeTh(data);
  }
}
