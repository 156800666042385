import axios from 'axios';
import domain from '@/environment';
import fraisMonthTh from '../../models/FraisMonthTh';
const Calendar_TH_frais = 'Calendar_TH_frais';

const state = {
  errorFraisTh: null,
  loagingFraisTh: false,
  fraisThData: [],
  totalRowFraisTh: null,
  loadingExportExcelHeadersData: false,
  errorExportExcelHeadersData: null,
  headersFieldsFraisExcelExport: [],
  columnsFieldsFraisExcelExport: []
};
const getters = {
  getErrorFraisTh: state => state.errorFraisTh,
  getLoagingFraisTh: state => state.loagingFraisTh,
  getFraisThData: state => state.fraisThData,
  getTotalRowFraisTh: state => state.totalRowFraisTh,
  getLoadingExportExcelHeadersData: state =>
    state.loadingExportExcelHeadersData,
  getErrorExportExcelHeadersData: state => state.errorExportExcelHeadersData,
  getHeadersFieldsFraisExcelExport: state =>
    state.headersFieldsFraisExcelExport,
  getColumnsFieldsFraisExcelExport: state => state.columnsFieldsFraisExcelExport
};
const mutations = {
  ERROR_FRAIS_TH_TO_CONFIG(state, payload) {
    state.errorFraisTh = payload;
  },
  LOADING_FRAIS_TH_TO_CONFIG(state, payload) {
    state.loagingFraisTh = payload;
  },
  NEW_FRAIS_TH_CONFIG(state, payload) {
    let existe = state.fraisThData.filter(item => item.id == payload.id);
    if (existe.length) {
      existe[0].update(payload);
    } else {
      state.fraisThData.push(fraisMonthTh.create(payload));
    }
  },
  ALL_FRAIS_TH_CONFIG(state, payload) {
    state.fraisThData = payload.map(item => fraisMonthTh.create(item));
  },
  TOTAL_FRAIS_TH_CONFIG(state, payload) {
    state.totalRowFraisTh = payload;
  },
  UPDATE_FRAIS_TH_CONFIG(state, payload) {
    //   for (let i = 0; i < state.fraisThData.length; i++) {
    //     if (state.fraisThData[i].id == payload.id) {
    //       state.fraisThData[i].update(payload);
    //       break;
    //     }
    //   }
    payload.data.update(payload.response);
  },
  LOADING_EXPORT_EXCEL_FRAIS_DATA_HEADERS(state, payload) {
    state.loadingExportExcelHeadersData = payload;
  },
  ERROR_EXPORT_EXCEL_FRAIS_DATA_HEADERS(state, payload) {
    state.errorExportExcelHeadersData = payload;
  },
  SET_FIELD_FOR_EXPORT_EXCEL_FRAIS(state, payload) {
    state.headersFieldsFraisExcelExport = payload.headers;
    state.columnsFieldsFraisExcelExport = payload.columns;
  }
};
const actions = {
  async newConfigFraisTh({ commit }, payload) {
    let params = { ...payload };
    // params.TH_value = parseFloat((params.TH_value + '').replace(',', '.'));
    try {
      const response = await axios.post(
        domain + `/api/${Calendar_TH_frais}/create_month_frais`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('NEW_FRAIS_TH_CONFIG', response.data.data);

      return {
        succes: true
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async fetchAllFraisTh({ commit }, payload) {
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      year: payload.year
    };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    if (payload.week && payload.week != null) {
      params.week = payload.week;
    }
    commit('LOADING_FRAIS_TH_TO_CONFIG', true);
    try {
      const response = await axios.get(
        domain + `/api/${Calendar_TH_frais}/get_month_frais`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('ALL_FRAIS_TH_CONFIG', response.data.data.data);
      commit('TOTAL_FRAIS_TH_CONFIG', response.data.data.total);
      commit('LOADING_FRAIS_TH_TO_CONFIG', false);

      return true;
    } catch (error) {
      if (error.response && error.respons.data && error.response.data.error) {
        commit('ERROR_FRAIS_TH_TO_CONFIG', error.response.data.error);
      } else {
        commit('ERROR_FRAIS_TH_TO_CONFIG', ['Une erreur est servenue']);
      }
      commit('LOADING_FRAIS_TH_TO_CONFIG', false);

      return false;
    }
  },

  async updateConfigParamsFraisTh({ commit }, payload) {
    let params = { ...payload.fraisToUpdate, type: payload.type };
    params.month = params.month_name;
    if (payload.type == 'week') {
      params.week = params.id;
    }
    if (payload.type == 'filiale') {
      params.filiale = payload.fraisToUpdate.name;
    }
    params.month = params.month_name;
    let routerName =
      payload.type == 'depot' ? 'edit_depot_frais' : 'edit_month_week_frais';
    try {
      const response = await axios.post(
        domain + `/api/${Calendar_TH_frais}/${routerName}`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_FRAIS_TH_CONFIG', {
        response: response.data.data,
        data: payload.data
      });

      return {
        succes: true
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async getHeadersAndColumnsForExcel({ commit }, payload) {
    commit('LOADING_EXPORT_EXCEL_FRAIS_DATA_HEADERS', true);
    try {
      const response = await axios.get(
        domain + `/api/frais/headers_and_columns/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FIELD_FOR_EXPORT_EXCEL_FRAIS', response.data);
      commit('LOADING_EXPORT_EXCEL_FRAIS_DATA_HEADERS', false);

      return true;
    } catch (error) {
      if (error.response && error.respons.data && error.response.data.error) {
        commit(
          'ERROR_EXPORT_EXCEL_FRAIS_DATA_HEADERS',
          error.response.data.error
        );
      } else {
        commit('ERROR_EXPORT_EXCEL_FRAIS_DATA_HEADERS', [
          'Une erreur est servenue'
        ]);
      }
      commit('LOADING_EXPORT_EXCEL_FRAIS_DATA_HEADERS', false);

      return false;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
