import AppelSubObligeeTh from './AppelSubObligeeTh';
export default class AppelObligeTh {
  constructor(data) {
    this.name = data.name;
    this.check = false;
    this.details = false;
    this.showDetails = false;

    this.ttc_avoir = data.ttc_avoir;
    this.kwh = data.kwh;
    this.mega = data.mega;
    this.cee = data.cee;
    this.montant_total_ttc = data.montant_total_ttc;
    this.reste_a_charge = data.reste_a_charge;

    this.id_vendeur = data.id_vendeur;
    this.id_acheteur = data.id_acheteur;
    this.id_group = data.id_group;
    this.name_acheteur = data.name_acheteur;
    this.name_group = data.name_group;
    this.name_vendeur = data.name_vendeur;
    this.warning = data.warning;
    this.error_missing_prime = data.error_missing_prime;
    ///error

    this.error_vendeur = data.error_vendeur;
    this.error_acheteur = data.error_acheteur;
    this.error_group = data.error_group;
    this.error_avoir = data.error;
    //missing prime
    this.missing_prime_ht = data.missing_prime_ht;
    this.missing_prime_tva = data.missing_prime_tva;
    this.missing_prime_ttc = data.missing_prime_ttc;
    this.missing_prime_id = data.missing_prime_id;
    this.missing_prime_statut = data.missing_prime_statut;
    this.missing_num_facture = data.missing_num_facture;

    //avoir prime
    this.missing_prime_av_ht = data.missing_prime_av_ht;
    this.missing_prime_av_tva = data.missing_prime_av_tva;
    this.missing_prime_av_ttc = data.missing_prime_av_ttc;
    this.missing_prime_av_id = data.missing_prime_av_id;
    this.missing_prime_av_statut = data.missing_prime_av_statut;
    this.missing_prime_av_num_facture = data.missing_prime_av_num_facture;

    //prime
    this.prime_ht = data.prime_ht;
    this.prime_tva = data.prime_tva;
    this.prime_ttc = data.prime_ttc;
    this.prime_id = data.prime_id;
    this.prime_statut = data.prime_statut;
    this.prime_num_facture = data.prime_num_facture;

    //surprime
    this.surprime_ht = data.surprime_ht;
    this.surprime_tva = data.surprime_tva;
    this.surprime_ttc = data.surprime_ttc;
    this.surprime_id = data.surprime_id;
    this.surprime_statut = data.surprime_statut;
    this.surprime_num_facture = data.surprime_num_facture;

    //commission
    this.commission_ht = data.commission_ht;
    this.commission_tva = data.commission_tva;
    this.commission_ttc = data.commission_ttc;
    this.commission_id = data.commission_id;
    this.commission_statut = data.commission_statut;
    this.commission_num_facture = data.commission_num_facture;

    //total
    this.total = data.total;
    this.projects = data.projects.map(item => AppelSubObligeeTh.create(item));
  }
  updateCoef(data) {
    let column = 'pu_' + data.column;
    let column1 =
      data.column == 'commission'
        ? 'montant_' + data.column + 's_cee'
        : 'montant_' + data.column + '_cee';
    this[column] = (
      parseFloat((this[column] + '').replace(',', '.')) -
      parseFloat((data.item[data.column] + '').replace(',', '.')) +
      parseFloat((data.response[column1] + '').replace(',', '.'))
    ).toFixed(2);
    this.total = (
      parseFloat((this.total + '').replace(',', '.')) -
      parseFloat((data.item[data.column] + '').replace(',', '.')) +
      parseFloat((data.response[column1] + '').replace(',', '.'))
    ).toFixed(2);
    if (data.column == 'prime') {
      this.pu_missing_prime = (
        parseFloat((this.pu_missing_prime + '').replace(',', '.')) -
        parseFloat((data.item['missing_prime'] + '').replace(',', '.')) +
        parseFloat(
          (data.response['montant_missing_prime_cee'] + '').replace(',', '.')
        )
      ).toFixed(2);
      this.total = (
        parseFloat((this.total + '').replace(',', '.')) -
        parseFloat((data.item['missing_prime'] + '').replace(',', '.')) +
        parseFloat(
          (data.response['montant_missing_prime_cee'] + '').replace(',', '.')
        )
      ).toFixed(2);
    }
  }
  update(data) {
    this[data.id] = data.id_value;
    this[data.statut] = data.statut_value;
    this[data.num_facture] = data.num_facture_value;
  }
  updateData(data) {
    this.ttc_avoir = data.ttc_avoir;
    this.kwh = data.kwh;
    this.mega = data.mega;
    this.cee = data.cee;
    this.montant_total_ttc = data.montant_total_ttc;
    this.reste_a_charge = data.reste_a_charge;

    this.id_vendeur = data.id_vendeur;
    this.id_acheteur = data.id_acheteur;
    this.id_group = data.id_group;
    this.name_acheteur = data.name_acheteur;
    this.name_group = data.name_group;
    this.name_vendeur = data.name_vendeur;
    this.warning = data.warning;
    this.error_missing_prime = data.error_missing_prime;
    ///error

    this.error_vendeur = data.error_vendeur;
    this.error_acheteur = data.error_acheteur;
    this.error_group = data.error_group;
    //missing prime
    this.missing_prime_ht = data.missing_prime_ht;
    this.missing_prime_tva = data.missing_prime_tva;
    this.missing_prime_ttc = data.missing_prime_ttc;
    this.missing_prime_id = data.missing_prime_id;
    this.missing_prime_num_facture = data.missing_prime_num_facture;
    //avoir prime
    this.missing_prime_av_ht = data.missing_prime_av_ht;
    this.missing_prime_av_tva = data.missing_prime_av_tva;
    this.missing_prime_av_ttc = data.missing_prime_av_ttc;
    this.missing_prime_av_id = data.missing_prime_av_id;
    this.missing_prime_av_statut = data.missing_prime_av_statut;
    this.missing_prime_av_num_facture = data.missing_prime_av_num_facture;

    //prime
    this.prime_ht = data.prime_ht;
    this.prime_tva = data.prime_tva;
    this.prime_ttc = data.prime_ttc;
    this.prime_id = data.prime_id;
    this.prime_statut = data.prime_statut;
    this.prime_num_facture = data.prime_num_facture;

    //surprime
    this.surprime_ht = data.surprime_ht;
    this.surprime_tva = data.surprime_tva;
    this.surprime_ttc = data.surprime_ttc;
    this.surprime_id = data.surprime_id;
    this.surprime_statut = data.surprime_statut;
    this.surprime_num_facture = data.surprime_num_facture;
    //commission
    this.commission_ht = data.commission_ht;
    this.commission_tva = data.commission_tva;
    this.commission_ttc = data.commission_ttc;
    this.commission_id = data.commission_id;
    this.commission_statut = data.commission_statut;
    this.commission_num_facture = data.commission_num_facture;

    //total
    this.total = data.total;
    this.projects = data.projects.map(item => AppelSubObligeeTh.create(item));
  }

  static create(data) {
    return new AppelObligeTh(data);
  }
}
