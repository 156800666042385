export default class Chart {
  constructor(data) {
    this.name = data.name;
    this.pourcentage = data.pourcentage;
  }
  update(data) {
    this.name = data.name;
    this.pourcentage = data.pourcentage;
  }
  static create(data) {
    return new Chart(data);
  }
}
