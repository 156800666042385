export default class FactureFiltered {
  constructor(data) {
    this.id = data.id;
    this.numero_facture = data.numero_facture;
    this.date_facture = data.date_facture;
    this.project = data.project;
    this.vendeur = data.vendeur;
    this.acheteur = data.acheteur;
    this.coef = data.coef;
    this.HT = data.HT;
    this.TVA = data.TVA;
    this.TVA_montant = data.TVA_montant;
    this.TTC = data.TTC;
    this.acompte = data.acompte;
    this.reduction = data.reduction;
    this.reduction_from = data.reduction_from;
    this.reduction_type = data.reduction_type;
    this.statut_vendeur = data.statut_vendeur;
    this.statut_acheteur = data.statut_acheteur;
    this.responsable_acheteur = data.responsable_acheteur;
    this.swift = data.swift;
    this.iban = data.iban;
    this.body = data.body;
    this.date_paiement = data.date_paiement;
    this.date_reglement = data.date_reglement;
    this.type = data.type;
    this.email_sent_at = data.email_sent_at;
    this.lot = data.lot;
    this.numero_lot = data.numero_lot;
    this.description = data.description;
    this.column = data.column;
    this.locked = data.locked;
    this.config_ref_id = data.config_ref_id;
    this.locked_by = data.locked_by;
    this.unlocked_by = data.unlocked_by;
    this.locked_at = data.locked_at;
    this.unlocked_at = data.unlocked_at;
  }
  static create(data) {
    return new FactureFiltered(data);
  }

  updateLock(data) {
    if (data.locked == true) {
      this.locked_by = data.locked_by;
      this.locked_at = data.locked_at;
    } else {
      this.unlocked_by = data.unlocked_by;
      this.unlocked_at = data.unlocked_at;
    }
  }
}
