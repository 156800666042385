export default class RegieTh {
  constructor(data) {
    this.id = data.id;
    this.month = data.month;
    this.year = data.year;
    // this.pay_previsite = data.pay_previsite;
    this.TH_value = data.TH_value;
    this.regie = data.regie;
  }
  update(data) {
    // this.pay_previsite = data.pay_previsite;
    this.TH_value = data.TH_value;
  }
  static create(data) {
    return new RegieTh(data);
  }
}
