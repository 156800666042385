export default class FraisCactusAlteoTh {
  constructor(data) {
    this.id = data.id;
    this.month = data.month;
    this.year = data.year;
    this.week_num = data.week_num;
    this.week_id = data.week_id;
    this.interne = data.interne;
    this.externe = data.externe;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.commercial = data.commercial;
    this.alteo1 = data.alteo1;
    this.alteo2 = data.alteo2;
  }
  update(data) {
    this.interne = data.interne;
    this.externe = data.externe;
    this.commercial = data.commercial;
    this.alteo1 = data.alteo1;
    this.alteo2 = data.alteo2;
  }
  static create(data) {
    return new FraisCactusAlteoTh(data);
  }
}
