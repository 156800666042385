<template>
  <div id="app">
    <router-view></router-view>
    <portal-target
      name="new-regie-modal"
      :transition="fadeTransition"
    ></portal-target>
    <portal-target
      name="new-salary-modal"
      :transition="fadeTransition"
    ></portal-target>
  </div>
</template>
<script>
import pusherAdmin from './pusher/admin_pusher';
import pusherAdminRegie from './pusher/admin_regie';
import pusherResponsableDepot from './pusher/responsable_depot';
import pusherEmployee from './pusher/employee';
import { mapActions, mapGetters } from 'vuex';
export default {
  mounted() {
    if (process.env.VUE_APP_ENV === 'develop') {
      document.title = `[dev] ${document.title}`;
    }
    if (this.getUserData && this.getUserData.role == 'admin') {
      pusherAdmin.checkanalyse();
      pusherAdmin.getnotificationForAdmin();
      pusherAdmin.checkvalidation();
    } else if (
      this.getUserData &&
      (this.getUserData.role == 'admin_regie' ||
        this.getUserData.role == 'commercial_regie')
    ) {
      pusherAdminRegie.getnotificationForAdminRegie();
    } else if (
      this.getUserData &&
      this.getUserData.role == 'responsable_depot'
    ) {
      pusherResponsableDepot.getnotificationForResponsableDepot();
    } else if (
      this.getUserData &&
      (this.getUserData.role === 'Responsable planning' ||
        this.getUserData.role === 'Commercial sedentaire' ||
        this.getUserData.role === 'Admin GE' ||
        this.getUserData.role === 'Commercial terrain' ||
        this.getUserData.role === 'Pre visiteur' ||
        this.getUserData.role === 'Poseur' ||
        this.getUserData.role === 'Co poseur')
    ) {
      pusherEmployee.getnotificationForEmployee();
    }
  },
  computed: {
    ...mapGetters(['getUserData', 'getCurrentType']),
    fadeTransition() {
      return {
        functional: true,
        render(h, context) {
          return h(
            'transition',
            { props: { name: 'slide-fade', mode: 'out-in' } },
            context.children
          );
        }
      };
    }
  },
  methods: {
    ...mapActions(['setCurrentTpe'])
  },
  watch: {
    $route(to, from) {
      const currentParentUrl = this.$route.path.split('/')[1];
      if (
        currentParentUrl != 'facture-libre' &&
        currentParentUrl != 'facture-sci'
      ) {
        let payload = {
          name: 'Bar TH',
          value: 'Th'
        };
        this.setCurrentTpe(payload);
      }
    }
  }
};
</script>
<style scoped lang="scss">
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(500px);
  opacity: 0;
}
</style>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
* {
  margin: 0;
  padding: 0;
}

#app {
  display: flex;
  position: relative;
  height: 100%;
  font: 400 15px/20px Ubuntu, sans-serif;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 15px;
}
</style>
