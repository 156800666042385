export default class Organisme {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.prix = data.prix;
    this.semaine = data.semaine;
    this.updated_at = data.updated_at;
    this.created_at = data.created_at;
    this.thisID = data.thisID;
  }
  update(data) {
    this.name = data.name;
    this.prix = data.prix;
    this.semaine = data.semaine;
    this.updated_at = data.updated_at;
    this.created_at = data.created_at;
  }
  static create(data) {
    return new Organisme(data);
  }
}
