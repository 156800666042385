import axios from 'axios';
import domain from '@/environment';
const state = {
  loadingExportAllFactureCdg: false,
  errorExportxAllFactureCdg: null
};
const getters = {
  getLoadingExportAllFactureCdg: state => state.loadingExportAllFactureCdg,
  getErrorExportAllFactureCdg: state => state.errorExportAllFactureCdg
};
const mutations = {
  SET_LOADER_EXPORT_FACTURE_CDG(state, payload) {
    state.loadingExportAllFactureCdg = payload;
  }
};
const actions = {
  async exportFactureCdg({ commit }, payload) {
    let params = {};
    if (payload?.date_debut != null) params.date_debut = payload.date_debut;
    if (payload?.date_fin != null) params.date_fin = payload.date_fin;
    if (payload?.societe.length) params.societe = payload.societe;
    commit('SET_LOADER_EXPORT_FACTURE_CDG', true);

    try {
      const response = await axios.get(domain + '/api/export_excel_global', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        responseType: 'blob'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'Facture_CDG' + '.xlsx');

      document.body.appendChild(link);
      link.click();

      commit('SET_LOADER_EXPORT_FACTURE_CDG', false);
    } catch (error) {
      commit('SET_LOADER_EXPORT_FACTURE_CDG', false);
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
