export default class CalenderTh {
  constructor(data) {
    this.id = data.id;
    this.year = data.year;
    this.month = data.month;
    this.month_name = data.month_name;
    this.depots = data.depots;
    this.weeks = data.weeks;
  }

  /**
   * @param {*} data
   */

  update(data) {
    this.year = data.year;
    this.month = data.month;
    this.month_name = data.month_name;
    this.depots = data.depots;
    this.weeks = data.weeks;
  }
  static create(data) {
    if (!data) {
      throw new Error('CalenderTh::create: "data" is undefined');
    }
    return new CalenderTh(data);
  }
}
