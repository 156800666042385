import axios from 'axios';
import domain from '@/environment';
import GestionConfiguration from '../../models/gestionConfiguration';
let cancelErrors = null;

const state = {
  filialsSupport: [],
  proFilials: [],
  NumeriqCulomn: [],
  AllCulomns: [],
  ConfigurationErrors: null,
  isLoading: false,
  AllConfigurations: [],
  totalRowConf: 0,
  errorForSpecific: null
};
const getters = {
  getFilialsSupport: state => state.filialsSupport,
  getProFilials: state => state.proFilials,
  getNumeriqCulomn: state => state.NumeriqCulomn,
  getColumns: state => state.AllCulomns,
  getIsLoding: state => state.isLoading,
  getConfigurationError: state => state.ConfigurationErrors,
  getTotalRowConf: state => state.totalRowConf,
  getAllConfigurations: state => state.AllConfigurations,
  getErrorForSpecific: state => state.errorForSpecific
};
const mutations = {
  ADD_NEW_CONF(state, payload) {
    state.AllConfigurations.unshift(GestionConfiguration.create(payload));
  },
  SET_ALL_CONFIG(state, payload) {
    state.AllConfigurations = payload.map(item =>
      GestionConfiguration.create(item)
    );
  },
  UPDATE_CONFIG(state, payload) {
    payload.originConfig.update(payload.response);
  },
  SET_FILIALES_SUPPORTS(state, payload) {
    state.filialsSupport = payload;
  },
  SET_PRO_FILIALS_SUPPORTS(state, payload) {
    state.proFilials = payload;
  },
  SET_NUM_CULOMN(state, payload) {
    state.NumeriqCulomn = payload;
  },
  SET_CULOMNS(state, payload) {
    state.AllCulomns = payload;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_CONFIGURATION_ERROR(state, payload) {
    state.ConfigurationErrors = payload;
  },
  SET_TOTAL_ROWS_CONF(state, payload) {
    state.totalRowConf = payload;
  },
  INIT_ERRORS(state, payload) {
    state.ConfigurationErrors = payload;
    state.errorForSpecific = payload;
  },
  SET_ERROR_SPECIFIC(state, payload) {
    state.errorForSpecific = payload;
  }
};
const actions = {
  async fetchAllconfigurations({ commit }, payload) {
    const params = {};
    if (payload?.page) {
      params.page = payload.page;
    }
    if (payload?.per_page) {
      params.per_page = payload.per_page;
    }
    if (payload?.date_debut) {
      params.date_debut = payload.date_debut;
    }
    if (payload?.nom_societe) {
      params.nom_societe = payload.nom_societe;
    }
    if (payload?.date_fin) {
      params.date_fin = payload.date_fin;
    }
    commit('SET_CONFIGURATION_ERROR', null);
    commit('SET_IS_LOADING', true);
    try {
      const response = await axios.get(
        `${domain}/api/config_societe_support/get_all`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_ALL_CONFIG', response.data.data);
      commit('SET_TOTAL_ROWS_CONF', response.data.total);
      commit('SET_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONFIGURATION_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
      }
      commit('SET_IS_LOADING', false);
    }
  },
  async getAllFilialSupport({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    try {
      const response = await axios.get(
        `${domain}/api/config_societe_support/get_supports`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FILIALES_SUPPORTS', response.data.data);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONFIGURATION_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
      }
    }
  },
  async getAllProFilial({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    try {
      const response = await axios.get(
        `${domain}/api/config_societe_support/get_filiales`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_PRO_FILIALS_SUPPORTS', response.data.data);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONFIGURATION_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
      }
    }
  },
  async getAllNumColums({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    try {
      const response = await axios.get(
        `${domain}/api/config_societe_support/get_numeric_columns`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_NUM_CULOMN', response.data);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONFIGURATION_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
      }
    }
  },
  async getAllColumns({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    try {
      const response = await axios.get(
        `${domain}/api/config_societe_support/get_columns`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_CULOMNS', response.data);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_CONFIGURATION_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
      }
    }
  },
  async addNewConfiguration({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    commit('SET_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/config_societe_support/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_CONF', response.data.data);
      commit('SET_IS_LOADING', false);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_IS_LOADING', false);
        commit('SET_CONFIGURATION_ERROR', data);
        return { success: false };
      } else {
        commit('SET_IS_LOADING', false);
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
        return { success: false };
      }
    }
  },
  async checkPeriod({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    try {
      if (cancelErrors) {
        cancelErrors.cancel('cancel-request');
      }
      cancelErrors = axios.CancelToken.source();
      const response = await axios.post(
        `${domain}/api/config_societe_support/errors`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: cancelErrors.token
        }
      );
      return { success: true };
    } catch (error) {
      if (error?.response?.data) {
        commit('SET_ERROR_SPECIFIC', error?.response?.data);
        return { success: false };
      }
    }
  },

  async updateConfiguration({ commit }, payload) {
    commit('SET_CONFIGURATION_ERROR', null);
    commit('SET_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/config_societe_support/update`,
        payload.configurationToUpdate,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_CONFIG', {
        response: response.data.data,
        originConfig: payload.configuration
      });
      commit('SET_IS_LOADING', false);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        commit('SET_IS_LOADING', false);
        commit('SET_CONFIGURATION_ERROR', data);
        return { success: false };
      } else {
        commit('SET_IS_LOADING', false);
        commit('SET_CONFIGURATION_ERROR', 'Une erreur est survenue');
        return { success: false };
      }
    }
  },
  initErrors({ commit }) {
    commit('INIT_ERRORS', null);
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
