import AppelObligeeDepotTh from './AppelObligeeDepotTh';
export default class AppelObligeTh {
  constructor(data) {
    this.name = data.name;
    this.check = false;
    this.lots = data.lots.map(item => AppelObligeeDepotTh.create(item));
  }
  static create(data) {
    return new AppelObligeTh(data);
  }
}
