export default class FPOUCE {
  constructor(data) {
    this.id = data.id;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.Classique = data.Classique;
    this.simple_ou_grand = data.simple_ou_grand;
  }
  update(data) {
    this.Classique = data.Classique;
    this.simple_ou_grand = data.simple_ou_grand;
  }
  static create(data) {
    return new FPOUCE(data);
  }
}
