import axios from 'axios';
import domain from '@/environment';
import AppelObligeTh from '../../models/AppelObligeeTh';
let requestgetPaiementObligeeThFiltred = null;
let requestgetObligeeTemplate = null;
let requestrefreshPaiementObligee = null;
let requestrefreshPaiementObligeeFilter = null;

const state = {
  chargementAppelPaiementThObligee: false,
  appeliPaiementThTableObligee: [],
  errorAppeliPaiementUpdateThObligee: null,
  errorAppeliPaiementThObligee: null,
  totalAppelPaiementObligee: null,
  templateObligeeToUpload: null,
  chargementAppelPaiementThObligeeFacture: false,
  errorAppeliPaiementThObligeeFacture: null,
  totalRowsThObligee: null,
  ListOrganismeObligee: [],
  ListInstallateurbligee: [],
  ListLotsOligee: [],
  loadingDataFilterObligeeThProjects: false,
  mailLodingVertigo: false,
  successMailsSent: [],
  errorMailsSent: [],
  otherErrorsMail: [],
  errorObligeXls: null,
  errorAppeliPaiementThObligeeExportXls: null,
  chargementAppelPaiementThObligeeExportXlsFacture: false,
  missingOrganismeInObligeeFile: []
};
const getters = {
  getChargementAppelPaiementThObligee: state =>
    state.chargementAppelPaiementThObligee,
  getAppeliPaiementThTableObligee: state => state.appeliPaiementThTableObligee,
  getErrorAppeliPaiementThObligee: state => state.errorAppeliPaiementThObligee,
  getErrorAppeliPaiementUpdateThObligee: state =>
    state.errorAppeliPaiementUpdateThObligee,
  getTotalAppelPaiementObligee: state => state.totalAppelPaiementObligee,
  getTemplateObligeeToUpload: state => state.templateObligeeToUpload,
  getChargementAppelPaiementThObligeeFacture: state =>
    state.chargementAppelPaiementThObligeeFacture,
  getErrorAppeliPaiementThObligeeFacture: state =>
    state.errorAppeliPaiementThObligeeFacture,
  geTtotalRowsThObligee: state => state.totalRowsThObligee,
  getListOrganismeObligee: state => state.ListOrganismeObligee,
  getListInstallateurbligee: state => state.ListInstallateurbligee,
  getListLotsOligee: state => state.ListLotsOligee,
  getLoadingDataFilterObligeeThProjects: state =>
    state.loadingDataFilterObligeeThProjects,
  getMailLoadingVertigo: state => state.mailLodingVertigo,
  getSuccessMailsSent: state => state.successMailsSent,
  getErrorMailsSent: state => state.errorMailsSent,
  getOtherErrorsMail: state => state.otherErrorsMail,
  geterrorObligeXls: state => state.errorObligeXls,
  errorAppeliPaiementThObligeeExportXls: state =>
    state.errorAppeliPaiementThObligeeExportXls,
  chargementAppelPaiementThObligeeExportXlsFacture: state =>
    state.chargementAppelPaiementThObligeeExportXlsFacture,
  missingOrganismeInObligeeFile: state => state.missingOrganismeInObligeeFile
};
const mutations = {
  SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE(state, payload) {
    state.chargementAppelPaiementThObligee = payload;
  },
  APPEL_PAIEMENT_TH_FILTRED_OBLIGEE(state, payload) {
    state.appeliPaiementThTableObligee = payload.map(item =>
      AppelObligeTh.create(item)
    );
  },
  APPEL_PAIEMENT_TH_FILTRED_OBLIGEE_MISSING(state, payload) {
    state.missingOrganismeInObligeeFile = payload;
  },
  SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH(state, payload) {
    state.errorAppeliPaiementThObligee = payload;
  },
  SET_ERROR_APPEL_PAIEMENT_OBLIGEE_UPDATE_TH(state, payload) {
    state.errorAppeliPaiementUpdateThObligee = payload;
  },
  SET_TOTAL_ROW_APPEL_PAIEMENT_TH(state, payload) {
    state.totalAppelPaiementObligee = payload;
  },
  SET_TEMPLATE_OBLIGEE_TO_UPLOAD(state, payload) {
    state.templateObligeeToUpload = payload;
  },
  SET_DATA_FOR_FACTURE_OBLIGEE_UPLOADED(state, payload) {
    payload.item.update(payload.response);
  },
  SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE(state, payload) {
    state.errorAppeliPaiementThObligeeFacture = payload;
  },
  SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE(state, payload) {
    state.chargementAppelPaiementThObligeeFacture = payload;
  },
  SET_PAGINATION_APPEL_PAIEMENT_TH(state, payload) {
    state.totalRowsThObligee = payload;
  },
  SET_FILTER_OBLIGEE_DATA(state, payload) {
    state.ListOrganismeObligee = payload.organismes;
    state.ListInstallateurbligee = payload.installateurs;
    state.ListLotsOligee = payload.lots;
  },
  SET_LOADING_GET_DATA_FILTER_TH_OBLIGEE_FACTURE(state, payload) {
    state.loadingDataFilterObligeeThProjects = payload;
  },
  UPDATE_FACTURE_SUIVI_PAIEMENT_DATA_TH(state, payload) {
    payload.row.updateData(payload.response);
  },
  SET_MAIL_LODING_VERTIGO(state, payload) {
    state.mailLodingVertigo = payload;
  },
  SET_ERROR_EMAIL(state, payload) {
    state.errorMailsSent = payload;
  },
  SET_SUCCESS_EMAIL(state, payload) {
    state.successMailsSent = payload;
  },
  SET_OTHER_EMAIL(state, payload) {
    state.otherErrorsMail = payload;
  },
  DOWNLOAD_EXCEL(state, payload) {
    const blob = new Blob([payload.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();
  },
  SET_ERROR_OBLIGEE_XLS(state, payload) {
    state.errorObligeXls = payload;
  },
  SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_EXPORT_XLS(state, payload) {
    state.errorAppeliPaiementThObligeeExportXls = payload;
  },
  SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_EXPORT_XLS(stat, payload) {
    state.chargementAppelPaiementThObligeeExportXlsFacture = payload;
  }
};
const actions = {
  async getAppelPaiementObligeeThFiltred({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    commit('APPEL_PAIEMENT_TH_FILTRED_OBLIGEE_MISSING', []);

    try {
      if (requestgetPaiementObligeeThFiltred) {
        requestgetPaiementObligeeThFiltred.cancel('cancel-request');
      }
      requestgetPaiementObligeeThFiltred = axios.CancelToken.source();

      const params = {
        year: payload.year,
        page: payload.page,
        per_page: payload.per_page
      };
      // if (payload.month != null) {
      //   params.month = payload.month;
      // }
      // if (payload.depot != null) {
      //   params.depot = payload.depot;
      // }
      if (payload.organisme.length) {
        params.organisme = payload.organisme.map(({ id }) => id);
      }
      // if (payload.week != null) {
      //   params.week = payload.week;
      // }
      if (payload.filiale.length) {
        params.installateur = payload.filiale.map(({ id }) => id);
      }
      if (payload.date_debut != null) {
        params.date_debut = payload.date_debut;
      }
      if (payload.date_fin != null) {
        params.date_fin = payload.date_fin;
      }
      if (payload.lot.length) {
        params.lot = payload.lot;
      }
      const response = await axios.get(domain + `/api/obligee/all_per_lot`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestgetPaiementObligeeThFiltred.token
      });
      commit('APPEL_PAIEMENT_TH_FILTRED_OBLIGEE', response.data.data);
      commit(
        'APPEL_PAIEMENT_TH_FILTRED_OBLIGEE_MISSING',
        response.data.missing_organisme
      );
      commit('SET_PAGINATION_APPEL_PAIEMENT_TH', response.data.count);
      commit('SET_TOTAL_ROW_APPEL_PAIEMENT_TH', {
        total_pu_commission: response.data.total_pu_commission,
        total_pu_prime: response.data.total_pu_prime,
        total_pu_surprime: response.data.total_pu_surprime,
        total_pu_total: response.data.total_pu_total,
        total_pu_missing_prime: response.data.total_pu_missing_prime
      });
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }

      return false;
    }
  },
  async downloadFactureObligeeItem({ commit }, payload) {
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      const response = await axios.get(
        domain + '/api/obligee/pdf/download/' + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          responseType: 'blob'
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
    }
  },

  async downloadFactureGlobaleObligeeItem({ commit }, payload) {
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      const params = {
        type: payload.type,
        depot: payload.item.name
      };
      const response = await axios.get(
        domain + '/api/download_suivi_th_per_depot_pdf',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          responseType: 'blob'
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
    }
  },
  async getObligeeTemplateAndFiledsDataToUpdate({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', true);
    try {
      let params = {
        lot: payload.lot,
        type: payload.type,
        av: payload.av,
        vendeur: payload.vendeur,
        acheteur: payload.acheteur
      };
      if (requestgetObligeeTemplate) {
        requestgetObligeeTemplate.cancel('cancel-request');
      }
      requestgetObligeeTemplate = axios.CancelToken.source();

      const response = await axios.get(
        domain + `/api/obligee/get_uploaded_template`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestgetObligeeTemplate.token
        }
      );
      commit('SET_TEMPLATE_OBLIGEE_TO_UPLOAD', response.data.data);

      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE', [
          'Une erreur est servenue'
        ]);
      }

      return false;
    }
  },
  async uploadObligeeTemplateInServieVisionFiliale({ commit }, payload) {
    var bodyFormData = new FormData();
    bodyFormData.append('type', payload.type);
    // bodyFormData.append('description', payload.description);
    bodyFormData.append('iban', payload.iban);
    bodyFormData.append('lot', payload.lot);
    bodyFormData.append('swift', payload.swift);
    bodyFormData.append('ttc', payload.ttc);
    bodyFormData.append('date', payload.date);
    bodyFormData.append('tva', payload.tva);
    bodyFormData.append('ht', payload.ht);
    bodyFormData.append('av', payload.av);
    bodyFormData.append('vendeur', payload.vendeur);
    bodyFormData.append('acheteur', payload.acheteur);
    bodyFormData.append('bank', payload.bank);

    try {
      const response = await axios.post(
        domain + `/api/obligee/upload`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_DATA_FOR_FACTURE_OBLIGEE_UPLOADED', {
        response: response.data,
        item: payload.item
      });
      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async refreshAooelPaiementObligeeAction({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    const params = {
      lots: payload.lot
    };
    try {
      if (requestrefreshPaiementObligee) {
        requestrefreshPaiementObligee.cancel('cancel-request');
      }
      requestrefreshPaiementObligee = axios.CancelToken.source();

      const response = await axios.post(
        domain + '/api/obligee/fixlot',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: requestrefreshPaiementObligee.token
        }
      );
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_FACTURE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE', [
          'Une erreur est servenue'
        ]);
      }

      return false;
    }
  },
  async getAllDataForFilterObligee({ commit }, payload) {
    commit('SET_LOADING_GET_DATA_FILTER_TH_OBLIGEE_FACTURE', true);

    try {
      if (requestrefreshPaiementObligeeFilter) {
        requestrefreshPaiementObligeeFilter.cancel('cancel-request');
      }
      requestrefreshPaiementObligeeFilter = axios.CancelToken.source();
      const response = await axios.get(domain + '/api/obligee/lot_online', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        cancelToken: requestrefreshPaiementObligeeFilter.token,
        params: payload
      });
      commit('SET_FILTER_OBLIGEE_DATA', response.data);
      commit('SET_LOADING_GET_DATA_FILTER_TH_OBLIGEE_FACTURE', false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_GET_DATA_FILTER_TH_OBLIGEE_FACTURE', true);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_FACTURE', [
          'Une erreur est servenue'
        ]);
      }

      return false;
    }
  },
  async updateSuiviPaiementDataTh({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_UPDATE_TH', null);
    const params = {
      column: payload.column,
      value: payload.value,
      id: payload.row.id
    };

    try {
      const response = await axios.post(
        domain + `/api/obligee/update_project`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        row: payload.data
      };

      if (response.data.error == true) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_UPDATE_TH',
          'Cette mise à jour ne peut se faire car il y a une facture déjà valide dans ce lot.'
        );
      }
      commit('UPDATE_FACTURE_SUIVI_PAIEMENT_DATA_TH', obj);
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);

      return false;
    }
  },
  async updateSuiviPaiementStatutTh({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    var lastIndex = payload.column.lastIndexOf('_');
    let idName = payload.column.substring(0, lastIndex);
    const params = {
      id: payload.row[idName + '_id'],
      statut: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/obligee/update_statut`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      payload.row[payload.column] = payload.value;
      // const obj = {
      //   response: response.data.data,
      //   row: payload.data
      // };
      // commit('UPDATE_FACTURE_SUIVI_PAIEMENT_DATA_TH', obj);
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);

      return false;
    }
  },
  setDaraTemplateObligeUploded({ commit }) {
    commit('SET_TEMPLATE_OBLIGEE_TO_UPLOAD', null);
  },
  async downloadPaiementObligeeThFiltredExcel({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      if (requestgetPaiementObligeeThFiltred) {
        requestgetPaiementObligeeThFiltred.cancel('cancel-request');
      }
      requestgetPaiementObligeeThFiltred = axios.CancelToken.source();
      const params = {};
      if (payload?.dataCheck?.length) {
        params.lot = payload.dataCheck;
      } else {
        if (payload.organisme.length) {
          params.organisme = payload.organisme.map(({ id }) => id);
        }
        if (payload.filiale.length) {
          params.installateur = payload.filiale.map(({ id }) => id);
        }
        if (payload.date_debut != null) {
          params.date_debut = payload.date_debut;
        }
        if (payload.date_fin != null) {
          params.date_fin = payload.date_fin;
        }
        if (payload.lot.length) {
          params.lot = payload.lot;
        }
      }
      if (payload?.exportExcel) {
        params.exportExcel = payload.exportExcel;
      }

      const response = await axios.get(domain + `/api/obligee/all_per_lot`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestgetPaiementObligeeThFiltred.token,
        responseType: 'blob'
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'OBLIGEE' + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }

      return false;
    }
  },
  async downloadPaiementObligeeThFactureFiltredExcel({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_EXPORT_XLS', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_EXPORT_XLS', true);
    try {
      if (requestgetPaiementObligeeThFiltred) {
        requestgetPaiementObligeeThFiltred.cancel('cancel-request');
      }
      requestgetPaiementObligeeThFiltred = axios.CancelToken.source();
      const params = {};
      if (payload?.dataCheck?.length) {
        params.lot = payload.dataCheck;
      } else {
        if (payload.organisme.length) {
          params.organisme = payload.organisme.map(({ id }) => id);
        }
        if (payload.filiale.length) {
          params.installateur = payload.filiale.map(({ id }) => id);
        }
        if (payload.date_debut != null) {
          params.date_debut = payload.date_debut;
        }
        if (payload.date_fin != null) {
          params.date_fin = payload.date_fin;
        }
        if (payload.lot.length) {
          params.lot = payload.lot;
        }
      }
      if (payload?.VH) {
        params.VH = payload.VH;
      }

      const response = await axios.post(
        domain + `/api/obligee/exportExcelFacture`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: requestgetPaiementObligeeThFiltred.token,
          responseType: 'blob'
        }
      );

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'Facture obligée' + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_EXPORT_XLS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_EXPORT_XLS',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE_EXPORT_XLS', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH_EXPORT_XLS', [
          'Une erreur est survenue'
        ]);
      }

      return false;
    }
  },
  async downloadPaiementObligeeThFactureFiltredPdf({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      if (requestgetPaiementObligeeThFiltred) {
        requestgetPaiementObligeeThFiltred.cancel('cancel-request');
      }
      requestgetPaiementObligeeThFiltred = axios.CancelToken.source();
      const params = {
        ids: []
      };
      if (payload?.dataCheck?.length) {
        for (let i = 0; i < payload.dataCheck.length; i++) {
          for (let j = 0; j < payload.dataCheck[i].lots.length; j++) {
            if (payload.dataCheck[i].lots[j].check == true) {
              if (payload.dataCheck[i].lots[j].prime_id != null) {
                params.ids.push(payload.dataCheck[i].lots[j].prime_id);
              }
              if (payload.dataCheck[i].lots[j].missing_prime_id != null) {
                params.ids.push(payload.dataCheck[i].lots[j].missing_prime_id);
              }
              if (payload.dataCheck[i].lots[j].missing_prime_av_id != null) {
                params.ids.push(
                  payload.dataCheck[i].lots[j].missing_prime_av_id
                );
              }
              if (payload.dataCheck[i].lots[j].commission_id != null) {
                params.ids.push(payload.dataCheck[i].lots[j].commission_id);
              }
              if (payload.dataCheck[i].lots[j].surprime_id != null) {
                params.ids.push(payload.dataCheck[i].lots[j].surprime_id);
              }
            }
          }
        }
      }

      const response = await axios.post(
        domain + `/api/obligee/download_pdf`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          cancelToken: requestgetPaiementObligeeThFiltred.token,
          responseType: 'blob'
        }
      );

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'FACTURE OBLIGEE.zip');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit(
          'SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH',
          error.response.data.error
        );
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', false);
        commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', [
          'Une erreur est survenue'
        ]);
      }

      return false;
    }
  },
  async updateStatueObligeeGlobale({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      const response = await axios.post(
        domain + '/api/obligee/update_statut_multiple',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, message: response.data };
    } catch (error) {
      return { succes: false };
    }
  },
  async updateCoefObligee({ commit }, payload) {
    commit('SET_ERROR_APPEL_PAIEMENT_OBLIGEE_TH', null);
    commit('SET_LOADING_APPEL_PAIEMENT_TH_OBLIGEE', true);
    try {
      await axios.post(
        domain + '/api/obligee/update_project_multiple',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  },
  async sendMailVertigo({ commit }, payload) {
    commit('SET_MAIL_LODING_VERTIGO', true);
    try {
      const response = await axios.post(domain + `/api/obligee/mail`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_MAIL_LODING_VERTIGO', false);
      commit('SET_ERROR_EMAIL', response.data.error);
      commit('SET_SUCCESS_EMAIL', response.data.success);
      return { succes: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_OTHER_EMAIL', error?.response?.data?.error);
      } else {
        commit('SET_OTHER_EMAIL', 'Une erreur est servenue');
      }
      commit('SET_MAIL_LODING_VERTIGO', false);
      return { succes: false };
    }
  },
  async uploadObligeexls({ commit }, payload) {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append('file', payload.file);

      const response = await axios.post(
        domain + `/api/check_first_file_before_analyse`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return {
        succes: true,
        data: response.data
      };
    } catch (error) {
      // commit('SET_LOADING', false);
      if (error.response && error.response.data.missing) {
        commit('SET_ERROR_OBLIGEE_XLS', {
          missing: error.response.data.missing
        });
      } else if (error?.response?.data?.error) {
        commit('SET_ERROR_OBLIGEE_XLS', {
          error: error.response.data.error
        });
      } else {
        commit('SET_ERROR_OBLIGEE_XLS', ['Une erreur est servenue']);
      }
      return {
        succes: false
      };
    }
  },
  async uploadObligeexlsAfterConfirmation({ commit }, payload) {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append('file', payload.file);
      bodyFormData.append('force', payload.force);
      const response = await axios.post(
        domain + `/api/analyse_first_file`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return {
        succes: true
      };
    } catch (error) {
      return {
        succes: false
      };
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
