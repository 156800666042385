export default class CategorieTh {
  constructor(data) {
    this.id = data.id;
    this.nom_categorie = data.nom_categorie;
  }
  update(data) {
    this.nom_categorie = data.nom_categorie;
  }
  static create(data) {
    return new CategorieTh(data);
  }
}
