export default class VariableTH {
  constructor(data) {
    this.id = data.id;
    this.month = data.month;
    this.year = data.year;
    this.type = data.type;
    this.facture_type = data.facture_type;
    this.value_m2 = data.value_m2;
    this.vente = data.vente;
    this.previsite = data.previsite;
    this.interne = data.interne;
    this.externe = data.externe;
    this.employee = data.employee;
    this.SD = data.SD;
    this.RPLUS = data.RPLUS;
    this.plafond = data.plafond;
    this.iti = data.iti;
    this.ite = data.ite;
    this.RR = data.RR;
    this.RO = data.RO;
    this.balonThermo = data.balonThermo;
    this.fix = data.fix;
    this.igd = data.igd;
    this.additional_prime = data.additional_prime;
    this.vmc = data.vmc;
    this.pac = data.pac;
    this.prime_qualite = data.prime_qualite;
    this.prime_objectif = data.prime_objectif;
    this.prime_sav = data.prime_sav;
    this.other_prime_name = data.other_prime_name;
    this.other_prime_value = data.other_prime_value;
    this.deducation = data.deducation;
    this.condition = data.condition;
  }
  update(data) {
    this.facture_type = data.facture_type;
    this.value_m2 = data.value_m2;
    this.vente = data.vente;
    this.previsite = data.previsite;
    this.interne = data.interne;
    this.externe = data.externe;
    this.employee = data.employee;
    this.SD = data.SD;
    this.RPLUS = data.RPLUS;
    this.plafond = data.plafond;
    this.iti = data.iti;
    this.ite = data.ite;
    this.RR = data.RR;
    this.RO = data.RO;
    this.balonThermo = data.balonThermo;
    this.fix = data.fix;
    this.igd = data.igd;
    this.additional_prime = data.additional_prime;
    this.vmc = data.vmc;
    this.pac = data.pac;
    this.prime_qualite = data.prime_qualite;
    this.prime_objectif = data.prime_objectif;
    this.prime_sav = data.prime_sav;
    this.other_prime_name = data.other_prime_name;
    this.other_prime_value = data.other_prime_value;
    this.deducation = data.deducation;
    this.condition = data.condition;
  }
  static create(data) {
    return new VariableTH(data);
  }
}
