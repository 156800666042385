export default class SubLCR {
  constructor(data) {
    this.id = data.id;
    this.numero = data.numero;
    this.value = data.value;
    this.date = data.date;
    this.swift = data.swift;
    this.iban = data.iban;
    this.can_create = data.can_create;
    this.statut_vendeur = data.statut_vendeur;
    this.statut_vendeur_loading = false;
    if (data.statut_vendeur == 'paye') {
      this.statut_vendeur_update = false;
    } else {
      this.statut_vendeur_update = true;
    }
    this.statut_acheteur = data.statut_acheteur;
    this.statut_acheteur_loading = false;
    if (data.statut_acheteur == 'paye') {
      this.statut_acheteur_update = false;
    } else {
      this.statut_acheteur_update = true;
    }
    this.download_loading = false;
  }

  update(data) {
    this.id = data.id;
    this.numero = data.numero;
    this.value = data.value;
    this.date = data.date;
    this.swift = data.swift;
    this.iban = data.iban;
    this.can_create = data.can_create;
    this.statut_vendeur = data.statut_vendeur;
    if (data.statut_vendeur == 'paye') {
      this.statut_vendeur_update = false;
    } else {
      this.statut_vendeur_update = true;
    }
    this.statut_acheteur = data.statut_acheteur;
    if (data.statut_acheteur == 'paye') {
      this.statut_acheteur_update = false;
    } else {
      this.statut_acheteur_update = true;
    }
  }
  static create(data) {
    return new SubLCR(data);
  }
}
