export default class FraisComptaRhTh {
  constructor(data) {
    this.id = data.id;
    this.montant = data.montant;
    this.date = data.date;
    this.filiale = data.filiale;
    this.statue = data.statue;
    this.statue_installateur = data.statue_installateur;
    this.type = data.type;
    this.valid = data.valid;
    this.year = data.year;
    this.month = data.month;
    this.montant_ttc = data.montant_ttc;
    this.num_facture = data.num_facture;
    this.responsable_installateur_email = data.responsable_installateur_email;
    this.id_avoir = data.id_avoir;
    this.check = false;
  }
  update(data) {
    this.date = data.date;
    this.statue = data.statue;
    this.statue_installateur = data.statue_installateur;
    this.valid = data.valid;
    this.montant = data.montant;
    this.montant_ttc = data.montant_ttc;
    this.num_facture = data.num_facture;
    this.responsable_installateur_email = data.responsable_installateur_email;
    this.id_avoir = data.id_avoir;
  }
  static create(data) {
    return new FraisComptaRhTh(data);
  }
}
