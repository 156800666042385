import DepotParamsTh from './DepotParamsTh';
export default class OrganismeTh {
  constructor(data) {
    this.id = data.id;
    this.depot = data.depot.map(item => {
      return DepotParamsTh.create(item);
    });
    this.name = data.name;
  }
  update(data) {
    this.name = data.name;
  }
  updateDepot(data) {
    this.depot = data.depot.map(item => {
      return DepotParamsTh.create(item);
    });
  }
  static create(data) {
    return new OrganismeTh(data);
  }
}
