import axios from 'axios';
import domain from '@/environment';
import Permission from '../../models/Permision';

const state = {
  allPermissions: [],
  loadingPermissions: false,
  loadingPermissionsUpdate: false,
  loadingPermissionsUpdateUser: false,
  loadingPermissionsUpdateInterface: false,
  errorPermission: null
};
const getters = {
  getAllPermissions: state => state.allPermissions,
  getLoadingPermissions: state => state.loadingPermissionsUpdate,
  getLoadingPermissionsUser: state => state.loadingPermissionsUpdateUser,
  getLoadingPermissionsInterface: state =>
    state.loadingPermissionsUpdateInterface,
  getErrorPermission: state => state.errorPermission
};
const mutations = {
  NEW_PERMISSIONS(state, payload) {
    state.allPermissions.push(Permission.create(payload));
  },
  DELETE_PERMISSION(state, payload) {
    for (let index = 0; index < state.allPermissions.length; index++) {
      if (state.allPermissions[index].name == payload.name) {
        state.allPermissions.splice(index, 1);
        break;
      }
    }
  },
  ALL_PERMISSIONS(state, payload) {
    state.allPermissions = payload.map(item => Permission.create(item));
  },
  LOADING_PERMISSIONS(state, payload = false) {
    state.loadingPermissionsUpdate = payload;
  },
  LOADING_PERMISSIONS_USER(state, payload = false) {
    state.loadingPermissionsUpdateUser = payload;
  },
  LOADING_PERMISSIONS_INTERFACE(state, payload = false) {
    state.loadingPermissionsUpdateInterface = payload;
  },
  SET_ERROR_PERMISSIONS(state, payload) {
    state.errorPermission = payload;
  },
  UPDATE_PERMISSIONS(state, payload) {
    payload.permission.update(payload.response);
  }
};
const actions = {
  async getAllPermission({ commit }) {
    commit('SET_ERROR_PERMISSIONS', null);
    commit('LOADING_PERMISSIONS', true);
    try {
      const response = await axios.get(domain + '/api/get_permissions', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('ALL_PERMISSIONS', response.data.data);
      commit('LOADING_PERMISSIONS');
      return true;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ERROR_PERMISSIONS', error.response.data.error.messages);
      } else {
        commit('SET_ERROR_PERMISSIONS', ['Une erreur est survenue']);
      }
      commit('LOADING_PERMISSIONS');
      return false;
    }
  },
  async updateNamePermission({ commit }, payload) {
    commit('SET_ERROR_PERMISSIONS', null);
    const params = new FormData();
    params.append('permission', payload.permission.name);
    params.append('old_permission', payload.oldPermission.name);
    if (payload.permission.users.length) {
      for (let i = 0; i < payload.permission.users.length; i++) {
        params.append('users[' + i + ']', payload.permission.users[i].id);
      }
    }
    if (payload.permission.pages) {
      var k = 0;
      for (const property in payload.permission.pages) {
        params.append('page[' + k + '][name]', property);
        for (let j = 0; j < payload.permission.pages[property].length; j++) {
          params.append(
            'page[' + k + '][authorisation][' + j + ']',
            payload.permission.pages[property][j]
          );
        }
        k = k + 1;
      }
    }

    commit('LOADING_PERMISSIONS', true);
    try {
      const response = await axios.post(
        domain + '/api/update_permission',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_PERMISSIONS', {
        permission: payload.oldPermission,
        response: response.data.data
      });
      commit('LOADING_PERMISSIONS');
      return true;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ERROR_PERMISSIONS', error.response.data.error.messages);
      } else {
        commit('SET_ERROR_PERMISSIONS', ['Une erreur est survenue']);
      }
      commit('LOADING_PERMISSIONS');
      return false;
    }
  },
  async updatePermission({ commit }, payload) {
    commit('SET_ERROR_PERMISSIONS', null);
    const params = new FormData();
    params.append('permission', payload.permission.name);
    params.append('old_permission', payload.oldPermission.name);
    if (payload.permission.users.length) {
      for (let i = 0; i < payload.permission.users.length; i++) {
        params.append('users[' + i + ']', payload.permission.users[i].id);
      }
    }
    if (payload.permission.pages) {
      let i = 0;
      for (const property in payload.permission.pages) {
        params.append('page[' + i + '][name]', property);
        for (let j = 0; j < payload.permission.pages[property].length; j++) {
          params.append(
            'page[' + i + '][authorisation][' + j + ']',
            payload.permission.pages[property][j]
          );
        }
        i = i + 1;
      }
    }

    try {
      const test = await axios.post(domain + '/api/update_permission', params, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('UPDATE_PERMISSIONS', {
        permission: payload.oldPermission,
        response: test.data.data
      });
      return true;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_ERROR_PERMISSIONS', error.response.data.error.messages);
      } else {
        commit('SET_ERROR_PERMISSIONS', ['Une erreur est survenue']);
      }
      return false;
    }
  },
  async addNewPermission({ commit }, payload) {
    commit('SET_ERROR_PERMISSIONS', null);
    const params = new FormData();
    params.append('permission', payload.permission);
    if (payload.users.length) {
      for (let i = 0; i < payload.users.length; i++) {
        params.append('users[' + i + ']', payload.users[i].id);
      }
    }
    if (payload.page.length) {
      for (let i = 0; i < payload.page.length; i++) {
        params.append('page[' + i + '][name]', payload.page[i].key);
        for (let j = 0; j < payload.page[i].value.length; j++) {
          params.append(
            'page[' + i + '][authorisation][' + j + ']',
            payload.page[i].value[j]
          );
        }
      }
    }

    try {
      const response = await axios.post(
        domain + '/api/create_permission',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('NEW_PERMISSIONS', response.data.data);
      return {
        succes: true
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      }
      return {
        succes: false,
        error: 'Une erreur est survenue'
      };
    }
  },
  async deletePermission({ commit }, payload) {
    commit('LOADING_PERMISSIONS', true);

    try {
      await axios.post(
        domain + '/api/delete_permission',
        { permission: payload.name },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_PERMISSION', payload);
      commit('LOADING_PERMISSIONS');
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_PERMISSIONS', error.response.data.error);
      } else {
        commit('SET_ERROR_PERMISSIONS', ['Une erreur est survenue']);
      }
      commit('LOADING_PERMISSIONS');
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
