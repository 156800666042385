import axios from 'axios';
import domain from '@/environment';
import EnergieInvestTh from '../../models/EnergieInvestTh';
const prefixFraisEnergieInvest = 'filiale-sci';
let requestSci = null;
const state = {
  fraisEnergieInvest: [],
  loadingFraisEnergieInvest: false,
  errorFraisEnergieInvest: null,
  templateEnergieInvestToUpload: null,
  loadingGetDataTemplateEnergieInvest: false,
  errorTemplateEnergieInvestDataToUpploadeed: null,
  loadingExtactionFriasVisionEnergiInvest: false,
  onlineFilialeInVisionFilialeFraisEnergieInvest: [],
  sciFilials: [],
  errorDeleteFacureNotValide: null,
  EmailLoading: false,
  errorOneDayEnergiInvest: {},
  successSendMailsEnergiInvest: {},
  errorInvalidElmailEnergiInvest: {},
  errorNotValidYet: {},
  errorHasentTemplate: {},
  errorHasentTemplateEmail: {},
  errorHasntEmailEnergiInvest: {},
  otherErrorMailEnergiInvestMail: null,
  errorMultiple: null,
  loadingSciAcheteurOnLine: false,
  dataSciAcheteurOnLine: [],
  loadingSciVendeurOnLine: false,
  dataSciVendeurOnLine: [],
  countSci: null,
  lastMonthBilles: []
};
const getters = {
  getFraisEnergieInvest: state => state.fraisEnergieInvest,
  getLoadingFraisEnergieInvest: state => state.loadingFraisEnergieInvest,
  getErrorFraisEnergieInvest: state => state.errorFraisEnergieInvest,
  getTemplateEnergieInvestToUpload: state =>
    state.templateEnergieInvestToUpload,
  getLoadingGetDataTemplateEnergieInvest: state =>
    state.loadingGetDataTemplateEnergieInvest,
  errorTemplateEnergieInvestDataToUpploadeed: state =>
    state.errorTemplateEnergieInvestDataToUpploadeed,
  getLoadingExtactionFriasVisionEnergieInvest: state =>
    state.loadingExtactionFriasVisionEnergiInvest,
  getOnlineFilialeInVisionFilialeFraisEnergiInvest: state =>
    state.onlineFilialeInVisionFilialeFraisEnergieInvest,
  getSciFiliales: state => state.sciFilials,
  getErrorDeleteFacureNotValide: state => state.errorDeleteFacureNotValide,
  getErrorOneDayEergiInvest: state => state.errorOneDayEnergiInvest,
  getSuccessSendMailsEnergieInvest: state => state.successSendMailsEnergiInvest,
  getErrorInvalidElmailEnergiInvest: state =>
    state.errorInvalidElmailEnergiInvest,
  getErrorHasntEmailEnergiInvest: state => state.errorHasntEmailEnergiInvest,
  getOtherErrorMailEnergiInvestMail: state =>
    state.otherErrorMailEnergiInvestMail,
  getEmailLoading: state => state.EmailLoading,
  getErrornotValidYet: state => state.errorNotValidYet,
  getErrorHasentTemplate: state => state.errorHasentTemplate,
  getErrorHasentTemplateEmail: state => state.errorHasentTemplateEmail,
  getErrorMultiple: state => state.errorMultiple,
  getLoadingSciAcheteurOnLine: state => state.loadingSciAcheteurOnLine,
  getDataSciAcheteurOnLine: state => state.dataSciAcheteurOnLine,
  getLoadingSciVendeurOnLine: state => state.loadingSciVendeurOnLine,
  getDataSciVendeurOnLine: state => state.dataSciVendeurOnLine,
  getCountSci: state => state.countSci,
  getLastMonthBilles: state => state.lastMonthBilles
};
const mutations = {
  SET_ENERGIE_INVEST(state, payload) {
    state.fraisEnergieInvest = payload.map(item =>
      EnergieInvestTh.create(item)
    );
  },
  SET_LOADING_ENERGI_INVEST(state, payload) {
    state.loadingFraisEnergieInvest = payload;
  },
  SET_ERROR_ENERGIE_INVEST(state, payload) {
    state.errorFraisEnergieInvest = payload;
  },
  SET_TEMPLATE_ENERGIE_INVEST_TO_UPLOAD(state, payload) {
    state.templateEnergieInvestToUpload = payload;
  },
  SET_LOADING_GET_TEMPLATE_ENERGIE_INVEST_DATA(state, payload) {
    state.loadingGetDataTemplateEnergieInvest = payload;
  },
  SET_ERROR_TEMPLATE_ENERGIE_INVEST_DATA_TO_UPLOAD(state, payload) {
    state.errorTemplateEnergieInvestDataToUpploadeed = payload;
  },
  SET_DATA_FOR_FACTURE_ENERGIE_INVEST_UPLOADED(state, payload) {
    payload.item.update(payload.response);
  },
  SET_TYPE_ENERGIE_INVEST_STATUE(state, payload) {
    payload.item.update(payload.response);
  },
  SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_ENERGIE_INVEST(state, payload) {
    state.loadingExtactionFriasVisionEnergiInvest = payload;
  },
  DELETE_FACTURE_ENERGIE_INVEST(state, payload) {
    const index = state.fraisEnergieInvest.findIndex(
      element => element.id === payload[0]
    );
    state.fraisEnergieInvest.splice(index, 1);
  },
  SET_FILIALE_ONLINE_FRAIS_VISION_FILIALE_ENERGIE_INVEST(state, payload) {
    state.onlineFilialeInVisionFilialeFraisEnergieInvest = payload;
  },
  SET_FILIALES_SCI(state, payload) {
    state.sciFilials = payload;
  },
  ERROR_NOT_VALID_FACTURE(state, payload) {
    state.errorDeleteFacureNotValide = payload;
  },
  SET_ERROR_ONE_DAY_ENERGI_INVEST(state, payload) {
    state.errorOneDayEnergiInvest = payload;
  },
  SET_SUCCESS_SEND_MAILS_ENERGI_INVEST(state, payload) {
    state.successSendMailsEnergiInvest = payload;
  },
  SET_HASNT_EMAIL_ENERGI_INVEST(state, payload) {
    state.errorHasntEmailEnergiInvest = payload;
  },
  SET_INVALID_EMAIL_ENERGI_INVEST(state, payload) {
    state.errorInvalidElmailEnergiInvest = payload;
  },
  SET_OTHER_ERROR_ENERGI_INVEST(state, payload) {
    state.otherErrorMailEnergiInvestMail = payload;
  },
  SET_MAIL_LODING(state, payload) {
    state.EmailLoading = payload;
  },
  SET_NOT_VALID_YET(state, payload) {
    state.errorNotValidYet = payload;
  },
  SET_EMAIL_HASENT_TEMPLATE_EMAIL(state, payload) {
    state.errorHasentTemplateEmail = payload;
  },
  SET_EMAIL_HASENT_TEMPLATE(state, payload) {
    state.errorHasentTemplate = payload;
  },
  SET_ERROR_MUTIPLE(state, payload) {
    state.errorMultiple = payload;
  },
  LOAIDNG_SCI_ACHETEUR_ONLINE(state, payload) {
    state.loadingSciAcheteurOnLine = payload;
  },
  SET_ACHETEUR_ONLINE_SCI(state, payload) {
    state.dataSciAcheteurOnLine = payload;
  },
  LOAIDNG_SCI_VENDEUR_ONLINE(state, payload) {
    state.loadingSciVendeurOnLine = payload;
  },
  SET_VENDEUR_ONLINE_SCI(state, payload) {
    state.dataSciVendeurOnLine = payload;
  },
  SET_ENERGIE_INVEST_COUNT(state, payload) {
    state.countSci = payload;
  },
  SET_LAST_MONTH_BILL(state, payload) {
    state.lastMonthBilles = payload;
  }
};
const actions = {
  //fatch all facture
  async fetchBillesOfLastMonth({ commit }, payload) {
    // commit('SET_LOADING_ENERGI_INVEST', true);
    try {
      const response = await axios.post(
        domain + `/api/Facture_sci/last_month`,
        payload,
        {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_LAST_MONTH_BILL', response.data.data);
      return {
        succes: true,
        response: response.data.data
      };
    } catch {}
  },
  async fetchFraisEnergiInvest({ commit }, payload) {
    commit('SET_ERROR_ENERGIE_INVEST', null);

    const params = { per_page: payload.per_page, page: payload.page };

    if (payload.search != null) {
      params.num = payload.search;
    }

    for (let i = 0; i < payload?.vendeur?.length; i++) {
      params['vendeur_id[' + i + ']'] = payload?.vendeur[i]?.id;
    }
    for (let i = 0; i < payload?.statut?.length; i++) {
      params['etat[' + i + ']'] = payload?.statut[i]?.value;
    }
    for (let i = 0; i < payload?.acheteur?.length; i++) {
      params['acheteur_id[' + i + ']'] = payload?.acheteur[i]?.id;
    }

    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.order != null) {
      params.order = payload.order;
    }
    if (payload.column != null) {
      params.column = payload.column;
    }
    if (payload.famille != null) {
      params.famille = payload.famille;
    }
    commit('SET_LOADING_ENERGI_INVEST', true);
    try {
      if (requestSci) {
        commit('SET_ERROR_ENERGIE_INVEST', null);
        requestSci.cancel('cancel-request');
      }
      requestSci = axios.CancelToken.source();
      const response = await axios.get(domain + `/api/Facture_sci/vision/all`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestSci.token
      });
      commit('SET_ENERGIE_INVEST', response.data.data.data);
      commit('SET_ENERGIE_INVEST_COUNT', response.data.data.total);
      commit('SET_LOADING_ENERGI_INVEST', false);
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_ERROR_ENERGIE_INVEST', error.response.data.error);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_LOADING_ENERGI_INVEST', false);
          commit('SET_ERROR_ENERGIE_INVEST', ['Une erreur est servenue']);
        }
      }
    }
  },
  //get Template Data
  async getEnergieInvestTemplateAndFiledsDataToUpdate({ commit }, payload) {
    commit('SET_ERROR_TEMPLATE_ENERGIE_INVEST_DATA_TO_UPLOAD', null);
    commit('SET_LOADING_GET_TEMPLATE_ENERGIE_INVEST_DATA', true);
    let idToUse = null;
    if (payload.type_societe === 'filial') {
      idToUse = payload?.element?.id;
    }
    if (payload.type_societe === 'sci') {
      idToUse = payload?.element?.sci_id;
    }
    const params = {
      id: idToUse
    };
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisEnergieInvest}/get_template`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_TEMPLATE_ENERGIE_INVEST_TO_UPLOAD', response.data);

      commit('SET_LOADING_GET_TEMPLATE_ENERGIE_INVEST_DATA', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_TEMPLATE_ENERGIE_INVEST_DATA_TO_UPLOAD',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_TEMPLATE_ENERGIE_INVEST_DATA_TO_UPLOAD', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_GET_TEMPLATE_ENERGIE_INVEST_DATA', false);

      return false;
    }
  },
  //   //upload facture
  async uploadEnergieInvestTemplateInServieVisionFiliale({ commit }, payload) {
    let idToUse = null;
    if (payload.type_societe === 'filial') {
      idToUse = payload?.item?.id;
    }
    if (payload.type_societe === 'sci') {
      idToUse = payload?.item?.sci_id;
    }
    const params = {
      id: idToUse,
      description: payload.description,
      iban: payload.iban,
      swift: payload.swift,
      date: payload.date,
      date_reglement: payload.date_reglement,
      num_facture: payload.num_facture
    };
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisEnergieInvest}/upload_one`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_DATA_FOR_FACTURE_ENERGIE_INVEST_UPLOADED', {
        response: response.data.data,
        item: payload.item
      });
      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return { success: false, error: data[0] };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //   //update statue facture
  async updateStatueFraisEnergiInvest({ commit }, payload) {
    commit('SET_ERROR_ENERGIE_INVEST', null);
    let idToUse = null;
    if (payload.type_ste === 'filial') {
      idToUse = payload?.row?.id;
    }
    if (payload.type_ste === 'sci') {
      idToUse = payload?.row?.sci_id;
    }
    const params = {
      id: idToUse,
      column: payload.column,
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisEnergieInvest}/update`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TYPE_ENERGIE_INVEST_STATUE', {
        response: response.data.data,
        item: payload.row
      });

      commit('SET_LOADING_ENERGI_INVEST', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_ENERGIE_INVEST', error.response.data.error);
      } else {
        commit('SET_ERROR_ENERGIE_INVEST', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_ENERGI_INVEST', false);

      return false;
    }
  },
  //export one pdf facture
  async exportPdfFactureUploadedTypeEnergieInvest({ commit }, payload) {
    commit('SET_LOADING_ENERGI_INVEST', true);
    let idToUse = null;
    if (payload.type_societe === 'filial') {
      idToUse = payload?.element?.id;
    }
    if (payload.type_societe === 'sci') {
      idToUse = payload?.element?.sci_id;
    }
    const params = {
      id: idToUse
    };
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisEnergieInvest}/download_one`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_ENERGI_INVEST', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_ENERGI_INVEST', false);
      return false;
    }
  },
  // add new facture
  async addNewFactureEnergieInvestActions({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/Facture_sci/creation/create`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //delete facture
  async deleteFactureEnergiInvest({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisEnergieInvest}/delete`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: {
            id: payload
          }
        }
      );
      commit('DELETE_FACTURE_ENERGIE_INVEST', response.data);
      commit('ERROR_NOT_VALID_FACTURE', response.data.error);
      return {
        succes: true,
        response: 'Les factures supprimées avec succeés'
      };
    } catch (error) {
      if (error?.response?.data?.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //generate facute auto
  async generateFactureEnergiInvest({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisEnergieInvest}/generated_last_month`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      return {
        succes: true,
        data: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //export excel globale
  async exportExcelFraisVisionEnergiInvest({ commit }, payload) {
    commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_ENERGIE_INVEST', true);
    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisEnergieInvest}/export`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'VISION Loyer EI - SCI' + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_ENERGIE_INVEST', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_ENERGIE_INVEST', false);
      return false;
    }
  },
  setDaraTemplateEnergiInvestUploded({ commit }) {
    commit('SET_TEMPLATE_ENERGIE_INVEST_TO_UPLOAD', null);
  },
  //global validation Energie invest
  async getGlobalTemplateAndFiledsDataToUpdateForEnergiInvest(
    { commit },
    payload
  ) {
    commit('SET_ERROR_TEMPLATE_ENERGIE_INVEST_DATA_TO_UPLOAD', null);
    commit('SET_LOADING_GET_TEMPLATE_ENERGIE_INVEST_DATA', true);
    const params = {
      type: payload.type
    };

    try {
      const response = await axios.get(domain + `/api/templates/get_one`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_TEMPLATE_COMPTA_RH_TO_UPLOAD', response.data.data);

      commit('SET_LOADING_GET_TEMPLATE_ENERGIE_INVEST_DATA', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit(
          'SET_ERROR_TEMPLATE_ENERGIE_INVEST_DATA_TO_UPLOAD',
          error.response.data.error
        );
      } else {
        commit('SET_ERROR_TEMPLATE_ENERGIE_INVEST_DATA_TO_UPLOAD', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_GET_TEMPLATE_ENERGIE_INVEST_DATA', false);

      return false;
    }
  },
  async uploadGlobalTemplateForEnergieInvest({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisEnergieInvest}/upload_multiple`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  //global export
  async exportAllPdfFraisEnergiInvest({ commit }, payload) {
    commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_ENERGIE_INVEST', true);
    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisEnergieInvest}/download_multiple`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'VISION  Loyer EI - SCI.zip');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_ENERGIE_INVEST', false);
      return true;
    } catch (error) {
      if (error?.response?.status != 200) {
        commit('SET_ERROR_MUTIPLE', 'Une erreur est servenue');
        commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_ENERGIE_INVEST', false);
      }
      commit('SET_LOADING_EXTRACTION_DBB_FRAIS_VISION_ENERGIE_INVEST', false);
      return false;
    }
  },
  // get online filiale
  async getAllOnlineFilialeInFraisVisionFilialeEnergiInvest(
    { commit },
    payload
  ) {
    commit('SET_LOADING_ENERGI_INVEST', true);
    const params = {
      year: payload.year
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisEnergieInvest}/filiale_online`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit(
        'SET_FILIALE_ONLINE_FRAIS_VISION_FILIALE_ENERGIE_INVEST',
        response.data.data
      );

      commit('SET_LOADING_ENERGI_INVEST', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_ENERGIE_INVEST', error.response.data.error);
      } else {
        commit('SET_ERROR_ENERGIE_INVEST', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_ENERGI_INVEST', false);

      return false;
    }
  },
  async sendMailEnergiInvest({ commit }, payload) {
    commit('SET_MAIL_LODING', true);
    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisEnergieInvest}/envoiMails`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_MAIL_LODING', false);
      commit('SET_ERROR_ONE_DAY_ENERGI_INVEST', response.data[2]);
      commit('SET_SUCCESS_SEND_MAILS_ENERGI_INVEST', response.data[6]);
      commit('SET_HASNT_EMAIL_ENERGI_INVEST', response.data[0]);
      commit('SET_NOT_VALID_YET', response.data[4]);
      commit('SET_EMAIL_HASENT_TEMPLATE', response.data[3]);
      commit('SET_EMAIL_HASENT_TEMPLATE_EMAIL', response.data[5]);
      commit('SET_INVALID_EMAIL_ENERGI_INVEST', response.data[1]);
      return { succes: true };
    } catch (error) {
      if (error?.response?.data?.errors?.ids[0]) {
        commit('SET_OTHER_ERROR_ENERGI_INVEST', 'Selectionner une filiale');
      } else {
        commit('SET_OTHER_ERROR_ENERGI_INVEST', 'Une erreur est servenue');
      }
      commit('SET_MAIL_LODING', false);
      return { succes: false };
    }
  },
  async getAllFilialeSci({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/Facture_sci/get_filiale`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_FILIALES_SCI', response.data.data);
      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async getdataSciVendeurOnLine({ commit }, payload) {
    commit('LOAIDNG_SCI_VENDEUR_ONLINE', true);
    const params = {};
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    try {
      const response = await axios.get(
        domain + `/api/Facture_sci/vision/vendeur_online`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_VENDEUR_ONLINE_SCI', response.data.data);
      commit('LOAIDNG_SCI_VENDEUR_ONLINE', false);

      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async getdataSciAcheteurOnLine({ commit }, payload) {
    commit('LOAIDNG_SCI_ACHETEUR_ONLINE', true);
    const params = {};
    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    try {
      const response = await axios.get(
        domain + `/api/Facture_sci/vision/client_online`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_ACHETEUR_ONLINE_SCI', response.data.data);
      commit('LOAIDNG_SCI_ACHETEUR_ONLINE', false);

      return {
        succes: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async transfromToFacture({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/Facture_sci/confirme`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async exportExcelFactureSci({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        domain + `/api/Facture_sci/export`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'Facture SCI' + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
