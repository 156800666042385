export default class SocieteFilialeSci {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.siret = data.siret;
    this.adresse = data.adresse;
    this.email = data.email;
    this.code_postal = data.code_postal;
    this.logo = data.logo;
    this.imageDataUrl = null;
    this.imageData = '';
    this.ville = data.ville;
    this.pays = data.pays;
    this.iban = data.iban;
    this.fax = data.fax;
    this.abreviation = data.abreviation;
    this.telephone = data.telephone;
    this.description = data.description;
    this.tva_value = data.tva_value;
    this.siren_value = data.siren_value;
    this.type = data.type;
    this.filiale = data.filiale;
    this.sci_id = data.sci_id;
  }

  /**
   * @param {*} data
   */

  update(data) {
    this.name = data.name;
    this.siret = data.siret;
    this.adresse = data.adresse;
    this.email = data.email;
    this.logo = data.logo;
    this.imageData = '';
    this.code_postal = data.code_postal;
    this.ville = data.ville;
    this.pays = data.pays;
    this.iban = data.iban;
    this.fax = data.fax;
    this.sci_id = data.sci_id;
    this.abreviation = data.abreviation;
    this.telephone = data.telephone;
    this.description = data.description;
    this.tva_value = data.tva_value;
    this.siren_value = data.siren_value;
    this.type = data.type;
    this.filiale = data.filiale;
    this.sci_id = data.sci_id;
  }
  static create(data) {
    if (!data) {
      throw new Error('FilialeSci::create: "data" is undefined');
    }
    return new SocieteFilialeSci(data);
  }
}
