export default class ProjectsFraisWebAbondanceClient {
  constructor(data) {
    this.depot = data.depot;
    this.depot_stockage = data.depot_stockage;
    this.dsi = data.dsi;
    this.dsi_date = data.dsi_date;
    this.dsi_download = data.dsi_download;
    this.dsi_id = data.dsi_id;
    this.dsi_av_id = data.dsi_av_id;
    this.dsi_numero = data.dsi_numero;
    this.dsi_av_numero = data.dsi_av_numero;
    this.dsi_email_sent_at = data.dsi_email_sent_at;
    this.dsi_lot = data.dsi_lot;
    this.dsi_statue_installateur = data.dsi_statue_installateur;
    this.dsi_type_paiement = data.dsi_type_paiement;
    this.dsi_lcr = data.dsi_lcr;
    this.id = data.id;
    this.installer_rge = data.installer_rge;
    this.montant_a_5_ht = data.montant_a_5_ht;
    this.montant_final = data.montant_final;
    this.montant_prime_cee = data.montant_prime_cee;
    this.montant_total_ht = data.montant_total_ht;
    this.nom1 = data.nom1;
    this.nom_pernom = data.nom_pernom;
    this.numero_de_depot = data.numero_de_depot;
    this.numero_dossier = data.numero_dossier;
    this.period_de_paie = data.period_de_paie;
    this.pernom1 = data.pernom1;
    this.statue_dsi = data.statue_dsi;
    this.BE_TYPE = data.BE_TYPE;
    this.month = data.month;
    this.pu_dsi = data.pu_dsi;
    this.numero_dossier = data.numero_dossier;
    this.upcube = data.upcube;
    this.upcube_numero = data.upcube_numero;
    this.upcube_av_numero = data.upcube_av_numero;
    this.upcube_statue_installateur = data.upcube_statue_installateur;
    this.upcube_date = data.upcube_date;
    this.upcube_email_sent_at = data.upcube_email_sent_at;
    this.upcube_email_lot = data.upcube_lot;
    this.upcube_download = data.upcube_download;
    this.upcube_id = data.upcube_id;
    this.upcube_av_id = data.upcube_av_id;
    this.upcube_type_paiement = data.upcube_type_paiement;
    this.upcube_lcr = data.upcube_lcr;
    this.statue_upcube = data.statue_upcube;
    this.check = false;
    this.dsi_responsable_email = data.dsi_responsable_email;
    this.upcube_responsable_email = data.upcube_responsable_email;
  }

  update(data) {
    this.dsi = data.dsi;
    this.dsi_date = data.dsi_date;
    this.dsi_download = data.dsi_download;
    this.dsi_id = data.dsi_id;
    this.dsi_numero = data.dsi_numero;
    this.dsi_statue_installateur = data.dsi_statue_installateur;
    this.statue_dsi = data.statue_dsi;
    this.upcube = data.upcube;
    this.upcube_numero = data.upcube_numero;
    this.upcube_statue_installateur = data.upcube_statue_installateur;
    this.upcube_date = data.upcube_date;
    this.upcube_download = data.upcube_download;
    this.upcube_id = data.upcube_id;
    this.statue_upcube = data.statue_upcube;
    this.check = false;
    this.dsi_responsable_email = data.dsi_responsable_email;
    this.upcube_responsable_email = data.upcube_responsable_email;
  }
  static create(data) {
    return new ProjectsFraisWebAbondanceClient(data);
  }
}
