export default class Calender {
  constructor(data) {
    this.year = data.year;
    this.months = data.months;
  }

  /**
   * @param {*} data
   */

  update(data) {
    this.months = data.months;
  }
  static create(data) {
    if (!data) {
      throw new Error('Calender::create: "data" is undefined');
    }
    return new Calender(data);
  }
}
