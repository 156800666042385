export default class Filiale {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.image = data.image;
    this.depot = data.depot;
    this.anomalies = [];
    this.loading = false;
    this.loadingAnomalie = false;
    this.download = false;
  }
  update(data) {
    this.anomalies = data.anomalies;
    this.download = data.download;
  }
  static create(data) {
    return new Filiale(data);
  }
}
