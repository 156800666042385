import axios from 'axios';
import domain from '@/environment';

let requestStats = null;
let requestPerMonth = null;
let requestPerFiliale = null;
let requestAllDepartements = null;
let requestParMap = null;
let requestParMapGlobal = null;

const state = {
  loading: false,
  loadingPerMonth: false,
  loadingPerFiliale: false,
  data: [],
  dataPerMonth: [],
  dataPerFiliale: [],
  statsTotalProject: null,
  statsNombreFactureTotale: null,
  statsSommeFactureTotale: null,
  allDepartements: [],
  loadingDepartement: false,
  loadingPerMap: false,
  dataPerMap: [],
  dataPerMapGlobal: [],
  errordataPerMap: null
};

const getters = {
  getStatsLoading: state => state.loading,
  getStatsPerMonthLoading: state => state.loadingPerMonth,
  getStatsPerFilialeLoading: state => state.loadingPerFiliale,
  getStatsData: state => state.data,
  getStatsDataPerMonth: state => state.dataPerMonth,
  getStatsDataPerFiliale: state => state.dataPerFiliale,
  getStatsTotalProject: state => state.statsTotalProject,
  getStatsNombreFactureTotale: state => state.statsNombreFactureTotale,
  getStatsSommeFactureTotale: state => state.statsSommeFactureTotale,
  getAllDepartements: state => state.allDepartements,
  getLoadingDepartement: state => state.loadingDepartement,
  getLoadingPerMap: state => state.loadingPerMap,
  getStatsDataPerMap: state => state.dataPerMap,
  getErrorDataPerMap: state => state.errordataPerMap,
  getStatsDataPerMapGlobal: state => state.dataPerMapGlobal
};
const mutations = {
  SET_LOADING_STATS(state, payload) {
    state.loading = payload;
  },
  SET_LOADING_STATS_PER_MONTH(state, payload) {
    state.loadingPerMonth = payload;
  },
  SET_LOADING_STATS_PER_FILIALE(state, payload) {
    state.loadingPerFiliale = payload;
  },
  SET_STATS_DATA(state, payload) {
    state.data = payload;
  },
  SET_STATS_DATA_MONTH(state, payload) {
    state.dataPerMonth = payload.data;
    state.statsTotalProject = payload.totale_project;
    state.statsNombreFactureTotale = payload.nombre_facture_totale;
    state.statsSommeFactureTotale = payload.somme_facture_totale;
  },
  SET_STATS_DATA_FILIALE(state, payload) {
    state.dataPerFiliale = payload;
  },
  GET_ALL_DEPARTEMENT(state, payload) {
    state.allDepartements = payload;
  },
  SET_LOADING_STATS_DEPARTEMENT(state, payload) {
    state.loadingDepartement = payload;
  },
  SET_LOADING_STATS_PER_MAP(state, payload) {
    state.loadingPerMap = payload;
  },
  SET_STATS_DATA_MAP(state, payload) {
    state.dataPerMap = payload;
  },
  SET_STATS_DATA_MAP_GLOBAL(state, payload) {
    state.dataPerMapGlobal = payload;
  },
  SET_ERROR_STATS_DATA_MAP(state, payload) {
    state.errordataPerMap = payload;
  }
};
const actions = {
  async fetchStats({ commit }, payload) {
    commit('SET_LOADING_STATS', true);
    try {
      if (requestStats) {
        requestStats.cancel('cancel-request');
      }
      requestStats = axios.CancelToken.source();
      const params = {};
      if (payload.year) {
        params.year = payload.year;
      }
      if (payload.month) {
        params.month = payload.month;
      }
      if (payload.week) {
        params.week = payload.week;
      }
      if (payload.filiale) {
        params.filiale = payload.filiale;
      }
      if (payload.depot) {
        params.depot = payload.depot;
      }
      if (payload.support) {
        params.support = payload.support;
      }
      const response = await axios.get(
        domain + `/api/stat_rapport_installatteur`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestStats.token
        }
      );

      commit('SET_STATS_DATA', response.data.data);
      commit('SET_LOADING_STATS', false);

      //   console.log(params);
      //   setTimeout(() => {
      //     commit('SET_STATS_DATA', mockData);
      //     commit('SET_LOADING_STATS', false);
      //     return true;
      //   }, 1000);
    } catch (error) {
      if (error.message !== 'cancel-request') {
        commit('SET_LOADING_STATS', false);
      }
      return false;
    }
  },
  async fetchStatsPerMonth({ commit }, payload) {
    commit('SET_LOADING_STATS_PER_MONTH', true);
    try {
      if (requestPerMonth) {
        requestPerMonth.cancel('cancel-request');
      }
      requestPerMonth = axios.CancelToken.source();
      const params = {};
      if (payload.year) {
        params.year = payload.year;
      }
      if (payload.month) {
        params.month = payload.month;
      }
      if (payload.week) {
        params.week = payload.week;
      }
      if (payload.filiale) {
        params.filiale = payload.filiale;
      }
      if (payload.depot) {
        params.depot = payload.depot;
      }
      if (payload.support) {
        params.support = payload.support;
      }
      const response = await axios.get(domain + `/api/stat_by_client`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestPerMonth.token
      });

      commit('SET_STATS_DATA_MONTH', response.data);
      commit('SET_LOADING_STATS_PER_MONTH', false);
    } catch (error) {
      if (error.message !== 'cancel-request') {
        commit('SET_LOADING_STATS_PER_MONTH', false);
      }
      return false;
    }
  },
  async fetchStatsPerFiliale({ commit }, payload) {
    commit('SET_LOADING_STATS_PER_FILIALE', true);
    try {
      if (requestPerFiliale) {
        requestPerFiliale.cancel('cancel-request');
      }
      requestPerFiliale = axios.CancelToken.source();

      const params = {};
      if (payload.year) {
        params.year = payload.year;
      }
      if (payload.month) {
        params.month = payload.month;
      }
      if (payload.week) {
        params.week = payload.week;
      }
      if (payload.filiale) {
        params.filiale = payload.filiale;
      }
      if (payload.depot) {
        params.depot = payload.depot;
      }
      if (payload.support) {
        params.support = payload.support;
      }
      const response = await axios.get(domain + `/api/stat_by_filleul`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestPerFiliale.token
      });

      commit('SET_STATS_DATA_FILIALE', response.data.data);
      commit('SET_LOADING_STATS_PER_FILIALE', false);
    } catch (error) {
      if (error.message !== 'cancel-request') {
        commit('SET_LOADING_STATS_PER_FILIALE', false);
      }
      return false;
    }
  },
  async fetchAllDepartements({ commit }, payload) {
    commit('SET_LOADING_STATS_DEPARTEMENT', true);
    try {
      if (requestAllDepartements) {
        requestAllDepartements.cancel('cancel-request');
      }
      requestAllDepartements = axios.CancelToken.source();

      const response = await axios.get(domain + `/api/map/all_depot_stockage`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        cancelToken: requestAllDepartements.token
      });

      commit('GET_ALL_DEPARTEMENT', response.data.data);
      commit('SET_LOADING_STATS_DEPARTEMENT', false);
    } catch (error) {
      if (error.message !== 'cancel-request') {
        commit('SET_LOADING_STATS_DEPARTEMENT', false);
      }
      return false;
    }
  },

  async fetchStatsParMap({ commit }, payload) {
    commit('SET_LOADING_STATS_PER_MAP', true);
    commit('SET_ERROR_STATS_DATA_MAP', false);

    try {
      if (requestParMap) {
        requestParMap.cancel('cancel-request');
      }
      requestParMap = axios.CancelToken.source();
      const response = await axios.post(domain + `/api/map/map_data`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        cancelToken: requestParMap.token
      });

      if (response.data.data.length == 0) {
        commit('SET_ERROR_STATS_DATA_MAP', '[Département vide .]');
      }
      commit('SET_STATS_DATA_MAP', response.data.data);
      commit('SET_LOADING_STATS_PER_MAP', false);

      return {
        succes: true,
        message: response.data
      };
    } catch (error) {
      if (error.message !== 'cancel-request') {
        commit('SET_LOADING_STATS_PER_MAP', false);
      }

      return false;
    }
  },
  async fetchStatsParMapGlobal({ commit }, payload) {
    //commit('SET_LOADING_STATS_PER_MAP', true);
    try {
      if (requestParMapGlobal) {
        requestParMapGlobal.cancel('cancel-request');
      }
      requestParMapGlobal = axios.CancelToken.source();
      const response = await axios.post(domain + `/api/map/map_data`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.state.User.access_token}`
        },

        cancelToken: requestParMapGlobal.token
      });

      if (response.data.data.length) {
        commit('SET_STATS_DATA_MAP_GLOBAL', response.data);
      } else {
        commit('SET_STATS_DATA_MAP_GLOBAL', []);
      }
      //commit('SET_LOADING_STATS_PER_MAP', false);

      return {
        succes: true,
        message: response.data
      };
    } catch (error) {
      //commit('SET_LOADING_STATS_PER_MAP', false);

      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
