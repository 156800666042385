import axios from 'axios';
import domain from '@/environment';
import SuiviIndependant from '../../models/SuiviIndependant';
const prefixbillEmployeeSuiviTH = 'billEmployeeSuiviTH';

const state = {
  chargementSuiviIndependantTh: false,
  SuiviIndependantTableTh: [],
  totalRowSuiviIndependantTh: null,
  onlineTypeEmloyeesInSuiviTh: [],
  activeEmployyesInSuiviTh: []
};
const getters = {
  getChargementSuiviIndependantTh: state => state.chargementSuiviIndependantTh,
  getSuiviIndependantTableTh: state => state.SuiviIndependantTableTh,
  getTotalRowSuiviIndependantTh: state => state.totalRowSuiviIndependantTh,
  getOnlineTypeEmloyeesInSuiviTh: state => state.onlineTypeEmloyeesInSuiviTh,
  getActiveEmployyesInSuiviTh: state => state.activeEmployyesInSuiviTh
};
const mutations = {
  SET_LOADING_SUIVI_INDEPENDANT_TH(state, payload) {
    state.chargementSuiviIndependantTh = payload;
  },
  SUIVI_INDEPENDANT_FILTRED_TH(state, payload) {
    state.SuiviIndependantTableTh = payload.map(item =>
      SuiviIndependant.create(item)
    );
  },
  SET_TOTAL_ROW_SUIVI_INDEPENDANT_TH(state, payload) {
    state.totalRowSuiviIndependantTh = payload;
  },
  UPDATE_FACTURE_SUIVI_INDEPENDANT_TH(state, payload) {
    payload.row.update(payload.response);
  },
  SET_ONLINE_TYPE_IN_SUIVI_TH(state, payload) {
    state.onlineTypeEmloyeesInSuiviTh = payload;
  },
  ALL_ACTIVE_EMPLOYEE_BY_TYPE_IN_SUIVI_TH(state, payload) {
    state.activeEmployyesInSuiviTh = payload;
  }
};
const actions = {
  async getSuiviIndependantThFiltred({ commit }, payload) {
    commit('SET_LOADING_SUIVI_INDEPENDANT_TH', true);
    try {
      const params = {
        page: payload.page,
        per_page: payload.perPage,
        year: payload.year
      };
      if (payload.month && payload.month != null) {
        params.month = payload.month;
      }
      if (payload.depot && payload.depot != null) {
        params.depot = payload.depot;
      }
      if (
        payload.employee_id &&
        payload.employee_id.id &&
        payload.employee_id.id != null
      ) {
        params.employee_id = payload.employee_id.id;
      }
      if (payload.validation != null) {
        params.statue = payload.validation;
      }
      if (payload.type !== null) {
        params.type = payload.type;
      }

      const response = await axios.get(
        domain + `/api/${prefixbillEmployeeSuiviTH}/all`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SUIVI_INDEPENDANT_FILTRED_TH', response.data.data.data);
      commit('SET_TOTAL_ROW_SUIVI_INDEPENDANT_TH', response.data.data.total);
      commit('SET_LOADING_SUIVI_INDEPENDANT_TH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_SUIVI_INDEPENDANT_TH', false);
      return false;
    }
  },
  async updateSuiviIndependentTh({ commit }, payload) {
    const params = {
      column: payload.column,
      value: payload.value,
      id: payload.row.id
    };
    try {
      const response = await axios.post(
        domain + `/api/${prefixbillEmployeeSuiviTH}/update`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        row: payload.row
      };
      commit('UPDATE_FACTURE_SUIVI_INDEPENDANT_TH', obj);
      return true;
    } catch (error) {
      return false;
    }
  },
  async exportExcelSuiviIndependantTh({ commit }, payload) {
    commit('SET_LOADING_SUIVI_INDEPENDANT_TH', true);
    const params = {
      page: payload.page,
      per_page: payload.perPage,
      year: payload.year
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (
      payload.employee_id &&
      payload.employee_id.id &&
      payload.employee_id.id != null
    ) {
      params.employee_id = payload.employee_id.id;
    }
    if (payload.validation != null) {
      params.statue = payload.validation;
    }
    if (payload.type !== null) {
      params.type = payload.type;
    }

    try {
      const response = await axios.get(
        domain + `/api/${prefixbillEmployeeSuiviTH}/export`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute(
        'download',
        'SUIVI INDEPENDANT - ' + payload.year + '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_SUIVI_INDEPENDANT_TH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_SUIVI_INDEPENDANT_TH', false);
      return false;
    }
  },
  async getOnlineTypeInSuiviTh({ commit }, { year, month }) {
    const params = { year: year };
    if (month != null) {
      params.month = month;
    }

    try {
      const responce = await axios.get(
        domain + `/api/${prefixbillEmployeeSuiviTH}/online_types`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_ONLINE_TYPE_IN_SUIVI_TH', responce.data.type);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getOnlineSalaryEmployeeBytypeInSuiviTh(
    { commit },
    { year, type, salarie, month }
  ) {
    const params = { year: year };
    if (type !== null) {
      params.type = type;
    }
    if (month !== null) {
      params.month = month;
    }
    try {
      const responce = await axios.get(
        domain + `/api/${prefixbillEmployeeSuiviTH}/online_employee_by_type`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit(
        'ALL_ACTIVE_EMPLOYEE_BY_TYPE_IN_SUIVI_TH',
        responce.data.employees
      );
      return true;
    } catch (error) {
      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
