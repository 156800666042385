export default class SalaryTh {
  constructor(data) {
    this.id = data.id;
    this.employee = data.employee;
    this.comment = data.comment;
    this.payed_at = data.payed_at;
    this.statue = data.statue;
    this.month = data.month;
    this.year = data.year;
    this.type = data.type;
    this.acompt = data.acompt;
    this.total = data.total;
    this.interne = data.interne;
    this.externe = data.externe;
    this.vente = data.vente;
    this.previsite = data.previsite;
    this.prime_qualite = data.prime_qualite;
    this.prime_objectif = data.prime_objectif;
    this.prime_sav = data.prime_sav;
    this.fix = data.fix;
    this.additional_prime = data.additional_prime;
    this.other_prime_name = data.other_prime_name;
    this.other_prime_value = data.other_prime_value;
    this.deducation = data.deducation;
    this.IGD = data.IGD;
    this.vmc = data.vmc;
    this.pac = data.pac;
    this.file = data.file;
    this.depots = data.depots.map(item => {
      return { ...item, show: false };
    });
  }
  update(data) {
    this.file = data.file;
    this.comment = data.comment;
    this.payed_at = data.payed_at;
    this.statue = data.statue;
    this.prime_qualite = data.prime_qualite;
    this.prime_objectif = data.prime_objectif;
    this.prime_sav = data.prime_sav;
    this.fix = data.fix;
    this.additional_prime = data.additional_prime;
    this.other_prime_name = data.other_prime_name;
    this.other_prime_value = data.other_prime_value;
    this.deducation = data.deducation;
    this.IGD = data.IGD;
    this.vmc = data.vmc;
    this.pac = data.pac;
  }
  static create(data) {
    return new SalaryTh(data);
  }
}
