export default class SousRegie {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.phone = data.phone;
    this.adress = data.adress;
    this.type = data.type;
    this.code_postal = data.code_postal;
    this.ville = data.ville;
    this.email = data.email;
    this.coeff_101 = data.coeff_101;
    this.coeff_102 = data.coeff_102;
    this.coeff_103 = data.coeff_103;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.files = data.files;
    this.details = false;
    this.coeff_101_deroule = data.coeff_101_deroule;
    this.coeff_101_souffle = data.coeff_101_souffle;
    this.coeff_101_rampant = data.coeff_101_rampant;
    this.coeff_102_murs = data.coeff_102_murs;
    this.coeff_102_murs_iti = data.coeff_102_murs_iti;
    this.coeff_102_murs_ite = data.coeff_102_murs_ite;
    this.coeff_102_pignons = data.coeff_102_pignons;
    this.coeff_103_vide_sanitaire = data.coeff_103_vide_sanitaire;
    this.coeff_103_plafonds = data.coeff_103_plafonds;
    this.pay_previsite = data.pay_previsite;
    this.semaine = data.semaine;
    this.zonz_climatique = data.zonz_climatique;
    this.type_de_chauffage = data.type_de_chauffage;
    this.precarite = data.precarite;
  }
  update(data) {
    this.phone = data.phone;
    this.adress = data.adress;
    this.code_postal = data.code_postal;
    this.ville = data.ville;
    this.email = data.email;
    this.coeff_101_deroule = data.coeff_101_deroule;
    this.coeff_101_souffle = data.coeff_101_souffle;
    this.coeff_101_rampant = data.coeff_101_rampant;
    this.coeff_102_murs = data.coeff_102_murs;
    this.coeff_102_murs_iti = data.coeff_102_murs_iti;
    this.coeff_102_murs_ite = data.coeff_102_murs_ite;
    this.coeff_102_pignons = data.coeff_102_pignons;
    this.coeff_103_vide_sanitaire = data.coeff_103_vide_sanitaire;
    this.coeff_103_plafonds = data.coeff_103_plafonds;
    this.pay_previsite = data.pay_previsite;
  }
  static create(data) {
    return new SousRegie(data);
  }
}
