import Router from '../router/index';
import state from '../store/index';
export default class Facture {
  constructor(data) {
    this.id = data.id;
    this.created_at = data.created_at;
    this.notification_type = data.notification_type;
    this.from = data.from;
    this.from_type = data.from_type;
    this.victime = data.victime;
    this.employee_type = data.employee_type;
    this.year = data.year;
    this.semaine = data.semaine;
    this.seen = data.seen;
    this.comment = data.comment;
    // this.victime_id = data.victime_id;
    this.employee_id = data.employee_id;
    this.total_salarys_count = data.total_salarys_count;
  }
  update(data) {
    this.seen = data.seen;
    this.comment = data.comment;
  }
  showdetailsNotification() {
    // if (this.notification_type === 'new_bills') {
    //   if (Router.history.current.fullPath === '/validation-salaire') {
    //     if (
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterStatu !== 'en attend' ||
    //       this.year !==
    //         JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //           .filteryear ||
    //       's' + this.semaine !==
    //         JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //           .filterDepot ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterStatu !== null ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterUser !== null ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterType !== null
    //     ) {
    //       localStorage.setItem(
    //         'Validation-salaire-Filters',
    //         JSON.stringify({
    //           filterDepot: null,
    //           filterSemaine: this.semaine,
    //           filterUser: null,
    //           filterValidation: 'en attend',
    //           filteryear: parseInt(this.year),
    //           filterType: null
    //         })
    //       );
    //       location.reload();
    //     }
    //   } else {
    //     localStorage.setItem(
    //       'Validation-salaire-Filters',
    //       JSON.stringify({
    //         filterDepot: null,
    //         filterSemaine:  this.semaine,
    //         filterUser: null,
    //         filterValidation: 'en attend',
    //         filteryear: parseInt(this.year),
    //         filterType: null
    //       })
    //     );
    //     Router.push('/validation-salaire');
    //   }
    // }
    if (this.notification_type === 'bill_demande') {
      if (state.getters.getUserData.role == 'commercial_regie') {
        if (
          Router.history.current.fullPath ===
          '/gestion-facture-commercial-regie'
        ) {
          if (
            JSON.parse(
              localStorage.getItem('gestion-facture-commercial-regie-filter')
            ).filterStatu !== 'valid' ||
            this.year !==
              JSON.parse(
                localStorage.getItem('gestion-facture-commercial-regie-filter')
              ).filteryear ||
            's' + this.semaine !==
              JSON.parse(
                localStorage.getItem('gestion-facture-commercial-regie-filter')
              ).filterSemaine
          ) {
            localStorage.setItem(
              'gestion-facture-commercial-regie-filter',
              JSON.stringify({
                filterSemaine: this.semaine,
                filteryear: parseInt(this.year),
                filterStatu: 'valid'
              })
            );
            location.reload();
          }
        } else {
          localStorage.setItem(
            'gestion-facture-commercial-regie-filter',
            JSON.stringify({
              filterSemaine: this.semaine,
              filteryear: parseInt(this.year),
              filterStatu: 'valid'
            })
          );
          Router.push('/gestion-facture-commercial-regie');
        }
      } else if (state.getters.getUserData.role == 'admin_regie') {
        if (Router.history.current.fullPath === '/gestion-facture-regie') {
          if (
            JSON.parse(localStorage.getItem('gestion-facture-regie-Filters'))
              .filterStatu !== 'valid' ||
            this.year !==
              JSON.parse(localStorage.getItem('gestion-facture-regie-Filters'))
                .filteryear ||
            's' + this.semaine !==
              JSON.parse(localStorage.getItem('gestion-facture-regie-Filters'))
                .filterSemaine
          ) {
            localStorage.setItem(
              'gestion-facture-regie-Filters',
              JSON.stringify({
                filterSemaine: this.semaine,
                filteryear: parseInt(this.year),
                filterStatu: 'valid'
              })
            );
            location.reload();
          }
        } else {
          localStorage.setItem(
            'gestion-facture-regie-Filters',
            JSON.stringify({
              filterSemaine: this.semaine,
              filteryear: parseInt(this.year),
              filterStatu: 'valid'
            })
          );
          Router.push('/gestion-facture-regie');
        }
      } else {
        //independant -> les commerciaux
        if (Router.history.current.fullPath === '/gestionFactureCommerciaux') {
          if (
            JSON.parse(
              localStorage.getItem('Validation-salaire-Filter-Commerciaux')
            ).filterValidation !== 'valid' ||
            this.year !==
              JSON.parse(
                localStorage.getItem('Validation-salaire-Filter-Commerciaux')
              ).filteryear ||
            's' + this.semaine !==
              JSON.parse(
                localStorage.getItem('Validation-salaire-Filter-Commerciaux')
              ).filterSemaine
          ) {
            localStorage.setItem(
              'Validation-salaire-Filter-Commerciaux',
              JSON.stringify({
                filterSemaine: this.semaine,
                filteryear: parseInt(this.year),
                filterValidation: 'valid'
              })
            );
            location.reload();
          }
        } else {
          localStorage.setItem(
            'Validation-salaire-Filter-Commerciaux',
            JSON.stringify({
              filterSemaine: this.semaine,
              filteryear: parseInt(this.year),
              filterValidation: 'valid'
            })
          );
          Router.push('/gestionFactureCommerciaux');
        }
      }
    }
    if (this.notification_type === 'bill_refuse') {
      if (
        Router.history.current.fullPath === '/employes/validation-salaire-admin'
      ) {
        if (
          this.year !==
            JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
              .filteryear ||
          this.semaine !==
            JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
              .filterSemaine ||
          JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
            .filterUser != null ||
          JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
            .filterValidation != 'refuse'
        ) {
          localStorage.setItem(
            'Validation-salaire-Filters',
            JSON.stringify({
              filteryear: parseInt(this.year),
              filterSemaine: this.semaine,
              filterValidation: 'refuse',
              filterType: this.victime.role,
              filterUser: null,
              filterDepot: null,
              filterProjectType: null
            })
          );
          location.reload();
        }
      } else {
        localStorage.setItem(
          'Validation-salaire-Filters',
          JSON.stringify({
            filteryear: parseInt(this.year),
            filterSemaine: this.semaine,
            filterValidation: 'refuse',
            filterType: this.victime.role,
            filterUser: null,
            filterDepot: null,
            filterProjectType: null
          })
        );
        Router.push('/employes/validation-salaire-admin');
      }
    }
    if (this.notification_type === 'regie_bill_waiting_for_validation') {
      if (state.getters.getUserData.role == 'admin') {
        if (Router.history.current.fullPath === '/regies/gestion-facture') {
          if (
            this.year !==
              JSON.parse(localStorage.getItem('gestion-facture-admin-Filters'))
                .filteryear ||
            's' + this.semaine !==
              JSON.parse(localStorage.getItem('gestion-facture-admin-Filters'))
                .filterSemaine ||
            JSON.parse(localStorage.getItem('gestion-facture-admin-Filters'))
              .filterStatu !== 'encore de verification' ||
            JSON.parse(localStorage.getItem('gestion-facture-admin-Filters'))
              .filterRegie !== null
          ) {
            localStorage.setItem(
              'gestion-facture-admin-Filters',
              JSON.stringify({
                filterRegie: null,
                filterSemaine: this.semaine,
                filterStatu: 'encore de verification',
                filteryear: this.year
              })
            );
            location.reload();
          }
        } else {
          localStorage.setItem(
            'gestion-facture-admin-Filters',
            JSON.stringify({
              filterRegie: null,
              filterSemaine: this.semaine,
              filterStatu: 'encore de verification',
              filteryear: this.year
            })
          );
          Router.push('/regies/gestion-facture');
        }
      } else {
        if (Router.history.current.fullPath === '/gestion-facture-sous-regie') {
          if (
            this.year !==
              JSON.parse(
                localStorage.getItem('gestion-facture-sous-regie-Filters')
              ).filteryear ||
            's' + this.semaine !==
              JSON.parse(
                localStorage.getItem('gestion-facture-sous-regie-Filters')
              ).filterSemaine ||
            JSON.parse(
              localStorage.getItem('gestion-facture-sous-regie-Filters')
            ).filterStatu !== 'encore de verification' ||
            JSON.parse(
              localStorage.getItem('gestion-facture-sous-regie-Filters')
            ).filterRegie !== null
          ) {
            localStorage.setItem(
              'gestion-facture-sous-regie-Filters',
              JSON.stringify({
                filterRegie: null,
                filterSemaine: this.semaine,
                filterStatu: 'encore de verification',
                filteryear: this.year
              })
            );
            location.reload();
          }
        } else {
          localStorage.setItem(
            'gestion-facture-sous-regie-Filters',
            JSON.stringify({
              filterRegie: null,
              filterSemaine: this.semaine,
              filterStatu: 'encore de verification',
              filteryear: this.year
            })
          );
          Router.push('/gestion-facture-sous-regie');
        }
      }
    }
    if (this.notification_type === 'bill_payed_successfully') {
      if (state.getters.getUserData.role == 'commercial_regie') {
        if (
          Router.history.current.fullPath ===
          '/gestion-facture-commercial-regie'
        ) {
          if (
            this.year !==
              JSON.parse(
                localStorage.getItem('gestion-facture-commercial-regie-filter')
              ).filteryear ||
            's' + this.semaine !==
              JSON.parse(
                localStorage.getItem('gestion-facture-commercial-regie-filter')
              ).filterSemaine ||
            JSON.parse(
              localStorage.getItem('gestion-facture-commercial-regie-filter')
            ).filterStatu !== 'paye'
          ) {
            localStorage.setItem(
              'gestion-facture-commercial-regie-filter',
              JSON.stringify({
                filterSemaine: this.semaine,
                filterStatu: 'paye',
                filteryear: this.year
              })
            );
            location.reload();
          }
        } else {
          localStorage.setItem(
            'gestion-facture-commercial-regie-filter',
            JSON.stringify({
              filterSemaine: this.semaine,
              filterStatu: 'paye',
              filteryear: this.year
            })
          );
          Router.push('/gestion-facture-commercial-regie');
        }
      } else if (state.getters.getUserData.role == 'admin_regie') {
        if (Router.history.current.fullPath === '/gestion-facture-regie') {
          if (
            this.year !==
              JSON.parse(localStorage.getItem('gestion-facture-regie-Filters'))
                .filteryear ||
            's' + this.semaine !==
              JSON.parse(localStorage.getItem('gestion-facture-regie-Filters'))
                .filterSemaine ||
            JSON.parse(localStorage.getItem('gestion-facture-regie-Filters'))
              .filterStatu !== 'paye'
          ) {
            localStorage.setItem(
              'gestion-facture-regie-Filters',
              JSON.stringify({
                filterSemaine: this.semaine,
                filterStatu: 'paye',
                filteryear: this.year
              })
            );
            location.reload();
          }
        } else {
          localStorage.setItem(
            'gestion-facture-regie-Filters',
            JSON.stringify({
              filterSemaine: this.semaine,
              filterStatu: 'paye',
              filteryear: this.year
            })
          );
          Router.push('/gestion-facture-regie');
        }
      } else {
        //independant
        if (Router.history.current.fullPath === '/gestionFactureCommerciaux') {
          if (
            this.year !==
              JSON.parse(
                localStorage.getItem('Validation-salaire-Filter-Commerciaux')
              ).filteryear ||
            's' + this.semaine !==
              JSON.parse(
                localStorage.getItem('Validation-salaire-Filter-Commerciaux')
              ).filterSemaine ||
            JSON.parse(
              localStorage.getItem('Validation-salaire-Filter-Commerciaux')
            ).filterValidation !== 'paye'
          ) {
            localStorage.setItem(
              'Validation-salaire-Filter-Commerciaux',
              JSON.stringify({
                filterSemaine: this.semaine,
                filterValidation: 'paye',
                filteryear: this.year
              })
            );
            location.reload();
          }
        } else {
          localStorage.setItem(
            'Validation-salaire-Filter-Commerciaux',
            JSON.stringify({
              filterSemaine: this.semaine,
              filterValidation: 'paye',
              filteryear: this.year
            })
          );
          Router.push('/gestionFactureCommerciaux');
        }
      }
    }
    // if (this.notification_type === 'revalidate_salary') {
    //   if (Router.history.current.fullPath === '/validation-salaire') {
    //     if (
    //       this.year !==
    //         JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //           .filteryear ||
    //       's' + this.semaine !==
    //         JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //           .filterSemaine ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterValidation !== 'en attend' ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterUser === null ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterUser.id !== this.victime.id ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterDepot !== null ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterType !== null
    //     ) {
    //       localStorage.setItem(
    //         'Validation-salaire-Filters',
    //         JSON.stringify({
    //           filterDepot: null,
    //           filterSemaine: this.semaine,
    //           filterUser: { full_name: this.victime, id: this.victime.id },
    //           filterValidation: 'en attend',
    //           filteryear: this.year,
    //           filterType: null
    //         })
    //       );
    //       location.reload();
    //     }
    //   } else {
    //     localStorage.setItem(
    //       'Validation-salaire-Filters',
    //       JSON.stringify({
    //         filterDepot: null,
    //         filterSemaine: this.semaine,
    //         filterUser: { full_name: this.victime, id: this.victime.id },

    //         filterValidation: 'en attend',
    //         filteryear: this.year,
    //         filterType: null
    //       })
    //     );
    //     Router.push('/validation-salaire');
    //   }
    // }
    // if (this.notification_type === 'revalidate_salary_regie') {
    //   if (Router.history.current.fullPath === '/gestion-facture-regie') {
    //     if (
    //       this.year !==
    //         JSON.parse(localStorage.getItem('gestion-facture-regie-Filters'))
    //           .filteryear ||
    //       's' + this.semaine !==
    //         JSON.parse(localStorage.getItem('gestion-facture-regie-Filters'))
    //           .filterSemaine ||
    //       JSON.parse(localStorage.getItem('gestion-facture-regie-Filters'))
    //         .filterStatu !== null
    //     ) {
    //       localStorage.setItem(
    //         'gestion-facture-regie-Filters',
    //         JSON.stringify({
    //           filterStatu: null,
    //           filterSemaine:  this.semaine,
    //           filteryear: this.year
    //         })
    //       );
    //       location.reload();
    //     }
    //   } else {
    //     localStorage.setItem(
    //       'gestion-facture-regie-Filters',
    //       JSON.stringify({
    //         filterStatu: null,
    //         filterSemaine: this.semaine,
    //         filteryear: this.year
    //       })
    //     );
    //     Router.push('/gestion-facture-regie');
    //   }
    // }
    // if (this.notification_type === 'employee_bill_updated') {
    //   if (Router.history.current.fullPath === '/validation-salaire') {
    //     if (
    //       this.year !==
    //         JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //           .filteryear ||
    //       's' + this.semaine !==
    //         JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //           .filterSemaine ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterValidation !== 'en attend' ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterUser === null ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterUser.id !== this.employee_id ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterDepot !== null ||
    //       JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
    //         .filterType !== null
    //     ) {
    //       localStorage.setItem(
    //         'Validation-salaire-Filters',
    //         JSON.stringify({
    //           filterDepot: null,
    //           filterSemaine: this.semaine,
    //           filterUser: { full_name: this.victime, id: this.employee_id },
    //           filterValidation: null,
    //           filteryear: this.year,
    //           filterType: null
    //         })
    //       );
    //       location.reload();
    //     }
    //   } else {
    //     localStorage.setItem(
    //       'Validation-salaire-Filters',
    //       JSON.stringify({
    //         filterDepot: null,
    //         filterSemaine: this.semaine,
    //         filterUser: { full_name: this.victime, id: this.employee_id },

    //         filterValidation: null,
    //         filteryear: this.year,
    //         filterType: null
    //       })
    //     );
    //     Router.push('/validation-salaire');
    //   }
    // }
    if (this.notification_type === 'bill_redemande') {
      if (state.getters.getUserData.role == 'commercial_regie') {
        if (
          Router.history.current.fullPath ===
          '/gestion-facture-commercial-regie'
        ) {
          if (
            JSON.parse(
              localStorage.getItem('gestion-facture-commercial-regie-filter')
            ).filterStatu !== 'facture refuse' ||
            this.year !==
              JSON.parse(
                localStorage.getItem('gestion-facture-commercial-regie-filter')
              ).filteryear ||
            's' + this.semaine !==
              JSON.parse(
                localStorage.getItem('gestion-facture-commercial-regie-filter')
              ).filterSemaine
          ) {
            localStorage.setItem(
              'gestion-facture-commercial-regie-filter',
              JSON.stringify({
                filterSemaine: this.semaine,
                filteryear: parseInt(this.year),
                filterStatu: 'facture refuse'
              })
            );
            location.reload();
          }
        } else {
          localStorage.setItem(
            'gestion-facture-commercial-regie-filter',
            JSON.stringify({
              filterSemaine: this.semaine,
              filteryear: parseInt(this.year),
              filterStatu: 'facture refuse'
            })
          );
          Router.push('/gestion-facture-commercial-regie');
        }
      } else if (state.getters.getUserData.role == 'admin_regie') {
        if (Router.history.current.fullPath === '/gestion-facture-regie') {
          if (
            JSON.parse(localStorage.getItem('gestion-facture-regie-Filters'))
              .filterStatu !== 'facture refuse' ||
            this.year !==
              JSON.parse(localStorage.getItem('gestion-facture-regie-Filters'))
                .filteryear ||
            's' + this.semaine !==
              JSON.parse(localStorage.getItem('gestion-facture-regie-Filters'))
                .filterSemaine
          ) {
            localStorage.setItem(
              'gestion-facture-regie-Filters',
              JSON.stringify({
                filterSemaine: this.semaine,
                filteryear: parseInt(this.year),
                filterStatu: 'facture refuse'
              })
            );
            location.reload();
          }
        } else {
          localStorage.setItem(
            'gestion-facture-regie-Filters',
            JSON.stringify({
              filterSemaine: this.semaine,
              filteryear: parseInt(this.year),
              filterStatu: 'facture refuse'
            })
          );
          Router.push('/gestion-facture-regie');
        }
      } else {
        //independent :gestionFactureCommerciaux
        if (Router.history.current.fullPath === '/gestionFactureCommerciaux') {
          if (
            JSON.parse(
              localStorage.getItem('Validation-salaire-Filter-Commerciaux')
            ).filterValidation !== 'facture refuse' ||
            this.year !==
              JSON.parse(
                localStorage.getItem('Validation-salaire-Filter-Commerciaux')
              ).filteryear ||
            's' + this.semaine !==
              JSON.parse(
                localStorage.getItem('Validation-salaire-Filter-Commerciaux')
              ).filterSemaine
          ) {
            localStorage.setItem(
              'Validation-salaire-Filter-Commerciaux',
              JSON.stringify({
                filterSemaine: this.semaine,
                filteryear: parseInt(this.year),
                filterValidation: 'facture refuse'
              })
            );
            location.reload();
          }
        } else {
          localStorage.setItem(
            'Validation-salaire-Filter-Commerciaux',
            JSON.stringify({
              filterSemaine: this.semaine,
              filteryear: parseInt(this.year),
              filterValidation: 'facture refuse'
            })
          );
          Router.push('/gestionFactureCommerciaux');
        }
      }
    }
    if (this.notification_type === 'employee_bill_waiting_for_validation') {
      if (
        Router.history.current.fullPath ===
        '/employes/validation-salaire-commerciaux'
      ) {
        if (
          this.year !==
            JSON.parse(
              localStorage.getItem('Validation-salaire-Filter-Commerciaux')
            ).filteryear ||
          's' + this.semaine !==
            JSON.parse(
              localStorage.getItem('Validation-salaire-Filter-Commerciaux')
            ).filterSemaine ||
          JSON.parse(
            localStorage.getItem('Validation-salaire-Filter-Commerciaux')
          ).filterValidation !== 'encore de verification' ||
          JSON.parse(
            localStorage.getItem('Validation-salaire-Filter-Commerciaux')
          ).filterUser !== null
        ) {
          localStorage.setItem(
            'Validation-salaire-Filter-Commerciaux',
            JSON.stringify({
              filterUser: null,
              filterSemaine: this.semaine,
              filterValidation: 'encore de verification',
              filteryear: this.year
            })
          );
          location.reload();
        }
      } else {
        localStorage.setItem(
          'Validation-salaire-Filter-Commerciaux',
          JSON.stringify({
            filterUser: null,
            filterSemaine: this.semaine,
            filterValidation: 'encore de verification',
            filteryear: this.year
          })
        );
        Router.push('/employes/validation-salaire-commerciaux');
      }
    }
    if (this.notification_type === 'new_salary') {
      if (Router.history.current.fullPath === '/validation-salaire') {
        if (
          this.year !==
            JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
              .filteryear ||
          this.semaine !==
            JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
              .filterSemaine ||
          JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
            .filterUser != null ||
          JSON.parse(localStorage.getItem('Validation-salaire-Filters'))
            .filterValidation != 'en attend'
        ) {
          localStorage.setItem(
            'Validation-salaire-Filters',
            JSON.stringify({
              filteryear: parseInt(this.year),
              filterSemaine: this.semaine,
              filterValidation: 'en attend',
              filterType: this.victime.role,
              filterUser: null,
              filterDepot: null,
              filterProjectType: null
            })
          );
          location.reload();
        }
      } else {
        localStorage.setItem(
          'Validation-salaire-Filters',
          JSON.stringify({
            filteryear: parseInt(this.year),
            filterSemaine: this.semaine,
            filterValidation: 'en attend',
            filterType: this.victime.role,
            filterUser: null,
            filterDepot: null,
            filterProjectType: null
          })
        );
        Router.push('/validation-salaire');
      }
    }
  }
  getMessageNotification() {
    switch (this.notification_type) {
      case 'bill_refuse':
        return (
          '<strong>' +
          this.from +
          '</strong>  ( ' +
          this.from_type +
          ' ) a refusé  le salaire de ' +
          this.victime.name +
          '( ' +
          this.victime.role +
          ' ) pour lannée ' +
          this.year +
          ' dans semaine ' +
          this.semaine
        );
      case 'new_salary':
        return (
          '<strong>' +
          this.total_salarys_count +
          '</strong>  ' +
          ' salaires génere pour lannée ' +
          this.year +
          ' dans semaine ' +
          this.semaine
        );
      case 'bill_demande':
        return (
          '<strong>' +
          this.from +
          "</strong> a validé l'appel a facture  de " +
          this.victime.name +
          " pour l'année " +
          this.year +
          ' dans semaine ' +
          this.semaine
        );
      case 'regie_bill_waiting_for_validation':
        return (
          '<strong>' +
          this.from +
          ' </strong> a uploadé la facture de ' +
          this.victime.name +
          " pour l' anné " +
          this.year +
          'dans semaine ' +
          this.semaine
        );
      case 'employee_bill_waiting_for_validation':
        return (
          '<strong>' +
          this.from +
          ' </strong> a uploadé la facture de ' +
          this.victime.name +
          " pour l' anné " +
          this.year +
          'dans semaine ' +
          this.semaine
        );
      case 'bill_payed_successfully':
        return (
          '<strong> Facture </strong> de semaine ' +
          this.semaine +
          "dans l'année " +
          this.year +
          'payé'
        );
      case 'new_bills':
        return (
          ' <strong>Semaine  </strong>' +
          this.semaine +
          "dans l'année " +
          this.year +
          'validé'
        );
      case 'revalidate_salary':
        return (
          "S'il vous plait revalide la  " +
          'salaire de ' +
          this.victime +
          ' pour la semaine ' +
          this.semaine +
          " dans l'année " +
          this.year
        );
      case 'revalidate_salary_regie':
        return (
          "S'il vous plait reuploade la  " +
          'facture de ' +
          ' pour la semaine ' +
          this.semaine +
          " dans l'année " +
          this.year
        );
      case 'employee_bill_updated':
        return (
          this.from +
          ' à modifier un prime de  ' +
          this.victime +
          ' pour la semaine ' +
          this.semaine +
          " dans l'année " +
          this.year
        );

      case 'bill_redemande':
        return (
          '<strong>' +
          this.from +
          '</strong> a refusé la facture de ' +
          this.victime.name +
          " pour l'année " +
          this.year +
          ' dans semaine ' +
          this.semaine
        );
      default:
        break;
    }
  }
  getIconName() {
    switch (this.notification_type) {
      case 'bill_refuse':
        return 'user-times';
      case 'new_salary':
        return 'check-circle';
      case 'bill_demande':
        return 'check-circle';
      case 'regie_bill_waiting_for_validation':
        return 'file-pdf';
      case 'employee_bill_waiting_for_validation':
        return 'file-pdf';
      case 'bill_payed_successfully':
        return 'euro-sign';
      case 'new_bills':
        return 'check-circle';
      case 'revalidate_salary':
        return 'sync-alt';
      case 'revalidate_salary_regie':
        return 'sync-alt';
      case 'employee_bill_updated':
        return 'edit';
      case 'bill_redemande':
        return 'user-times';
      default:
        break;
    }
  }

  static create(data) {
    return new Facture(data);
  }
}
