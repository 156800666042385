import axios from 'axios';
import domain from '@/environment';

export default {
  state: {
    pseudos: [],
    pseudo_loader: false,
    errorPseudo: [],
    errorRemovePseudo: [],
    errorUpdatePseudo: [],
    errorAddPseudo: [],
    pseudo_remove_loader: false
  },
  getters: {
    pseudos: state => state.pseudos,
    pseudo_loader: state => state.pseudo_loader,
    errorPseudo: state => state.errorPseudo,
    errorRemovePseudo: state => state.errorRemovePseudo,
    errorUpdatePseudo: state => state.errorUpdatePseudo,
    errorAddPseudo: state => state.errorAddPseudo,
    pseudo_remove_loader: state => state.pseudo_remove_loader
  },
  mutations: {
    SET_PSEUDO_LOADER(state, payload) {
      state.pseudo_loader = payload;
    },
    SET_PSEUDO_FIILIALE(state, payload) {
      state.pseudos = payload;
    },
    SET_ERROR_PSEUDO_FILIALE(state, payload) {
      state.errorPseudo = payload;
    },
    ADD_NEW_PSEUDO(state, payload) {
      state.pseudos.push(payload);
    },
    UPDATE_PSEUDO(state, payload) {
      state.pseudos.map(pseudo => {
        if (pseudo.id == payload.id) {
          return Object.assign({}, pseudo, payload);
        }
        return payload;
      });
    },
    DELETE_PSEUDO(state, payload) {
      let index = state.pseudos.findIndex(pseudo => pseudo.id == payload);
      state.pseudos.splice(index, 1);
    },
    SET_ERROR_ADD_PSEUDO(state, payload) {
      state.errorAddPseudo = payload;
    },
    SET_ERROR_UPDATE_PSEUDO(state, payload) {
      state.errorUpdatePseudo = payload;
    },
    SET_ERROR_REMOVE_PSEUDO(state, payload) {
      state.errorRemovePseudo = payload;
    },
    SET_REMOVE_PSEUDO_LOADER(state, payload) {
      state.pseudo_remove_loader = payload;
    }
  },
  actions: {
    async getAllPseudoFiliale({ commit }, payload) {
      commit('SET_PSEUDO_LOADER', true);
      try {
        const response = await axios.get(
          domain + `/api/filiale/pseudos/${payload}`,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('SET_PSEUDO_FIILIALE', response.data);
        commit('SET_PSEUDO_LOADER', false);
        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_PSEUDO_FILIALE', error.response.data.data);
        } else {
          commit('SET_ERROR_PSEUDO_FILIALE', 'Une erreur est survenue');
        }
        commit('SET_PSEUDO_LOADER', false);
        return false;
      }
    },
    async addNewPseudo({ commit }, payload) {
      commit('SET_ERROR_ADD_PSEUDO', null);
      try {
        const response = await axios.post(
          domain + `/api/filiale/addPseudo`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('ADD_NEW_PSEUDO', response.data);
        commit('SET_ERROR_ADD_PSEUDO', null);
        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_ADD_PSEUDO', error.response.data.errors.pseudo);
        } else {
          commit('SET_ERROR_ADD_PSEUDO', 'Une erreur est survenue');
        }
        return false;
      }
    },
    async updatePseudo({ commit }, payload) {
      commit('SET_ERROR_UPDATE_PSEUDO', null);
      const params = {
        pseudo: payload.pseudo
      };
      try {
        const response = await axios.put(
          domain + `/api/filiale/updatePseudo/${payload.id}`,
          params,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('UPDATE_PSEUDO', response.data);
        commit('SET_ERROR_UPDATE_PSEUDO', null);
        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_UPDATE_PSEUDO', error.response.data.errors.pseudo);
        } else {
          commit('SET_ERROR_UPDATE_PSEUDO', 'Une erreur est survenue');
        }
        return false;
      }
    },
    async deletePseudo({ commit }, payload) {
      commit('SET_REMOVE_PSEUDO_LOADER', true);
      try {
        await axios.delete(domain + `/api/filiale/deletePseudos/${payload}`, {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        });
        commit('DELETE_PSEUDO', payload);
        commit('SET_ERROR_REMOVE_PSEUDO', null);
        commit('SET_REMOVE_PSEUDO_LOADER', false);
        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_ERROR_REMOVE_PSEUDO', error.response.data.data);
        } else {
          commit('SET_ERROR_REMOVE_PSEUDO', 'Une erreur est survenue');
        }
        commit('SET_REMOVE_PSEUDO_LOADER', false);
        return false;
      }
    },
    resetPseudoFiliale({ commit }, payload) {
      commit('SET_ERROR_REMOVE_PSEUDO', payload);
      commit('SET_ERROR_UPDATE_PSEUDO', payload);
      commit('SET_ERROR_ADD_PSEUDO', payload);
    }
  }
};
