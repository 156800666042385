import axios from 'axios';
import OrganismeThNew from '../../models/OrganismeThNew';
import domain from '@/environment';
let requestfetchAllOrganismeThNew = null;
const state = {
  OrganismeThNew: [],
  OrganismeThNewLoading: false,
  OrganismeThNewError: null,
  totalRowOrganismeThNew: null,
  OrganismeThNewErrorDupplication: null
};
const getters = {
  getOrganismeThNew: state => state.OrganismeThNew,
  getOrganismeThNewLoading: state => state.OrganismeThNewLoading,
  getOrganismeThNewError: state => state.OrganismeThNewError,
  getTotalRowOrganismeThNew: state => state.totalRowOrganismeThNew,
  getOrganismeThNewErrorDupplication: state =>
    state.OrganismeThNewErrorDupplication
};
const mutations = {
  SET_ORGANISME_TH_NEW(state, payload) {
    state.OrganismeThNew = payload.map(item => OrganismeThNew.create(item));
  },
  ADD_NEW_ORGANISME_TH_NEW(state, payload) {
    state.OrganismeThNew.unshift(OrganismeThNew.create(payload));
  },
  SET_ORGANISME_TH_NEW_LOADING(state, payload) {
    state.OrganismeThNewLoading = payload;
  },

  SET_ORGANISME_TH_NEW_ERROR(state, payload) {
    state.OrganismeThNewError = payload;
  },
  SET_ORGANISME_TH_NEW_ERROR_DUPLICATION(state, payload) {
    state.OrganismeThNewErrorDupplication = payload;
  },
  SET_ORGANISME_TH_NEW_DATA(state, payload) {
    for (let index = 0; index < state.OrganismeThNew.length; index++) {
      if (state.OrganismeThNew[index].id == payload.organisme.id) {
        state.OrganismeThNew[index].update(payload.response);
      }
    }
  },

  DELETE_ORGANISME_TH_NEW(state, payload) {
    for (let index = 0; index < state.OrganismeThNew.length; index++) {
      if (state.OrganismeThNew[index].id == payload.id) {
        state.OrganismeThNew.splice(index, 1);
        break;
      }
    }
  },
  TOTAL_ORGANISME_TH_NEW(state, payload) {
    state.totalRowOrganismeThNew = payload;
  }
};
const actions = {
  async fetchAllOrganismeThNew({ commit }, payload) {
    commit('SET_ORGANISME_TH_NEW_ERROR', null);
    commit('SET_ORGANISME_TH_NEW_LOADING', true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      organisme: payload.organisme,
      group: payload.group,
      date_debut: payload.date_debut,
      date_fin: payload.date_fin
    };

    try {
      if (requestfetchAllOrganismeThNew) {
        requestfetchAllOrganismeThNew.cancel('cancel-request');
      }
      requestfetchAllOrganismeThNew = axios.CancelToken.source();

      const response = await axios.get(domain + '/api/config_obligee/index', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params,
        cancelToken: requestfetchAllOrganismeThNew.token
      });

      commit('SET_ORGANISME_TH_NEW', response.data.data.data);
      commit('TOTAL_ORGANISME_TH_NEW', response.data.data.total);
      commit('SET_ORGANISME_TH_NEW_LOADING', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ORGANISME_TH_NEW_ERROR', error.response.data.error);
      } else if (error.message !== 'cancel-request') {
        commit('SET_ORGANISME_TH_NEW_LOADING', false);
        commit('SET_ORGANISME_TH_NEW_ERROR', 'Une erreur est survenue');
      }
    }
  },
  async addNewOrganismeThNew({ commit }, payload) {
    commit('SET_ORGANISME_TH_NEW_ERROR', null);

    try {
      const response = await axios.post(
        domain + '/api/config_obligee/create',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_ORGANISME_TH_NEW', response.data.data);

      return { succes: true };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return {
          succes: false,
          error: data
        };
      } else {
        return {
          succes: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async EditOrganismeTh({ commit }, payload) {
    commit('SET_ORGANISME_TH_NEW_ERROR', null);
    commit('SET_ORGANISME_TH_NEW_LOADING', true);
    let params = {
      organisme: payload.organisme.id,
      group: payload.group.id,
      coef_prime_installateur: payload.coef_prime_installateur,
      tva_coef_prime_installateur: payload.tva_coef_prime_installateur,
      coef_commercial_prime: payload.coef_commercial_prime,
      tva_coef_commercial_prime: payload.tva_coef_commercial_prime,
      coef_commercial_group: payload.coef_commercial_group,
      tva_coef_commercial_group: payload.tva_coef_commercial_group,
      coef_surprime_group: payload.coef_surprime_group,
      tva_coef_surprime_group: payload.tva_coef_surprime_group,
      date_debut: payload.date_debut,
      date_fin: payload.date_fin,
      label_commercial_prime: payload.label_commercial_prime,
      description__commercial_prime: payload.description__commercial_prime,
      label_commercial_group: payload.label_commercial_group,
      description_commercial_group: payload.description_commercial_group,
      label_surprime_group: payload.label_surprime_group,
      description_surprime_group: payload.description_surprime_group,
      label_missing_prime: payload.label_missing_prime,
      description_missing_prime: payload.description_missing_prime,
      label_missing_prime_av: payload.label_missing_prime_av,
      description_missing_prime_av: payload.description_missing_prime_av,
      id: payload.id
    };

    try {
      const response = await axios.post(
        domain + '/api/config_obligee/update',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_ORGANISME_TH_NEW_DATA', {
        response: response.data.data,
        organisme: payload
      });
      commit('SET_ORGANISME_TH_NEW_LOADING', false);

      return true;
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        commit('SET_ORGANISME_TH_NEW_ERROR', 'Une erreur est survenue');
        commit('SET_ORGANISME_TH_NEW_LOADING', false);
      }
      commit('SET_ORGANISME_TH_NEW_LOADING', false);
    }
  },

  async deletOrganismeThNew({ commit }, payload) {
    commit('SET_ORGANISME_TH_NEW_ERROR', null);
    commit('SET_ORGANISME_TH_NEW_LOADING', true);
    try {
      const response = await axios.get(
        domain + `/api/config_obligee/delete/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_ORGANISME_TH_NEW', payload);
      commit('SET_ORGANISME_TH_NEW_LOADING', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ORGANISME_TH_NEW_ERROR', error.response.data.error);
      } else {
        commit('SET_ORGANISME_TH_NEW_ERROR', 'Une erreur est survenue');
      }
      commit('SET_ORGANISME_TH_NEW_LOADING', false);
    }
  },

  async checkPeriodOrganism({ commit }, payload) {
    commit('SET_ORGANISME_TH_NEW_ERROR_DUPLICATION', null);
    try {
      // if (cancelErrors) {
      //   cancelErrors.cancel('cancel-request');
      // }
      // cancelErrors = axios.CancelToken.source();
      const response = await axios.post(
        `${domain}/api/config_obligee/errors`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
          // cancelToken: cancelErrors.token
        }
      );
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit(
          'SET_ORGANISME_TH_NEW_ERROR_DUPLICATION',
          error?.response?.data?.error
        );
        return { success: false, errorDupplicate: error.response.data.error };
      }
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
