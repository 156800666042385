import axios from 'axios';
import domain from '@/environment';
export default {
  state: {
    mails: [],
    mailsError: null,
    addMailsError: null,
    mailsIsLoading: false,
    errorRemoveMail: null,
    loadingRemoveMail: false,
    updateMailError: null
  },
  getters: {
    mails(state) {
      return state.mails;
    },
    mailsIsLoading(state) {
      return state.mailsIsLoading;
    },
    mailsError(state) {
      return state.mailsError;
    },
    loadingRemoveMail(state) {
      return state.loadingRemoveMail;
    },
    errorRemoveMail(state) {
      return state.errorRemoveMail;
    },
    addMailsError(state) {
      return state.addMailsError;
    },
    updateMailError(state) {
      return state.updateMailError;
    }
  },
  mutations: {
    SET_ALL_MAILS(state, payload) {
      state.mails = payload;
    },
    SET_ADD_MAILS_ERROR(state, payload) {
      state.addMailsError = payload;
    },
    SET_MAILS_IS_LOADING(state, payload) {
      state.mailsIsLoading = payload;
    },
    ADD_NEW_EXTRA_MAIL(state, payload) {
      state.mails.push(payload);
    },
    DELETE_EXTRA_MAILS(state, id) {
      let index = state.mails.findIndex(mail => mail.id == id);
      state.mails.splice(index, 1);
    },
    UPDATE_EXTRA_MAILS(state, payload) {
      state.mails.map(mail => {
        if (mail.id == payload.id) {
          return Object.assign({}, mail, payload);
        }
        return payload;
      });
    },
    SET_MAILS_REMOVE_ERROR(state, payload) {
      state.errorRemoveMail = payload;
    },
    SET_MAILS_ERROR(state, payload) {
      state.mailsError = payload;
    },
    REMOVE_MAILS_IS_LOADING(state, payload) {
      state.loadingRemoveMail = payload;
    },
    SET_UPDATE_MAILS_ERROR(state, payload) {
      state.updateMailError = payload;
    }
  },
  actions: {
    async getEmailsById({ commit }, id) {
      commit('SET_MAILS_IS_LOADING', true);
      try {
        let response = await axios
          .get(`${domain}/api/filiale/extra-mails/${id}`, {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          })
          .then(result => {
            commit('SET_ALL_MAILS', result.data.data);
            commit('SET_MAILS_IS_LOADING', false);
          });
        return true;
      } catch (error) {
        if (error?.response?.data?.errors) {
          commit('SET_MAILS_ERROR', error?.response?.data?.errors?.nom);
        } else {
          commit('SET_MAILS_ERROR', 'Une erreur est survenue');
        }
        commit('SET_MAILS_IS_LOADING', false);
      }
    },
    async createExtraMails({ commit }, payload) {
      commit('SET_ADD_MAILS_ERROR', null);
      try {
        let response = await axios
          .post(`${domain}/api/filiale/create-extra-mails`, payload, {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          })
          .then(result => {
            commit('ADD_NEW_EXTRA_MAIL', result.data.data);
            commit('SET_ADD_MAILS_ERROR', null);
          });
        return true;
      } catch (error) {
        if (error?.response?.data) {
          commit('SET_ADD_MAILS_ERROR', error.response.data.errors.email);
          return false;
        } else {
          commit('SET_ADD_MAILS_ERROR', 'Une erreur est survenue');
          return false;
        }
      }
    },
    async updateExtraMails({ commit }, payload) {
      commit('SET_UPDATE_MAILS_ERROR', null);
      const params = {
        email: payload.email
      };
      try {
        let response = await axios.put(
          `${domain}/api/filiale/update-extra-mails/${payload.id}`,
          params,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('UPDATE_EXTRA_MAILS', response.data.data);
        commit('SET_UPDATE_MAILS_ERROR', null);

        return true;
      } catch (error) {
        if (error?.response?.data) {
          commit('SET_UPDATE_MAILS_ERROR', error.response.data.errors.email);
          return false;
        } else {
          commit('SET_UPDATE_MAILS_ERROR', 'Une erreur est survenue');
          return false;
        }
      }
    },
    async deleteExtraMails({ commit }, id) {
      commit('SET_MAILS_REMOVE_ERROR', null);
      commit('REMOVE_MAILS_IS_LOADING', true);
      try {
        const response = await axios.delete(
          `${domain}/api/filiale/delete-extra-mails/${id}`,
          {
            headers: {
              Authorization: `Bearer ${this.state.User.access_token}`
            }
          }
        );
        commit('REMOVE_MAILS_IS_LOADING', false);
        commit('DELETE_EXTRA_MAILS', id);
        return true;
      } catch (error) {
        if (error && error.response && error.response.data) {
          commit('SET_MAILS_REMOVE_ERROR', error.response.data);
        } else {
          commit('SET_MAILS_REMOVE_ERROR', 'Une erreur est servenue');
        }
        commit('REMOVE_MAILS_IS_LOADING', false);

        return false;
      }
    },
    resetExtraMailSociete({ commit }, payload) {
      commit('SET_ADD_MAILS_ERROR', payload);
      commit('SET_UPDATE_MAILS_ERROR', payload);
    }
  }
};
