export default class OrganismeThNew {
  constructor(data) {
    this.id = data.id;
    this.organisme = data.organisme;
    this.group = data.group;
    this.coef_prime_installateur = data.coef_prime_installateur;
    this.tva_coef_prime_installateur = data.tva_coef_prime_installateur;
    this.coef_commercial_prime = data.coef_commercial_prime;
    this.tva_coef_commercial_prime = data.tva_coef_commercial_prime;
    this.coef_commercial_group = data.coef_commercial_group;
    this.tva_coef_commercial_group = data.tva_coef_commercial_group;
    this.coef_surprime_group = data.coef_surprime_group;
    this.tva_coef_surprime_group = data.tva_coef_surprime_group;
    this.date_debut = data.date_debut;
    this.date_fin = data.date_fin;
    this.label_commercial_prime = data.label_commercial_prime;
    this.description__commercial_prime = data.description__commercial_prime;
    this.label_commercial_group = data.label_commercial_group;
    this.description_commercial_group = data.description_commercial_group;
    this.label_surprime_group = data.label_surprime_group;
    this.description_surprime_group = data.description_surprime_group;
    this.label_missing_prime = data.label_missing_prime;
    this.description_missing_prime = data.description_missing_prime;
    this.label_missing_prime_av = data.label_missing_prime_av;
    this.description_missing_prime_av = data.description_missing_prime_av;
  }
  update(data) {
    this.organisme = data.organisme;
    this.group = data.group;
    this.coef_prime_installateur = data.coef_prime_installateur;
    this.tva_coef_prime_installateur = data.tva_coef_prime_installateur;
    this.coef_commercial_prime = data.coef_commercial_prime;
    this.tva_coef_commercial_prime = data.tva_coef_commercial_prime;
    this.coef_commercial_group = data.coef_commercial_group;
    this.tva_coef_commercial_group = data.tva_coef_commercial_group;
    this.coef_surprime_group = data.coef_surprime_group;
    this.tva_coef_surprime_group = data.tva_coef_surprime_group;
    this.date_debut = data.date_debut;
    this.date_fin = data.date_fin;
    this.label_commercial_prime = data.label_commercial_prime;
    this.description__commercial_prime = data.description__commercial_prime;
    this.label_commercial_group = data.label_commercial_group;
    this.description_commercial_group = data.description_commercial_group;
    this.label_surprime_group = data.label_surprime_group;
    this.description_surprime_group = data.description_surprime_group;
    this.label_missing_prime = data.label_missing_prime;
    this.description_missing_prime = data.description_missing_prime;
    this.label_missing_prime_av = data.label_missing_prime_av;
    this.description_missing_prime_av = data.description_missing_prime_av;
  }
  static create(data) {
    return new OrganismeThNew(data);
  }
}
