import axios from 'axios';
import domain from '@/environment';
import Regie from '../../models/Regie';
const state = {
  regieInfo: null,
  loadingUpdateInfoRegie: false,
  errorInUpdateRegie: null,
  loadingUploadFileRegie: false,
  errorLoadingFile: null,
  regieDoc: null,
  totalRowDocRegie: null,
  chargementDocRegie: false,
  regiechecked: null,
  loadingModelRegie: false,
  allRegieTarif: null,
  totalRowTarifRegie: null,
  loadingTarifRegie: false,
  loadingEditRegieTarif: false,
  errorEditRegieTarif: null,
  loagingRegieTarifs: false,
  errorRegieTarifs: null
};
const getters = {
  getRegieInfo: state => state.regieInfo,
  getLoadingUpdateInfoRegie: state => state.loadingUpdateInfoRegie,
  getErrorInUpdateRegie: state => state.errorInUpdateRegie,
  getLoadingUploadFileRegie: state => state.loadingUploadFileRegie,
  getErrorLoadingFile: state => state.errorLoadingFile,
  getRegieDocFiltred: state => state.regieDoc,
  getTotalRowDocRegie: state => state.totalRowDocRegie,
  getChargementDocRegie: state => state.chargementDocRegie,
  getRegiechecked: state => state.regiechecked,
  getLoadingModelRegie: state => state.loadingModelRegie,
  getAllRegieTarif: state => state.allRegieTarif,
  getTotalRowTarifRegie: state => state.totalRowTarifRegie,
  getLoadingTarifRegie: state => state.loadingTarifRegie,
  getlLoadingEditRegieTarif: state => state.loadingEditRegieTarif,
  getErrorEditRegieTarif: state => state.errorEditRegieTarif,
  getLoagingRegieTarifs: state => state.loagingRegieTarifs,
  getErrorRegieTarifs: state => state.errorRegieTarifs
};
const mutations = {
  CREATE_REGIE_INFO(state, payload) {
    state.regieInfo = Regie.create(payload);
  },
  UPDATE_REGIE_INFO(state, payload) {
    state.regieInfo.update(payload.response);
  },
  UPDATE_REGIE_INFO_FOR_ADMIN(state, payload) {
    payload.item.update(payload.response);
    state.regieDoc.map(item => {
      if (item.id === payload.item.id) {
        item.update(payload.response);
        return;
      }
    });
  },
  SET_LOADING_INFO_REGIE(state, payload) {
    state.loadingUpdateInfoRegie = payload;
  },
  SET_ERROR_EDIT_REGIE(state, payload) {
    state.errorInUpdateRegie = payload;
  },
  SET_LOADING_UPLOAD_FILE_REGIE(state, payload) {
    state.loadingUploadFileRegie = payload;
  },
  ADD_NEW_FILE(state, payload) {
    for (let index in payload.files) {
      if (payload.files[index].length != 0) {
        payload.files[index].map(item => {
          const result = state.regieInfo.files[index].find(
            ({ id }) => id === item.id
          );
          if (result === undefined) {
            state.regieInfo.files[index].push(item);
          }
        });
      }
    }
  },
  SET_ERROR_IN_LOADING_FILE(state, payload) {
    state.errorLoadingFile = payload;
  },
  DLETE_REGIE_FILE(state, payload) {
    const type = payload.file.type;
    for (let index = 0; index < state.regieInfo.files[type].length; index++) {
      if (state.regieInfo.files[type][index].id == payload.file.id) {
        state.regieInfo.files[type].splice(index, 1);
        break;
      }
    }
  },
  DLETE_REGIE_FILE_FOR_ADMIN(state, payload) {
    state.regieDoc.map(item => {
      if (item.id === payload.item.id) {
        const type = payload.file.type;
        for (let index = 0; index < item.files[type].length; index++) {
          if (item.files[type][index].id == payload.file.id) {
            item.files[type].splice(index, 1);
            break;
          }
        }
      }
    });
  },

  CREATE_REGIE_DOC(state, payload) {
    state.regieDoc = payload.map(item => Regie.create(item));
  },
  CREATE_REGIE(state, payload) {
    state.regiechecked = Regie.create(payload);
  },
  SET_LOADING_MODEL_REGIE(state, payload) {
    state.loadingModelRegie = payload;
  },
  SET_TOTAL_ROW_DOC_REGIE(state, payload) {
    state.totalRowDocRegie = payload;
  },
  SET_LOADING_DOC_REGIE(state, payload) {
    state.chargementDocRegie = payload;
  },
  SET_All_REGIE_TARIF(state, payload) {
    state.allRegieTarif = payload.map(item => Regie.create(item));
  },
  SET_TOTAL_ROW_REGIE_TARIF(state, payload) {
    state.totalRowTarifRegie = payload;
  },
  SET_LOADING_TARIF_REGIE(state, payload) {
    state.loadingTarifRegie = payload;
  },
  SET_TARIF_REGIE(state, payload) {
    state.allRegieTarif.map(item => {
      if (item.id === payload.id) {
        item.update(payload);
        return;
      }
    });
  },
  SET_LOADING_EDIT_TARIF_REGIE(state, payload) {
    state.loadingEditRegieTarif = payload;
  },
  SET_ERROR_EDIT_TARIF_REGIE(state, payload) {
    state.errorEditRegieTarif = payload;
  },
  SET_LOADING_NEW_REGIE__TARIF(state, payload) {
    state.loagingRegieTarifs = payload;
  },
  SET_ERROR_NEW_REGIE_TARIF(state, payload) {
    state.errorRegieTarifs = payload;
  },
  SET_TARIFS_REGIE(state, payload) {
    // payload.map(item => {
    //   state.allRegieTarif.push(Regie.create(item));
    // });
    state.allRegieTarif = payload.map(item => Regie.create(item));
  }
};
const actions = {
  async fetchRegieInfo({ commit }) {
    try {
      const response = await axios.get(
        domain + `/api/regieInfo/${this.state.User.regie_id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('CREATE_REGIE_INFO', response.data.regie);
      return true;
    } catch (error) {
      return false;
    }
  },
  async editRegieInfo({ commit }, payload) {
    commit('SET_LOADING_INFO_REGIE', true);
    try {
      const params = {
        regie_id: payload.id,
        name: payload.name,
        phone: payload.phone,
        adress: payload.adress,
        code_postal: payload.code_postal,
        ville: payload.ville,
        email: payload.email
      };
      const responce = await axios.post(domain + `/api/editInfo`, params, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('UPDATE_REGIE_INFO', {
        response: responce.data.regie,
        item: payload
      });
      commit('SET_LOADING_INFO_REGIE', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_EDIT_REGIE', error.response);
      commit('SET_LOADING_INFO_REGIE', false);
      return false;
    }
  },
  async editRegieInfoForAdmin({ commit }, payload) {
    try {
      const params = {
        regie_id: payload.row.id,
        name: payload.row.name,
        phone: payload.row.phone,
        adress: payload.row.adress,
        code_postal: payload.row.code_postal,
        ville: payload.row.ville,
        email: payload.row.email
      };
      params[payload.column] = payload.value;
      const responce = await axios.post(domain + `/api/editInfo`, params, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('UPDATE_REGIE_INFO_FOR_ADMIN', {
        response: responce.data.regie,
        item: payload.row
      });
      commit('SET_LOADING_INFO_REGIE', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_EDIT_REGIE', error.response);
      commit('SET_LOADING_INFO_REGIE', false);
      return false;
    }
  },
  async uploadFilesRegie({ commit }, payload) {
    commit('SET_ERROR_IN_LOADING_FILE', null);
    commit('SET_LOADING_UPLOAD_FILE_REGIE', true);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.state.User.access_token}`
      }
    };
    try {
      const responce = await axios.post(
        domain + `/api/addRegieFiles`,
        payload,
        config
      );
      commit('ADD_NEW_FILE', responce.data.regie);
      commit('SET_LOADING_UPLOAD_FILE_REGIE', false);

      return true;
    } catch (error) {
      commit('SET_ERROR_IN_LOADING_FILE', error.response.data.errors);
      commit('SET_LOADING_UPLOAD_FILE_REGIE', false);

      return false;
    }
  },

  async deleteRegieFile({ commit }, payload) {
    try {
      const responce = await axios.post(
        domain + `/api/deleteRegieFile/${payload.file.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DLETE_REGIE_FILE', payload);
      return true;
    } catch (error) {
      return false;
    }
  },
  async deleteRegieFileInSuperAdmin({ commit }, payload) {
    try {
      const responce = await axios.post(
        domain + `/api/deleteRegieFile/${payload.file.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DLETE_REGIE_FILE_FOR_ADMIN', payload);
      return true;
    } catch (error) {
      return false;
    }
  },
  async getAllRegieDocFiltred({ commit }, payload) {
    commit('SET_LOADING_DOC_REGIE', true);
    const params = { page: payload.page, per_page: payload.per_page };
    if (payload.id && payload.id.id && payload.id.id != null) {
      params.id = payload.id.id;
    }
    try {
      const response = await axios.get(
        domain + `/api/AdminGetAllRegiesInfoFiltred`,
        {
          params: params,
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TOTAL_ROW_DOC_REGIE', response.data.regies.total);
      commit('CREATE_REGIE_DOC', response.data.regies.data);
      commit('SET_LOADING_DOC_REGIE', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_DOC_REGIE', false);
      return false;
    }
  },
  async fetchRegieWithid({ commit }, payload) {
    commit('SET_LOADING_MODEL_REGIE', true);
    const params = { id: payload.id };
    try {
      const response = await axios.get(
        domain + `/api/AdminGetAllRegiesInfoFiltred`,
        {
          params: params,
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('CREATE_REGIE', response.data.regies.data[0]);
      commit('SET_LOADING_MODEL_REGIE', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_MODEL_REGIE', false);
      return false;
    }
  },
  async DownloadingRegieDoc({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/api/AdminDownloadingRegieFile/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          responseType: 'blob'
        }
      );

      const blob = response.config.url;
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = blob;
      a.Bearer = this.state.User.access_token;
      a.click();
      return true;
    } catch (error) {
      return false;
    }
  },
  async fetchAllregieWithCoeff({ commit }, payload) {
    commit('SET_LOADING_TARIF_REGIE', true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      year: payload.year,
      type: payload.type
    };
    if (payload.filterRegie && payload.filterRegie.value !== null) {
      params.id = payload.filterRegie.id;
    }
    if (payload.semaine !== null) {
      params.semaine = payload.semaine;
    }
    try {
      const response = await axios.get(domain + `/api/getAllregieWithCoeff`, {
        params: params,
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_All_REGIE_TARIF', response.data.regie.data);
      commit('SET_TOTAL_ROW_REGIE_TARIF', response.data.regie.total);
      commit('SET_LOADING_TARIF_REGIE', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_TARIF_REGIE', false);

      return false;
    }
  },
  async configeRegieCoeff({ commit }, payload) {
    commit('SET_LOADING_EDIT_TARIF_REGIE', true);
    commit('SET_ERROR_EDIT_TARIF_REGIE', null);
    const params = {
      id: payload.regieTarif.id,
      type: payload.regieTarif.type
    };
    if (payload.regieTarif.type == 'ISO') {
      params.zonz_climatique = payload.regieTarif.zonz_climatique;
      params.type_de_chauffage = payload.regieTarif.type_de_chauffage;
      params.precarite = payload.regieTarif.precarite;
      params.coeff_101_souffle = parseFloat(
        (payload.regieTarif.coeff_101_souffle + '').replace(',', '.')
      );
      params.coeff_101_deroule = parseFloat(
        (payload.regieTarif.coeff_101_deroule + '').replace(',', '.')
      );
      params.coeff_101_rampant = parseFloat(
        (payload.regieTarif.coeff_101_rampant + '').replace(',', '.')
      );
      params.coeff_102_murs = parseFloat(
        (payload.regieTarif.coeff_102_murs + '').replace(',', '.')
      );
      params.coeff_102_murs_iti = parseFloat(
        (payload.regieTarif.coeff_102_murs_iti + '').replace(',', '.')
      );
      params.coeff_102_murs_ite = parseFloat(
        (payload.regieTarif.coeff_102_murs_ite + '').replace(',', '.')
      );
      params.coeff_102_pignons = parseFloat(
        (payload.regieTarif.coeff_102_pignons + '').replace(',', '.')
      );
      params.coeff_103_vide_sanitaire = parseFloat(
        (payload.regieTarif.coeff_103_vide_sanitaire + '').replace(',', '.')
      );
      params.coeff_103_plafonds = parseFloat(
        (payload.regieTarif.coeff_103_plafonds + '').replace(',', '.')
      );
      params.pay_previsite = payload.regieTarif.pay_previsite;
    }
    if (payload.regieTarif.type == 'TH') {
      params.pourcentage = parseFloat(
        (payload.regieTarif.pourcentage + '').replace(',', '.')
      );
    }
    try {
      const responce = await axios.post(
        domain + `/api/editRegieCoeff`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TARIF_REGIE', responce.data.data);
      commit('SET_LOADING_EDIT_TARIF_REGIE', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_EDIT_TARIF_REGIE', false);
      commit('SET_ERROR_EDIT_TARIF_REGIE', error.response);
      return false;
    }
  },
  async makeRegiesTarifs({ commit }, payload) {
    commit('SET_LOADING_NEW_REGIE__TARIF', true);
    commit('SET_ERROR_NEW_REGIE_TARIF', null);
    const params = {
      regie_id: payload.regie_id,
      year: payload.year,
      semaine: payload.semaine,
      pay_previsite: payload.pay_previsite,
      type: payload.type
    };
    if (payload.type == 'ISO') {
      params.salary = payload.salary;
    }
    if (payload.type == 'TH') {
      params.pourcentage = parseFloat(
        (payload.pourcentage + '').replace(',', '.')
      );
    }
    params.regie_id = params.regie_id.map(item => {
      return item.id;
    });
    try {
      const responce = await axios.post(
        domain + `/api/makeRegieCoeff`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TARIFS_REGIE', responce.data.data);
      commit('SET_LOADING_NEW_REGIE__TARIF', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_NEW_REGIE_TARIF', error.response.data.error);
      }

      commit('SET_LOADING_NEW_REGIE__TARIF', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
