export default class AafRegie {
  constructor(data) {
    this.id = data.id;
    this.regie = data.regie;
    this.depots = data.depots.map(item => {
      return { ...item, show: false };
    });
    this.comment = data.comment;
    this.pay_previsite = data.pay_previsite;
    this.statue = data.statue;
    this.payed_at = data.payed_at;
    this.total = data.total;
    this.file = data.file;
    this.month = data.month;
    this.year = data.year;
  }
  update(data) {
    this.comment = data.comment;
    this.pay_previsite = data.pay_previsite;
    this.statue = data.statue;
    this.payed_at = data.payed_at;
    this.total = data.total;
    this.file = data.file;
  }
  static create(data) {
    return new AafRegie(data);
  }
}
