import axios from 'axios';
import domain from '@/environment';
import Depot from '../../models/Depot';
const state = {
  loadingDepotTh: false,
  errorDepotTh: null,
  DepotsTh: []
};
const getters = {
  getDepotsTh: state => state.DepotsTh,
  getLoadingDepotTh: state => state.loadingDepotTh,
  getErrorDepotTh: state => state.errorDepotTh
};
const mutations = {
  SET_LOADING_DEPOT_TH(state, payload) {
    state.loadingDepotTh = payload;
  },
  SET_ERROR_DEPOT_TH(state, payload) {
    state.errorDepotTh = payload;
  },
  NEW_DEPOT_TH(state, payload) {
    state.DepotsTh.push(Depot.create(payload));
  },
  SET_DEPOT_TH(state, payload) {
    state.DepotsTh = payload.map(item => Depot.create(item));
  },
  ADD_DEPARTEMENT_TO_DEPOT(state, payload) {
    payload.depotToUpdate.departement.push(payload.response);
  },
  DELETE_DEPARTEMENT_IN_DEPOT_TH(state, payload) {
    for (let index = 0; index < state.DepotsTh.length; index++) {
      if (state.DepotsTh[index].id == payload.depot.id) {
        for (
          let depot = 0;
          depot < state.DepotsTh[index].departement.length;
          depot++
        ) {
          if (
            state.DepotsTh[index].departement[depot].id ==
            payload.departement.id
          ) {
            state.DepotsTh[index].departement.splice(depot, 1);
          }
        }
      }
      if (state.DepotsTh[index].id == payload.departement.depot_id) {
        state.DepotsTh[index].departement.push(payload.departement);
      }
    }
  },
  UPDATE_DEPARTEMENT_DATA(state, payload) {
    for (
      let index = 0;
      index < payload.depotToUpdate.departement.length;
      index++
    ) {
      if (
        payload.depotToUpdate.departement[index].id == payload.departement_id
      ) {
        payload.depotToUpdate.departement[index] = payload.response;
      }
    }
  },
  UPDATE_DEPOT_DATA_TH(state, payload) {
    payload.depotToUpdate.update(payload.response);
  },
  DELETE_DEPOT_DATA_TH(state, payload) {
    for (let index = 0; index < state.DepotsTh.length; index++) {
      if (state.DepotsTh[index].id == payload.id) {
        state.DepotsTh.splice(index, 1);
      }
    }
  }
};
const actions = {
  async AddDepotTh({ commit }, payload) {
    try {
      payload = 'DÉPÔT ' + payload;
      const response = await axios.post(
        domain + `/api/filiale/create_depot`,
        { name: payload },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('NEW_DEPOT_TH', response.data.data);
      return {
        success: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          success: false,
          error: error.response.data.error
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async fetchDepotsTh({ commit }, busy) {
    commit('SET_ERROR_DEPOT_TH', null);
    let params = {};
    if (busy) {
      params.busy = busy;
    }
    commit('SET_LOADING_DEPOT_TH', true);
    try {
      const response = await axios.get(domain + `/api/filiale/all_depot`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_DEPOT_TH', response.data.data);
      commit('SET_LOADING_DEPOT_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_DEPOT_TH', error.response.data);
      } else {
        commit('SET_ERROR_DEPOT_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_DEPOT_TH', false);

      return false;
    }
  },
  async addDepartementToDepot({ commit }, payload) {
    commit('SET_ERROR_DEPOT_TH', null);

    const params = {
      name: payload.departement.name,
      zone: payload.departement.zone_climatique,
      depot_id: payload.depot.id
    };
    commit('SET_LOADING_DEPOT_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/filiale/create_departement`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_DEPARTEMENT_TO_DEPOT', {
        response: response.data.data,
        depotToUpdate: payload.depot
      });
      commit('SET_LOADING_DEPOT_TH', false);
      return {
        succes: true
      };
    } catch (error) {
      commit('SET_LOADING_DEPOT_TH', false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async removeDepartmentFromDepot({ commit }, payload) {
    commit('SET_ERROR_DEPOT_TH', null);
    const params = {
      id: payload.departement.id,
      name: payload.departement.departement,
      zone: payload.departement.zone,
      depot_id: payload.departement.depot_id
    };
    commit('SET_LOADING_DEPOT_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/filiale/edit_departement`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_DEPARTEMENT_IN_DEPOT_TH', {
        depot: payload.depot,
        departement: response.data.data
      });
      commit('SET_LOADING_DEPOT_TH', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_DEPOT_TH', error.response.data);
      } else {
        commit('SET_ERROR_DEPOT_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_DEPOT_TH', false);

      return false;
    }
  },
  async updateNameDepartementTh({ commit }, payload) {
    commit('SET_ERROR_DEPOT_TH', null);
    const params = {
      depot_id: payload.depot_id,
      id: payload.id,
      name: payload.name,
      zone: payload.zone
    };
    commit('SET_LOADING_DEPOT_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/filiale/edit_departement`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_DEPARTEMENT_DATA', {
        response: response.data.data,
        depotToUpdate: payload.depot,
        departement_id: payload.id,
        departement: payload.departement
      });
      commit('SET_LOADING_DEPOT_TH', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_DEPOT_TH', error.response.data.error);
      } else {
        commit('SET_ERROR_DEPOT_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_DEPOT_TH', false);

      return false;
    }
  },
  async updateNameDepotRTh({ commit }, payload) {
    commit('SET_ERROR_DEPOT_TH', null);
    const params = {
      id: payload.depot.id,
      name: payload.name,
      responsable_depot: payload.depot.responsable_depot,
      filiale_id: payload.depot.filiale_id
    };
    commit('SET_LOADING_DEPOT_TH', true);
    try {
      const response = await axios.post(
        domain + `/api/filiale/edit_depot`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_DEPOT_DATA_TH', {
        response: response.data.data,
        depotToUpdate: payload.depot
      });
      commit('SET_LOADING_DEPOT_TH', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_DEPOT_TH', error.response.data.error);
      } else {
        commit('SET_ERROR_DEPOT_TH', 'Une erreur est survenue');
      }
      commit('SET_LOADING_DEPOT_TH', false);

      return false;
    }
  },

  async deleteDepotTh({ commit }, payload) {
    commit('SET_ERROR_DEPOT_TH', null);

    commit('SET_LOADING_DEPOT_TH', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale/delete_depot/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_DEPOT_DATA_TH', payload);
      commit('SET_LOADING_DEPOT_TH', false);
      return {
        succes: true
      };
    } catch (error) {
      commit('SET_LOADING_DEPOT_TH', false);

      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
