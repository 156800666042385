import axios from 'axios';
import domain from '@/environment';
import SousRegie from '../../models/SousRegie';
const state = {
  allSousRegie: [],
  loadingTarifSousRegie: false,
  allSousRegieTarif: [],
  totalRowTarifSousRegie: null,
  loadingEditSousRegieTarif: false,
  errorEditSousRegieTarif: null,
  loagingSousRegieTarifs: false,
  errorSousRegieTarifs: null
};
const getters = {
  getAllSousRegie: state => state.allSousRegie,
  getLoadingTarifSousRegie: state => state.loadingTarifSousRegie,
  getAllSousRegieTarif: state => state.allSousRegieTarif,
  getTotalRowTarifSousRegie: state => state.totalRowTarifSousRegie,
  getlLoadingEditSousRegieTarif: state => state.loadingEditSousRegieTarif,
  getErrorEditSousRegieTarif: state => state.errorEditSousRegieTarif,
  getLoagingSousRegieTarifs: state => state.loagingSousRegieTarifs,
  getErrorSousRegieTarifs: state => state.errorSousRegieTarifs
};
const mutations = {
  ALL_SOUS_REGIE(state, payload) {
    state.allSousRegie = payload;
  },
  SET_LOADING_TARIF_SOUS_REGIE(state, payload = false) {
    state.loadingTarifSousRegie = payload;
  },
  SET_All_SOUS_REGIE_TARIF(state, payload) {
    state.allSousRegieTarif = payload.map(item => SousRegie.create(item));
  },
  SET_TOTAL_ROW_SOUS_REGIE_TARIF(state, payload) {
    state.totalRowTarifSousRegie = payload;
  },
  SET_LOADING_EDIT_TARIF_SOUS_REGIE(state, payload = false) {
    state.loadingEditSousRegieTarif = payload;
  },
  SET_ERROR_EDIT_TARIF_SOUS_REGIE(state, payload = null) {
    state.errorEditSousRegieTarif = payload;
  },
  SET_TARIF_SOUS_REGIE(state, payload) {
    state.allSousRegieTarif.map(item => {
      if (item.id === payload.id) {
        item.update(payload);
        return;
      }
    });
  },
  SET_LOADING_NEW_SOUS_REGIE__TARIF(state, payload = false) {
    state.loagingSousRegieTarifs = payload;
  },
  SET_ERROR_NEW_SOUS_REGIE_TARIF(state, payload) {
    state.errorSousRegieTarifs = payload;
  },
  SET_TARIFS_SOUS_REGIE(state, payload) {
    payload.map(item => {
      state.allSousRegieTarif.push(SousRegie.create(item));
    });
  }
};
const actions = {
  async fetchAllSousRegieForAdmin({ commit }, payload) {
    try {
      const responce = await axios.get(domain + `/api/getAllCommercialregie`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('ALL_SOUS_REGIE', responce.data.commercial_regie);
      return true;
    } catch (error) {
      return false;
    }
  },
  async fetchAllSousRegieWithCoeff({ commit }, payload) {
    commit('SET_LOADING_TARIF_SOUS_REGIE', true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      year: payload.year
    };
    if (payload.filterSousRegie && payload.filterSousRegie.value !== null) {
      params.id = payload.filterSousRegie.id;
    }
    if (payload.semaine !== null) {
      params.semaine = payload.semaine;
    }
    try {
      const response = await axios.get(
        domain + `/api/getAllCommercialregieWeeklySalary`,
        {
          params: params,
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_All_SOUS_REGIE_TARIF', response.data.regie.data);
      commit('SET_TOTAL_ROW_SOUS_REGIE_TARIF', response.data.regie.total);
      commit('SET_LOADING_TARIF_SOUS_REGIE');

      return true;
    } catch (error) {
      commit('SET_LOADING_TARIF_SOUS_REGIE');
      return false;
    }
  },
  async configeSousRegieCoeff({ commit }, payload) {
    commit('SET_LOADING_EDIT_TARIF_SOUS_REGIE', true);
    commit('SET_ERROR_EDIT_TARIF_SOUS_REGIE');
    const params = {
      id: payload.SousRegieTarif.id,
      zonz_climatique: payload.SousRegieTarif.zonz_climatique,
      type_de_chauffage: payload.SousRegieTarif.type_de_chauffage,
      precarite: payload.SousRegieTarif.precarite,
      coeff_101_souffle: parseFloat(
        (payload.SousRegieTarif.coeff_101_souffle + '').replace(',', '.')
      ),
      coeff_101_deroule: parseFloat(
        (payload.SousRegieTarif.coeff_101_deroule + '').replace(',', '.')
      ),
      coeff_101_rampant: parseFloat(
        (payload.SousRegieTarif.coeff_101_rampant + '').replace(',', '.')
      ),
      coeff_102_murs: parseFloat(
        (payload.SousRegieTarif.coeff_102_murs + '').replace(',', '.')
      ),
      coeff_102_murs_iti: parseFloat(
        (payload.SousRegieTarif.coeff_102_murs_iti + '').replace(',', '.')
      ),
      coeff_102_murs_ite: parseFloat(
        (payload.SousRegieTarif.coeff_102_murs_ite + '').replace(',', '.')
      ),
      coeff_102_pignons: parseFloat(
        (payload.SousRegieTarif.coeff_102_pignons + '').replace(',', '.')
      ),
      coeff_103_vide_sanitaire: parseFloat(
        (payload.SousRegieTarif.coeff_103_vide_sanitaire + '').replace(',', '.')
      ),
      coeff_103_plafonds: parseFloat(
        (payload.SousRegieTarif.coeff_103_plafonds + '').replace(',', '.')
      ),
      pay_previsite: payload.SousRegieTarif.pay_previsite
    };
    try {
      const responce = await axios.post(
        domain + `/api/editCommercialRegieCoeff`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TARIF_SOUS_REGIE', responce.data.data);
      commit('SET_LOADING_EDIT_TARIF_SOUS_REGIE');
      return true;
    } catch (error) {
      commit('SET_LOADING_EDIT_TARIF_SOUS_REGIE');
      commit('SET_ERROR_EDIT_TARIF_SOUS_REGIE', error.response);
      return false;
    }
  },
  async makeSousRegiesTarifs({ commit }, payload) {
    commit('SET_LOADING_NEW_SOUS_REGIE__TARIF', true);
    commit('SET_ERROR_NEW_SOUS_REGIE_TARIF', null);
    const params = {
      user_id: payload.user_id,
      year: payload.year,
      semaine: payload.semaine,
      salary: payload.salary,
      pay_previsite: payload.pay_previsite
    };
    params.user_id = params.user_id.map(item => {
      return item.id;
    });
    try {
      const responce = await axios.post(
        domain + `/api/makeCommercialRegieCoeff`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_TARIFS_SOUS_REGIE', responce.data.data);
      commit('SET_LOADING_NEW_SOUS_REGIE__TARIF');
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_NEW_SOUS_REGIE_TARIF', error.response.data.error);
      }

      commit('SET_LOADING_NEW_SOUS_REGIE__TARIF');
      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
