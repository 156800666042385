export default class FraisResponsableFiliale {
  constructor(data) {
    this.id = data.id;
    this.month = data.month;
    this.year = data.year;
    this.name_holding = data.name_holding;
    this.percent = data.percent;
    this.responsable = data.responsable;
    this.filiale = data.filiale;
  }
  update(data) {
    this.name_holding = data.name_holding;
    this.percent = data.percent;
    this.responsable = data.responsable;
    this.filiale = data.filiale;
  }
  static create(data) {
    return new FraisResponsableFiliale(data);
  }
}
