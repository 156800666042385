export default class Depot {
  constructor(data) {
    this.id = data.id;
    this.departement = data.departement;
    this.depot = data.depot;
    this.responsable_depot = data.responsable_depot;
    this.filiale_id = data.filiale_id;
  }

  /**
   * @param {*} data
   */

  update(data) {
    this.departement = data.departement;
    this.depot = data.depot;
    this.responsable_depot = data.responsable_depot;
    this.filiale_id = data.filiale_id;
  }
  static create(data) {
    if (!data) {
      throw new Error('Depot::create: "data" is undefined');
    }
    return new Depot(data);
  }
}
