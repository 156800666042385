export default class AppelSubObligeTh {
  constructor(data) {
    this.missing_prime_warning = data.missing_prime_warning;
    this.warning = data.warning;
    this.coef_prime_installateur = data.coef_prime_installateur;
    this.numero_dossier = data.numero_dossier;
    this.montant_min_lock = data.montant_min_lock;
    this.surprime_ht = data.surprime_ht;
    this.prime_ht = data.prime_ht;
    this.missing_prime_ht = data.missing_prime_ht;
    this.commission_ht = data.commission_ht;
    this.surprime_ttc = data.surprime_ttc;
    this.prime_ttc = data.prime_ttc;
    this.missing_prime_ttc = data.missing_prime_ttc;
    this.commission_ttc = data.commission_ttc;
    this.surprime_tva = data.surprime_tva;
    this.prime_tva = data.prime_tva;
    this.missing_prime_tva = data.missing_prime_tva;
    this.commission_tva = data.commission_tva;
    this.surprime_pu = data.surprime_pu;
    this.prime_pu = data.prime_pu;
    this.missing_prime_pu = data.missing_prime_pu;
    this.commission_pu = data.commission_pu;
    this.id = data.id;
    this.check = false;
    this.kwh_cumac = data.kwh_cumac;
    this.m_cumac = data.m_cumac;
    this.nom1 = data.nom1;
    this.prenom1 = data.prenom1;
    this.date_depot_dossier = data.date_depot_dossier;
    this.installateur = data.installateur;
    this.total_coef = data.total_coef;
    this.reste_a_charge = data.reste_a_charge;
    this.montant_ttc_avoir = data.montant_ttc_avoir;
    this.montant_total_ttc = data.montant_total_ttc;
    this.montant_prime_cee = data.montant_prime_cee;
    this.organisme = data.organisme;
    this.montant_avoir = data.montant_avoir;
  }
  static create(data) {
    return new AppelSubObligeTh(data);
  }
  update(data) {
    this.commission = data.montant_commissions_cee;
    this.pu_commission = data.commissions_cee;
    this.prime = data.montant_prime_cee;
    this.pu_prime = data.prime_cee;
    this.surprime = data.montant_surprime_cee;
    this.pu_surprime = data.surprime_cee;
    this.missing_prime = data.montant_missing_prime_cee;
    this.pu_missing_prime = data.missing_prime_cee;
    this.error = data.error;
    this.montant_avoir = data.montant_avoir;
  }
}
