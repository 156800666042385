import axios from 'axios';
import domain from '@/environment';
import stateGlobale from '../index';
import fraisBddInterneIhsTh from '../../models/fraisBddInterneIhsTh';
const prefixIHS = 'IHS';

const state = {
  fraisBddInterneIhsData: [],
  loadingFraisBddInterneIhs: false,
  errorFraisBddInterneIhs: null,
  globaleTemplateIhs: null
};
const getters = {
  getFraisBddInterneIhsData: state => state.fraisBddInterneIhsData,
  getLoadingFraisBddInterneIhs: state => state.loadingFraisBddInterneIhs,
  getErrorFraisBddInterneIhs: state => state.errorFraisBddInterneIhs,
  getGlobaleTemplateIhs: state => state.globaleTemplateIhs
};
const mutations = {
  SET_DATA_FRAIS_BDD_INTERNE_IHS(state, payload) {
    state.fraisBddInterneIhsData = payload.map(item =>
      fraisBddInterneIhsTh.create(item)
    );
  },
  SET_LOADING_FRAIS_BDD_INTERNE_IHS(state, payload) {
    state.loadingFraisBddInterneIhs = payload;
  },
  SET_ERROR_FRAIS_BDD_INTERNE_IHS(state, payload) {
    state.errorFraisBddInterneIhs = payload;
  },
  UPDATE_FRAIS_BDD_INTERNE_IHS_STAT(state, payload) {
    payload.row.update(payload.response);
  },
  SET_TEMPLATE_IHS_TO_UPLOAD(state, payload) {
    state.globaleTemplateIhs = payload;
  },
  SET_STATUE_MAIL_CACTUES__VALUE(state, payload) {
    payload.item.update(payload.response);
  },
  SET_DATA_FRAIS_VISION_FILIALE_DETAILS_IHS(state, payload) {
    payload.row.update(payload.response);
  }
};
const actions = {
  async getAllFraisBddInterneIhs({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', null);
    commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', true);
    const params = {
      year: payload.year
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.filiale != null) {
      params.filiale = payload.filiale;
    }
    if (payload.week != null) {
      params.week = payload.week;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }
    if (payload.regie != null) {
      params.regie = payload.regie;
    }
    if (payload.super_regie != null) {
      params.super_regie = payload.super_regie;
    }

    try {
      const response = await axios.get(domain + '/api/' + prefixIHS + '/all', {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_DATA_FRAIS_BDD_INTERNE_IHS', response.data.data);
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', error.response.data.errors);
      } else {
        commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);

      return false;
    }
  },
  async updateFraisBddInterneIhs({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', null);
    commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', true);
    const params = {
      id: payload.row.id,
      ihs_type: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/${prefixIHS}/update_ihs_type`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        row: payload.row
      };
      commit('UPDATE_FRAIS_BDD_INTERNE_IHS_STAT', obj);
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', error.response.data.errors);
      } else {
        commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);

      return false;
    }
  },
  // statut
  async updateFraisBddInterneIhsStatut({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', null);
    commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', true);
    const params = {
      id: payload.row.id_to_use,
      column: 'statue',
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/frais/vision_filiale_update_statue_date`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      payload.row.item[payload.column] = payload.value;
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', error.response.data.errors);
      } else {
        commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);

      return false;
    }
  },
  // statut installateur
  async updateFraisBddInterneIhsStatutInstallateur({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', null);
    commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', true);
    const params = {
      id: payload.row.id_to_use,
      statue_installateur: payload.value,
      vision: stateGlobale.state.currentInterfaceFrais
    };
    try {
      const response = await axios.post(
        domain + `/api/frais/update_statue_installateur`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_STATUE_MAIL_CACTUES__VALUE', {
        item: payload.row.item,
        response: response.data.data
      });
      payload.row.item[payload.column] = payload.value;
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', error.response.data.errors);
      } else {
        commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);

      return false;
    }
  },
  //global validation IHS
  async getGlobalTemplateAndFiledsDataToUpdateForInterneIhs(
    { commit },
    payload
  ) {
    commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', null);
    commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', true);
    const params = {
      type: payload.type
    };

    try {
      const response = await axios.get(domain + `/api/templates/get_one`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_TEMPLATE_IHS_TO_UPLOAD', response.data.data);

      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', error.response.data.error);
      } else {
        commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);

      return false;
    }
  },
  async uploadGlobalTemplateForInterneIhs({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/filiale_template_generator/upload_all`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );

      return {
        succes: true,
        message: response.data.data
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async exportPdfFraisVisionInterneIhsTh({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', null);
    commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', true);
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.post(
        domain + `/api/filiale_template_generator/download_all`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const month_name =
        payload.month && payload.month != null ? '-' + payload.month : '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'VISION COMMERCIAL IHS.zip');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', ['Une erreur est servenue']);
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);
      return false;
    }
  },
  async exportPdfInvalidFraisVisionInterneIhsTh({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', null);
    commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', true);
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    try {
      const response = await axios.post(
        domain + `/api/download_pdfNonValide`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const month_name =
        payload.month && payload.month != null ? '-' + payload.month : '';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'VISION COMMERCIAL IHS INVALID.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', ['Une erreur est servenue']);
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);
      return false;
    }
  },
  async exportExcelFraisVisionCommercialInterneIhsTh({ commit }, payload) {
    commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', null);
    commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', true);
    try {
      const response = await axios.post(
        domain + `/api/${prefixIHS}/export`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute(
        'download',
        'VISION COMMERCIAL IHS  ' +
          payload.get('type').toUpperCase() +
          ' - ' +
          payload.get('year') +
          '.xlsx'
      );
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', ['Une erreur est servenue']);
      commit('SET_LOADING_FRAIS_BDD_INTERNE_IHS', false);
      return false;
    }
  },
  async getDataForRowFraisIHS({ commit }, payload) {
    payload.item.loadingDetailt = true;

    const params = {
      year: payload.year,
      filiale: payload.item.name
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }

    if (payload.week != null) {
      params.week = payload.week;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.type != null) {
      params.type = payload.type;
    }
    if (payload.statue != null) {
      params.statue = payload.statue;
    }
    if (payload.regie != null) {
      params.regie = payload.regie;
    }
    if (payload.super_regie != null) {
      params.super_regie = payload.super_regie;
    }

    try {
      const response = await axios.get(
        domain + '/api/' + prefixIHS + '/all_projects',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_DATA_FRAIS_VISION_FILIALE_DETAILS_IHS', {
        response: response.data,
        row: payload.item
      });
      payload.item.loadingDetailt = false;

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', error.response.data.errors);
      } else {
        commit('SET_ERROR_FRAIS_BDD_INTERNE_IHS', ['Une erreur est servenue']);
      }
      payload.item.loadingDetailt = false;

      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
