export default class FraisBddTh {
  constructor(data) {
    this.id = data.id;
    this.numero_dossier = data.numero_dossier;
    this.nom_prenom = data.nom_prenom;
    this.confirme_frais = data.confirme_frais;
    this.regie = data.regie;
    this.filiale = data.filiale;
    this.montant_final = data.montant_final;
    //
    this.operationnel_statue = data.operationnel_statue;
    this.operationnel = data.operationnel;
    this.operationnel_id = data.operationnel_id;
    this.operationnel_montant = data.operationnel_montant;
    //
    this.marketing_statue = data.marketing_statue;
    this.marketing = data.marketing;
    this.marketing_id = data.marketing_id;
    this.marketing_montant = data.marketing_montant;
    //
    this.technique_statue = data.technique_statue;
    this.technique = data.technique;
    this.technique_id = data.technique_id;
    this.technique_montant = data.technique_montant;
    //
    this.rge_statue = data.rge_statue;
    this.rge = data.rge;
    this.rge_id = data.rge_id;
    this.rge_montant = data.rge_montant;
    //
    this.CDG_statue = data.CDG_statue;
    this.CDG = data.CDG;
    this.CDG_id = data.CDG_id;
    this.CDG_montant = data.CDG_montant;
    //
    this.administratif_sav_statue = data.administratif_sav_statue;
    this.administratif_sav = data.administratif_sav;
    this.administratif_sav_id = data.administratif_sav_id;
    this.administratif_sav_montant = data.administratif_sav_montant;
    //
    this.juridique_statue = data.juridique_statue;
    this.juridique = data.juridique;
    this.juridique_id = data.juridique_id;
    this.juridique_montant = data.juridique_montant;
    //
    this.alger_statue = data.alger_statue;
    this.alger = data.alger;
    this.alger_id = data.alger_id;
    this.alger_montant = data.alger_montant;
    //
    this.mairie = data.mairie;
    this.mairie_statue = data.mairie_statue;
    this.mairie_id = data.mairie_id;
    this.mairie_montant = data.mairie_montant;
    //
    this.maintenance_pac = data.maintenance_pac;
    this.maintenance_pac_id = data.maintenance_pac_id;
    this.maintenance_pac_statue = data.maintenance_pac_statue;
    this.maintenance_pac_montant = data.maintenance_pac_montant;
    //wa
    this.dsi_statue = data.dsi_statue;
    this.dsi = data.dsi;
    this.dsi_id = data.dsi_id;
    this.dsi_montant = data.dsi_montant;
    //upcube
    this.upcube = data.upcube;
    this.upcube_id = data.upcube_id;
    this.upcube_statue = data.upcube_statue;
    this.upcube_montant = data.upcube_montant;

    //interne
    this.interne_value = data.interne_value;
    this.interne_value_id = data.interne_value_id;
    this.interne_value_statue = data.interne_value_statue;
    this.interne_value_montant = data.interne_value_montant;
    //interne
    this.externe_value = data.externe_value;
    this.externe_value_id = data.externe_value_id;
    this.externe_value_statue = data.externe_value_statue;
    this.externe_value_montant = data.externe_value_montant;
    //commercail
    this.commercial_value = data.commercial_value;
    this.commercial_value_id = data.commercial_value_id;
    this.commercial_value_statue = data.commercial_value_statue;
    this.commercial_value_montant = data.commercial_value_montant;
    //alteo
    this.alteo_value = data.alteo_value;
    this.alteo_value_id = data.alteo_value_id;
    this.alteo_value_statue = data.alteo_value_statue;
    this.alteo_value_montant = data.alteo_value_montant;
    //cactus
    this.cactus = data.cactus;
    this.commercial = data.commercial;
    this.commercial_id = data.commercial_id;
    this.commercial_statue = data.commercial_statue;
    this.commercial_montant = data.commercial_montant;
    this.acompte = data.acompte;
    this.super_regie = data.super_regie;
    //be be
    this.BE_TYPE = data.BE_TYPE;
    this.binifis_alteo = data.binifis_alteo;
    this.binifis_alteo_id = data.binifis_alteo_id;
    this.binifis_alteo_statue = data.binifis_alteo_statue;
    this.binifis_alteo_montant = data.binifis_alteo_montant;
    //
    this.binifis_kynan = data.binifis_kynan;
    this.binifis_kynan_id = data.binifis_kynan_id;
    this.binifis_kynan_statue = data.binifis_kynan_statue;
    this.binifis_kynan_montant = data.binifis_kynan_montant;
    //
    this.bureau_detude = data.bureau_detude;
    this.bureau_detude_id = data.bureau_detude_id;
    this.bureau_detude_statue = data.bureau_detude_statue;
    this.bureau_detude_montant = data.bureau_detude_montant;
    //ihs
    this.ihs_type = data.ihs_type;
    this.ihs = data.ihs;
    this.ihs_id = data.ihs_id;
    this.ihs_statue = data.ihs_statue;
    this.ihs_montant = data.ihs_montant;
    //
    this.alteo_control_gestion_statue = data.alteo_control_gestion_statue;
    this.alteo_control_gestion = data.alteo_control_gestion;
    this.alteo_control_gestion_id = data.alteo_control_gestion_id;
    this.alteo_control_gestion_montant = data.alteo_control_gestion_montant;
    this.check = false;
  }

  update(data) {
    this.confirme_frais = data.confirme_frais;
    //
    this.operationnel_statue = data.operationnel_statue;
    this.operationnel = data.operationnel;
    this.operationnel_id = data.operationnel_id;
    this.operationnel_montant = data.operationnel_montant;
    //
    this.marketing_statue = data.marketing_statue;
    this.marketing = data.marketing;
    this.marketing_id = data.marketing_id;
    this.marketing_montant = data.marketing_montant;
    //
    this.technique_statue = data.technique_statue;
    this.technique = data.technique;
    this.technique_id = data.technique_id;
    this.technique_montant = data.technique_montant;
    //
    this.rge_statue = data.rge_statue;
    this.rge = data.rge;
    this.rge_id = data.rge_id;
    this.rge_montant = data.rge_montant;
    //
    this.CDG_statue = data.CDG_statue;
    this.CDG = data.CDG;
    this.CDG_id = data.CDG_id;
    this.CDG_montant = data.CDG_montant;
    //
    this.administratif_sav_statue = data.administratif_sav_statue;
    this.administratif_sav = data.administratif_sav;
    this.administratif_sav_id = data.administratif_sav_id;
    this.administratif_sav_montant = data.administratif_sav_montant;
    //
    this.juridique_statue = data.juridique_statue;
    this.juridique = data.juridique;
    this.juridique_id = data.juridique_id;
    this.juridique_montant = data.juridique_montant;
    //
    this.alger_statue = data.alger_statue;
    this.alger = data.alger;
    this.alger_id = data.alger_id;
    this.alger_montant = data.alger_montant;
    //
    this.mairie = data.mairie;
    this.mairie_statue = data.mairie_statue;
    this.mairie_id = data.mairie_id;
    this.mairie_montant = data.mairie_montant;
    //
    this.maintenance_pac = data.maintenance_pac;
    this.maintenance_pac_id = data.maintenance_pac_id;
    this.maintenance_pac_statue = data.maintenance_pac_statue;
    this.maintenance_pac_montant = data.maintenance_pac_montant;
    //wa
    this.dsi_statue = data.dsi_statue;
    this.dsi = data.dsi;
    this.dsi_id = data.dsi_id;
    this.dsi_montant = data.dsi_montant;
    //upcube
    this.upcube = data.upcube;
    this.upcube_id = data.upcube_id;
    this.upcube_statue = data.upcube_statue;
    this.upcube_montant = data.upcube_montant;

    //interne
    this.interne_value = data.interne_value;
    this.interne_value_id = data.interne_value_id;
    this.interne_value_statue = data.interne_value_statue;
    this.interne_value_montant = data.interne_value_montant;
    //interne
    this.externe_value = data.externe_value;
    this.externe_value_id = data.externe_value_id;
    this.externe_value_statue = data.externe_value_statue;
    this.externe_value_montant = data.externe_value_montant;
    //commercail
    this.commercial_value = data.commercial_value;
    this.commercial_value_id = data.commercial_value_id;
    this.commercial_value_statue = data.commercial_value_statue;
    this.commercial_value_montant = data.commercial_value_montant;
    //alteo
    this.alteo_value = data.alteo_value;
    this.alteo_value_id = data.alteo_value_id;
    this.alteo_value_statue = data.alteo_value_statue;
    this.alteo_value_montant = data.alteo_value_montant;
    //cactus
    this.cactus = data.cactus;
    this.commercial = data.commercial;
    this.commercial_id = data.commercial_id;
    this.commercial_statue = data.commercial_statue;
    this.commercial_montant = data.commercial_montant;
    this.acompte = data.acompte;
    this.super_regie = data.super_regie;
    //be be
    this.BE_TYPE = data.BE_TYPE;
    this.binifis_alteo = data.binifis_alteo;
    this.binifis_alteo_id = data.binifis_alteo_id;
    this.binifis_alteo_statue = data.binifis_alteo_statue;
    this.binifis_alteo_montant = data.binifis_alteo_montant;
    //
    this.binifis_kynan = data.binifis_kynan;
    this.binifis_kynan_id = data.binifis_kynan_id;
    this.binifis_kynan_statue = data.binifis_kynan_statue;
    this.binifis_kynan_montant = data.binifis_kynan_montant;
    //
    this.bureau_detude = data.bureau_detude;
    this.bureau_detude_id = data.bureau_detude_id;
    this.bureau_detude_statue = data.bureau_detude_statue;
    this.bureau_detude_montant = data.bureau_detude_montant;
    //ihs
    this.ihs_type = data.ihs_type;
    this.ihs = data.ihs;
    this.ihs_id = data.ihs_id;
    this.ihs_statue = data.ihs_statue;
    this.ihs_montant = data.ihs_montant;
    //alteo controle gestion
    this.alteo_control_gestion_statue = data.alteo_control_gestion_statue;
    this.alteo_control_gestion = data.alteo_control_gestion;
    this.alteo_control_gestion_id = data.alteo_control_gestion_id;
    this.alteo_control_gestion_montant = data.alteo_control_gestion_montant;
  }
  updateCoef(data) {
    this[data.payload.column] = data.payload.value;
    this[data.payload.column + '_montant'] = data.response;
  }
  static create(data) {
    return new FraisBddTh(data);
  }
}
