import axios from 'axios';
import organismeTh from '../../models/OrganismeTh';
import domain from '@/environment';
let requestfetchAllOrganismeTh = null;
const state = {
  organismeTh: [],
  organismeThLoading: false,
  organismeThError: null,
  organismeThDetailsLoading: false
};
const getters = {
  getOrganismeTh: state => state.organismeTh,
  getOrganismeThLoading: state => state.organismeThLoading,
  getOrganismeThError: state => state.organismeThError,
  getOrganismeThDetailsLoading: state => state.organismeThDetailsLoading
};
const mutations = {
  SET_ORGANISME_TH(state, payload) {
    state.organismeTh = payload.map(item => organismeTh.create(item));
  },
  SET_ORGANISME_TH_DETAILS(state, payload) {
    payload.organisme.updateDepot(payload.response);
  },
  ADD_NEW_ORGANISME_TH(state, payload) {
    state.organismeTh.push(organismeTh.create(payload));
  },
  SET_ORGANISME_TH_LOADING(state, payload) {
    state.organismeThLoading = payload;
  },
  SET_ORGANISME_TH_DETAILS_LOADING(state, payload) {
    state.organismeThDetailsLoading = payload;
  },
  SET_ORGANISME_TH_ERROR(state, payload) {
    state.organismeThError = payload;
  },
  SET_ORGANISME_TH_NAME(state, payload) {
    for (let index = 0; index < state.organismeTh.length; index++) {
      if (state.organismeTh[index].id == payload.organisme.id) {
        state.organismeTh[index].name = payload.response.name;
      }
    }
  },
  SET_ORGANISME_TH_VALUE(state, payload) {
    payload.row.update(payload.response);
  },
  DELETE_ORGANISME_TH(state, payload) {
    for (let index = 0; index < state.organismeTh.length; index++) {
      if (state.organismeTh[index].id == payload.id) {
        state.organismeTh.splice(index, 1);
        break;
      }
    }
  }
};
const actions = {
  async fetchAllOrganismeTh({ commit }, payload) {
    commit('SET_ORGANISME_TH_ERROR', null);
    commit('SET_ORGANISME_TH_LOADING', true);
    try {
      if (requestfetchAllOrganismeTh) {
        requestfetchAllOrganismeTh.cancel('cancel-request');
      }
      requestfetchAllOrganismeTh = axios.CancelToken.source();

      const response = await axios.get(
        domain + '/api/organismeTH/all_organisme',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload,
          cancelToken: requestfetchAllOrganismeTh.token
        }
      );
      commit('SET_ORGANISME_TH', response.data.data);
      commit('SET_ORGANISME_TH_LOADING', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ORGANISME_TH_ERROR', error.response.data.error);
      } else if (error.message !== 'cancel-request') {
        commit('SET_ORGANISME_TH_LOADING', false);
        commit('SET_ORGANISME_TH_ERROR', 'Une erreur est survenue');
      }
    }
  },
  async addNewOrganismeTh({ commit }, payload) {
    commit('SET_ORGANISME_TH_ERROR', null);
    try {
      const response = await axios.post(
        domain + '/api/organismeTH/create_organisme',
        { name: payload },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('ADD_NEW_ORGANISME_TH', response.data.data);

      return { succes: true };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async EditOrganismeNameTh({ commit }, payload) {
    commit('SET_ORGANISME_TH_ERROR', null);
    commit('SET_ORGANISME_TH_LOADING', true);
    // let params = payload.organisme;
    // params.name = payload.name;
    try {
      const response = await axios.post(
        domain + '/api/organismeTH/edit_organisme',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_ORGANISME_TH_NAME', {
        response: response.data.data,
        organisme: payload
      });
      commit('SET_ORGANISME_TH_LOADING', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ORGANISME_TH_ERROR', error.response.data.error);
      } else {
        commit('SET_ORGANISME_TH_ERROR', 'Une erreur est survenue');
      }
      commit('SET_ORGANISME_TH_LOADING', false);
    }
  },
  async EditVariableDepotOrganismeTh({ commit }, payload) {
    commit('SET_ORGANISME_TH_ERROR', null);
    commit('SET_ORGANISME_TH_LOADING', true);
    let params = {
      id: payload.row.id,
      column: payload.column,
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + '/api/organismeTH/edit_organismeDepotVariable',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_ORGANISME_TH_VALUE', {
        response: response.data.data,
        row: payload.row
      });
      commit('SET_ORGANISME_TH_LOADING', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ORGANISME_TH_ERROR', error.response.data.error);
      } else {
        commit('SET_ORGANISME_TH_ERROR', 'Une erreur est survenue');
      }
      commit('SET_ORGANISME_TH_LOADING', false);
    }
  },
  async deletOrganismeTh({ commit }, payload) {
    commit('SET_ORGANISME_TH_ERROR', null);
    commit('SET_ORGANISME_TH_LOADING', true);
    try {
      const response = await axios.get(
        domain + `/api/organismeTH/delete/${payload.id}`,

        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_ORGANISME_TH', payload);
      commit('SET_ORGANISME_TH_LOADING', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ORGANISME_TH_ERROR', error.response.data.error);
      } else {
        commit('SET_ORGANISME_TH_ERROR', 'Une erreur est survenue');
      }
      commit('SET_ORGANISME_TH_LOADING', false);
    }
  },
  async fetchOrganismeThDetails({ commit }, payload) {
    let params = {
      year: payload.year,
      month: payload.month,
      id: payload.organisme.id
    };
    commit('SET_ORGANISME_TH_ERROR', null);
    commit('SET_ORGANISME_TH_DETAILS_LOADING', true);
    try {
      const response = await axios.get(
        domain + '/api/organismeTH/all_organisme',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_ORGANISME_TH_DETAILS', {
        organisme: payload.organisme,
        response: response.data.data
      });
      commit('SET_ORGANISME_TH_DETAILS_LOADING', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ORGANISME_TH_ERROR', error.response.data.error);
      } else {
        commit('SET_ORGANISME_TH_ERROR', 'Une erreur est survenue');
      }
      commit('SET_ORGANISME_TH_LOADING', false);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
