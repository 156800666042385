import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import { logToConsole } from 'pusher-js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/login.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.state.User) {
        next();
      } else {
        next('/404');
      }
    }
  },
  {
    path: '/',
    name: '',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.User) {
        next();
      } else {
        next('/login');
      }
    },
    children: [
      {
        path: '',
        name: 'index',
        component: () =>
          import(/* webpackChunkName: "index" */ '../views/index.vue'),
        beforeEnter: (to, from, next) => {
          if (store.state.User) {
            next();
          } else {
            next('/login');
          }
        },
        children: [
          {
            path: '',
            name: 'statistics-general',
            component: () =>
              import(
                /* webpackChunkName: "statistics-general" */ '../views/StatisticsGeneral.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() || permission('StatisticsGeneral')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/404',
            name: '404',
            component: () =>
              import(
                /* webpackChunkName: "statistics-general" */ '../views/NotFound.vue'
              )
          },
          {
            path: '/insertion-bdd',
            name: 'analyse',
            component: () =>
              import(
                /* webpackChunkName: "insertion-bdd" */ '../views/UploadFile.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() || permission('Insertion BDD')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/add-facture',
            component: () =>
              import(
                /* webpackChunkName: "add-facture-libre" */ '../views/factureLibre/AddFactureLibre.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() || permission('Facture libre')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/update-facture/:id',
            // name: "update-facture",
            component: () =>
              import(
                /* webpackChunkName: "update-facture-libre" */ '../views/factureLibre/UpadateFacureLibre.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() || permission('Facture libre')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/duplicate-facture/:id',
            // name: "update-facture",
            component: () =>
              import(
                /* webpackChunkName: "duplicate-facture-libre" */ '../views/factureLibre/DuplicateFacture.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() || permission('Facture libre')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/display-facture/:id',
            // name: "update-facture",
            component: () =>
              import(
                /* webpackChunkName: "update-facture-libre" */ '../views/factureLibre/FactureInterface.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() || permission('Facture libre')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/rapport',
            name: 'rapport',
            component: () =>
              import(/* webpackChunkName: "rapport" */ '../views/Rapport.vue'),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Analyse et validation')) &&
                isIsoProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'employes',
            name: 'employes',
            component: () =>
              import(
                /* webpackChunkName: "employes" */ '../views/Employe/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() ||
                  permission('Validation salaires') ||
                  permission('Validation indépendants') ||
                  permission('Suivi indépendants') ||
                  permission('Récapitulatif commerciaux par zone')) &&
                isIsoProject()
              ) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'validation-salaire-admin',
                name: 'validationSalaireAdmin',
                component: () =>
                  import(
                    /* webpackChunkName: "validationSalaireAdmin" */ '../views/setting/ValidationSalaire.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin() || permission('Validation salaires')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'validation-salaire-commerciaux',
                name: 'validationSalaireCommerciaux',
                component: () =>
                  import(
                    /* webpackChunkName: "validationSalaireCommerciaux" */ '../views/Employe/ValidationCommerciaux.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin() || permission('Validation indépendants')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'suivi-independant',
                name: 'SuiviIndependant',
                component: () =>
                  import(
                    /* webpackChunkName: "SuiviIndependant" */ '../views/Employe/SuiviIndependant.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin() || permission('Suivi indépendants')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'recap-commerciaux',
                name: 'RecapCommerciaux',
                component: () =>
                  import(
                    /* webpackChunkName: "RecapCommerciaux" */ '../views/Employe/recapCommerciaux.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    isSuperAdmin() ||
                    permission('Récapitulatif commerciaux par zone')
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'regies',
            name: 'regies',
            component: () =>
              import(
                /* webpackChunkName: "regies" */ '../views/Regie/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() ||
                  permission('Gestion des appels à facturation régie') ||
                  permission('Liste des documents') ||
                  permission('Coût des régies') ||
                  permission('Suivi des régies') ||
                  permission('Récapitulatif régie par zone')) &&
                isIsoProject()
              ) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'gestion-facture',
                name: 'GestionFacture',
                component: () =>
                  import(
                    /* webpackChunkName: "GestionFacture" */ '../views/setting/gestionFactureAdmin.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    isSuperAdmin() ||
                    permission('Gestion des appels à facturation régie')
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'all-document-regie',
                name: 'DocumentRegies',
                component: () =>
                  import(
                    /* webpackChunkName: "DocumentRegies" */ '../views/Regie/ListDocRegie.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin() || permission('Liste des documents')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'validation-cout',
                name: 'validation-cout',
                component: () =>
                  import(
                    /* webpackChunkName: "validation-cout" */ '../views/Regie/validationCoutRegie.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin() || permission('Coût des régies')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'suivi-regie',
                name: 'SuiviRegie',
                component: () =>
                  import(
                    /* webpackChunkName: "SuiviRegie" */ '../views/Regie/suiviRegie.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin() || permission('Suivi des régies')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'recap-regie',
                name: 'RecapRegie',
                component: () =>
                  import(
                    /* webpackChunkName: "RecapRegie" */ '../views/Regie/recapRegie.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    isSuperAdmin() ||
                    permission('Récapitulatif régie par zone')
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'regies-th',
            name: 'regies-th',
            component: () =>
              import(
                /* webpackChunkName: "regies-th" */ '../views/ThProject/Regie/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() ||
                  permission('Gestion des AAF régie Th') ||
                  permission('Suivi des régies Th') ||
                  permission('Récapitulatif régie par filiale Th')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'gestion-aaf',
                name: 'gestionAAF',
                component: () =>
                  import(
                    /* webpackChunkName: "gestionAAF" */ '../views/ThProject/Regie/gestionAAF.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    isSuperAdmin() ||
                    permission('Gestion des AAF régie Th')
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },

              {
                path: 'suivi-regie-th',
                name: 'SuiviRegieTh',
                component: () =>
                  import(
                    /* webpackChunkName: "SuiviRegieTh" */ '../views/ThProject/Regie/suiviRegie.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin() || permission('Suivi des régies Th')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'recap-regie-th',
                name: 'RecapRegieTh',
                component: () =>
                  import(
                    /* webpackChunkName: "RecapRegieTh" */ '../views/ThProject/Regie/recapRegie.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    isSuperAdmin() ||
                    permission('Récapitulatif régie par filiale Th')
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'validation-salaire',
            name: 'validationSalaire',
            component: () =>
              import(
                /* webpackChunkName: "validationSalaire" */ '../views/setting/ValidationSalaire.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                isResponsableCommercial() ||
                isResponsableDepot() ||
                isResponsablePoseurs() ||
                isSuperAdmin()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-facture-regie',
            name: 'GestionFactureRegie',
            component: () =>
              import(
                /* webpackChunkName: "GestionFactureRegie" */ '../views/Regie/gestionFactureRegie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isRegie() && isIsoProject()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-aaf-regie',
            name: 'gestionAAFRegie',
            component: () =>
              import(
                /* webpackChunkName: "gestionAAFRegie" */ '../views/ThProject/Regie/gestionAAF.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isRegie() && isThProject()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-facture-sous-regie',
            name: 'GestionFactureSousRegie',
            component: () =>
              import(
                /* webpackChunkName: "GestionFactureSousRegie" */ '../views/Regie/gestionFactureSousRegie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isRegie() && isIsoProject()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-facture-commercial-regie',
            name: 'GestionFactureCommercialRegie',
            component: () =>
              import(
                /* webpackChunkName: "GestionFactureCommercialRegie" */ '../views/Regie/gestionFactureComercialRegie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isCommercialRegie()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'all-notification',
            name: 'Notification',
            component: () =>
              import(
                /* webpackChunkName: "Notification" */ '../views/Notification.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isRegie() || isSuperAdmin() || isResponsableDepot()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'statistique',
            name: 'statistique',
            component: () =>
              import(
                /* webpackChunkName: "statistique" */ '../views/Statistique/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() || isRegie() || permission('Statistique')) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'employeur',
                name: 'employeur',
                component: () =>
                  import(
                    /* webpackChunkName: "employeur" */ '../views/Statistique/StatistiqueEmploye.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin() || permission('Statistique')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'regie',
                name: 'regie',
                component: () =>
                  import(
                    /* webpackChunkName: "regie" */ '../views/Statistique/StatistiqueRegie.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin() || permission('Statistique')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'sousregie',
                name: 'sousregie',
                component: () =>
                  import(
                    /* webpackChunkName: "sousregie" */ '../views/Statistique/StatistiqueSousRegie.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isRegie()) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: '/rapport-poseur',
            name: 'rapport-poseur',
            component: () =>
              import(
                /* webpackChunkName: "rapport-poseur" */ '../views/RapportPoseur.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/rapportTh',
            name: 'rapportTh',
            component: () =>
              import(
                /* webpackChunkName: "rapportTh" */ '../views/ThProject/RapportTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Analyse et validation TH')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'facture-libre',
            component: () =>
              import(
                /* webpackChunkName: "factureLibre-index" */ '../views/factureLibre/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() || permission('Facture libre')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: '/gestionFactureCommerciaux',
            name: 'gestionFactureCommerciaux',
            component: () =>
              import(
                /* webpackChunkName: "gestionFactureCommerciaux" */ '../views/Employe/gestionFactureCommerciaux.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isCommerciaux()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'obligee',
            name: 'obligee',
            component: () =>
              import(
                /* webpackChunkName: "obligee" */ '../views/ThProject/oblige/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() ||
                  permission('Suivi des paiement Obligé') ||
                  permission('Gestions des appels à paiement Obligé')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'suivi-paiement-obligee',
                name: 'suiviPaiementObligee',
                component: () =>
                  import(
                    /* webpackChunkName: "suiviPaiementObligee" */ '../views/ThProject/oblige/suiviPaiementObligee.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    isSuperAdmin() ||
                    permission('Suivi des paiement Obligé')
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'gestion-appels-paiement-obligee',
                name: 'gestionAppelPaiementObligee',
                component: () =>
                  import(
                    /* webpackChunkName: "gestionAppelPaiementObligee" */ '../views/ThProject/oblige/gestionAppelPaiementObligee.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    isSuperAdmin() ||
                    permission('Gestions des appels à paiement Obligé')
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'commerciaux',
            name: 'commerciaux',
            component: () =>
              import(
                /* webpackChunkName: "commerciaux" */ '../views/ThProject/commerciaux/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() ||
                  permission('Suivi des acomptes') ||
                  permission('Validation de paie commerciaux Th') ||
                  permission('Validation des AAF commerciaux') ||
                  permission('Suivi de paiement des Indépendants Th') ||
                  permission('Récapitulatif Commemciaux par filiale Th') ||
                  isCommerciaux()) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'suivi-acomptes',
                name: 'suiviAcomptes',
                component: () =>
                  import(
                    /* webpackChunkName: "suiviAcomptes" */ '../views/ThProject/commerciaux/suiviAcomptes.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin() || permission('Suivi des acomptes')) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'validation-paie',
                name: 'validation-paie-th',
                component: () =>
                  import(
                    /* webpackChunkName: "validationPaieTh" */ '../views/ThProject/commerciaux/ValidationSalaire.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    isSuperAdmin() ||
                    permission('Validation de paie commerciaux Th') ||
                    isCommerciaux()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'validation-aff',
                name: 'validationAaf',
                component: () =>
                  import(
                    /* webpackChunkName: "validationAaf" */ '../views/ThProject/commerciaux/ValidationAaf.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    isSuperAdmin() ||
                    permission('Validation des AAF commerciaux') ||
                    isCommerciaux()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'suivi-paiement-independant',
                name: 'suiviPaiementIndependant',
                component: () =>
                  import(
                    /* webpackChunkName: "suiviPaiementIndependant" */ '../views/ThProject/commerciaux/suiviIndependant.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    isSuperAdmin() ||
                    permission('Suivi de paiement des Indépendants Th') ||
                    isCommerciaux()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'recap-commerciaux-par-filiale',
                name: 'recapCommerciauxParFiliale',
                component: () =>
                  import(
                    /* webpackChunkName: "recapCommerciauxParFiliale" */ '../views/ThProject/commerciaux/recapCommerciaux.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (
                    isSuperAdmin() ||
                    permission('Récapitulatif Commemciaux par filiale Th') ||
                    isCommerciaux()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'frais-th',
            name: 'fraisTh',
            component: () =>
              import(
                /*WebpackChunName: "fraisTh" */ '../views/ThProject/frais/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() ||
                  permission('Frais BDD') ||
                  permission('Récapitulatif gestion des frais') ||
                  permission('Gestion Commercial Cactus') ||
                  permission('Gestion Bureau Etude') ||
                  permission('Gestion Compta') ||
                  permission('Gestion RH') ||
                  permission('Gestion informatique web abondance') ||
                  permission('Gestion Invest')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: 'bdd',
                name: 'bdd',
                component: () =>
                  import(
                    /*WebpackChunName: "bdd" */ '../views/ThProject/frais/bdd.vue'
                  ),
                beforEnter(to, from, next) {
                  if (
                    (isSuperAdmin() || permission('Frais BDD')) &&
                    isThProject()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },

              {
                path: 'vision-filiale',
                name: 'visionFiliale',
                component: () =>
                  import(
                    /*WebpackChunName: "visionFiliale" */ '../views/ThProject/frais/visionFiliale.vue'
                  ),
                beforEnter(to, from, next) {
                  if (
                    (isSuperAdmin() ||
                      permission('Récapitulatif gestion des frais')) &&
                    isThProject()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'comercial-cactus',
                name: 'comercialCactus',
                component: () =>
                  import(
                    /*WebpackChunName: "comercialCactus" */ '../views/ThProject/frais/GestionCommercialCactus.vue'
                  ),
                beforEnter(to, from, next) {
                  if (
                    (isSuperAdmin() ||
                      permission('Gestion Commercial Cactus')) &&
                    isThProject()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'bureau-etude',
                name: 'bureauEtude',
                component: () =>
                  import(
                    /*WebpackChunName: "bureauEtude" */ '../views/ThProject/frais/gestionBureauEtude.vue'
                  ),
                beforEnter(to, from, next) {
                  if (
                    (isSuperAdmin() || permission('Gestion Bureau Etude')) &&
                    isThProject()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'compta',
                name: 'compta',
                component: () =>
                  import(
                    /*WebpackChunName: "comptaRh" */ '../views/ThProject/frais/gestionCompta.vue'
                  ),
                beforEnter(to, from, next) {
                  if (
                    (isSuperAdmin() || permission('Gestion Compta')) &&
                    isThProject()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'rh',
                name: 'rh',
                component: () =>
                  import(
                    /*WebpackChunName: "comptaRh" */ '../views/ThProject/frais/gestionRh.vue'
                  ),
                beforEnter(to, from, next) {
                  if (
                    (isSuperAdmin() || permission('Gestion RH')) &&
                    isThProject()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'web-abondance',
                name: 'webAbondance',
                component: () =>
                  import(
                    /*WebpackChunName: "webAbondance" */ '../views/ThProject/frais/gestionWebAbondance.vue'
                  ),
                beforEnter(to, from, next) {
                  if (
                    (isSuperAdmin() ||
                      permission('Gestion informatique web abondance')) &&
                    isThProject()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'interne-ihs',
                name: 'interneIhs',
                component: () =>
                  import(
                    /*WebpackChunName: "webAbondance" */ '../views/ThProject/frais/gestionIhs.vue'
                  ),
                beforEnter(to, from, next) {
                  if (
                    (isSuperAdmin() || permission('Gestion Interne IHS')) &&
                    isThProject()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'simulation-facture-frais',
                name: 'simulationFactureFrais',
                component: () =>
                  import(
                    /*WebpackChunName: "simulationFactureFrais" */ '../views/ThProject/frais/Simulation/simulationFrais.vue'
                  ),
                beforEnter(to, from, next) {
                  if (
                    (isSuperAdmin() || permission('Simulation Facture Frai')) &&
                    isThProject()
                  ) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'facture-sci',
            name: 'facture-sci',
            component: () =>
              import(
                /*WebpackChunName: "facture-sci" */ '../views/factureSci/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Gestion Invest')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          }
        ]
      },
      {
        path: '/setting',
        name: 'setting',
        component: () =>
          import(
            /* webpackChunkName: "setting" */ '../views/setting/Setting.vue'
          ),
        beforeEnter: (to, from, next) => {
          if (isUser()) {
            next();
          } else {
            next('/login');
          }
        },
        children: [
          {
            path: 'gestion',
            name: 'gestion',
            component: () =>
              import(
                /* webpackChunkName: "gestion" */ '../views/setting/GestionOrganisme.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() && isIsoProject()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'configuration',
            name: 'configuration',
            component: () =>
              import(
                /* webpackChunkName: "configuration" */ '../views/setting/Configuration.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() && isIsoProject()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'configurationfiche',
            name: 'configurationFiche',
            component: () =>
              import(
                /* webpackChunkName: "configurationFiche" */ '../views/setting/configurationFiche.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'paies',
            name: 'GestionPaies',
            component: () =>
              import(
                /* webpackChunkName: "GestionPaies" */ '../views/setting/GestionPaies.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() && isIsoProject()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-variable-th',
            name: 'gestion-variable-th',
            component: () =>
              import(
                /* webpackChunkName: "gestion-variable-th" */ '../views/setting/GestionPaiesTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Gestion des variable')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },

          {
            path: 'tarif-regie',
            name: 'GestionTarifRegie',
            component: () =>
              import(
                /* webpackChunkName: "GestionTarifRegie" */ '../views/setting/GestionTarifRegie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'tarif-regie-th',
            name: 'GestionTarifRegieTh',
            component: () =>
              import(
                /* webpackChunkName: "GestionTarifRegieTh" */ '../views/setting/GestionTarifRegieTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() || permission('Gestion des tarifs régie')) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'tarif-sous-regie',
            name: 'GestionTarifSousRegie',
            component: () =>
              import(
                /* webpackChunkName: "GestionTarifSousRegie" */ '../views/setting/GestionTarifSousRegie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isRegie()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'personal-info',
            name: 'PersonelDataUser',
            component: () =>
              import(
                /* webpackChunkName: "PersonelDataUser" */ '../views/setting/InformationsPersonnelles.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isUser()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'regie-info',
            name: 'DataRegie',
            component: () =>
              import(
                /* webpackChunkName: "DataRegie" */ '../views/setting/InfoRégie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isRegie()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'file',
            name: 'FileRegie',
            component: () =>
              import(
                /* webpackChunkName: "FileRegie" */ '../views/setting/ListFileRegie.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isRegie()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'calender',
            name: 'calender',
            component: () =>
              import(
                /* webpackChunkName: "calender" */ '../views/setting/Calendrier.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() && isIsoProject()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'calender-th',
            name: 'calender-th',
            component: () =>
              import(
                /* webpackChunkName: "calender-th" */ '../views/setting/CalendrierTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Calendrier TH')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'repartition-zone-th',
            name: 'repartition-zone-th',
            component: () =>
              import(
                /* webpackChunkName: "repartition-zone-th" */ '../views/setting/RepartitionZoneSettingTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Répartition des zones')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-filiale-th',
            name: 'gestion-filiale-th',
            component: () =>
              import(
                /* webpackChunkName: "gestion-filiale-th" */ '../views/setting/GestionFilialeTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Gestion des sociétés')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-societe-filiale-sci',
            name: 'gestion-societe-filiale-sci',
            component: () =>
              import(
                /* webpackChunkName: "gestion-societe-filiale-sci" */ '../views/setting/GestionSocieteFilialeSci.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Gestion des sociétés SCI')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/');
              }
            }
          },
          {
            path: 'gestion-configuration',
            name: 'gestion-configuration',
            component: () =>
              import(
                /* webpackChunkName: "gestion-configuration" */ '../views/setting/GestionConfiguration.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() && isThProject()) {
                next();
              } else {
                next('/');
              }
            }
          },
          {
            path: 'gestion-categories',
            name: 'gestion-categories',
            component: () =>
              import(
                /* webpackChunkName: "gestion-categories" */ '../views/setting/GestionCategories.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Gestion des catégories')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'organisme-th',
            name: 'organisme-th',
            component: () =>
              import(
                /* webpackChunkName: "organisme-th" */ '../views/setting/GestionOrganismeTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Gestion des organismes')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'organisme-new-th',
            name: 'organisme-new-th',
            component: () =>
              import(
                /* webpackChunkName: "organisme-new-th" */ '../views/setting/GestionOrganismeThNew.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Gestion des organismes')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'users',
            component: () =>
              import(
                /* webpackChunkName: "users-index" */ '../views/setting/users/index.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() || permission('Gestion des utilisateurs')) {
                next();
              } else {
                next('/404');
              }
            },
            children: [
              {
                path: '',
                name: 'users',
                component: () =>
                  import(
                    /* webpackChunkName: "users" */ '../views/setting/users/Users.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin()) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              },
              {
                path: 'permissions',
                name: 'permission',
                component: () =>
                  import(
                    /* webpackChunkName: "permission" */ '../views/setting/users/Permission.vue'
                  ),
                beforeEnter: (to, from, next) => {
                  if (isSuperAdmin()) {
                    next();
                  } else {
                    next('/404');
                  }
                }
              }
            ]
          },
          {
            path: 'gestion-produit',
            name: 'gestion-produit',
            component: () =>
              import(
                /* webpackChunkName: "gestion-produit" */ '../views/setting/GestionProduits.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Gestion des produits')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-params-frais-th',
            name: 'gestion-params-frais-th',
            component: () =>
              import(
                /* webpackChunkName: "gestion-params-frais-th" */ '../views/setting/frais/GestionParamsFrais.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Gestion des variables frais')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },

          {
            path: 'template-th',
            name: 'template-th',
            component: () =>
              import(
                /* webpackChunkName: "template-th" */ '../views/setting/GestionTemplateTh.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Gestion des templates')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-params-frais-par-filiale-th',
            name: 'gestion-params-frais-par-filiale-th',
            component: () =>
              import(
                /* webpackChunkName: "gestion-params-frais-par-filiale-th" */ '../views/setting/GestionFraisParFiliale.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() && isThProject()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-params-frais-cactus-alteo-th',
            name: 'gestion-params-frais-cactus-alteo-th',
            component: () =>
              import(
                /* webpackChunkName: "gestion-params-frais-cactus-alteo-th" */ '../views/setting/frais/GestionFraisCactusAlteo.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (isSuperAdmin() && isThProject()) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'gestion-params-frais-responsable-filiale-th',
            name: 'gestion-params-frais-responsable-filiale-th',
            component: () =>
              import(
                /* webpackChunkName: "gestion-params-frais-responsable-filiale-th" */ '../views/setting/frais/GestionFraisResponsableFiliale.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() ||
                  permission('Gestion des variables responsable filiale')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'vue',
            name: 'vue',
            component: () =>
              import(
                /* webpackChunkName: "vue" */ '../views/setting/vueDynamique.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Gestion des vues')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          },
          {
            path: 'dashboard-factures',
            name: 'dashboard-factures',
            component: () =>
              import(
                /* webpackChunkName: "vue" */ '../views/setting/dashboardFacture.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (
                (isSuperAdmin() || permission('Gestion des vues')) &&
                isThProject()
              ) {
                next();
              } else {
                next('/404');
              }
            }
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
const isSuperAdmin = () => {
  if (store.state.User && store.state.User.role == 'admin') {
    return true;
  }
  return false;
};
const isRegie = () => {
  if (store.state.User && store.state.User.role == 'admin_regie') {
    return true;
  }
  return false;
};
const isResponsableCommercial = () => {
  if (store.state.User && store.state.User.role == 'responsable_commercial') {
    return true;
  }
  return false;
};
const isResponsableDepot = () => {
  if (store.state.User && store.state.User.role == 'responsable_depot') {
    return true;
  }
  return false;
};
const isResponsablePoseurs = () => {
  if (store.state.User && store.state.User.role == 'responsable_poseurs') {
    return true;
  }
  return false;
};
const isCommercialRegie = () => {
  if (store.state.User && store.state.User.role == 'commercial_regie') {
    return true;
  }
  return false;
};

const isUser = () => {
  if (
    store.state.User &&
    (store.state.User.role == 'responsable_poseurs' ||
      store.state.User.role === 'responsable_depot' ||
      store.state.User.role === 'responsable_commercial' ||
      store.state.User.role === 'admin_regie' ||
      store.state.User.role === 'admin' ||
      store.state.User.role === 'commercial_regie' ||
      store.state.User.role === 'contabilite' ||
      store.state.User.role === 'responsable_novae' ||
      store.state.User.role === 'responsable_cactus' ||
      store.state.User.role === 'responsable_web_abondan' ||
      store.state.User.role === 'responsable_iso_33' ||
      store.state.User.role === 'responsable_rge' ||
      store.state.User.role === 'responsable_dnh' ||
      store.state.User.role === 'responsable_sav_s' ||
      store.state.User.role === 'responsable_web_abondance' ||
      store.state.User.role === 'responsable_corpedys' ||
      store.state.User.role === 'responsable_acteo' ||
      store.state.User.role === 'responsable_ge_compta' ||
      store.state.User.role === 'responsable_ge_rh' ||
      store.state.User.role === 'responsable_bureau_etude' ||
      store.state.User.role === 'responsable_mairie' ||
      store.state.User.role === 'responsable_gse' ||
      store.state.User.role === 'responsable_filiale' ||
      store.state.User.role === 'responsable_alteo' ||
      store.state.User.role === 'responsable_artisans' ||
      store.state.User.role === 'responsable_upcube_technologies' ||
      store.state.User.role === 'responsable_ge_rh_compta' ||
      store.state.User.role === 'responsable_controle_de_gestion' ||
      store.state.User.role === 'responsable_validation' ||
      store.state.User.role === 'responsable_finance')
  ) {
    return true;
  }
  return false;
};
const isCommerciaux = () => {
  if (
    store.state.User &&
    (store.state.User.role === 'Responsable planning' ||
      store.state.User.role === 'Commercial sedentaire' ||
      store.state.User.role === 'Admin GE' ||
      store.state.User.role === 'Commercial terrain' ||
      store.state.User.role === 'Pre visiteur' ||
      store.state.User.role === 'Poseur' ||
      store.state.User.role === 'Co poseur')
  ) {
    return true;
  }
  return false;
};
const permission = permissionName => {
  let Existe = false;
  if (store.state.User && store.state.User.permissions) {
    Object.keys(store.state.User.permissions).map(function(key, value) {
      if (store.state.User.permissions[key][permissionName]) {
        Existe = true;
        return;
      }
    });
    return Existe;
  } else {
    return false;
  }
};
const isThProject = () => {
  if (
    store.state.currentType &&
    (store.state.currentType.value == 'Th' ||
      store.state.currentType.value == 'libres' ||
      store.state.currentType.value == 'sci')
  ) {
    return true;
  }
  return false;
};
const isIsoProject = () => {
  if (store.state.currentType && store.state.currentType.value == 'iso') {
    return true;
  }
  return false;
};
export default router;
