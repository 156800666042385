export default class SuiviIndependant {
  constructor(data) {
    this.id = data.id;
    this.type = data.type;
    this.mois = data.mois;
    this.nom_crm = data.nom_crm;
    this.num_aaf = data.num_aaf;
    this.montant_ht = data.montant_ht;
    this.nom_commercial = data.nom_commercial;
    this.socite_facture = data.socite_facture;
    this.facture_recue = data.facture_recue;
    this.num_facture = data.num_facture;
    this.date_facture = data.date_facture;
    this.s_montant_ht = data.s_montant_ht;
    this.s_montant_ttc = data.s_montant_ttc;
    this.comptabilse = data.comptabilse;
    this.saisie_le = data.saisie_le;
    this.a_payer_par = data.a_payer_par;
    this.payed_at = data.payed_at;
    this.date_envoi = data.date_envoi;
    this.employee = data.employee;
    this.semaine = data.semaine;
    this.statue = data.statue;
    this.month = data.month;
  }
  update(data) {
    this.nom_commercial = data.nom_commercial;
    this.socite_facture = data.socite_facture;
    this.facture_recue = data.facture_recue;
    this.num_facture = data.num_facture;
    this.date_facture = data.date_facture;
    this.s_montant_ht = data.s_montant_ht;
    this.s_montant_ttc = data.s_montant_ttc;
    this.comptabilse = data.comptabilse;
    this.saisie_le = data.saisie_le;
    this.a_payer_par = data.a_payer_par;
    this.payed_at = data.payed_at;
    this.date_envoi = data.date_envoi;
    this.statue = data.statue;
  }
  static create(data) {
    return new SuiviIndependant(data);
  }
}
