import axios from 'axios';
import domain from '@/environment';
import AafRegie from '../../models/AafRegie';
const prefixBillRegieTH = 'billRegieTH';
const state = {
  regieFactureAaf: [],
  totalRowFactureRegieAaf: null,
  chargementFactureRegieAaf: false,
  errorFactureAaf: null,
  regieWithFactureGenerateTh: [],
  loadingRegieWithFactureGenerateTh: null,
  loadingUploadFactureRegieTh: false,
  errorUploadFactureRegieTh: null
};
const getters = {
  getRegieFactureAaf: state => state.regieFactureAaf,
  getTotalRowFactureRegieAaf: state => state.totalRowFactureRegieAaf,
  getChargementFactureRegieAaf: state => state.chargementFactureRegieAaf,
  getErrorFactureAaf: state => state.errorFactureAaf,
  getRegieWithFactureGenerateTh: state => state.regieWithFactureGenerateTh,
  getLoadingRegieWithFactureGenerateTh: state =>
    state.loadingRegieWithFactureGenerateTh,
  getLoadingUploadFactureRegieTh: state => state.loadingUploadFactureRegieTh,
  getErrorUploadFactureRegieTh: stat => state.errorUploadFactureRegieTh
};
const mutations = {
  SET_LOADING_AAF_REGIE(state, payload) {
    state.chargementFactureRegieAaf = payload;
  },
  SET_TOTAL_ROW_AAF_REGIE(state, payload) {
    state.totalRowFactureRegieAaf = payload;
  },
  SET_ERROR_AAF_REGIE(state, payload) {
    state.errorFactureAaf = payload;
  },
  SET_AAF_REGIE(state, payload) {
    state.regieFactureAaf = payload.map(item => AafRegie.create(item));
  },
  UPDATE_AAF_REGIE(state, payload) {
    payload.row.update(payload.response);
  },
  LOADING_SELECT_REGIE_TH_WITH_FACTURE_GENERATE(state, payload) {
    state.loadingRegieWithFactureGenerateTh = payload;
  },
  SET_REGIE_TH_WITH_FACTURE_GENERATE(state, payload) {
    state.regieWithFactureGenerateTh = payload;
  },
  SET_LOADING_UPLOAD_FACTURE_AAF_REGIE_TH(state, payload) {
    state.loadingUploadFactureRegieTh = payload;
  },
  SET_ERROR_UPLOAD_FACTURE_TH(state, payload) {
    state.errorUploadFactureRegieTh = payload;
  }
};
const actions = {
  async getAafRegie({ commit }, payload) {
    commit('SET_LOADING_AAF_REGIE', true);
    commit('SET_ERROR_AAF_REGIE', null);
    try {
      const params = {
        page: payload.page,
        year: payload.year,
        per_page: payload.per_page
      };
      if (payload.month != null) {
        params.month = payload.month;
      }
      if (payload.depot != null) {
        params.depot = payload.depot;
      }
      if (payload.statu != null) {
        params.statue = payload.statu;
      }
      if (
        payload.filterRegie &&
        payload.filterRegie.id &&
        payload.filterRegie.id != null
      ) {
        params.regie_id = payload.filterRegie.id;
      }

      const response = await axios.get(
        domain + `/api/${prefixBillRegieTH}/all`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_AAF_REGIE', response.data.data.data);
      commit('SET_TOTAL_ROW_AAF_REGIE', response.data.data.total);
      commit('SET_LOADING_AAF_REGIE', false);

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_AAF_REGIE', error.response.data.error);
      } else {
        commit('SET_ERROR_AAF_REGIE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_AAF_REGIE', false);

      return false;
    }
  },
  async validationAafRegie({ commit }, payload) {
    commit('SET_LOADING_AAF_REGIE', true);
    try {
      const params = new URLSearchParams();
      params.append('id', payload.row.id);
      params.append('commentaire', payload.row.comment);
      if (payload.value) {
        params.append('statue', payload.value);
      }
      const response = await axios.post(
        domain + `/api/${prefixBillRegieTH}/validateRegieBill`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        row: payload.row
      };
      commit('UPDATE_AAF_REGIE', obj);
      commit('SET_LOADING_AAF_REGIE', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_AAF_REGIE', error.response.data.error);
      } else {
        commit('SET_ERROR_AAF_REGIE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_AAF_REGIE', false);

      return false;
    }
  },
  async DownloadingAafRegieBill({ commit }, payload) {
    let params = {
      id: payload.file.id
    };
    try {
      const response = await axios.get(
        domain + `/api/${prefixBillRegieTH}/admin_download_bill`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          responseType: 'blob'
        }
      );
      var words =
        'Facture ' +
        payload.regie.name +
        '-' +
        payload.month +
        '-' +
        payload.year;

      const link = document.createElement('a');
      link.setAttribute('download', words + '.pdf');

      link.href = window.URL.createObjectURL(new Blob([response.data]));
      document.body.appendChild(link);
      link.click();

      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_AAF_REGIE', error.response.data.error);
      } else {
        commit('SET_ERROR_AAF_REGIE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_AAF_REGIE', false);

      return false;
    }
  },
  async payedAafeRegie({ commit }, payload) {
    const params = { payed_at: payload.value, id: payload.row.id };
    try {
      const response = await axios.post(
        domain + `/api/${prefixBillRegieTH}/regiePayed_at`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.bill,
        row: payload.row
      };
      commit('UPDATE_AAF_REGIE', obj);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_AAF_REGIE', error.response.data.error);
      } else {
        commit('SET_ERROR_AAF_REGIE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_AAF_REGIE', false);

      return false;
    }
  },
  async downloadAafRegiePDF({ commit }, payload) {
    try {
      const params = { id: payload.item.id };
      if (payload.depot && payload.depot != null) {
        params.depot = payload.depot;
      }
      const response = await axios.get(
        domain + `/api/${prefixBillRegieTH}/download_bill`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          responseType: 'blob'
        }
      );

      var words =
        'AAF' +
        ' ' +
        payload.item.regie.name +
        '-' +
        payload.item.month +
        '-' +
        payload.item.year;
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words + '.pdf');
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_AAF_REGIE', error.response.data.error);
      } else {
        commit('SET_ERROR_AAF_REGIE', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_AAF_REGIE', false);

      return false;
    }
  },
  async fetchRegieWithFactureGenerateTh({ commit }, payload) {
    commit('LOADING_SELECT_REGIE_TH_WITH_FACTURE_GENERATE', true);
    try {
      const response = await axios.get(
        domain + `/api/${prefixBillRegieTH}/online_regie`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_REGIE_TH_WITH_FACTURE_GENERATE', response.data.data);
      commit('LOADING_SELECT_REGIE_TH_WITH_FACTURE_GENERATE', false);

      return true;
    } catch (error) {
      commit('LOADING_SELECT_REGIE_TH_WITH_FACTURE_GENERATE', false);
      return false;
    }
  },
  async newCommentRegieAaf({ commit }, payload) {
    const params = new URLSearchParams();
    params.append('id', payload.id);
    params.append('commentaire', payload.comment);
    if (payload.row.statue) {
      params.append('statue', payload.row.statue);
    }
    try {
      const response = await axios.post(
        domain + `/api/${prefixBillRegieTH}/validateRegieBill`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        row: payload.row
      };
      commit('UPDATE_FACTURE_REGIE', obj);
      return true;
    } catch (error) {
      return false;
    }
  },
  async uploadFactureRegieAafTh({ commit }, payload) {
    commit('SET_ERROR_UPLOAD_FACTURE_TH', null);
    commit('SET_LOADING_UPLOAD_FACTURE_AAF_REGIE_TH', true);
    try {
      var bodyFormData = new FormData();
      bodyFormData.append('file', payload.file);
      bodyFormData.append('bill_id', payload.id);
      const responce = await axios.post(
        domain + `/api/${prefixBillRegieTH}/uploadRegieBill`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: responce.data.bill,
        row: payload
      };
      commit('UPDATE_AAF_REGIE', obj);
      commit('SET_LOADING_UPLOAD_FACTURE_AAF_REGIE_TH', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.file
      ) {
        commit('SET_ERROR_UPLOAD_FACTURE_TH', error.response.data.errors.file);
      }
      commit('SET_LOADING_UPLOAD_FACTURE_AAF_REGIE_TH', false);
      return false;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
