export default class Row {
  constructor(data) {
    this.anomalies = data.anomalies;
    this.alerts = data.alerts;
    this.id = data.contenu.id;
    this.nom1 = data.contenu.nom1;
    this.prenom1 = data.contenu.prenom1;
    this.email = data.contenu.email;
    this.semaine = data.contenu.semaine;
    this.numero_dossier = data.contenu.numero_dossier;
    this.adresse = data.contenu.adresse;
    this.code_postal = data.contenu.code_postal;
    this.departement = data.contenu.departement;
    this.ville = data.contenu.ville;
    this.mobile = data.contenu.mobile;
    this.fixe = data.contenu.fixe;
    this.etape = data.contenu.etape;
    this.categorie = data.contenu.categorie;
    this.statut = data.contenu.statut;
    this.organisme = data.contenu.organisme;
    this.regie = data.contenu.regie;
    this.commercial_regie = data.contenu.commercial_regie;
    this.installeur_rge = data.contenu.installeur_rge;
    this.previsiteur = data.contenu.previsiteur;
    this.confirmateur = data.contenu.confirmateur;
    this.administrateur = data.contenu.administrateur;
    this.pose_date = data.contenu.pose_date;
    this.pose_semaine = data.contenu.pose_semaine;
    this.poseur = data.contenu.poseur;
    this.coef_ratio_prime = data.contenu.coef_ratio_prime;
    this.montant_101 = data.contenu.montant_101;
    this.montant_102 = data.contenu.montant_102;
    this.montant_103 = data.contenu.montant_103;
    this.montant_total_ht = data.contenu.montant_total_ht;
    this.montant_total_ttc = data.contenu.montant_total_ttc;
    this.surface_101 = data.contenu.surface_101;
    this.surface_102 = data.contenu.surface_102;
    this.surface_103 = data.contenu.surface_103;
    this.surface_101_cp_deroule = data.contenu.surface_101_cp_deroule;
    this.surface_101_cp_souffle = data.contenu.surface_101_cp_souffle;
    this.surface_101_rampant = data.contenu.surface_101_rampant;
    this.surface_102_murs = data.contenu.surface_102_murs;
    this.surface_102_pignons = data.contenu.surface_102_pignons;
    this.surface_102_murs_iti = data.contenu.surface_102_murs_iti;
    this.surface_102_murs_ite = data.contenu.surface_102_murs_ite;
    this.surface_103_plafonds = data.contenu.surface_103_plafonds;
    this.surface_103_vide_sanitaire = data.contenu.surface_103_vide_sanitaire;
    this.total_surface_a_isoler = data.contenu.total_surface_a_isoler;
    this.cumac_101 = data.contenu.cumac_101;
    this.cumac_102 = data.contenu.cumac_102;
    this.cumac_103 = data.contenu.cumac_103;
    this.kwh_cumac = data.contenu.kwh_cumac;
    this.precarite = data.contenu.precarite;
    this.type_chauffage = data.contenu.type_chauffage;
    this.personnes_au_foyer = data.contenu.personnes_au_foyer;
    this.numero_facture = data.contenu.numero_facture;
    this.numero_lot = data.contenu.numero_lot;
    this.num_depot = data.contenu.num_depot;
    this.date_devis = data.contenu.date_devis;
    this.date_facture = data.contenu.date_facture;
    this.coposeurs = data.contenu.coposeurs;
    this.revenu_fiscal_ref = data.contenu.revenu_fiscal_ref;
    this.date_depot_dossier = data.contenu.date_depot_dossier;
    this.societe_poseur = data.contenu.societe_poseur;
    this.responsable_zone = data.contenu.responsable_zone;
    this.depot_stockage = data.contenu.depot_stockage;
    this.source = data.contenu.source;
    this.pipedrive_lead_id = data.contenu.pipedrive_lead_id;
    this.date_creation_fiche = data.contenu.date_creation_fiche;
    this.type_lead = data.contenu.type_lead;
    this.commentaire = data.contenu.commentaire;
    this.zone_climatique = data.contenu.zone_climatique;
    this.montant_operation = data.contenu.montant_operation;
    this.created_at = data.contenu.created_at;
    this.updated_at = data.contenu.updated_at;
    this.total_prime_ttc_101 = data.contenu.total_prime_ttc_101;
    this.total_prime_ttc_102 = data.contenu.total_prime_ttc_102;
    this.total_prime_ttc_103 = data.contenu.total_prime_ttc_103;
    this.total_prime_ttc = data.contenu.total_prime_ttc;
    this.total_commission_tcc = data.contenu.total_commission_tcc;
    this.type_de_projet = data.contenu.type_de_projet;
    this.agent_commercial_terrain = data.contenu.agent_commercial_terrain;
    this.surface_103_plafond_sous_sol =
      data.contenu.surface_103_plafond_sous_sol;
    this.surface_103_plafond_garage = data.contenu.surface_103_plafond_garage;
    this.surface_103_plafond_cave = data.contenu.surface_103_plafond_cave;
    this.surface_103_plafond_autres = data.contenu.surface_103_plafond_autres;
    this.previsite_date = data.contenu.previsite_date;
    this.montant_prime_CEE = data.contenu.montant_prime_CEE;
    this.couleur = data.contenu.couleur;
    this.montant_MPR = data.contenu.montant_MPR;
    this.reste_a_charge = data.contenu.reste_a_charge;
    this.dossier_MPR = data.contenu.dossier_MPR;
    this.dossier_referance = data.contenu.dossier_referance;
    this.a_2_euro = data.contenu.a_2_euro;
    this.a_3_euro = data.contenu.a_3_euro;
  }
  update(data) {
    if (data.anomalies && data.anomalies.length == 0) {
      delete data.anomalies;
    }
    if (data.alerts && data.alerts.length == 0) {
      delete data.alerts;
    }
    this.anomalies = data.anomalies;
    this.alerts = data.alerts;
    this.nom1 = data.contenu.nom1;
    this.prenom1 = data.contenu.prenom1;
    this.email = data.contenu.email;
    this.numero_dossier = data.contenu.numero_dossier;
    this.adresse = data.contenu.adresse;
    this.code_postal = data.contenu.code_postal;
    this.departement = data.contenu.departement;
    this.ville = data.contenu.ville;
    this.mobile = data.contenu.mobile;
    this.fixe = data.contenu.fixe;
    this.etape = data.contenu.etape;
    this.categorie = data.contenu.categorie;
    this.statut = data.contenu.statut;
    this.organisme = data.contenu.organisme;
    this.regie = data.contenu.regie;
    this.installeur_rge = data.contenu.installeur_rge;
    this.previsiteur = data.contenu.previsiteur;
    this.confirmateur = data.contenu.confirmateur;
    this.administrateur = data.contenu.administrateur;
    this.pose_date = data.contenu.pose_date;
    this.pose_semaine = data.contenu.pose_semaine;
    this.poseur = data.contenu.poseur;
    this.coef_ratio_prime = data.contenu.coef_ratio_prime;
    this.montant_101 = data.contenu.montant_101;
    this.montant_102 = data.contenu.montant_102;
    this.montant_103 = data.contenu.montant_103;
    this.montant_total_ht = data.contenu.montant_total_ht;
    this.montant_total_ttc = data.contenu.montant_total_ttc;
    this.surface_101 = data.contenu.surface_101;
    this.surface_102 = data.contenu.surface_102;
    this.surface_103 = data.contenu.surface_103;
    this.surface_101_cp_deroule = data.contenu.surface_101_cp_deroule;
    this.surface_101_cp_souffle = data.contenu.surface_101_cp_souffle;
    this.surface_101_rampant = data.contenu.surface_101_rampant;
    this.surface_102_murs = data.contenu.surface_102_murs;
    this.surface_102_pignons = data.contenu.surface_102_pignons;
    this.surface_102_murs_iti = data.contenu.surface_102_murs_iti;
    this.surface_102_murs_ite = data.contenu.surface_102_murs_ite;
    this.surface_103_plafonds = data.contenu.surface_103_plafonds;
    this.surface_103_vide_sanitaire = data.contenu.surface_103_vide_sanitaire;
    this.total_surface_a_isoler = data.contenu.total_surface_a_isoler;
    this.cumac_101 = data.contenu.cumac_101;
    this.cumac_102 = data.contenu.cumac_102;
    this.cumac_103 = data.contenu.cumac_103;
    this.kwh_cumac = data.contenu.kwh_cumac;
    this.precarite = data.contenu.precarite;
    this.type_chauffage = data.contenu.type_chauffage;
    this.personnes_au_foyer = data.contenu.personnes_au_foyer;
    this.numero_facture = data.contenu.numero_facture;
    this.numero_lot = data.contenu.numero_lot;
    this.num_depot = data.contenu.num_depot;
    this.date_devis = data.contenu.date_devis;
    this.date_facture = data.contenu.date_facture;
    this.coposeurs = data.contenu.coposeurs;
    this.revenu_fiscal_ref = data.contenu.revenu_fiscal_ref;
    this.date_depot_dossier = data.contenu.date_depot_dossier;
    this.societe_poseur = data.contenu.societe_poseur;
    this.responsable_zone = data.contenu.responsable_zone;
    this.depot_stockage = data.contenu.depot_stockage;
    this.source = data.contenu.source;
    this.pipedrive_lead_id = data.contenu.pipedrive_lead_id;
    this.date_creation_fiche = data.contenu.date_creation_fiche;
    this.type_lead = data.contenu.type_lead;
    this.commentaire = data.contenu.commentaire;
    this.zone_climatique = data.contenu.zone_climatique;
    this.montant_operation = data.contenu.montant_operation;
    this.created_at = data.contenu.created_at;
    this.updated_at = data.contenu.updated_at;
    this.total_prime_ttc_101 = data.contenu.total_prime_ttc_101;
    this.total_prime_ttc_102 = data.contenu.total_prime_ttc_102;
    this.total_prime_ttc_103 = data.contenu.total_prime_ttc_103;
    this.total_prime_ttc = data.contenu.total_prime_ttc;
    this.total_commission_tcc = data.contenu.total_commission_tcc;
    this.type_de_projet = data.contenu.type_de_projet;
    this.agent_commercial_terrain = data.contenu.agent_commercial_terrain;
    this.surface_103_plafond_sous_sol =
      data.contenu.surface_103_plafond_sous_sol;
    this.surface_103_plafond_garage = data.contenu.surface_103_plafond_garage;
    this.surface_103_plafond_cave = data.contenu.surface_103_plafond_cave;
    this.surface_103_plafond_autres = data.contenu.surface_103_plafond_autres;
    this.previsite_date = data.contenu.previsite_date;
    this.commercial_regie = data.contenu.commercial_regie;
    this.montant_prime_CEE = data.contenu.montant_prime_CEE;
    this.couleur = data.contenu.couleur;
    this.montant_MPR = data.contenu.montant_MPR;
    this.reste_a_charge = data.contenu.reste_a_charge;
    this.dossier_MPR = data.contenu.dossier_MPR;
    this.dossier_referance = data.contenu.dossier_referance;
    this.a_2_euro = data.contenu.a_2_euro;
    this.a_3_euro = data.contenu.a_3_euro;
  }
  static create(data) {
    return new Row(data);
  }
}
