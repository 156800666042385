export default class fraisBddCommericalCactusProjectsTh {
  constructor(data) {
    this.id = data.id;
    this.numero_dossier = data.numero_dossier;
    this.nom1 = data.nom1;
    this.prenom1 = data.prenom1;
    this.montant_final = data.montant_final;
    this.acompte = data.acompte;
    this.installeur = data.installeur;
    this.super_regie = data.super_regie;
    this.regie = data.regie;
    // this.previsiteur = data.previsiteur;
    this.type_lead = data.type_lead;
    this.agent_commercial_terrain = data.agent_commercial_terrain;
    this.agent_commercial = data.agent_commercial;
    this.interne = data.interne;
    this.externe = data.externe;
    this.commercial = data.commercial;
    this.alteo1 = data.alteo1;
    this.alteo2 = data.alteo2;
    this.montant_interne = data.montant_interne;
    this.montant_externe = data.montant_externe;
    this.montant_commercial = data.montant_commercial;
    this.montant_alteo1 = data.montant_alteo1;
    this.montant_alteo2 = data.montant_alteo2;
    this.alteo_download = data.alteo_download;
    this.commercial_download = data.commercial_download;
    this.externe_download = data.externe_download;
    this.interne_download = data.interne_download;
    this.alteo_id = data.alteo_id;
    this.commercial_id = data.commercial_id;
    this.externe_id = data.externe_id;
    this.interne_id = data.interne_id;
    this.commercial_numero = data.commercial_numero;
    this.externe_numero = data.externe_numero;
    this.interne_numero = data.interne_numero;
    this.cactus_numero = data.cactus_numero;
    this.alteo_numero = data.alteo_numero;
    this.alteo_email_sent_at = data.alteo_email_sent_at;
    this.alteo_lot = data.alteo_lot;
    this.cactus_email_sent_at = data.cactus_email_sent_at;
    this.cactus_lot = data.cactus_lot;
    this.commercial_email_sent_at = data.commercial_email_sent_at;
    this.commercial_lot = data.commercial_lot;
    this.externe_email_sent_at = data.externe_email_sent_at;
    this.externe_lot = data.externe_lot;
    this.interne_email_sent_at = data.interne_email_sent_at;
    this.interne_lot = data.interne_lot;
    this.montant_alteo = data.montant_alteo;
    this.interne_statue = data.interne_statue;
    this.interne_statue_installateur = data.interne_statue_installateur;
    this.externe_statue = data.externe_statue;
    this.externe_statue_installateur = data.externe_statue_installateur;
    this.commercial_statue = data.commercial_statue;
    this.commercial_statue_installateur = data.commercial_statue_installateur;
    this.alteo_statue = data.alteo_statue;
    this.alteo_statue_installateur = data.alteo_statue_installateur;
    this.interne_date = data.interne_date;
    this.externe_date = data.externe_date;
    this.commercial_date = data.commercial_date;
    this.alteo_date = data.alteo_date;
    this.cactus_date = data.cactus_date;
    this.montant_cactus = data.montant_cactus;
    this.cactus_download = data.cactus_download;
    this.cactus_id = data.cactus_id;
    this.cactus_statue = data.cactus_statue;
    this.cactus_statue_installateur = data.cactus_statue_installateur;
    this.cactus = data.cactus;
    this.check = false;
    this.alteo_responsable_email = data.alteo_responsable_email;
    this.cactus_responsable_email = data.cactus_responsable_email;
    this.commercial_responsable_email = data.commercial_responsable_email;
    this.externe_responsable_email = data.externe_responsable_email;
    this.interne_responsable_email = data.interne_responsable_email;
    this.pu_cactus = data.pu_cactus;

    this.cactus_av_id = data.cactus_av_id;
    this.cactus_av_numero = data.cactus_av_numero;
    this.cactus_type_paiement = data.cactus_type_paiement;
    this.cactus_lcr = data.cactus_lcr;
    //
    this.externe_av_id = data.externe_av_id;
    this.externe_av_numero = data.externe_av_numero;
    this.externe_type_paiement = data.externe_type_paiement;
    this.externe_lcr = data.externe_lcr;
    //
    this.alteo_av_id = data.alteo_av_id;
    this.alteo_av_numero = data.alteo_av_numero;
    this.alteo_type_paiement = data.alteo_type_paiement;
    this.alteo_lcr = data.alteo_lcr;
    //
    this.commercial_av_id = data.commercial_av_id;
    this.commercial_av_numero = data.commercial_av_numero;
    this.commercial_type_paiement = data.commercial_type_paiement;
    this.commercial_lcr = data.commercial_lcr;
    //
    this.interne_av_id = data.interne_av_id;
    this.interne_av_numero = data.interne_av_numero;
    this.interne_type_paiement = data.interne_type_paiement;
    this.interne_lcr = data.interne_lcr;
  }

  update(data) {
    this.interne = data.interne;
    this.externe = data.externe;
    this.commercial = data.commercial;
    this.alteo1 = data.alteo1;
    this.alteo2 = data.alteo2;
    this.montant_interne = data.montant_interne;
    this.montant_externe = data.montant_externe;
    this.montant_commercial = data.montant_commercial;
    this.montant_alteo1 = data.montant_alteo1;
    this.montant_alteo2 = data.montant_alteo2;
    this.acompte = data.acompte;
    this.super_regie = data.super_regie;
    this.montant_alteo = data.montant_alteo;
    this.alteo_download = data.alteo_download;
    this.commercial_download = data.commercial_download;
    this.externe_download = data.externe_download;
    this.interne_download = data.interne_download;
    this.alteo_id = data.alteo_id;
    this.commercial_id = data.commercial_id;
    this.externe_id = data.externe_id;
    this.interne_id = data.interne_id;
    this.interne_statue = data.interne_statue;
    this.externe_statue = data.externe_statue;
    this.commercial_statue = data.commercial_statue;
    this.alteo_statue = data.alteo_statue;
    this.interne_date = data.interne_date;
    this.externe_date = data.externe_date;
    this.externe_date = data.externe_date;
    this.commercial_date = data.commercial_date;
    this.alteo_date = data.alteo_date;
    this.cactus_date = data.cactus_date;
    this.montant_cactus = data.montant_cactus;
    this.cactus_download = data.cactus_download;
    this.cactus_id = data.cactus_id;
    this.cactus_statue = data.cactus_statue;
    this.cactus = data.cactus;
    this.cactus_numero = data.cactus_numero;
    this.commercial_numero = data.commercial_numero;
    this.externe_numero = data.externe_numero;
    this.interne_numero = data.interne_numero;
    this.alteo_numero = data.alteo_numero;
    this.alteo_responsable_email = data.alteo_responsable_email;
    this.cactus_responsable_email = data.cactus_responsable_email;
    this.commercial_responsable_email = data.commercial_responsable_email;
    this.externe_responsable_email = data.externe_responsable_email;
    this.interne_responsable_email = data.interne_responsable_email;
    this.pu_cactus = data.pu_cactus;
  }
  static create(data) {
    return new fraisBddCommericalCactusProjectsTh(data);
  }
}
