export default class RowTh {
  constructor(data) {
    this.anomalies = data.anomalies;
    this.alerts = data.alerts;
    this.id = data.contenu.id;
    this.depot = data.contenu.depot;
    this.installeur = data.contenu.installeur;
    this.nom1 = data.contenu.nom1;
    this.prenom1 = data.contenu.prenom1;
    this.numero_dossier = data.contenu.numero_dossier;
    this.adresse = data.contenu.adresse;
    this.code_postal = data.contenu.code_postal;
    this.statut = data.contenu.statut;
    this.installateur = data.contenu.installateur;
    this.regie = data.contenu.regie;
    // this.previsiteur = data.contenu.previsiteur;
    this.agent_commercial_terrain = data.contenu.agent_commercial_terrain;
    this.agent_commercial = data.contenu.agent_commercial;
    this.pose_date = data.contenu.pose_date;
    this.pose_semaine = data.contenu.pose_semaine;
    this.depot_stockage = data.contenu.depot_stockage;
    this.source = data.contenu.source;
    this.poseur = data.contenu.poseur;
    this.coposeurs = data.contenu.coposeurs;
    this.agent_technique = data.contenu.agent_technique;
    this.societe_agent_technique = data.contenu.societe_agent_technique;
    this.kwh_cumac = data.contenu.kwh_cumac;
    this.montant_total_ht = data.contenu.montant_total_ht;
    this.montant_total_ttc = data.contenu.montant_total_ttc;
    this.montant_prime_cee = data.contenu.montant_prime_cee;
    this.montant_mpr = data.contenu.montant_mpr;
    this.zone_climatique = data.contenu.zone_climatique;
    this.dossier_mpr = data.contenu.dossier_mpr;
    this.surface_101_souflee = data.contenu.surface_101_souflee;
    this.surface_101_deroule = data.contenu.surface_101_deroule;
    this.surface_101_rampants = data.contenu.surface_101_rampants;
    this.surface_102_murs_iti = data.contenu.surface_102_murs_iti;
    this.surface_102_murs_ite = data.contenu.surface_102_murs_ite;
    this.surface_103_plafond = data.contenu.surface_103_plafond;
    this.VMC = data.contenu.VMC;
    this.commentaire = data.contenu.commentaire;
    this.departement = data.contenu.departement;
    this.etape = data.contenu.etape;
    this.organisme = data.contenu.organisme;
    this.categorie = data.contenu.categorie;
    this.montant_net_ht = data.contenu.montant_net_ht;
    this.ballon = data.contenu.ballon;
    this.RR = data.contenu.RR;
    this.RO = data.contenu.RO;
    this.precarite = data.contenu.precarite;
    this.type_de_chauffage = data.contenu.type_de_chauffage;
    this.personnes_au_foyer = data.contenu.personnes_au_foyer;
    this.numero_de_facture = data.contenu.numero_de_facture;
    this.numero_de_lot = data.contenu.numero_de_lot;
    this.numero_de_depot = data.contenu.numero_de_depot;
    this.date_dengagement = data.contenu.date_dengagement;
    this.date_facture = data.contenu.date_facture;
    this.revenu_fiscal_ref = data.contenu.revenu_fiscal_ref;
    this.date_depot_dossier = data.contenu.date_depot_dossier;
    this.email = data.contenu.email;
    this.societe_poseur = data.contenu.societe_poseur;
    this.responsable_zone = data.contenu.responsable_zone;
    this.pipedrive_lead_id = data.contenu.pipedrive_lead_id;
    this.date_creation_de_la_fiche = data.contenu.date_creation_de_la_fiche;
    this.type_lead = data.contenu.type_lead;
    this.montant_operation = data.contenu.montant_operation;
    this.previsite_date = data.contenu.previsite_date;
    this.campagne = data.contenu.campagne;
    this.ref_dossier_externe = data.contenu.ref_dossier_externe;
    this.couleur = data.contenu.couleur;
    this.montant_a_5_ht = data.contenu.montant_a_5_ht;
    this.montant_final = data.contenu.montant_final;
    this.check = false;
    this.statue_paye_non_paye = data.contenu.statue_paye_non_paye;
    this.type_isolation = data.contenu.type_isolation;
    this.etat_de_statut = data.contenu.etat_de_statut;
    this.agent_administratif = data.contenu.agent_administratif;
    this.conducteur_de_travaux = data.contenu.conducteur_de_travaux;
    this.montant_TVA = data.contenu.montant_TVA;
    this.reste_a_charge = data.contenu.reste_a_charge;
    this.etat_du_devis = data.contenu.etat_du_devis;
    this.devis_signe_par = data.contenu.devis_signe_par;
    this.numero_avoir = data.contenu.numero_avoir;
    this.date_avoir = data.contenu.date_avoir;
    this.montant_ttc_avoir = data.contenu.montant_ttc_avoir;
    this.montant_tva_avoir = data.contenu.montant_tva_avoir;
    this.cofrac_bureau_de_control = data.contenu.cofrac_bureau_de_control;
    this.dossier_ID = data.contenu.dossier_ID;
    this.super_regie = data.super_regie;
    this.responsable_qualite = data.responsable_qualite;
    this.poseur_pac = data.poseur_pac;
    this.BE_TYPE = data.BE_TYPE;
    this.statue_date = data.statue_date;
    this.coef_prime_installateur = data.contenu.coef_prime_installateur;
    this.montant_min_lock = data.contenu.montant_min_lock;
  }
  update(data) {
    if (data.anomalies && data.anomalies.length == 0) {
      delete data.anomalies;
    }
    if (data.alerts && data.alerts.length == 0) {
      delete data.alerts;
    }
    this.anomalies = data.anomalies;
    this.alerts = data.alerts;
    this.depot = data.contenu.depot;
    this.installeur = data.contenu.installeur;
    this.nom1 = data.contenu.nom1;
    this.prenom1 = data.contenu.prenom1;
    this.numero_dossier = data.contenu.numero_dossier;
    this.adresse = data.contenu.adresse;
    this.code_postal = data.contenu.code_postal;
    this.statut = data.contenu.statut;
    this.installateur = data.contenu.installateur;
    this.regie = data.contenu.regie;
    // this.previsiteur = data.contenu.previsiteur;
    this.agent_commercial_terrain = data.contenu.agent_commercial_terrain;
    this.agent_commercial = data.contenu.agent_commercial;
    this.pose_date = data.contenu.pose_date;
    this.pose_semaine = data.contenu.pose_semaine;
    this.depot_stockage = data.contenu.depot_stockage;
    this.source = data.contenu.source;
    this.poseur = data.contenu.poseur;
    this.coposeurs = data.contenu.coposeurs;
    this.agent_technique = data.contenu.agent_technique;
    this.kwh_cumac = data.contenu.kwh_cumac;
    this.montant_total_ht = data.contenu.montant_total_ht;
    this.montant_total_ttc = data.contenu.montant_total_ttc;
    this.montant_prime_cee = data.contenu.montant_prime_cee;
    this.montant_mpr = data.contenu.montant_mpr;
    this.zone_climatique = data.contenu.zone_climatique;
    this.dossier_mptr = data.contenu.dossier_mpr;
    this.surface_101_souflee = data.contenu.surface_101_souflee;
    this.surface_101_deroule = data.contenu.surface_101_deroule;
    this.surface_101_rampants = data.contenu.surface_101_rampants;
    this.surface_102_murs_iti = data.contenu.surface_102_murs_iti;
    this.surface_102_murs_ite = data.contenu.surface_102_murs_ite;
    this.surface_103_plafond = data.contenu.surface_103_plafond;
    this.VMC = data.contenu.VMC;
    this.commentaire = data.contenu.commentaire;
    this.departement = data.contenu.departement;
    this.etape = data.contenu.etape;
    this.organisme = data.contenu.organisme;
    this.categorie = data.contenu.categorie;
    this.montant_net_ht = data.contenu.montant_net_ht;
    this.ballon = data.contenu.ballon;
    this.RR = data.contenu.RR;
    this.RO = data.contenu.RO;
    this.precarite = data.contenu.precarite;
    this.type_de_chauffage = data.contenu.type_de_chauffage;
    this.personnes_au_foyer = data.contenu.personnes_au_foyer;
    this.numero_de_facture = data.contenu.numero_de_facture;
    this.numero_de_lot = data.contenu.numero_de_lot;
    this.numero_de_depot = data.contenu.numero_de_depot;
    this.date_dengagement = data.contenu.date_dengagement;
    this.date_facture = data.contenu.date_facture;
    this.revenu_fiscal_ref = data.contenu.revenu_fiscal_ref;
    this.date_depot_dossier = data.contenu.date_depot_dossier;
    this.email = data.contenu.email;
    this.societe_poseur = data.contenu.societe_poseur;
    this.responsable_zone = data.contenu.responsable_zone;
    this.pipedrive_lead_id = data.contenu.pipedrive_lead_id;
    this.date_creation_de_la_fiche = data.contenu.date_creation_de_la_fiche;
    this.type_lead = data.contenu.type_lead;
    this.montant_operation = data.contenu.montant_operation;
    this.previsite_date = data.contenu.previsite_date;
    this.campagne = data.contenu.campagne;
    this.ref_dossier_externe = data.contenu.ref_dossier_externe;
    this.couleur = data.contenu.couleur;
    this.montant_a_5_ht = data.contenu.montant_a_5_ht;
    this.montant_final = data.contenu.montant_final;
    this.statue_paye_non_paye = data.contenu.statue_paye_non_paye;
    this.type_isolation = data.contenu.type_isolation;
    this.etat_de_statut = data.contenu.etat_de_statut;
    this.agent_administratif = data.contenu.agent_administratif;
    this.conducteur_de_travaux = data.contenu.conducteur_de_travaux;
    this.montant_TVA = data.contenu.montant_TVA;
    this.reste_a_charge = data.contenu.reste_a_charge;
    this.etat_du_devis = data.contenu.etat_du_devis;
    this.devis_signe_par = data.contenu.devis_signe_par;
    this.numero_avoir = data.contenu.numero_avoir;
    this.date_avoir = data.contenu.date_avoir;
    this.montant_ttc_avoir = data.contenu.montant_ttc_avoir;
    this.montant_tva_avoir = data.contenu.montant_tva_avoir;
    this.cofrac_bureau_de_control = data.contenu.cofrac_bureau_de_control;
    this.dossier_ID = data.contenu.dossier_ID;
    this.super_regie = data.super_regie;
    this.responsable_qualite = data.responsable_qualite;
    this.poseur_pac = data.poseur_pac;
    this.BE_TYPE = data.BE_TYPE;
    this.statue_date = data.statue_date;
    this.coef_prime_installateur = data.contenu.coef_prime_installateur;
  }
  static create(data) {
    return new RowTh(data);
  }
}
