export default class Acompte {
  constructor(data) {
    this.id = data.id;
    this.numero_dossier = data.numero_dossier;
    this.nom = data.nom;
    this.prenom = data.prenom;
    this.amount = data.amount;
    this.employee = data.employee;
  }
  update(data) {
    this.amount = data.amount;
    this.nom = data.nom;
    this.prenom = data.prenom;
  }

  static create(data) {
    return new Acompte(data);
  }
}
