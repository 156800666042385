export default class EnergieInvestTh {
  constructor(data) {
    this.id = data.id;
    this.FactureAvoir = data.FactureAvoir;
    this.acheteur = data.acheteur;
    this.ajout_reduction_global = data.ajout_reduction_global;
    this.categorie = data.categorie;
    this.confirme = data.confirme;
    this.date_creation = data.date_creation;
    this.date_exact_limite_reglement = data.date_exact_limite_reglement;
    this.date_limite_reglement = data.date_limite_reglement;
    this.date_paiement = data.date_paiement;
    this.devise = data.devise;
    this.disabled = data.disabled;
    this.email_sent_at = data.email_sent_at;
    this.etat = data.etat;
    this.iban = data.iban;
    this.check = false;
    this.lieu_creation = data.lieu_creation;
    this.mode_reglement = data.mode_reglement;
    this.montant_payer = data.montant_payer;
    this.motif_avoir = data.motif_avoir_sci;
    this.num = data.num;
    this.num_proforma = data.num_proforma;
    this.numero_document_corrige = data.numero_document_corrige;
    this.objet = data.objet;
    this.produits = data.produits;
    this.swift = data.swift;
    this.total_ht = data.total_ht;
    this.total_ht_data_base = data.total_ht_data_base;
    this.total_net_ht = data.total_net_ht;
    this.total_net_ht_data_base = data.total_net_ht_data_base;
    this.total_reduction = data.total_reduction;
    this.total_reduction_data_base = data.total_reduction_data_base;
    this.total_ttc = data.total_ttc;
    this.total_ttc_data_base = data.total_ttc_data_base;
    this.total_tva = data.total_tva;
    this.total_tva_data_base = data.total_tva_data_base;
    this.type = data.type;
    this.user_id = data.user_id;
    this.vendeur = data.vendeur;
    this.openSelect = false;
    this.FactureAvoir = data.FactureAvoir.map(item => {
      return { ...item, checkAvoir: false };
    });
    this.disabled = data.disabled;
    this.checkGlobale = false;
  }
  update(data) {
    this.date = data.date;
    this.statue = data.statue;
    this.valid = data.valid;
    this.montant = data.montant;
    this.montant_ttc = data.montant_ttc;
    this.num_facture = data.num_facture;
    this.Facture_filiale = data.Facture_filiale;
    this.sci_Facture = data.sci_Facture_sci;
    this.sci_montant = data.sci_montant;
    this.sci_statue = data.sci_statue;
    this.sci_valid = data.sci_valid;
    this.sci_montant_ttc = data.sci_montant_ttc;
    this.sci_num_facture = data.sci_num_facture;
    this.sci_id = data.sci_id;
    this.sci_type = data.sci_type;
    this.sci_date = data.sci_date;
    this.sci_societe = data.sci_societe;
    this.delete = data.delete;
    this.vendeur = data.vendeur;
    this.openSelect = false;
    this.FactureAvoir = data.FactureAvoir.map(item => {
      return { ...item, checkAvoir: false };
    });
    this.disabled = data.disabled;
    this.checkGlobale = false;
  }
  static create(data) {
    return new EnergieInvestTh(data);
  }
}
