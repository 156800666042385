export default class SuiviPaiementTh {
  constructor(data) {
    this.id = data.id;
    this.montant_prime_cee = data.montant_prime_cee;
    this.montant_surprime_cee = data.montant_surprime_cee;
    this.montant_commissions_cee = data.montant_commissions_cee;
    this.kwh_cumac = data.kwh_cumac;
    this.numero_facture_prime = data.numero_facture_prime;
    this.numero_facture_commissions = data.numero_facture_commissions;
    this.etat_de_paiement = data.etat_de_paiement;
    this.numero_facture_surprime = data.numero_facture_surprime;
    this.numero_dossier = data.numero_dossier;
    this.nom1 = data.nom1;
    this.prenom1 = data.prenom1;
    //coef
    this.prime_cee = data.prime_cee;
    this.surprime_cee = data.surprime_cee;
    this.commissions_cee = data.commissions_cee;
    //prime coef verif
    this.error = data.error;
    //origin
    this.montant_prime_cee_origin = data.montant_prime_cee_origin;
    this.kwh_cumac_origin = data.kwh_cumac_origin;
  }
  update(data) {
    this.montant_prime_cee = data.montant_prime_cee;
    this.montant_surprime_cee = data.montant_surprime_cee;
    this.montant_commissions_cee = data.montant_commissions_cee;
    this.kwh_cumac = data.kwh_cumac;
    this.numero_facture_prime = data.numero_facture_prime;
    this.numero_facture_commissions = data.numero_facture_commissions;
    this.etat_de_paiement = data.etat_de_paiement;
    this.numero_facture_surprime = data.numero_facture_surprime;
    this.numero_dossier = data.numero_dossier;
    this.nom1 = data.nom1;
    this.prenom1 = data.prenom1;
    //coef
    this.prime_cee = data.prime_cee;
    this.surprime_cee = data.surprime_cee;
    this.commissions_cee = data.commissions_cee;
    //prime coef verif
    this.error = data.error;
    //origin
    this.montant_prime_cee_origin = data.montant_prime_cee_origin;
    this.kwh_cumac_origin = data.kwh_cumac_origin;
  }
  static create(data) {
    return new SuiviPaiementTh(data);
  }
}
