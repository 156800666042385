import axios from 'axios';
import domain from '@/environment';
import SimulationFactureFrais from '../../models/SimulationFactureFrais';
let requestSimulationFactureFrais = null;
let prefixSimulationFactureFrauis = 'frais';

const state = {
  simulationFactureFrais: [],
  loadingSimulationFactureFrais: false,
  errorSimulationFactureFrais: null,
  simulationTotalRow: null
};
const getters = {
  getSimulationFactureFraisData: state => state.simulationFactureFrais,
  getLoadingSimulationFactureFrais: state =>
    state.loadingSimulationFactureFrais,
  getErrorSimulationFactureFrais: state => state.errorSimulationFactureFrais,
  getSimulationTotalRow: state => state.simulationTotalRow
};
const mutations = {
  SET_DATA_SIMULATION_FACTURE_FRAIS(state, payload) {
    state.simulationFactureFrais = payload.map(item =>
      SimulationFactureFrais.create(item)
    );
  },
  SET_LOADING_SIMULATION_FACTURE_FRAIS(state, payload) {
    state.loadingSimulationFactureFrais = payload;
  },
  SET_ERROR_SIMULATION_FACTURE_FRAIS(state, payload) {
    state.errorSimulationFactureFrais = payload;
  },
  UPDATE_SIMULATION_FACTURE_FRAIS_STAT(state, payload) {
    payload.payload.update(payload.response);
  },
  UPDATE_SIMULATION_ROWS(state, payload) {
    state.simulationTotalRow = payload;
  }
};
const actions = {
  async getAllSimulationFactureFrais({ commit }, payload) {
    commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
    commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);
    const params = {
      year: payload.year,
      page: payload.page,
      per_page: payload.perPage
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.filiale != null) {
      params.filiale = payload.filiale;
    }
    if (payload.week != null) {
      params.week = payload.week;
    }
    if (payload.confirmation != null) {
      params.confirmation = payload.confirmation;
    }
    try {
      if (requestSimulationFactureFrais) {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
        requestSimulationFactureFrais.cancel('cancel-request');
      }
      requestSimulationFactureFrais = axios.CancelToken.source();
      const response = await axios.get(
        domain +
          '/api/' +
          prefixSimulationFactureFrauis +
          '/vision_confirmation',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestSimulationFactureFrais.token
        }
      );
      commit('SET_DATA_SIMULATION_FACTURE_FRAIS', response.data.data.data);
      commit('UPDATE_SIMULATION_ROWS', response.data.data.total);
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', error.response.data.error);
      } else {
        if (!error.message == 'cancel-request') {
          commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', [
            'Une erreur est servenue'
          ]);
          commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
        }
      }

      return false;
    }
  },
  async updateCoefFraisSimulation({ commit }, payload) {
    commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
    commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);

    const params = {
      id: payload.row.id,
      column: payload.column == 'alteo_value' ? 'alteo1_value' : payload.column,
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/frais/update_frais`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        payload: payload.row
      };
      commit('UPDATE_SIMULATION_FACTURE_FRAIS_STAT', obj);
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', error.response.data.error);
      } else {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);

      return false;
    }
  },

  async confirmationDossierSimulation({ commit }, payload) {
    commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
    commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);
    const params = new FormData();
    payload?.supports?.forEach((item, index) =>
      params.append('supports[' + index + ']', item)
    );
    try {
      const response = await axios.post(
        domain + `/api/frais/confirmation/${payload.data.item.id}`,
        params,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        payload: payload.data.item
      };
      // commit('UPDATE_SIMULATION_FACTURE_FRAIS_STAT', obj);
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
      return { succes: true, data: response.data.project, obj: obj };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', error.response.data.error);
      } else {
        commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', [
          'Une erreur est servenue'
        ]);
      }
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);

      return { succes: false };
    }
  },
  async confirmationMultipleDossierSimulation({ commit }, payload) {
    commit('SET_ERROR_SIMULATION_FACTURE_FRAIS', null);
    // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);

    try {
      const response = await axios.post(
        domain + `/api/frais/confirmation_multiple`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { succes: true, message: response.data };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);

        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        // commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
        return {
          succes: false,
          error: 'Une erreur est servenue'
        };
      }
    }
  },
  async exportExcelSimulationFactureFraisTh({ commit }, payload) {
    commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', true);
    const params = {
      year: payload.year
    };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    if (payload.depot && payload.depot != null) {
      params.depot = payload.depot;
    }
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisBDD}/export`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'Frais BDD - ' + payload.year + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_SIMULATION_FACTURE_FRAIS', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
