import axios from 'axios';
import domain from '@/environment';
import fraisBddTh from '../../models/FraisBddTh';
const prefixFraisBDD = 'frais';
let requestBdd = null;

const state = {
  fraisBddData: [],
  totalfraisBddData: null,
  loadingFraisBdd: false,
  errorFraisBdd: null
};
const getters = {
  getFraisBddData: state => state.fraisBddData,
  getLoadingFraisBdd: state => state.loadingFraisBdd,
  getErrorFraisBdd: state => state.errorFraisBdd,
  getTotalfraisBddData: state => state.totalfraisBddData
};
const mutations = {
  SET_DATA_FRAIS_BDD(state, payload) {
    state.fraisBddData = payload.map(item => fraisBddTh.create(item));
  },
  SET_TOTAL_FRAIS_BDD(state, payload) {
    state.totalfraisBddData = payload;
  },
  SET_LOADING_FRAIS_BDD(state, payload) {
    state.loadingFraisBdd = payload;
  },
  SET_ERROR_FRAIS_BDD(state, payload) {
    state.errorFraisBdd = payload;
  },
  UPDATE_FRAIS_BDD_STAT(state, payload) {
    payload.row.update(payload.response);
  }
};
const actions = {
  async getAllFraisBdd({ commit }, payload) {
    commit('SET_LOADING_FRAIS_BDD', true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      year: payload.year
    };
    if (payload.month != null) {
      params.month = payload.month;
    }
    if (payload.depot != null) {
      params.depot = payload.depot;
    }
    if (payload.search != null) {
      params.search = payload.search;
    }
    if (payload.filiale != null) {
      params.filiale = payload.filiale;
    }
    if (payload.week != null) {
      params.week = payload.week;
    }
    try {
      if (requestBdd) {
        commit('SET_ERROR_FRAIS_BDD', null);
        requestBdd.cancel('cancel-request');
      }
      requestBdd = axios.CancelToken.source();
      const response = await axios.get(
        domain + '/api/' + prefixFraisBDD + '/all',
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          cancelToken: requestBdd.token
        }
      );
      commit('SET_DATA_FRAIS_BDD', response.data.data.data);
      commit('SET_TOTAL_FRAIS_BDD', response.data.data.total);
      commit('SET_LOADING_FRAIS_BDD', false);

      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        commit('SET_ERROR_FRAIS_BDD', error.response.data.error);
        commit('SET_LOADING_FRAIS_BDD', false);
      } else if (error.message !== 'cancel-request') {
        commit('SET_LOADING_FRAIS_BDD', false);
        commit('SET_ERROR_FRAIS_BDD', ['Une erreur est servenue']);
      }
      return false;
    }
  },

  async updateStatueFraisBdd({ commit }, payload) {
    commit('SET_LOADING_FRAIS_BDD', true);

    const params = {
      id: payload.row.id,
      column: payload.column,
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/${prefixFraisBDD}/update_statue`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        row: payload.row
      };
      commit('UPDATE_FRAIS_BDD_STAT', obj);
      commit('SET_LOADING_FRAIS_BDD', false);
      return true;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        commit('SET_ERROR_FRAIS_BDD', error.response.data.error);
      } else {
        commit('SET_ERROR_FRAIS_BDD', ['Une erreur est servenue']);
      }
      commit('SET_LOADING_FRAIS_BDD', false);

      return false;
    }
  },
  async exportExcelFraisBddTh({ commit }, payload) {
    commit('SET_LOADING_FRAIS_BDD', true);
    const params = {
      year: payload.year
    };
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    if (payload.depot && payload.depot != null) {
      params.depot = payload.depot;
    }
    try {
      const response = await axios.get(
        domain + `/api/${prefixFraisBDD}/export`,
        {
          params: params,
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'Frais BDD - ' + payload.year + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_FRAIS_BDD', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_FRAIS_BDD', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
