import axios from 'axios';
import domain from '@/environment';
import RegieTh from '../../models/RegieTh';
const prefixConfigRegieTh = 'salaryManagementRegie';

const state = {
  regieToConfigTh: [],
  errorRegieTarifsTh: null,
  loagingRegieTarifsTh: false,
  loadingSelectRegieConfigTh: false,
  regieConfigThData: [],
  totalRowRegieConfigTh: null
};
const getters = {
  getRegieToConfigTh: state => state.regieToConfigTh,
  getErrorRegieTarifsTh: state => state.errorRegieTarifsTh,
  getLoagingRegieTarifsTh: state => state.loagingRegieTarifsTh,
  getLoadingSelectRegieConfigTh: state => state.loadingSelectRegieConfigTh,
  getRegieConfigThData: state => state.regieConfigThData,
  getTotalRowRegieConfigTh: state => state.totalRowRegieConfigTh
};
const mutations = {
  SET_REGIE_TH_TO_CONFIG(state, payload) {
    state.regieToConfigTh = payload;
  },
  LOADING_SELECT_REGIE_TH_TO_CONFIG(state, payload) {
    state.loadingSelectRegieConfigTh = payload;
  },
  ERROR_REGIE_TH_TO_CONFIG(state, payload) {
    state.errorRegieTarifsTh = payload;
  },
  LOADING_REGIE_TH_TO_CONFIG(state, payload) {
    state.loagingRegieTarifsTh = payload;
  },
  NEW_REGIE_TH_CONFIG(state, payload) {
    payload.map(item => state.regieConfigThData.push(RegieTh.create(item)));
  },
  ALL_REGIE_TH_CONFIG(state, payload) {
    state.regieConfigThData = payload.map(item => RegieTh.create(item));
  },
  TOTAL_REGIE_TH_CONFIG(state, payload) {
    state.totalRowRegieConfigTh = payload;
  },
  UPDATE_REGIE_TH_CONFIG(state, payload) {
    for (let i = 0; i < state.regieConfigThData.length; i++) {
      if (state.regieConfigThData[i].id == payload.id) {
        state.regieConfigThData[i].update(payload);
        break;
      }
    }
  }
};
const actions = {
  async fetchRegieToConfigTh({ commit }, payload) {
    commit('LOADING_SELECT_REGIE_TH_TO_CONFIG', true);
    try {
      const response = await axios.get(
        domain + `/api/${prefixConfigRegieTh}/online_regie`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: payload
        }
      );
      commit('SET_REGIE_TH_TO_CONFIG', response.data.data);
      commit('LOADING_SELECT_REGIE_TH_TO_CONFIG', false);

      return true;
    } catch (error) {
      commit('LOADING_SELECT_REGIE_TH_TO_CONFIG', false);
      return false;
    }
  },
  async newConfigRegieTarifTh({ commit }, payload) {
    let params = { ...payload };
    params.regie_id = params.regie_id.map(item => {
      return item.id;
    });
    params.TH_value = parseFloat((params.TH_value + '').replace(',', '.'));
    try {
      const response = await axios.post(
        domain + `/api/${prefixConfigRegieTh}/create`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('NEW_REGIE_TH_CONFIG', response.data.data);

      return {
        succes: true
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  },
  async fetchAllRegieConfigTh({ commit }, payload) {
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      year: payload.year
    };
    if (payload.regie_id && payload.regie_id.id != null) {
      params.regie_id = payload.regie_id.id;
    }
    if (payload.month && payload.month != null) {
      params.month = payload.month;
    }
    commit('LOADING_REGIE_TH_TO_CONFIG', true);
    try {
      const response = await axios.get(
        domain + `/api/${prefixConfigRegieTh}/all`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('ALL_REGIE_TH_CONFIG', response.data.data.data);
      commit('TOTAL_REGIE_TH_CONFIG', response.data.data.total);
      commit('LOADING_REGIE_TH_TO_CONFIG', false);

      return true;
    } catch (error) {
      if (error.response && error.respons.data && error.response.data.error) {
        commit('ERROR_REGIE_TH_TO_CONFIG', error.response.data.error);
      } else {
        commit('ERROR_REGIE_TH_TO_CONFIG', ['Une erreur est servenue']);
      }
      commit('LOADING_REGIE_TH_TO_CONFIG', false);

      return false;
    }
  },

  async updateConfigParamsRegieTh({ commit }, payload) {
    let params = {
      id: payload.id,
      month: payload.month,
      year: payload.year,
      pay_previsite: payload.pay_previsite
    };

    params.TH_value = parseFloat((payload.TH_value + '').replace(',', '.'));
    try {
      const response = await axios.post(
        domain + `/api/${prefixConfigRegieTh}/update`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('UPDATE_REGIE_TH_CONFIG', response.data.data);

      return {
        succes: true
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return {
          succes: false,
          error: error.response.data.error
        };
      } else {
        return {
          succes: false,
          error: ['Une erreur est servenue']
        };
      }
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
