import vueDynamiqueAVColumns from './vueDynamiqueAVColumns';
export default class vueDynamiqueAV {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.blocked = data.blocked;
    this.columns = data.columns.map(item => vueDynamiqueAVColumns.create(item));
  }
  update(data) {
    this.name = data.name;
    this.blocked = data.blocked;
    this.columns = data.columns.map(item => vueDynamiqueAVColumns.create(item));
  }

  static create(data) {
    return new vueDynamiqueAV(data);
  }
}
