export default class FraisBddTh {
  constructor(data) {
    this.id = data.id;
    this.numero_dossier = data.numero_dossier;
    this.period_de_paie = data.period_de_paie;
    this.nom1 = data.nom1;
    this.pernom1 = data.pernom1;
    this.installer_rge = data.installer_rge;
    this.montant_prime_cee = data.montant_prime_cee;
    this.montant_total_ht = data.montant_total_ht;
    this.montant_a_5_ht = data.montant_a_5_ht;
    this.montant_final = data.montant_final;
    this.statue_operationnel = data.statue_operationnel;
    this.operationnel = data.operationnel;
    this.statue_commercial = data.statue_commercial;
    this.commercial = data.commercial;
    this.statue_marketing = data.statue_marketing;
    this.marketing = data.marketing;
    this.statue_technique = data.statue_technique;
    this.technique = data.technique;
    this.statue_rge = data.statue_rge;
    this.rge = data.rge;
    this.statue_CDG = data.statue_CDG;
    this.CDG = data.CDG;
    this.statue_administratif_sav = data.statue_administratif_sav;
    this.administratif_sav = data.administratif_sav;
    this.statue_dsi = data.statue_dsi;
    this.dsi = data.dsi;
    this.statue_juridique = data.statue_juridique;
    this.juridique = data.juridique;
    this.statue_alger = data.statue_alger;
    this.alger = data.alger;
    this.comptabilite = data.comptabilite;
    this.statue_comptabilite = data.statue_comptabilite;
    this.RH = data.RH;
    this.statue_RH = data.statue_RH;
    this.bureau_detude = data.bureau_detude;
    this.statue_bureau_detude = data.statue_bureau_detude;
    this.mairie = data.mairie;
    this.statue_mairie = data.statue_mairie;
    this.maintenance_pac = data.maintenance_pac;
    this.statue_maintenance_pac = data.statue_maintenance_pac;
    this.depot_stockage = data.depot_stockage;
    this.numero_de_depot = data.numero_de_depot;
    this.depot = data.depot;
    this.super_regie = data.super_regie;
  }

  update(data) {
    this.statue_operationnel = data.statue_operationnel;
    this.statue_commercial = data.statue_commercial;
    this.statue_marketing = data.statue_marketing;
    this.statue_technique = data.statue_technique;
    this.statue_rge = data.statue_rge;
    this.statue_CDG = data.statue_CDG;
    this.statue_administratif_sav = data.statue_administratif_sav;
    this.statue_dsi = data.statue_dsi;
    this.statue_juridique = data.statue_juridique;
    this.statue_alger = data.statue_alger;
    this.statue_maintenance_pac = data.statue_maintenance_pac;
    this.statue_mairie = data.statue_mairie;
    this.statue_bureau_detude = data.statue_bureau_detude;
    this.statue_RH = data.statue_RH;
    this.statue_comptabilite = data.statue_comptabilite;
    this.super_regie = data.super_regie;
  }
  static create(data) {
    return new FraisBddTh(data);
  }
}
