export default class Permission {
  constructor(data) {
    this.name = data.name;
    this.pages = data.pages;
    this.users = data.users;
  }
  update(data) {
    this.name = data.name;
    this.pages = data.pages;
    this.users = data.users;
  }
  static create(data) {
    return new Permission(data);
  }
}
