import axios from 'axios';
import domain from '@/environment';
import SettingSocietefilialeSci from '../../models/SocieteFilialeSci';
const state = {
  loadingSettingSocietefilialeSci: false,
  errorSettingSocietefilialeSci: null,
  SettingSocietefilialeSci: [],
  sciTypeSci: []
};
const getters = {
  getSettingSocietefilialeSci: state => state.SettingSocietefilialeSci,
  getLoadingSettingSocieteFilialeSci: state =>
    state.loadingSettingSocietefilialeSci,
  getErrorSettingSocieteFilialeSci: state =>
    state.errorSettingSocietefilialeSci,
  getSciTypeSci: state => state.sciTypeSci
};
const mutations = {
  SET_LOADING_SETTING_SOCIETE_FILIALE_SCI(state, payload) {
    state.loadingSettingSocietefilialeSci = payload;
  },
  SET_ERROR_SETTING_SOCIETE_FILIALE_SCI(state, payload) {
    state.errorSettingSocietefilialeSci = payload;
  },
  NEW_SETTING_SOCIETE_FILIALE_SCI(state, payload) {
    state.SettingSocietefilialeSci.unshift(
      SettingSocietefilialeSci.create(payload)
    );
  },
  UPDATE_SETTING_SOCIETE_FILIALE_SCI(state, payload) {
    payload.SettingSocietefilialeSci.update(payload.response);
  },
  SET_SETTING_SOCIETE_FILIALE_SCI(state, payload) {
    state.SettingSocietefilialeSci = payload.map(item =>
      SettingSocietefilialeSci.create(item)
    );
  },
  DELETE_SETTING_SOCIETE_FILIALE_SCI(state, payload) {
    for (
      let index = 0;
      index < state.SettingSocietefilialeSci.length;
      index++
    ) {
      if (state.SettingSocietefilialeSci[index].id == payload.id) {
        state.SettingSocietefilialeSci.splice(index, 1);
      }
    }
  },
  SET_SCI_TYPE_SCI(state, payload) {
    state.sciTypeSci = payload;
  }
};
const actions = {
  async addSettingSocietefilialeSci({ commit }, payload) {
    const params = new FormData();
    params.append('name', payload.name);
    if (payload?.type != null) {
      params.append('type', payload.type);
    }
    if (payload.civilite && payload.civilite != null) {
      params.append('civilite', payload.civilite);
    }
    if (payload?.image != null) {
      params.append('logo', payload.image);
    }
    if (payload.siret && payload.siret != null) {
      params.append('siret', payload.siret);
    }
    if (payload?.abreviation != null) {
      params.append('abreviation', payload.abreviation);
    }
    if (payload?.sci_id != null) {
      params.append('sci_id', payload.sci_id);
    }
    if (payload?.siren_value != null) {
      params.append('siren_value', payload.siren_value);
    }
    if (payload?.tva_value != null) {
      params.append('tva_value', payload.tva_value);
    }
    if (payload.iban && payload.iban != null) {
      params.append('iban', payload.iban);
    }
    if (payload.code_postal && payload.code_postal != null) {
      params.append('code_postal', payload.code_postal);
    }
    if (payload.ville && payload.ville != null) {
      params.append('ville', payload.ville);
    }
    if (payload.pays && payload.pays != null) {
      params.append('pays', payload.pays);
    }
    if (payload.fax && payload.fax != null) {
      params.append('fax', payload.fax);
    }
    if (payload.telephone && payload.telephone != null) {
      params.append('telephone', payload.telephone);
    }
    if (payload.description && payload.description != null) {
      params.append('description', payload.description);
    }
    if (payload.filiale_id && payload.filiale_id != null) {
      params.append('filiale_id', payload.filiale_id);
    }
    if (payload.adresse == null) {
      params.append('adresse', '');
    } else {
      params.append('adresse', payload.adresse);
    }
    if (payload.email == null) {
      params.append('email', '');
    } else {
      params.append('email', payload.email);
    }
    try {
      const response = await axios.post(
        domain + `/api/filiale-sci/create_societe-filiale-sci`,
        params,

        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
      commit('NEW_SETTING_SOCIETE_FILIALE_SCI', response.data.data);
      return {
        success: true
      };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return {
          success: false,
          error: data
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async getAllSettingSocieteFilialeSci({ commit }, payload) {
    const params = {};
    params.filiale_id = payload.filiale_id;
    if (payload?.type != null) {
      params.type = payload.type;
    }
    commit('SET_ERROR_SETTING_SOCIETE_FILIALE_SCI', null);
    commit('SET_LOADING_SETTING_SOCIETE_FILIALE_SCI', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale-sci/all_societe-filiale-sci`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_SETTING_SOCIETE_FILIALE_SCI', response.data.data);
      commit('SET_LOADING_SETTING_SOCIETE_FILIALE_SCI', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_SOCIETE_FILIALE_SCI', error.response.data);
      } else {
        commit(
          'SET_ERROR_SETTING_SOCIETE_FILIALE_SCI',
          'Une erreur est survenue'
        );
      }
      commit('SET_LOADING_SETTING_SOCIETE_FILIALE_SCI', false);

      return false;
    }
  },
  async deleteSettingSocieteFilialeSci({ commit }, payload) {
    commit('SET_ERROR_SETTING_SOCIETE_FILIALE_SCI', null);

    commit('SET_LOADING_SETTING_SOCIETE_FILIALE_SCI', true);
    try {
      const response = await axios.get(
        domain + `/api/filiale-sci/delete_societe-filiale-sci/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DELETE_SETTING_SOCIETE_FILIALE_SCI', payload);
      commit('SET_LOADING_SETTING_SOCIETE_FILIALE_SCI', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_SOCIETE_FILIALE_SCI', error.response.data);
      } else {
        commit(
          'SET_ERROR_SETTING_SOCIETE_FILIALE_SCI',
          'Une erreur est survenue'
        );
      }
      commit('SET_LOADING_SETTING_SOCIETE_FILIALE_SCI', false);

      return false;
    }
  },
  async updateSettingSocieteFilialeSci({ commit }, payload) {
    const params = new FormData();
    if (payload?.filiale.type != null) {
      params.append('name', payload.filiale.name);
    }
    if (payload?.filiale?.type != null) {
      params.append('type', payload.filiale.type);
    }
    if (payload?.filiale?.image != null) {
      params.append('logo', payload.filiale.image);
    }
    if (payload.filiale.civilite && payload.filiale.civilite != null) {
      params.append('civilite', payload.filiale.civilite);
    }
    if (payload.filiale.siret && payload.filiale.siret != null) {
      params.append('siret', payload.filiale.siret);
    }
    if (payload?.filiale?.abreviation != null) {
      params.append('abreviation', payload.filiale.abreviation);
    }
    if (payload?.filiale?.siren_value != null) {
      params.append('siren_value', payload.filiale.siren_value);
    }
    if (payload?.filiale?.tva_value != null) {
      params.append('tva_value', payload.filiale.tva_value);
    }
    if (payload?.filiale?.sci_id != null) {
      params.append('sci_id', payload.filiale.sci_id);
    }
    if (payload.filiale.iban && payload.filiale.iban != null) {
      params.append('iban', payload.filiale.iban);
    }

    if (payload.filiale.code_postal && payload.filiale.code_postal != null) {
      params.append('code_postal', payload.filiale.code_postal);
    }
    if (payload.filiale.ville && payload.filiale.ville != null) {
      params.append('ville', payload.filiale.ville);
    }
    if (payload.filiale.pays && payload.filiale.pays != null) {
      params.append('pays', payload.filiale.pays);
    }
    if (payload.filiale.fax && payload.filiale.fax != null) {
      params.append('fax', payload.filiale.fax);
    }
    if (payload.filiale.telephone && payload.filiale.telephone != null) {
      params.append('telephone', payload.filiale.telephone);
    }
    if (payload.filiale.description && payload.filiale.description != null) {
      params.append('description', payload.filiale.description);
    }
    if (payload.filiale.filiale_id && payload.filiale.filiale_id != null) {
      params.append('filiale_id', payload.filiale.filiale_id);
    }
    if (payload.filiale.adresse == null) {
      params.append('adresse', '');
    } else {
      params.append('adresse', payload.filiale.adresse);
    }
    if (payload.filiale.email == null) {
      params.append('email', '');
    } else {
      params.append('email', payload.filiale.email);
    }
    try {
      const response = await axios.post(
        domain +
          `/api/filiale-sci/update_societe-filiale-sci/${payload.filiale.id}`,
        params,

        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
      commit('UPDATE_SETTING_SOCIETE_FILIALE_SCI', {
        response: response.data.data,
        SettingSocietefilialeSci: payload.filiale_origin
      });
      return {
        success: true
      };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error.response.data.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return {
          success: false,
          error: data
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async getAllSciTypeSci({ commit }, payload) {
    commit('SET_ERROR_SETTING_SOCIETE_FILIALE_SCI', null);
    commit('SET_LOADING_SETTING_SOCIETE_FILIALE_SCI', true);
    try {
      const response = await axios.get(domain + `/api/filiale-sci/get_sci`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_SCI_TYPE_SCI', response.data.data);
      commit('SET_LOADING_SETTING_SOCIETE_FILIALE_SCI', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_SETTING_SOCIETE_FILIALE_SCI', error.response.data);
      } else {
        commit(
          'SET_ERROR_SETTING_SOCIETE_FILIALE_SCI',
          'Une erreur est survenue'
        );
      }
      commit('SET_LOADING_SETTING_SOCIETE_FILIALE_SCI', false);

      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
