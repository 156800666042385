import axios from 'axios';
import domain from '@/environment';
import Organisme from '../../models/Organisme';
const state = {
  organisations: [],
  organisationsConfig: [],
  TotalOrganisation: null,
  totalRowOrganisme: null,
  totalRowOrganismes: null,
  loagingOrganisme: false,
  errorOrganisme: null,
  Allorganisations: []
};
const getters = {
  getTotalRowOrganisme: state => state.totalRowOrganisme,
  getTotalRowOrganismes: state => state.totalRowOrganismes,
  getOrganisations: state => state.organisations,
  getorganisationsConfig: state => state.organisationsConfig,
  getTotalOrganisation: state => state.TotalOrganisation,
  getloagingOrganisme: state => state.loagingOrganisme,
  getErrorOrganisme: state => state.errorOrganisme,
  getAllorganisations: state => state.Allorganisations
};
const mutations = {
  SET_TOTAL_ORGANISME(state, payload) {
    state.totalRowOrganisme = payload;
  },
  SET_TOTAL_ORGANISMES(state, payload) {
    state.totalRowOrganismes = payload;
  },
  SET_TOTAL_ORGANISATION(state, payload) {
    state.TotalOrganisation = payload;
  },
  SET_LOADING_ORGANISME(state, payload) {
    state.loagingOrganisme = payload;
  },
  SET_ORGANISATION(state, payload) {
    state.organisations = payload.map(item => Organisme.create(item));
  },
  SET_ORGANISATION_LIST(state, payload) {
    state.Allorganisations = payload;
  },
  SET_ORGANISATION_CONFIG(state, payload) {
    state.organisationsConfig = payload.map(item => Organisme.create(item));
  },
  NEW_ORGANISME(state, payload) {
    state.organisations.push(Organisme.create(payload));
  },
  UPDATE_ORGANISME(state, payload) {
    for (let index = 0; index < state.organisations.length; index++) {
      if (state.organisations[index].id == payload.organisation.id) {
        state.organisations[index].update(payload.response);
        break;
      }
    }
  },
  DLETE_ORGANISME(state, payload) {
    for (let index = 0; index < state.organisations.length; index++) {
      if (state.organisations[index].id == payload.id) {
        state.organisations.splice(index, 1);
        break;
      }
    }
  },
  UPDATE_NEW_CONFIG(state, payload) {
    var existe = false;
    for (let index = 0; index < state.organisationsConfig.length; index++) {
      if (state.organisationsConfig[index].thisID == payload.thisID) {
        existe = true;
        state.organisationsConfig[index].update(payload);
        break;
      }
    }
    if (existe == false) {
      state.organisationsConfig.push(Organisme.create(payload));
    }
  },
  SET_ERROR_ORGANISME(state, payload) {
    state.errorOrganisme = payload;
  }
};
const actions = {
  async fetchAllOrganisme({ commit }, payload) {
    commit('SET_LOADING', true);

    const body = { page: payload.page, annee: payload.annee.toString() };
    try {
      const response = await axios.get(
        domain + `/api/getAllOrganisme/paginated`,
        {
          params: body,
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_ORGANISATION', response.data.organisme.data);
      commit('SET_TOTAL_ORGANISMES', response.data.organisme.total);
      commit('SET_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      return false;
    }
  },
  async getAllOrganisme({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await axios.get(domain + `/api/getAllOrganisme/All`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ORGANISATION_LIST', response.data.organisme);
      commit('SET_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      return false;
    }
  },
  async fetchOrganismeFiltred({ commit }, payload) {
    commit('SET_LOADING', true);
    const body = { page: payload.page, annee: payload.annee.toString() };
    if (payload.id != null && payload.semaine != null) {
      body.id = payload.id;
      body.semaine = payload.semaine;
    } else if (payload.id != null && payload.semaine == null) {
      body.id = payload.id;
    } else if (payload.id == null && payload.semaine != null) {
      body.semaine = payload.semaine;
    }

    try {
      const response = await axios.post(
        domain + `/api/getOrganimseFiltred`,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_ORGANISATION_CONFIG', response.data.organisme.data);
      commit('SET_TOTAL_ORGANISME', response.data.organisme.total);
      commit('SET_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_LOADING', false);
      return false;
    }
  },
  async addOrganisme({ commit }, payload) {
    commit('SET_LOADING_ORGANISME', true);
    commit('SET_ERROR_ORGANISME', null);
    try {
      const response = await axios.post(domain + `/api/addOrganisme`, payload, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('NEW_ORGANISME', response.data.organism);
      commit('SET_LOADING_ORGANISME', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_ORGANISME', error.response.data.errors.name[0]);
      commit('SET_LOADING_ORGANISME', false);
      return false;
    }
  },
  async updateOrganismes({ commit }, payload) {
    commit('SET_LOADING_ORGANISME', true);
    commit('SET_ERROR_ORGANISME', null);
    const body = {
      annee: payload.annee.toString(),
      id: payload.OrganismeToUpdate.id,
      name: payload.OrganismeToUpdate.name,
      prix: payload.OrganismeToUpdate.prix
    };
    try {
      const response = await axios.post(domain + `/api/editOrganisme`, body, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('UPDATE_ORGANISME', {
        response: response.data.organisme,
        organisation: payload.OrganismeToUpdate
      });
      commit('SET_LOADING_ORGANISME', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_ORGANISME', error.response.data.errors.name[0]);
      commit('SET_LOADING_ORGANISME', false);
      return false;
    }
  },
  async deleteOrganismes({ commit }, payload) {
    commit('SET_LOADING_ORGANISME', true);
    commit('SET_ERROR_ORGANISME', null);
    try {
      const response = await axios.post(
        domain + `/api/deleteOrganisme`,
        {
          id: payload.id
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('DLETE_ORGANISME', payload);
      commit('SET_LOADING_ORGANISME', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_ORGANISME', error);
      commit('SET_LOADING_ORGANISME', false);
      return false;
    }
  },
  async configOrganismes({ commit }, payload) {
    commit('SET_LOADING_ORGANISME', true);
    commit('SET_ERROR_ORGANISME', null);
    const body = {
      annee: payload.annee.toString(),
      id: payload.organisation.id,
      name: payload.organisation.name,
      prix: payload.organisation.prix,
      semaine: payload.organisation.semaine
    };
    try {
      const response = await axios.post(domain + `/api/editOrganisme`, body, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('UPDATE_NEW_CONFIG', response.data.organisme);
      commit('SET_LOADING_ORGANISME', false);
      return true;
    } catch (error) {
      commit('SET_ERROR_ORGANISME', error);
      commit('SET_LOADING_ORGANISME', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
