import axios from 'axios';
import domain from '@/environment';
import SalaryTh from '../../models/SalaryTh';
const prefixbillEmployeeTH = 'billEmployeeTH';
const state = {
  chargementPaieTh: false,
  SalaryTableValidationTh: [],
  totalRowSalaryTh: null,
  onlineTypeEmloyeesTh: [],
  activeEmployyesTh: [],
  //params employee independant th
  chargementPaieIndependantTh: false,
  SalaryTableValidationIndependantTh: [],
  totalRowSalaryIndependantTh: null,
  errorUploadFactureIndependantTh: null,
  loadingUploadFactureIndependantTh: false
};
const getters = {
  getChargementPaieTh: state => state.chargementPaieTh,
  getTotalRowUserSalaryTh: state => state.totalRowSalaryTh,
  getUserSalaryTh: state => state.SalaryTableValidationTh,
  getOnlineTypeEmloyeesTh: state => state.onlineTypeEmloyeesTh,
  getActiveEmployyesTh: state => state.activeEmployyesTh,
  //independant employee th
  getChargementPaieIndependantTh: state => state.chargementPaieIndependantTh,
  getTotalRowUserSalaryIndependantTh: state =>
    state.totalRowSalaryIndependantTh,
  getUserSalaryIndependantTh: state => state.SalaryTableValidationIndependantTh,
  getErrorUploadFactureIndependantTh: state =>
    state.errorUploadFactureIndependantTh,
  getLoadingUploadFactureIndependantTh: state =>
    state.loadingUploadFactureIndependantTh
};
const mutations = {
  SET_LOADING_PAIE_TH(state, payload) {
    state.chargementPaieTh = payload;
  },
  SALARY_FILTRED_TH(state, payload) {
    state.SalaryTableValidationTh = payload.map(item => SalaryTh.create(item));
  },
  SET_TOTAL_ROW_SALARY_TH(state, payload) {
    state.totalRowSalaryTh = payload;
  },
  UPDATE_ROW_SALARY_TH(state, payload) {
    payload.row.update(payload.response);
  },
  SET_ONLINE_TYPE_TH(state, payload) {
    state.onlineTypeEmloyeesTh = payload;
  },
  ALL_ACTIVE_EMPLOYEE_BY_TYPE_TH(state, payload) {
    state.activeEmployyesTh = payload;
  },
  SET_PRIME_TH(state, payload) {
    payload.row.update(payload.responce);
  },
  //Setters employee indepandant
  SET_LOADING_PAIE_INDEPENDANT_TH(state, payload) {
    state.chargementPaieIndependantTh = payload;
  },
  SALARY_FILTRED_INDEPENDANT_TH(state, payload) {
    state.SalaryTableValidationIndependantTh = payload.map(item =>
      SalaryTh.create(item)
    );
  },
  SET_TOTAL_ROW_SALARY_INDEPENDANT_TH(state, payload) {
    state.totalRowSalaryIndependantTh = payload;
  },
  SET_ERROR_UPLOAD_FACTURE_INDEPENDANT_TH(state, payload) {
    state.errorUploadFactureIndependantTh = payload;
  },
  SET_LOADING_UPLOAD_FACTURE_AAF_INDEPENDANT_TH(state, payload) {
    state.loadingUploadFactureIndependantTh = payload;
  }
};
const actions = {
  async getSalaryFiltredTh({ commit }, payload) {
    commit('SET_LOADING_PAIE_TH', true);
    try {
      const params = {
        page: payload.page,
        per_page: payload.perPage,
        year: payload.year,
        facture_type: 'salarie'
      };
      if (payload.month != null) {
        params.month = payload.month;
      }
      if (payload.depot != null) {
        params.depot = payload.depot;
      }
      if (
        payload.employee_id &&
        payload.employee_id.id &&
        payload.employee_id.id != null
      ) {
        params.employee_id = payload.employee_id.id;
      }
      if (payload.validation != null) {
        params.statue = payload.validation;
      }
      if (payload.type !== null) {
        params.type = payload.type;
      }

      const response = await axios.get(
        domain + `/api/${prefixbillEmployeeTH}/all`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SALARY_FILTRED_TH', response.data.data.data);
      commit('SET_TOTAL_ROW_SALARY_TH', response.data.data.total);
      commit('SET_LOADING_PAIE_TH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_PAIE_TH', false);
      return false;
    }
  },
  async getOnlineSalaryEmployeeBytypeTh(
    { commit },
    { year, type, salarie, month }
  ) {
    const params = { year: year };
    if (type !== null) {
      params.type = type;
    }
    if (month !== null) {
      params.month = month;
    }
    if (salarie) {
      params.salarie = salarie;
    }
    try {
      const responce = await axios.get(
        domain + `/api/${prefixbillEmployeeTH}/online_employee_by_type`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('ALL_ACTIVE_EMPLOYEE_BY_TYPE_TH', responce.data.employees);
      return true;
    } catch (error) {
      return false;
    }
  },
  async validationSalaryTh({ commit }, payload) {
    commit('SET_LOADING_PAIE_TH', true);
    const body = {
      id: payload.item ? payload.item.id : payload.row.id,
      column: payload.column,
      value: payload.value
    };
    try {
      const response = await axios.post(
        domain + `/api/${prefixbillEmployeeTH}/update`,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      let obj = {
        response: response.data.data,
        row: payload.item ? payload.item : payload.row
      };

      commit('UPDATE_ROW_SALARY_TH', obj);
      commit('SET_LOADING_PAIE_TH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_PAIE_TH', false);
      return false;
    }
  },
  async newCommentTh({ commit }, payload) {
    const body = {
      id: payload.id,
      column: 'comment',
      value: payload.comment == '\n' ? 'null' : payload.comment
    };
    try {
      const response = await axios.post(
        domain + `/api/${prefixbillEmployeeTH}/update`,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: response.data.data,
        row: payload.row
      };
      commit('UPDATE_ROW_SALARY_TH', obj);
      return true;
    } catch (error) {
      return false;
    }
  },
  async downloadAAFCommerciauxIndependantPDF({ commit }, payload) {
    const params = {};
    if (payload.project_type && payload.project_type != null) {
      params.project_type = payload.project_type;
    }
    if (Array.isArray(payload.item.id)) {
      params.bill_id = payload.item.id;
    } else {
      params.bill_id = [];
      params.bill_id.push(payload.item.id);
    }
    try {
      const response = await axios.get(
        domain + `/api/downloadAllBill/${payload.year}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params,
          responseType: 'blob'
        }
      );
      var words = response.headers['cache-control'].split('.pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words[0] + '.pdf');
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      return false;
    }
  },
  async getOnlineTypeTh({ commit }, { year, salarie, month }) {
    const params = { year: year };
    if (salarie) {
      params.salarie = salarie;
    }
    if (month != null) {
      params.month = month;
    }

    try {
      const responce = await axios.get(
        domain + `/api/${prefixbillEmployeeTH}/online_types`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SET_ONLINE_TYPE_TH', responce.data.type);
      return true;
    } catch (error) {
      return false;
    }
  },
  async updatePrimeTh({ commit }, payload) {
    const params = {
      id: payload.row.id,
      column: payload.column,
      value: parseFloat((payload.value + '').replace(',', '.'))
    };
    try {
      const responce = await axios.post(
        domain + `/api/${prefixbillEmployeeTH}/update`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_PRIME_TH', {
        responce: responce.data.data,
        row: payload.row
      });
      return true;
    } catch (error) {
      return false;
    }
  },
  async getSalaryIndependantFiltredTh({ commit }, payload) {
    commit('SET_LOADING_PAIE_INDEPENDANT_TH', true);
    try {
      const params = {
        page: payload.page,
        per_page: payload.perPage,
        year: payload.year,
        facture_type: 'independant'
      };
      if (payload.month != null) {
        params.month = payload.month;
      }
      if (payload.depot != null) {
        params.depot = payload.depot;
      }
      if (
        payload.employee_id &&
        payload.employee_id.id &&
        payload.employee_id.id != null
      ) {
        params.employee_id = payload.employee_id.id;
      }
      if (payload.validation != null) {
        params.statue = payload.validation;
      }
      if (payload.type !== null) {
        params.type = payload.type;
      }

      const response = await axios.get(
        domain + `/api/${prefixbillEmployeeTH}/all`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          params: params
        }
      );
      commit('SALARY_FILTRED_INDEPENDANT_TH', response.data.data.data);
      commit('SET_TOTAL_ROW_SALARY_INDEPENDANT_TH', response.data.data.total);
      commit('SET_LOADING_PAIE_INDEPENDANT_TH', false);
      return true;
    } catch (error) {
      commit('SET_LOADING_PAIE_INDEPENDANT_TH', false);
      return false;
    }
  },
  async downloadPDFIndependantUploadedTh({ commit }, payload) {
    commit('SET_LOADING_PAIE_INDEPENDANT_TH', true);

    const params = { id: payload.item.file.id };

    try {
      const response = await axios.post(
        domain + `/api/${prefixbillEmployeeTH}/admin_download_uploaded_facture`,
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          responseType: 'blob'
        }
      );
      var words = 'Facture de ' + payload.item.employee;
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_PAIE_INDEPENDANT_TH', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_PAIE_INDEPENDANT_TH', false);

      return false;
    }
  },
  async uploadFactureAafIndependantTh({ commit }, payload) {
    commit('SET_ERROR_UPLOAD_FACTURE_INDEPENDANT_TH', null);
    commit('SET_LOADING_UPLOAD_FACTURE_AAF_INDEPENDANT_TH', true);
    try {
      var bodyFormData = new FormData();
      bodyFormData.append('file', payload.file);
      bodyFormData.append('bill_id', payload.id);
      const responce = await axios.post(
        domain + `/api/${prefixbillEmployeeTH}/upload_facture`,
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const obj = {
        response: responce.data.data,
        row: payload
      };
      commit('UPDATE_ROW_SALARY_TH', obj);
      commit('SET_LOADING_UPLOAD_FACTURE_AAF_INDEPENDANT_TH', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.file
      ) {
        commit(
          'SET_ERROR_UPLOAD_FACTURE_INDEPENDANT_TH',
          error.response.data.errors.file
        );
      }
      commit('SET_LOADING_UPLOAD_FACTURE_AAF_INDEPENDANT_TH', false);
      return false;
    }
  },
  async downloadSalaryValidationPDFTh({ commit }, payload) {
    commit('SET_LOADING_PAIE_INDEPENDANT_TH', true);

    const params = { id: payload.item.id };
    if (payload.type && payload.type != null) {
      params.type = payload.type;
    }
    if (payload.depot && payload.depot != null) {
      params.depot = payload.depot;
    }

    try {
      const response = await axios.get(
        domain + `/api/${prefixbillEmployeeTH}/export`,

        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          },
          responseType: 'blob',
          params: params
        }
      );
      var words = 'Facture de ' + payload.item.employee;
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', words + '.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_LOADING_PAIE_INDEPENDANT_TH', false);

      return true;
    } catch (error) {
      commit('SET_LOADING_PAIE_INDEPENDANT_TH', false);

      return false;
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
