import fraisBddInterneIhsProjectsTh from './fraisBddInterneIhsProjectsTh';
export default class fraisBddInterneIhsTh {
  constructor(data) {
    this.name = data.name;
    this.interne = data.interne;
    this.commercial = data.commercial;
    this.ihs = data.ihs;
    this.check = false;
    this.projects = data.projects;
  }

  update(data) {
    this.projects = data.projects.map(item =>
      fraisBddInterneIhsProjectsTh.create(item)
    );
  }
  static create(data) {
    return new fraisBddInterneIhsTh(data);
  }
}
