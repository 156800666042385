export default class GestionConfiguration {
  constructor(data) {
    this.id = data.id;
    this.filiale_id = data.filiale_id;
    this.filiale_name = data.filiale_name;
    this.acheteur_id = data.acheteur_id;
    this.tva = data.tva;
    this.coef = data.coef;
    this.coef_type = data.coef_type;
    this.label = data.label;
    this.condition_types = data.condition_types;
    this.formule_types = data.formule_types;
    this.reduction = data.reduction;
    this.reduction_type = data.reduction_type;
    this.date_debut = data.date_debut;
    this.date_fin = data.date_fin;
    this.conditions = data.conditions;
    this.specifics = data.specifics;
    this.coefs = data.coefs;
    this.formules = data.formules;
  }
  update(data) {
    this.filiale_id = data.filiale_id;
    this.filiale_name = data.filiale_name;
    this.acheteur_id = data.acheteur_id;
    this.tva = data.tva;
    this.coef = data.coef;
    this.coef_type = data.coef_type;
    this.label = data.label;
    this.condition_types = data.condition_types;
    this.formule_types = data.formule_types;
    this.reduction = data.reduction;
    this.reduction_type = data.reduction_type;
    this.date_debut = data.date_debut;
    this.date_fin = data.date_fin;
    this.conditions = data.conditions;
    this.specifics = data.specifics;
    this.coefs = data.coefs;
    this.formules = data.formules;
  }
  static create(data) {
    return new GestionConfiguration(data);
  }
}
