import axios from 'axios';
import domain from '@/environment';
import calender from '../../models/calender';
const state = {
  loadingCalender: false,
  errorCalender: null,
  calenders: []
};
const getters = {
  getCalenders: state => state.calenders,
  getLoadingCalender: state => state.loadingCalender,
  getErrorCalender: state => state.errorCalender
};
const mutations = {
  SET_LOADING_CALENDER(state, payload) {
    state.loadingCalender = payload;
  },
  SET_ERROR_CALANDER(state, payload) {
    state.errorCalender = payload;
  },
  NEW_CALENDER(state, payload) {
    state.calenders = calender.create(payload);
  },
  SET_CALENDER(state, payload) {
    state.calenders = calender.create(payload);
  },
  SET_ORDER_WEEK(state, payload) {
    state.calenders.update(payload.response);
  }
};
const actions = {
  async AddCalendrier({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/api/create_month`,
        { year: payload },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('NEW_CALENDER', response.data.data);
      return {
        success: true
      };
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        return {
          success: false,
          error: error.response.data.error
        };
      } else {
        return {
          success: false,
          error: 'Une erreur est survenue'
        };
      }
    }
  },
  async getCallender({ commit }, payload) {
    commit('SET_ERROR_CALANDER', null);

    commit('SET_LOADING_CALENDER', true);
    try {
      const response = await axios.get(
        domain + `/api/monthResponse/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_CALENDER', response.data);
      commit('SET_LOADING_CALENDER', false);
      return true;
    } catch (error) {
      if (error && error.response && error.response.data) {
        commit('SET_ERROR_CALANDER', error.response.data);
      } else {
        commit('SET_ERROR_CALANDER', 'Une erreur est survenue');
      }
      commit('SET_LOADING_CALENDER', false);

      return false;
    }
  },
  async DragWeek({ commit }, payload) {
    commit('SET_ERROR_CALANDER', null);

    const params = {
      semaine: payload.week,
      year: payload.year,
      move_to: payload.type
    };
    commit('SET_LOADING_CALENDER', true);
    try {
      const response = await axios.post(domain + `/api/edit_month`, params, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ORDER_WEEK', { response: response.data.data, payload });
      commit('SET_LOADING_CALENDER', false);
      return true;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      ) {
        commit('SET_ERROR_CALANDER', error.response.data.errors);
      } else {
        commit('SET_ERROR_CALANDER', 'Une erreur est survenue');
      }
      commit('SET_LOADING_CALENDER', false);
      return false;
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};
