import axios from 'axios';
import domain from '@/environment';
import factureLibreTh from '../../models/factureLibreTh';

const state = {
  facturesLibre: [],
  factureLibreLoading: false,
  factureLibreErrores: null,
  factureLibreTotalRows: 0,
  PaystList: [],
  factureLibreToUpdate: null,
  listFactureForAvoir: [],
  filialeOfResponsable: [],
  allUnites: [],
  allTvas: [],
  allCategorie: [],
  loadingMailFactureLibre: false,
  errorMailingFactureLibre: [],
  successSendMailsFactureLibre: {},
  otherErrorMailFacturelibre: null,
  errorCreationFactureLibre: null
};
const getters = {
  getFacturesLibresTh: state => state.facturesLibre,
  getFactureLibreLoadingTh: state => state.factureLibreLoading,
  getFactureLibreErroresTh: state => state.factureLibreErrores,
  getFactureLibreTotalRowsTh: state => state.factureLibreTotalRows,
  getPaysList: state => state.PaysList,
  getFactureLibreToUpdate: state => state.factureLibreToUpdate,
  getListFactureForAvoir: state => state.listFactureForAvoir,
  getFiliaeOfResponsable: state => state.filialeOfResponsable,
  getAllUnites: state => state.allUnites,
  getAllTvas: state => state.allTvas,
  getAllCategories: state => state.allCategorie,
  getLoadingMailFactureLibre: state => state.loadingMailFactureLibre,
  getErrorMailingFactureLibre: state => state.errorMailingFactureLibre,
  getSuccessSendMailsFactureLibre: state => state.successSendMailsFactureLibre,
  getOtherErrorMailFactureLibre: state => state.otherErrorMailFacturelibre,
  getErrorCreationFactureLibre: state => state.errorCreationFactureLibre
};
const mutations = {
  ADD_NEW_FACTURE_LIBRE(state, payload) {
    state.facturesLibre.unshift(factureLibreTh.create(payload));
  },
  SET_FACTURE_LIBRE_ERROR(state, payloade) {
    state.factureLibreErrores = payloade;
  },
  SET_FACTURE_LIBRE_IS_LOADING(state, payload) {
    state.factureLibreLoading = payload;
  },
  SET_ALL_FACTURE_LIBRES(state, payload) {
    state.facturesLibre = payload?.map(item => factureLibreTh.create(item));
  },
  SET_TOTAL_ROWS_FACTURE_LIBRES(state, payload) {
    state.factureLibreTotalRows = payload;
  },
  SET_ALL_CONTRIES(state, payload) {
    state.PaysList = payload;
  },
  UPDATE_STATUS_FACTURE_LIBRE(satet, payload) {
    payload.payload.updateStatue(payload.response);
  },
  // SET_FACTURE_CONFIRMED_STATUE(satet, payload) {
  //   payload.map(item => item.updateStatueConfirmation());
  // },
  SET_ONE_FACTURE_LIBRE(state, payload) {
    state.factureLibreToUpdate = payload;
  },
  SET_ALL_FACTURES_AVOIR(state, payload) {
    state.listFactureForAvoir = payload;
  },
  SET_FILIALS_OF_RESPONSABLE(state, payload) {
    state.filialeOfResponsable = payload;
  },
  SET_ALL_UNITES(state, payload) {
    state.allUnites = payload;
  },
  SET_ALL_TVA(state, payload) {
    state.allTvas = payload;
  },
  SET_ALL_CATEGORIES_FACTURE_LIBRE(state, payload) {
    state.allCategorie = payload;
  },
  SET_MAIL_LODING_FACTURE_LIBRE(state, payload) {
    state.loadingMailFactureLibre = payload;
  },
  SET_ERROR_MAILING_FACTURE_LIBRE(state, payload) {
    state.errorMailingFactureLibre = payload;
  },
  SET_SUCCESS_SEND_MAILS_FACTURE_LIBRE(state, payload) {
    state.successSendMailsFactureLibre = payload;
  },
  SET_OTHER_ERROR_FACTURE_LIBRE(state, payload) {
    state.otherErrorMailFacturelibre = payload;
  },
  SET_ERROR_CREATION_FACTURE_LIBRE(state, payload) {
    state.errorCreationFactureLibre = payload;
  }
};
const actions = {
  async getAllErrorsCreationFactureLibre({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/libre/treat_errors`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true };
    } catch (error) {
      return { success: false, error: error.response.data.error };
    }
  },
  async addNewFactureLibreTh({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/libre/facture`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.errors) {
        let data = [];
        for (var item in error?.response?.data?.errors) {
          if (error.response.data.errors[item].length)
            data.push(...error.response.data.errors[item]);
        }
        return { success: false, error: data };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async displayFacture({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    try {
      const response = await axios.post(
        `${domain}/api/libre/generate`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.errors) {
        return { success: false, error: data };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async fetchAllFacturesLibres({ commit }, payload) {
    var params = { page: payload.page, per_page: payload.per_page };
    if (payload.num != null) {
      params.num = payload.num;
    }
    for (let i = 0; i < payload?.type?.length; i++) {
      params['type[' + i + ']'] = payload?.type[i]?.value;
    }
    for (let i = 0; i < payload?.etat?.length; i++) {
      params['etat[' + i + ']'] = payload.etat[i].value;
    }
    for (let i = 0; i < payload?.mode_reglement?.length; i++) {
      params['mode_reglement[' + i + ']'] = payload?.mode_reglement[i]?.value;
    }
    for (let i = 0; i < payload?.vendeur?.length; i++) {
      params['vendeur_id[' + i + ']'] = payload?.vendeur[i]?.value;
    }
    for (let i = 0; i < payload?.categorie_id?.length; i++) {
      params['categorie_id[' + i + ']'] = payload?.categorie_id[i]?.value;
    }
    for (let i = 0; i < payload?.acheteur_id?.length; i++) {
      params['acheteur_id[' + i + ']'] = payload?.acheteur_id[i]?.value;
    }

    if (payload.date_debut != null) {
      params.date_debut = payload.date_debut;
    }
    if (payload.date_fin != null) {
      params.date_fin = payload.date_fin;
    }
    if (payload.column != null) {
      params.column = payload.column;
    }
    if (payload.order != null) {
      params.order = payload.order;
    }
    if (payload.objet != null) {
      params.objet = payload.objet;
    }
    if (payload.famille != null) {
      params.famille = payload.famille;
    }
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/factures`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        },
        params: params
      });
      commit('SET_ALL_FACTURE_LIBRES', response.data.data.data);
      commit('SET_TOTAL_ROWS_FACTURE_LIBRES', response.data.data.total);
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async getFactureLibreNumber({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(
        `${domain}/api/libre/numero?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      if (error?.response?.data?.errors) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.errors);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return { success: false };
    }
  },
  async getAllCountreies({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/pays`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_CONTRIES', response.data);
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, error: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async updatePaymentDate({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.put(`${domain}/api/libre/etat`, payload, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('UPDATE_STATUS_FACTURE_LIBRE', {
        payload: payload,
        response: response.data.data
      });
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);

      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return { success: false };
    }
  },
  async downloadPDF({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/downloadallpdf`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'Facture CRM CDG.pdf');
      document.body.appendChild(link);
      link.click();
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async downloadZIP({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/downloadzip`,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'Facture CRM CDG.zip');
      document.body.appendChild(link);
      link.click();
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async confirmedBills({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/confirme`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async transformProformatToFacture({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/transform_proforma`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async downloadOnePDF({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/downloadpdf?id=${payload.id}`,
        {},
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', `${payload.num}.pdf`);
      document.body.appendChild(link);
      link.click();
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_ERROR', error?.response?.data?.error);
      } else {
        commit('SET_FACTURE_LIBRE_ERROR', 'Une erreur est survenue');
      }
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async getOneFacturesLibres({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(
        `${domain}/api/libre/facture/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_ONE_FACTURE_LIBRE', response.data.data);
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, error: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, error: error?.response?.data?.error };
      }
    }
  },
  async updateFactureLibre({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.put(
        `${domain}/api/libre/facture`,
        payload.factureLibre,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_ONE_FACTURE_LIBRE', {
        response: response.data.data,
        factureLibreOrigin: payload.factureLibreOrigin
      });
      return { success: true, response: response.data.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },

  async getAllFacturesForAvoir({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/allfactures`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_FACTURES_AVOIR', response.data.data);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async getFilialsOfConnectedResponsable({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/vendeur`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_FILIALS_OF_RESPONSABLE', response.data.data);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async addUniteTva({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/libre/unite_tva`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        return { success: false, response: error?.response?.data?.error };
      } else {
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async addCategorie({ commit }, payload) {
    try {
      const response = await axios.post(
        `${domain}/api/libre/categorie/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      if (error?.response?.data?.error) {
        return {
          success: false,
          error: error?.response?.data?.error
        };
      } else {
        return { success: false, error: 'Une erreur est survenue' };
      }
    }
  },
  async getUnites({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/unite`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_UNITES', response.data);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async getTvas({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/tva`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_TVA', response.data);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async getCategories({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.get(`${domain}/api/libre/categorie_all`, {
        headers: {
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      commit('SET_ALL_CATEGORIES_FACTURE_LIBRE', response.data);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async CreateBillAvoirGlobal({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_ERROR', null);
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(
        `${domain}/api/libre/avoir_globale`,
        { id: payload.id },
        {
          headers: {
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      // commit('SET_ALL_TVA', response.data);
      return { success: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      } else {
        commit('SET_FACTURE_LIBRE_IS_LOADING', false);
        return { success: false, response: error?.response?.data?.error };
      }
    }
  },
  async exportExcelFactureLibre({ commit }, payload) {
    commit('SET_FACTURE_LIBRE_IS_LOADING', true);
    try {
      const response = await axios.post(domain + `/api/libre/export`, payload, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.state.User.access_token}`
        }
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'Facture Libre' + '.xlsx');
      document.body.appendChild(link);
      link.click();
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return true;
    } catch (error) {
      commit('SET_FACTURE_LIBRE_IS_LOADING', false);
      return false;
    }
  },
  async sendMailFactureLibre({ commit }, payload) {
    commit('SET_MAIL_LODING_FACTURE_LIBRE', true);
    try {
      const response = await axios.post(
        domain + `/api/libre/envoiMails`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.User.access_token}`
          }
        }
      );
      commit('SET_MAIL_LODING_FACTURE_LIBRE', false);
      commit('SET_ERROR_MAILING_FACTURE_LIBRE', response.data.error);
      commit('SET_SUCCESS_SEND_MAILS_FACTURE_LIBRE', response.data.success);
      return { succes: true };
    } catch (error) {
      if (error?.response?.data?.error) {
        commit('SET_OTHER_ERROR_FACTURE_LIBRE', error?.response?.data?.error);
      } else {
        commit('SET_OTHER_ERROR_FACTURE_LIBRE', 'Une erreur est servenue');
      }
      commit('SET_MAIL_LODING_FACTURE_LIBRE', false);
      return { succes: false };
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
